//////////////////////////////////////////
//		  ooOOOO BOILERPLATE FILE		//
//		 oo		 _____					//
//		_I__n_n__||_|| ________			//
//	  >(_________|_7_|-|______|			//
//	   /o ()() ()() o   oo  oo			//
//////////////////////////////////////////

///////////////////////////////
// Description
///////////////////////////////

	/*
		DESCRIPTION / USAGE:
			// Deploy to GCP
			// https://docs.meilisearch.com/learn/cookbooks/gcp.html

		TODO:
			Prod mode keys
				https://docs.meilisearch.com/learn/security/master_api_keys.html#protecting-a-meilisearch-instance

			Implement on Tables
				probably new search type
				pass in params
					type: "meilisearch"
					resultRenderer: (): JSX => {}
					client_key: string
					search_index: string

	*/


///////////////////////////////
// Imports
///////////////////////////////

import match from 'autosuggest-highlight/match'
import parse from 'autosuggest-highlight/parse'
import MeiliSearch from 'meilisearch'
import {
	useContext,
	useEffect,
	useReducer,
	useState
} from 'react'
import {
	Trans
} from 'react-i18next'
import {
	useNavigate
} from 'react-router-dom'
import {
	themeVariables
} from 'rfbp_aux/config/app_theme'
import {
	searchConfig
} from 'rfbp_aux/config/search_config'
import {
	TsInterface_InputHooksObject
} from 'rfbp_core/components/form/form_types'
import {
	TsType_TableSearchResultRenderer
} from 'rfbp_core/components/table/table_types'
import {
	Context_RootData_ClientKey,
	Context_RootData_ClientPermissions,
	Context_RootData_ClientUser,
	Context_RootData_GlobalUser,
	Context_UserInterface_AlertDialog,
	Context_UserInterface_ConfirmDialog,
	Context_UserInterface_CustomDialog,
	Context_UserInterface_ErrorDialog,
	Context_UserInterface_FormDialog,
	Context_UserInterface_LoadingBar,
	Context_UserInterface_PromptDialog
} from 'rfbp_core/services/context'
import {
	getProp
} from 'rfbp_core/services/helper_functions'
import {
	TsInterface_UnspecifiedObject,
	TsType_Any,
	TsType_JSX,
	TsType_Null,
	TsType_Number,
	TsType_String,
	TsType_UnknownPromise,
	TsType_Void
} from 'rfbp_core/typescript/global_types'
import {
	Autocomplete,
	Box,
	TextField
} from '@mui/material/'

///////////////////////////////
// Typescript
///////////////////////////////

	interface TsInterface_ComponentProps {
		// propKey: TsType_Any
		clientKey: TsType_String
		searchIndexKey: TsType_String
		searchResultRenderer: TsType_TableSearchResultRenderer
		searchFilters: ( TsType_String | TsType_String[] )[]
		additionalSearchData: TsInterface_UnspecifiedObject
		defaultSearchValue?: TsType_String
		sx?: TsInterface_UnspecifiedObject
	}


///////////////////////////////
// Variables
///////////////////////////////

	// Displayed Translatable Strings
	// { sort-start } - displayed text - scoped sort plugin
	const s_FAILED_TO_CREATE_SEARCH_INDEX: TsType_JSX = 			<Trans>Failed to create search index</Trans>
	const s_MISSING_REQUIRED_PARAMETERS: TsType_JSX = 				<Trans>Missing Required Parameters</Trans>
	const s_NO_SEARCH_RESULTS: TsType_JSX = 						<Trans>No Search Results</Trans>
	const se_SEARCH: TsType_String = 								"Search"
	// { sort-end } - displayed text

	// Create Meilisearch Search Client
	let searchClient: TsType_Any = null
	if( searchConfig != null && searchConfig.host !== "" && searchConfig.apiKey !== "" ){
		searchClient = new MeiliSearch( searchConfig )
	}

///////////////////////////////
// Functions
///////////////////////////////


///////////////////////////////
// Component
///////////////////////////////

	// Meilisearch Helper Functions - Index Management
	export const createSearchIndex = (
		clientKey: TsType_String,
		searchIndexKey: TsType_String,
		itemKey: TsType_String // ussually "id"
	): TsType_UnknownPromise => {
		return new Promise( ( resolve, reject ) => {
			if( searchClient != null ){
				let combinedIndexKey = clientKey + "_" + searchIndexKey
				searchClient.createIndex( combinedIndexKey, { primaryKey: itemKey } ).then(( res_CI: TsType_Any ) => {
					resolve( res_CI )
				}).catch(( rej_CI: TsType_Any ) => {
					console.error( rej_CI )
					reject({
						success: false,
						error: {
							message: s_FAILED_TO_CREATE_SEARCH_INDEX,
							details: rej_CI.message,
							code: "ER-D-SI-CI-01"
						}
					})
				})
			} else {
				reject({
					success: false,
					error: {
						message: s_FAILED_TO_CREATE_SEARCH_INDEX,
						details: s_MISSING_REQUIRED_PARAMETERS,
						code: "ER-D-SI-CI-02"
					}
				})
			}
		})
	}

	export const deleteSearchIndex = (
		clientKey: TsType_String,
		searchIndexKey: TsType_String
	): TsType_UnknownPromise => {
		return new Promise( ( resolve, reject ) => {
			if( searchClient != null ){
				let combinedIndexKey = clientKey + "_" + searchIndexKey
				searchClient.deleteIndex( combinedIndexKey ).then(( res_DI: TsType_Any ) => {
					resolve( res_DI )
				}).catch(( rej_DI: TsType_Any ) => {
					console.error( rej_DI )
					reject({
						success: false,
						error: {
							message: s_FAILED_TO_CREATE_SEARCH_INDEX,
							details: rej_DI.message,
							code: "ER-D-SI-DSI-01"
						}
					})
				})
			} else {
				reject({
					success: false,
					error: {
						message: s_FAILED_TO_CREATE_SEARCH_INDEX,
						details: s_MISSING_REQUIRED_PARAMETERS,
						code: "ER-D-SI-DSI-02"
					}
				})
			}
		})
	}

	export const listSearchIndices = (
		offset: TsType_Number, // default 0
		limit: TsType_Number // default 20
	): TsType_UnknownPromise => {
		return new Promise( ( resolve, reject ) => {
			if( searchClient != null ){
				searchClient.getIndexes({ offset: offset, limit: limit }).then(( res_GI: TsType_Any ) => {
					resolve( res_GI )
				}).catch(( rej_GI: TsType_Any ) => {
					console.error( rej_GI )
					reject({
						success: false,
						error: {
							message: s_FAILED_TO_CREATE_SEARCH_INDEX,
							details: rej_GI.message,
							code: "ER-D-SI-DSI-01"
						}
					})
				})
			} else {
				reject({
					success: false,
					error: {
						message: s_FAILED_TO_CREATE_SEARCH_INDEX,
						details: s_MISSING_REQUIRED_PARAMETERS,
						code: "ER-D-SI-DSI-02"
					}
				})
			}
		})
	}

	export const getSpecificSearchIndexInfo = (
		clientKey: TsType_String,
		searchIndexKey: TsType_String,
	): TsType_UnknownPromise => {
		return new Promise( ( resolve, reject ) => {
			if( searchClient != null ){
				let combinedIndexKey = clientKey + "_" + searchIndexKey
				searchClient.index( combinedIndexKey ).getSettings().then(( res_GI: TsType_Any ) => {
					resolve( res_GI )
				}).catch(( rej_GI: TsType_Any ) => {
					console.error( rej_GI )
					reject({
						success: false,
						error: {
							message: s_FAILED_TO_CREATE_SEARCH_INDEX,
							details: rej_GI.message,
							code: "ER-D-SI-DSI-01"
						}
					})
				})

			} else {
				reject({
					success: false,
					error: {
						message: s_FAILED_TO_CREATE_SEARCH_INDEX,
						details: s_MISSING_REQUIRED_PARAMETERS,
						code: "ER-D-SI-GSSII-02"
					}
				})
			}
		})
	}

	// Meilisearch Helper Functions - Get Documents
	export const getDocumentsFromSearchIndex = (
		clientKey: TsType_String,
		searchIndexKey: TsType_String,
		offset: TsType_Number, // default 0
		limit: TsType_Number // default 20
	): TsType_UnknownPromise => {
		return new Promise( ( resolve, reject ) => {
			if( searchClient != null ){
				let combinedIndexKey = clientKey + "_" + searchIndexKey
				searchClient.index( combinedIndexKey ).getDocuments({ offset: offset, limit: limit }).then(( res_GD: TsType_Any ) => {
					resolve( res_GD )
				}).catch(( rej_GD: TsType_Any ) => {
					console.error( rej_GD )
					reject({
						success: false,
						error: {
							message: s_FAILED_TO_CREATE_SEARCH_INDEX,
							details: rej_GD.message,
							code: "ER-D-SI-DSI-01"
						}
					})
				})
			} else {
				reject({
					success: false,
					error: {
						message: s_FAILED_TO_CREATE_SEARCH_INDEX,
						details: s_MISSING_REQUIRED_PARAMETERS,
						code: "ER-D-SI-DSI-02"
					}
				})
			}
		})
	}

	export const getDocumentFromSearchIndex = (
		clientKey: TsType_String,
		searchIndexKey: TsType_String,
		documentKey: TsType_String
	): TsType_UnknownPromise => {
		return new Promise( ( resolve, reject ) => {
			if( searchClient != null ){
				let combinedIndexKey = clientKey + "_" + searchIndexKey
				searchClient.index( combinedIndexKey ).getDocument(documentKey, { fields: "*" }).then(( res_GD: TsType_Any ) => {
					resolve( res_GD )
				}).catch(( rej_GD: TsType_Any ) => {
					console.error( rej_GD )
					reject({
						success: false,
						error: {
							message: s_FAILED_TO_CREATE_SEARCH_INDEX,
							details: rej_GD.message,
							code: "ER-D-SI-DSI-01"
						}
					})
				})
			} else {
				reject({
					success: false,
					error: {
						message: s_FAILED_TO_CREATE_SEARCH_INDEX,
						details: s_MISSING_REQUIRED_PARAMETERS,
						code: "ER-D-SI-DSI-02"
					}
				})
			}
		})
	}

	// Meilisearch Helper Functions - Add / Update Documents
	export const addDocumentsToSearchIndex = (
		clientKey: TsType_String,
		searchIndexKey: TsType_String,
		documentArray: TsInterface_UnspecifiedObject[]
	): TsType_UnknownPromise => {
		return new Promise( ( resolve, reject ) => {
			if( searchClient != null ){
				let combinedIndexKey = clientKey + "_" + searchIndexKey
				searchClient.index( combinedIndexKey ).addDocuments( documentArray ).then(( res_AD: TsType_Any ) => {
					resolve( res_AD )
				}).catch(( rej_AD: TsType_Any ) => {
					console.error( rej_AD )
					reject({
						success: false,
						error: {
							message: s_FAILED_TO_CREATE_SEARCH_INDEX,
							details: rej_AD.message,
							code: "ER-D-SI-DSI-01"
						}
					})
				})
			} else {
				reject({
					success: false,
					error: {
						message: s_FAILED_TO_CREATE_SEARCH_INDEX,
						details: s_MISSING_REQUIRED_PARAMETERS,
						code: "ER-D-SI-DSI-02"
					}
				})
			}
		})
	}

	export const updateDocumentsInSearchIndex = (
		clientKey: TsType_String,
		searchIndexKey: TsType_String,
		documentArray: TsInterface_UnspecifiedObject[]
	) => {
		return new Promise( ( resolve, reject ) => {
			if( searchClient != null ){
				let combinedIndexKey = clientKey + "_" + searchIndexKey
				searchClient.index( combinedIndexKey ).updateDocuments( documentArray ).then(( res_UD: TsType_Any ) => {
					resolve( res_UD )
				}).catch(( rej_UD: TsType_Any ) => {
					console.error( rej_UD )
					reject({
						success: false,
						error: {
							message: s_FAILED_TO_CREATE_SEARCH_INDEX,
							details: rej_UD.message,
							code: "ER-D-SI-DSI-01"
						}
					})
				})
			} else {
				reject({
					success: false,
					error: {
						message: s_FAILED_TO_CREATE_SEARCH_INDEX,
						details: s_MISSING_REQUIRED_PARAMETERS,
						code: "ER-D-SI-DSI-02"
					}
				})
			}
		})
	}

	// Meilisearch Helper Functions - Delete Documents
	export const deleteAllDocumentsFromSearchIndex = (
		clientKey: TsType_String,
		searchIndexKey: TsType_String,
	): TsType_UnknownPromise => {
		return new Promise( ( resolve, reject ) => {
			if( searchClient != null ){
				let combinedIndexKey = clientKey + "_" + searchIndexKey
				searchClient.index( combinedIndexKey ).deleteAllDocuments().then(( res_DAD: TsType_Any ) => {
					resolve( res_DAD )
				}).catch(( rej_DAD: TsType_Any ) => {
					console.error( rej_DAD )
					reject({
						success: false,
						error: {
							message: s_FAILED_TO_CREATE_SEARCH_INDEX,
							details: rej_DAD.message,
							code: "ER-D-SI-DSI-01"
						}
					})
				})
			} else {
				reject({
					success: false,
					error: {
						message: s_FAILED_TO_CREATE_SEARCH_INDEX,
						details: s_MISSING_REQUIRED_PARAMETERS,
						code: "ER-D-SI-DSI-02"
					}
				})
			}
		})
	}

	export const deleteSpecifiedDocumentsFromSearchIndex = (
		clientKey: TsType_String,
		searchIndexKey: TsType_String,
		documentKeysArray: TsType_String[]
	): TsType_UnknownPromise => {
		return new Promise( ( resolve, reject ) => {
			if( searchClient != null ){
				let combinedIndexKey = clientKey + "_" + searchIndexKey
				searchClient.index( combinedIndexKey ).deleteDocuments( documentKeysArray ).then(( res_DD: TsType_Any ) => {
					resolve( res_DD )
				}).catch(( rej_DD: TsType_Any ) => {
					console.error( rej_DD )
					reject({
						success: false,
						error: {
							message: s_FAILED_TO_CREATE_SEARCH_INDEX,
							details: rej_DD.message,
							code: "ER-D-SI-DSI-01"
						}
					})
				})
			} else {
				reject({
					success: false,
					error: {
						message: s_FAILED_TO_CREATE_SEARCH_INDEX,
						details: s_MISSING_REQUIRED_PARAMETERS,
						code: "ER-D-SI-DSI-02"
					}
				})
			}
		})
	}

	export const deleteDocumentFromSearchIndex = (
		clientKey: TsType_String,
		searchIndexKey: TsType_String,
		documentKey: TsType_String
	): TsType_UnknownPromise => {
		return new Promise( ( resolve, reject ) => {
			if( searchClient != null ){
				let combinedIndexKey = clientKey + "_" + searchIndexKey
				searchClient.index( combinedIndexKey ).deleteDocument( documentKey ).then(( res_DD: TsType_Any ) => {
					resolve( res_DD )
				}).catch(( rej_DD: TsType_Any ) => {
					console.error( rej_DD )
					reject({
						success: false,
						error: {
							message: s_FAILED_TO_CREATE_SEARCH_INDEX,
							details: rej_DD.message,
							code: "ER-D-SI-DSI-01"
						}
					})
				})
			} else {
				reject({
					success: false,
					error: {
						message: s_FAILED_TO_CREATE_SEARCH_INDEX,
						details: s_MISSING_REQUIRED_PARAMETERS,
						code: "ER-D-SI-DSI-02"
					}
				})
			}
		})
	}

	// Keys
	export const getSearchClientKeys = (): TsType_UnknownPromise => {
		return new Promise( ( resolve, reject ) => {
			if( searchClient != null ){
				searchClient.getKeys().then(( res_GK: TsType_Any ) => {
					resolve( res_GK )
				}).catch(( rej_GK: TsType_Any ) => {
					console.error( rej_GK )
					reject({
						success: false,
						error: {
							message: s_FAILED_TO_CREATE_SEARCH_INDEX,
							details: rej_GK.message,
							code: "ER-D-SI-DSI-01"
						}
					})
				})
			} else {
				reject({
					success: false,
					error: {
						message: s_FAILED_TO_CREATE_SEARCH_INDEX,
						details: s_MISSING_REQUIRED_PARAMETERS,
						code: "ER-D-SI-DSI-02"
					}
				})
			}
		})
	}

	// Filters
	export const getSearchIndexFilters = (
		clientKey: TsType_String,
		searchIndexKey: TsType_String,
	) => {
		return new Promise( ( resolve, reject ) => {
			if( searchClient != null ){
				let combinedIndexKey = clientKey + "_" + searchIndexKey
				searchClient.index( combinedIndexKey ).getFilterableAttributes().then(( res_GFA: TsType_Any ) => {
					resolve( res_GFA )
				}).catch(( rej_GFA: TsType_Any ) => {
					console.error( rej_GFA )
					reject({
						success: false,
						error: {
							message: s_FAILED_TO_CREATE_SEARCH_INDEX,
							details: rej_GFA.message,
							code: "ER-D-SI-DSI-01"
						}
					})
				})
			} else {
				reject({
					success: false,
					error: {
						message: s_FAILED_TO_CREATE_SEARCH_INDEX,
						details: s_MISSING_REQUIRED_PARAMETERS,
						code: "ER-D-SI-DSI-02"
					}
				})
			}
		})
	}

	// TODO - Has to be done for new clients and indices
	export const updateSearchIndexFilters = (
		clientKey: TsType_String,
		searchIndexKey: TsType_String,
		filterableAttributes: TsType_String[]
	) => {
		return new Promise( ( resolve, reject ) => {
			if( searchClient != null ){
				let combinedIndexKey = clientKey + "_" + searchIndexKey
				searchClient.index( combinedIndexKey ).updateFilterableAttributes( filterableAttributes ).then(( res_UFA: TsType_Any ) => {
					resolve( res_UFA )
				}).catch(( rej_UFA: TsType_Any ) => {
					console.error( rej_UFA )
					reject({
						success: false,
						error: {
							message: s_FAILED_TO_CREATE_SEARCH_INDEX,
							details: rej_UFA.message,
							code: "ER-D-SI-DSI-01"
						}
					})
				})
			} else {
				reject({
					success: false,
					error: {
						message: s_FAILED_TO_CREATE_SEARCH_INDEX,
						details: s_MISSING_REQUIRED_PARAMETERS,
						code: "ER-D-SI-DSI-02"
					}
				})
			}
		})
	}

	// Meilisearch Helper Functions - Search
	export const searchIndex = (
		clientKey: TsType_String,
		searchIndexKey: TsType_String,
		searchQuery: TsType_String,
		searchFilters: (TsType_String | TsType_String[])[]
	): TsType_UnknownPromise => {
		return new Promise( ( resolve, reject ) => {
			if( searchClient != null ){
				let offset = 0
				let limit = 20
				let combinedIndexKey = clientKey + "_" + searchIndexKey
				searchClient.index( combinedIndexKey ).search( searchQuery, {
					filter: searchFilters,
					offset: offset,
					limit: limit
					// filter: [['genres = Horror', 'genres = Mystery'], 'director = "Jordan Peele"']
				} ).then(( res_S: TsType_Any ) => {
					resolve( res_S )
				}).catch(( rej_S: TsType_Any ) => {
					console.error( rej_S )
					reject({
						success: false,
						error: {
							message: s_FAILED_TO_CREATE_SEARCH_INDEX,
							details: rej_S.message,
							code: "ER-D-SI-DSI-01"
						}
					})
				})
			} else {
				reject({
					success: false,
					error: {
						message: s_FAILED_TO_CREATE_SEARCH_INDEX,
						details: s_MISSING_REQUIRED_PARAMETERS,
						code: "ER-D-SI-DSI-02"
					}
				})
			}
		})
	}

	// Helper Functions
	export const returnJSX_HighlightedSearchString = (
		searchInputValue: TsType_String | TsType_Null,
		stringToHighlight: TsType_String
	): TsType_JSX => {
		let stringJSX = <></>
		if( stringToHighlight != null ){
			let displayStringSearchMatches: [ TsType_Number, TsType_Number ][] = []
			if( searchInputValue != null  ){
				displayStringSearchMatches = match( stringToHighlight , searchInputValue )
			}
			const displayStringSearchParts = parse(
				stringToHighlight,
				displayStringSearchMatches
			)
			stringJSX =
			<>
				{displayStringSearchParts.map((part: TsInterface_UnspecifiedObject, index: TsType_Number) => (
					<Box
						key={index}
						component="span"
						sx={{
							backgroundColor: part.highlight ? themeVariables.warning_dark : "rgba(0,0,0,0)",
							fontWeight: part.highlight ? 'bold' : 'regular',
							opacity: part.highlight ? '100%' : '30%'
						}}
					>
						{part.text}
					</Box>
				))}
			</>
		}
		return stringJSX
	}

	// Component
	export const SearchInput = ( props: TsInterface_ComponentProps ): TsType_JSX => {

		// Props
		let pr_clientKey: TsInterface_ComponentProps["clientKey"] = 							getProp( props, "clientKey", null )
		let pr_searchIndexKey: TsInterface_ComponentProps["searchIndexKey"] = 					getProp( props, "searchIndexKey", null )
		let pr_searchFilters: TsInterface_ComponentProps["searchFilters"] = 					getProp( props, "searchFilters", [] )
		let pr_searchResultRenderer: TsInterface_ComponentProps["searchResultRenderer"] =		getProp( props, "searchResultRenderer", ( option: TsInterface_UnspecifiedObject, searchInputValue: TsType_String, inputHooks: TsInterface_InputHooksObject ) => { return <>{ option.id }</> } )
		let pr_additionalSearchData: TsInterface_ComponentProps["additionalSearchData"] =		getProp( props, "additionalSearchData", {} )
		let pr_defaultSearchValue: TsInterface_ComponentProps["defaultSearchValue"] =			getProp( props, "defaultSearchValue", null )
		let pr_sx: TsInterface_ComponentProps["sx"] = 											getProp( props, "sx", { width: "280px", height: "36px" } )

		// Hooks - useContext, useState, useReducer, other
		// { sort-start } - hooks
		const [ us_searchInputValue, us_setSearchInputValue ] = 			useState<TsType_String | TsType_Null>( pr_defaultSearchValue as TsType_String | TsType_Null )
		const [ us_searchOptions, us_setSearchOptions ] = 					useState<TsInterface_UnspecifiedObject[]>([])
		const un_routerNaviation = 											useNavigate()
		const ur_forceRerender = 											useReducer( () => ( {} ), {} )[1] as () => TsType_Void
		const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = 		useContext( Context_RootData_ClientKey )
		const { uc_RootData_ClientPermissions } = 							useContext( Context_RootData_ClientPermissions )
		const { uc_RootData_ClientUser } = 									useContext( Context_RootData_ClientUser )
		const { uc_RootData_GlobalUser } = 									useContext( Context_RootData_GlobalUser )
		const { uc_setUserInterface_AlertDialogDisplay } = 					useContext( Context_UserInterface_AlertDialog )
		const { uc_setUserInterface_ConfirmDialogDisplay } = 				useContext( Context_UserInterface_ConfirmDialog )
		const { uc_setUserInterface_CustomDialogDisplay } = 				useContext( Context_UserInterface_CustomDialog )
		const { uc_setUserInterface_ErrorDialogDisplay } = 					useContext( Context_UserInterface_ErrorDialog )
		const { uc_setUserInterface_FormDialogDisplay } = 					useContext( Context_UserInterface_FormDialog )
		const { uc_setUserInterface_LoadingBarDisplay } = 					useContext( Context_UserInterface_LoadingBar )
		const { uc_setUserInterface_PromptDialogDisplay } = 				useContext( Context_UserInterface_PromptDialog )
		// { sort-end } - hooks

		// Hooks - useEffect
		useEffect(() => {
			if(
				pr_clientKey != null &&
				pr_searchIndexKey != null &&
				us_searchInputValue != null &&
				us_searchInputValue !== ""
			){
				searchIndex(pr_clientKey, pr_searchIndexKey, us_searchInputValue, pr_searchFilters).then((res_SI) => {
					let searchResults = getProp( res_SI, "hits", [] )
					us_setSearchOptions( searchResults )
				})
			} else {
				us_setSearchOptions([])
			}
			return () => {}
		}, [pr_clientKey, pr_searchIndexKey, us_searchInputValue, pr_searchFilters])

		// Other Variables
		const inputHooks: TsInterface_InputHooksObject = {
			uc_RootData_ClientKey: uc_RootData_ClientKey,
			uc_RootData_ClientPermissions: uc_RootData_ClientPermissions,
			uc_RootData_ClientUser: uc_RootData_ClientUser,
			uc_RootData_GlobalUser: uc_RootData_GlobalUser,
			ur_forceRerender: ur_forceRerender,
			un_routerNaviation: un_routerNaviation,
			uc_setRootData_ClientKey: uc_setRootData_ClientKey,
			uc_setUserInterface_AlertDialogDisplay: uc_setUserInterface_AlertDialogDisplay,
			uc_setUserInterface_ConfirmDialogDisplay: uc_setUserInterface_ConfirmDialogDisplay,
			uc_setUserInterface_CustomDialogDisplay: uc_setUserInterface_CustomDialogDisplay,
			uc_setUserInterface_ErrorDialogDisplay: uc_setUserInterface_ErrorDialogDisplay,
			uc_setUserInterface_FormDialogDisplay: uc_setUserInterface_FormDialogDisplay,
			uc_setUserInterface_LoadingBarDisplay: uc_setUserInterface_LoadingBarDisplay,
			uc_setUserInterface_PromptDialogDisplay: uc_setUserInterface_PromptDialogDisplay,
			us_setSearchInputValue: us_setSearchInputValue,
		}

		// Functions



		// JSX Generation

		const returnJSX_Component = (): TsType_JSX => {
			let componentJSX =
			<Box className="bp_thin_autocomplete_input tw-inline-block" sx={ pr_sx }>
				<Autocomplete
					freeSolo
					getOptionLabel={(option: TsType_Any) =>
						typeof option === 'string' ? option : option.id
					}
					filterOptions={(x) => x}
					options={us_searchOptions}
					autoComplete
					includeInputInList
					filterSelectedOptions
					value={ us_searchInputValue }
					noOptionsText={ s_NO_SEARCH_RESULTS }
					onChange={( event: TsType_Any, newValue: TsType_Any ) => {
						// Not relevent at this time?
					}}
					onInputChange={(event, newInputValue) => {
						us_setSearchInputValue(newInputValue)
					}}
					renderInput={(params) => (
						<TextField {...params} placeholder={ se_SEARCH } fullWidth />
					)}
					renderOption={(props, option) => {
						return (
							<Box key={ option.id } >
								{ pr_searchResultRenderer( option, us_searchInputValue, inputHooks, pr_additionalSearchData ) }
							</Box>
						)
					}}
				/>
			</Box>
			return componentJSX
		}

		// Render
		return <>{ returnJSX_Component() }</>
	}