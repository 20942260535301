///////////////////////////////
// Description
///////////////////////////////

	/*
		DESCRIPTION / USAGE:
			containers are pages / views used in the app and are made up of components and can interact with services and models

		TODO:

	*/


///////////////////////////////
// Imports
///////////////////////////////

import {
	StripeProd_CreateCustomer,
	StripeProd_ListSubscriptionProducts,
	StripeProd_ListUsersSubscriptions,
	StripeProd_UpdateDatabaseSubscriptionRecords,
	StripeStaging_CreateCustomer,
	StripeStaging_ListSubscriptionProducts,
	StripeStaging_ListUsersSubscriptions,
	StripeStaging_UpdateDatabaseSubscriptionRecords
} from 'app/models/stripe/stripe_functions'
import axios from 'axios'
import React, {
	useContext,
	useEffect,
	useReducer,
	useState
} from 'react'
import {
	Trans
} from 'react-i18next'
import {
	useNavigate
} from 'react-router-dom'
import {
	themeVariables
} from 'rfbp_aux/config/app_theme'
import {
	AuthenticatedContainer,
	returnApplicationSubscriptionStatus
} from 'rfbp_aux/containers/authenticated_container'
import {
	ApplicationPages
} from 'rfbp_aux/data/application_structure'
import {
	DatabaseRef_RootClient_Document
} from 'rfbp_aux/services/database_endpoints/standard_database_endpoints'
import {
	Icon
} from 'rfbp_core/components/icons'
import {
	Context_RootData_ClientKey,
	Context_RootData_GlobalUser,
	Context_UserInterface_ConfirmDialog,
	Context_UserInterface_ErrorDialog
} from 'rfbp_core/services/context'
import {
	DatabaseGetLiveDocument,
	DatabaseSetMergeDocument
} from 'rfbp_core/services/database_management'
import {
	dynamicSort,
	getProp,
	objectToArray,
	returnDateFromUnknownDateFormat,
	returnFormattedDate
} from 'rfbp_core/services/helper_functions'
import {
	getClientKey,
	logOut
} from 'rfbp_core/services/user_authentication'
import {
	TsInterface_UnspecifiedObject,
	TsType_Boolean,
	TsType_Date,
	TsType_JSX,
	TsType_Null,
	TsType_Number,
	TsType_String,
	TsType_Void,
	TsType_VoidFunction
} from 'rfbp_core/typescript/global_types'
import {
	Box,
	Button,
	Card,
	CircularProgress,
	Divider,
	Typography
} from '@mui/material/'
import Grid2 from '@mui/material/Unstable_Grid2'

///////////////////////////////
// Typescript
///////////////////////////////

	interface TsInterface_AuthorizedClient {
		[ key: TsType_String ]: TsType_String
	}

	interface TsInterface_AuthorizedClientsObject {
		[ key: TsType_String ]: TsType_Boolean
	}

	interface TsInterface_AuthorizedClientArray extends Array< TsInterface_AuthorizedClient >{}


///////////////////////////////
// Variables
///////////////////////////////

	// Authenticated Nav Data
	const pageKey: TsType_String = ApplicationPages["UserSettingsPage"]["key"]

	// Displayed Translatable Strings
	// { sort-start } - displayed text - scoped sort plugin
	const SUBSCRIPTION_OPTIONS: TsType_JSX = 												<Trans>Subscription Options</Trans>
	const s_ACCOUNT_SETTINGS: TsType_JSX = 													<Trans>Accounting Settings</Trans>
	const s_ACTIVE_STAGING_SUBSCRIPTION: TsType_JSX = 										<Trans>Active Staging Subscription</Trans>
	const s_ACTIVE_SUBSCRIPTION: TsType_JSX = 												<Trans>Active Subscription</Trans>
	const s_ACTIVE_UNTIL: TsType_JSX = 														<Trans>Active Until</Trans>
	const s_ARE_YOU_SURE_THAT_YOU_WANT_TO_CANCEL_YOUR_SUBSCRIPTION: TsType_JSX = 			<Trans>Are you sure that you want to cancel your subscription?</Trans>
	const s_ARE_YOU_SURE_THAT_YOU_WANT_TO_CONTINUE_YOUR_SUBSCRIPTION: TsType_JSX = 			<Trans>Are you sure that you want to continue your subscription?</Trans>
	const s_CANCEL_SUBSCRIPTION: TsType_JSX = 												<Trans>Cancel Subscription</Trans>
	const s_CANCEL_YOUR_SUBSCRIPTION: TsType_JSX = 											<Trans>Cancel your Subscription</Trans>
	const s_CONTINUE_SUBSCRIPTION: TsType_JSX = 											<Trans>Continue subscription</Trans>
	const s_CONTINUE_YOUR_SUBSCRIPTION: TsType_JSX = 										<Trans>Continue your subscription</Trans>
	const s_CURRENT_SUBSCRIPTION: TsType_JSX = 												<Trans>Current Subscription</Trans>
	const s_EMAIL: TsType_JSX = 															<Trans>Email</Trans>
	const s_EXPIRED_FREE_TRIAL: TsType_JSX = 												<Trans>Expired Free Trial</Trans>
	const s_FREE_TRIAL: TsType_JSX = 														<Trans>Free Trial</Trans>
	const s_LOG_OUT: TsType_JSX = 															<Trans>Log Out</Trans>
	const s_NAME: TsType_JSX = 																<Trans>Name</Trans>
	const s_NEXT_BILLED_ON: TsType_JSX = 													<Trans>Next Billed On</Trans>
	const s_NO_CURRENT_SUBSCRIPTION: TsType_JSX = 											<Trans>No Current Subscription</Trans>
	const s_RESUBSCRIBE: TsType_JSX = 														<Trans>Resubscribe</Trans>
	const s_STAGING_SUBSCRIPTION_CANCELLING_ON: TsType_JSX = 								<Trans>Staging Subscription Cancelling On</Trans>
	const s_SUBSCRIBE: TsType_JSX = 														<Trans>Subscribe</Trans>
	const s_SUBSCRIBE_AFTER_FREE_TRIAL: TsType_JSX = 										<Trans>Subscribe after free trial</Trans>
	const s_SUBSCRIPTION_CANCELLING_ON: TsType_JSX = 										<Trans>Subscription Cancelling On</Trans>
	const s_UNKNOWN_DATE: TsType_JSX = 														<Trans>Unknown Date</Trans>
	const s_UNKNOWN_SUBSCRIPTION_STATUS: TsType_JSX = 										<Trans>Unknown Subscription Status</Trans>
	const s_YOU_WILL_RETAIN_ACCESS_UNTIL: TsType_JSX = 										<Trans>You will retain access until</Trans>
	// { sort-end } - displayed text


///////////////////////////////
// Functions
///////////////////////////////

	export const makeStripeGetRequest = ( endpoint: TsType_String ) => {
		return new Promise(( resolve, reject ) => {
			axios.get( 'https://us-central1-loadcall-last-mile.cloudfunctions.net/stripePayments/' + endpoint + "/" ).then(( res_A ) => {
				resolve(res_A)
			}).catch((rej_A) => {
				reject(rej_A)
			})
		})
	}

	export const makeStripePostRequest = ( endpoint: TsType_String, requestBody: TsInterface_UnspecifiedObject ) => {
		return new Promise(( resolve, reject ) => {
			axios.post( 'https://us-central1-loadcall-last-mile.cloudfunctions.net/stripePayments/' + endpoint + "/", requestBody ).then(( res_A ) => {
				resolve(res_A)
			}).catch((rej_A) => {
				reject(rej_A)
			})
		})
	}

	const formatCurrency = (
		number: TsType_Number,
		currency: TsType_String = 'USD',
		locale: TsType_String = 'en-US'
	) => {
		return new Intl.NumberFormat(locale, {
		style: 'currency',
		currency: currency,
		}).format(number);
	}

///////////////////////////////
// Container
///////////////////////////////

	export const Container: React.FC = (): TsType_JSX => {

		// Props

		// Hooks - useContext, useState, useReducer, other
		// { sort-start } - hooks
		// const [ us_foundActiveSubscription, us_setFoundActiveSubscription ] = 					useState<TsType_Boolean>(true)
		// const [ us_lastAccessDate, us_setLastAccessDate ] = 									useState<TsType_Date | TsType_Null>(null)
		const [ us_clientData, us_setClientData ] = 											useState<TsInterface_UnspecifiedObject>({})
		const [ us_clientStripeData, us_setClientStripeData ] = 								useState<TsInterface_UnspecifiedObject>({})
		const [ us_loadedCustomerStripeData, us_setLoadedCustomerStripeData ] = 				useState<TsType_Boolean>(false)
		const [ us_loadedUserData, us_setLoadedUserData ] = 									useState<TsType_Boolean>(false)
		const [ us_loadingPaymentUrl, us_setLoadingPaymentUrl ] = 								useState<TsType_Boolean>(false)
		const [ us_stripeActiveSubscriptionData, us_setStripeActiveSubscriptionData ] = 		useState<TsInterface_UnspecifiedObject>({})
		const [ us_stripeSubscriptionViewMode, us_setStripeSubscriptionViewMode ] = 			useState<TsType_String>("loading")
		const [ us_subscriptionOptionsData, us_setSubscriptionOptionsData ] = 					useState<TsInterface_UnspecifiedObject>({})
		const un_routerNaviation = 																useNavigate()
		const ur_forceRerender = 																useReducer(() => ({}), {})[1] as () => TsType_Void
		const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = 							useContext( Context_RootData_ClientKey )
		const { uc_RootData_GlobalUser } = 														useContext( Context_RootData_GlobalUser )
		const { uc_setUserInterface_ConfirmDialogDisplay } = 									useContext( Context_UserInterface_ConfirmDialog )
		const { uc_setUserInterface_ErrorDialogDisplay } = 										useContext( Context_UserInterface_ErrorDialog )
		// { sort-end } - hooks

		// Hooks - useEffect
		useEffect(() => {
			let unsubscribeLiveData: TsType_VoidFunction
			const updateLiveData = ( newData: TsInterface_UnspecifiedObject ) => {
				us_setClientData( newData )
				setTimeout( () => {
					us_setLoadedUserData( true )
					ur_forceRerender()
				}, 100)
				us_setClientStripeData( newData )
				setTimeout( () => {
					us_setLoadedCustomerStripeData( true )
					ur_forceRerender()
				}, 100)
			}
			getClientKey( uc_RootData_ClientKey, uc_setRootData_ClientKey ).then(( res_GCK ) => {
				unsubscribeLiveData = DatabaseGetLiveDocument( DatabaseRef_RootClient_Document( res_GCK.clientKey ), updateLiveData )
			}).catch(( rej_GCK ) => {
				console.error( rej_GCK )
			})
			return () => {
				if (typeof unsubscribeLiveData === 'function'){
					unsubscribeLiveData()
				}
			}
		}, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender])

		useEffect(() => {
			if(
				us_clientStripeData != null &&
				us_clientStripeData["email"] != null &&
				us_clientStripeData["use_staging_payments"] !== true
			){
				let pricingTypesToShow = "regular"
				if(
					us_clientStripeData != null &&
					us_clientStripeData["use_promo_rates"] === true
				){
					pricingTypesToShow = "promo"
				}
				// Load All Stripe Products and set to scope
				StripeProd_ListSubscriptionProducts( pricingTypesToShow ).then(( res_SSLSP ) => {
					// Loop through and only display products that have the correct metadata
					let productList: TsInterface_UnspecifiedObject = {}
					// @ts-expect-error
					for( let loopIndex in res_SSLSP ){
						// @ts-expect-error
						let loopProduct = res_SSLSP[loopIndex]
						if(
							loopProduct != null &&
							loopProduct.product != null &&
							loopProduct.product.metadata != null &&
							loopProduct.product.metadata["pricing_type"] === pricingTypesToShow
						){
							productList[ loopIndex ] = loopProduct
						}
					}
					us_setSubscriptionOptionsData( productList )
				}).catch(( rej_SSLSP ) => {
					console.error( rej_SSLSP )
				})
			} else if(
				us_clientStripeData != null &&
				us_clientStripeData["email"] != null
			){
				let pricingTypesToShow = "regular"
				if(
					us_clientStripeData != null &&
					us_clientStripeData["use_promo_rates"] === true
				){
					pricingTypesToShow = "promo"
				}
				// Load All Stripe Products and set to scope
				StripeStaging_ListSubscriptionProducts( pricingTypesToShow ).then(( res_SSLSP ) => {
					// Loop through and only display products that have the correct metadata
					let productList: TsInterface_UnspecifiedObject = {}
					// @ts-expect-error
					for( let loopIndex in res_SSLSP ){
						// @ts-expect-error
						let loopProduct = res_SSLSP[loopIndex]
						if(
							loopProduct != null &&
							loopProduct.product != null &&
							loopProduct.product.metadata != null &&
							loopProduct.product.metadata["pricing_type"] === pricingTypesToShow
						){
							productList[ loopIndex ] = loopProduct
						}
					}
					us_setSubscriptionOptionsData( productList )
				}).catch(( rej_SSLSP ) => {
					console.error( rej_SSLSP )
				})
			}
		}, [us_clientStripeData])

		useEffect(() => {
			if(
				us_loadedCustomerStripeData === true &&
				us_loadedUserData === true
			){
				if(
					us_clientStripeData != null &&
					us_clientStripeData["email"] != null &&
					us_clientStripeData["use_staging_payments"] !== true
				){
					if(
						us_clientStripeData != null &&
						us_clientStripeData["stripe_data"] != null &&
						us_clientStripeData["stripe_data"]["stripe_prod_customer_id"] != null
					){
						// Create Stripe Customer Since One Does Not Exist
						getClientKey( uc_RootData_ClientKey, uc_setRootData_ClientKey ).then(( res_GCK ) => {
							StripeProd_ListUsersSubscriptions( res_GCK.clientKey ).then(( res_SSLUS ) => {
								// @ts-expect-error
								us_setStripeActiveSubscriptionData( res_SSLUS )
								us_setStripeSubscriptionViewMode( "customer_exists" )
							}).catch(( rej_SSLUS ) => {
								console.error( rej_SSLUS )
							})
						}).catch(( rej_GCK ) => {
							console.error( rej_GCK )
						})
					} else if(
						(
							us_clientStripeData == null ||
							us_clientStripeData["stripe_data"] == null ||
							us_clientStripeData["stripe_data"]["stripe_prod_customer_id"] == null
						) &&
						us_clientData != null &&
						us_clientData["email"] != null
					){
						// Create Stripe Customer Since One Does Not Exist
						getClientKey( uc_RootData_ClientKey, uc_setRootData_ClientKey ).then(( res_GCK ) => {
							StripeProd_CreateCustomer( res_GCK.clientKey, us_clientData["email"] ).then(( res_SSCC ) => {
								us_setStripeSubscriptionViewMode( "customer_exists_unknown_subscription" )
							}).catch(( rej_SSCC ) => {
								console.error( rej_SSCC )
							})
						}).catch(( rej_GCK ) => {
							console.error( rej_GCK )
						})
					} else {
						// Nothing?
					}
				} else if(
					us_clientStripeData != null &&
					us_clientStripeData["email"] != null
				){
					if(
						us_clientStripeData != null &&
						us_clientStripeData["stripe_data"] != null &&
						us_clientStripeData["stripe_data"]["stripe_staging_customer_id"] != null
					){
						// Create Stripe Customer Since One Does Not Exist
						getClientKey( uc_RootData_ClientKey, uc_setRootData_ClientKey ).then(( res_GCK ) => {
							StripeStaging_ListUsersSubscriptions( res_GCK.clientKey ).then(( res_SSLUS ) => {
								// @ts-expect-error
								us_setStripeActiveSubscriptionData( res_SSLUS )
								us_setStripeSubscriptionViewMode( "customer_exists" )
							}).catch(( rej_SSLUS ) => {
								console.error( rej_SSLUS )
							})
						}).catch(( rej_GCK ) => {
							console.error( rej_GCK )
						})
					} else if(
						(
							us_clientStripeData == null ||
							us_clientStripeData["stripe_data"] == null ||
							us_clientStripeData["stripe_data"]["stripe_staging_customer_id"] == null
						) &&
						us_clientData != null &&
						us_clientData["email"] != null
					){
						// Create Stripe Customer Since One Does Not Exist
						getClientKey( uc_RootData_ClientKey, uc_setRootData_ClientKey ).then(( res_GCK ) => {
							StripeStaging_CreateCustomer( res_GCK.clientKey, us_clientData["email"] ).then(( res_SSCC ) => {
								us_setStripeSubscriptionViewMode( "customer_exists_unknown_subscription" )
							}).catch(( rej_SSCC ) => {
								console.error( rej_SSCC )
							})
						}).catch(( rej_GCK ) => {
							console.error( rej_GCK )
						})
					} else {
						// Nothing?
					}
				}
			} else {
				us_setStripeSubscriptionViewMode( "loading" )
			}
		}, [uc_RootData_ClientKey, uc_setRootData_ClientKey, us_clientData, us_clientStripeData, us_loadedCustomerStripeData, us_loadedUserData])

		useEffect(() => {
			if( us_stripeSubscriptionViewMode === "customer_exists" ){
				getClientKey( uc_RootData_ClientKey, uc_setRootData_ClientKey ).then(( res_GCK ) => {
					if(
						us_clientStripeData != null &&
						us_clientStripeData["email"] != null &&
						us_clientStripeData["use_staging_payments"] !== true
					){
						StripeProd_UpdateDatabaseSubscriptionRecords(
							res_GCK.clientKey,
							us_stripeActiveSubscriptionData,
							us_clientStripeData,
							false
						)
					} else if(
						us_clientStripeData != null &&
						us_clientStripeData["email"] != null
					){
						StripeStaging_UpdateDatabaseSubscriptionRecords(
							res_GCK.clientKey,
							us_stripeActiveSubscriptionData,
							us_clientStripeData,
							false
						)
					}
				}).catch(( rej_GCK ) => {
					console.error( rej_GCK )
				})
			}
		}, [uc_RootData_ClientKey, uc_setRootData_ClientKey, us_clientStripeData, us_stripeActiveSubscriptionData, us_stripeSubscriptionViewMode])

		// Other Variables
		let displayUserName: TsType_String = getProp( uc_RootData_GlobalUser, "name", null )
		let authorizedClients: TsInterface_AuthorizedClientsObject = getProp( uc_RootData_GlobalUser, "client_access", {} )
		let authorizedClientArray: TsInterface_AuthorizedClientArray = []

		// Functions
		const signOut = (): TsType_Void => {
			logOut().then(( res_LO ) => {
				un_routerNaviation( ApplicationPages.UnauthenticatedLoginPage.url() )
			}).catch(( rej_LO ) => {
				console.error( rej_LO )
			})
		}

		const generateAuthorizedClientList = (): TsType_Void => {
			for ( let clientKey in authorizedClients ){
				authorizedClientArray.push({ key: clientKey })
			}
			authorizedClientArray.sort( dynamicSort( "key", null ) )
		}

		const checkIfSubscriptionIsActive = ( subscriptionID: TsType_String ): TsType_Boolean => {
			let subscriptionIsActive: TsType_Boolean = false
			if(
				us_clientStripeData != null &&
				us_clientStripeData["email"] != null &&
				us_clientStripeData["use_staging_payments"] !== true
			){
				if(
					us_clientStripeData != null &&
					us_clientStripeData["stripe_data"] != null &&
					us_clientStripeData["stripe_data"]["prod_subscriptions"] != null
				){
					for( let loopSubscriptionID in us_clientStripeData["stripe_data"]["prod_subscriptions"] ){
						let loopSubscription: TsInterface_UnspecifiedObject = us_clientStripeData["stripe_data"]["prod_subscriptions"][loopSubscriptionID]
						if( loopSubscription["product_id"] === subscriptionID && loopSubscription["stripe_status"] === "active" ){
							subscriptionIsActive = true
						}
					}
				}
			} else if(
				us_clientStripeData != null &&
				us_clientStripeData["email"] != null
			){
				if(
					us_clientStripeData != null &&
					us_clientStripeData["stripe_data"] != null &&
					us_clientStripeData["stripe_data"]["staging_subscriptions"] != null
				){
					for( let loopSubscriptionID in us_clientStripeData["stripe_data"]["staging_subscriptions"] ){
						let loopSubscription: TsInterface_UnspecifiedObject = us_clientStripeData["stripe_data"]["staging_subscriptions"][loopSubscriptionID]
						if( loopSubscription["product_id"] === subscriptionID && loopSubscription["stripe_status"] === "active" ){
							subscriptionIsActive = true
						}
					}
				}
			}
			return subscriptionIsActive
		}

		const returnStagingSubscriptionNextBillDate = (): TsType_Date | TsType_Null => {
			let nextBillingTimestamp = null
			let nextBillingDate = null
			if(
				us_clientStripeData != null &&
				us_clientStripeData.stripe_data != null &&
				us_clientStripeData.stripe_data.staging_subscriptions != null
			){
				for( let loopSubscriptionKey in us_clientStripeData.stripe_data.staging_subscriptions ){
					let loopSubscription = us_clientStripeData.stripe_data.staging_subscriptions[loopSubscriptionKey]
					if(
						loopSubscription != null &&
						loopSubscription.subscription_id != null &&
						loopSubscription.stripe_status === "active" &&
						loopSubscription.current_period_end != null
					){
						if(
							nextBillingTimestamp == null ||
							loopSubscription.current_period_end < nextBillingTimestamp
						){
							nextBillingTimestamp = loopSubscription.current_period_end
							nextBillingDate = new Date( nextBillingTimestamp )
						}
					}
				}
			}
			return nextBillingDate
		}

		const returnProdSubscriptionNextBillDate = (): TsType_Date | TsType_Null => {
			let nextBillingTimestamp = null
			let nextBillingDate = null
			if(
				us_clientStripeData != null &&
				us_clientStripeData.stripe_data != null &&
				us_clientStripeData.stripe_data.prod_subscriptions != null
			){
				for( let loopSubscriptionKey in us_clientStripeData.stripe_data.prod_subscriptions ){
					let loopSubscription = us_clientStripeData.stripe_data.prod_subscriptions[loopSubscriptionKey]
					if(
						loopSubscription != null &&
						loopSubscription.subscription_id != null &&
						loopSubscription.stripe_status === "active" &&
						loopSubscription.current_period_end != null
					){
						if(
							nextBillingTimestamp == null ||
							loopSubscription.current_period_end < nextBillingTimestamp
						){
							nextBillingTimestamp = loopSubscription.current_period_end
							nextBillingDate = new Date( nextBillingTimestamp )
						}
					}
				}
			}
			return nextBillingDate
		}

		function areDatesMoreThan48HoursApart(date1: TsType_Date, date2: TsType_Date) {
			// Parse the input dates to obtain JavaScript Date objects
			const parsedDate1 = new Date(date1);
			const parsedDate2 = new Date(date2);
			// Calculate the time difference in milliseconds between the two dates
			// @ts-expect-error
			const timeDifference = Math.abs(parsedDate1 - parsedDate2);
			// Calculate the number of hours in 48 hours (48 * 60 minutes * 60 seconds * 1000 milliseconds)
			const fortyEightHoursInMilliseconds = 48 * 60 * 60 * 1000;
			// Compare the time difference with 48 hours
			return timeDifference > fortyEightHoursInMilliseconds;
		}

		// Call Functions
		generateAuthorizedClientList()

		// JSX Generation
		const returnJSX_ProdSubscribeOrCancelButton = (
			subscriptionProduct: TsInterface_UnspecifiedObject,
		): TsType_JSX => {
			let buttonJSX = <></>
			// Subscription Exists
			let stripeStatus = "inactive" // "active" | "to_be_cancelled" | "inactive
			let activeSubscriptionExists: TsType_Boolean = false
			let activeSubscriptionKey: TsType_String | TsType_Null = null
			let toBeCancelledSubscriptionKey: TsType_String | TsType_Null = null
			if(
				us_clientStripeData != null &&
				us_clientStripeData["stripe_data"] != null &&
				us_clientStripeData["stripe_data"]["prod_subscriptions"] != null
			){
				for( let loopSubscriptionID in us_clientStripeData["stripe_data"]["prod_subscriptions"] ){
					let loopSubscription: TsInterface_UnspecifiedObject = us_clientStripeData["stripe_data"]["prod_subscriptions"][loopSubscriptionID]
					if( loopSubscription["product_id"] === subscriptionProduct.id ){
						if( loopSubscription.database_status === "to_be_cancelled" ){
							toBeCancelledSubscriptionKey = loopSubscription.subscription_id
							if( stripeStatus === "inactive" || stripeStatus === "active" ){
								stripeStatus = "to_be_cancelled"
								activeSubscriptionExists = true
							}
						} else if( loopSubscription.stripe_status === "active" ){
							activeSubscriptionKey = loopSubscription.subscription_id
							if( stripeStatus === "inactive" ){
								stripeStatus = "active"
								activeSubscriptionExists = true
							}
						}
					}
				}
			}
			if( stripeStatus === "active" ){
				buttonJSX =
				<Button
					color="error"
					disabled={ us_loadingPaymentUrl === true }
					onClick={ () => {
						// Cancel Subscription
						if( activeSubscriptionKey != null ){
							let unsubscribeTextJSX = s_ARE_YOU_SURE_THAT_YOU_WANT_TO_CANCEL_YOUR_SUBSCRIPTION
							let expirationCutoffDate: TsType_Number = new Date().getTime()
							if(
								us_clientStripeData != null &&
								us_clientStripeData["stripe_data"] != null &&
								us_clientStripeData["stripe_data"]["prod_subscriptions"] != null &&
								us_clientStripeData["stripe_data"]["prod_subscriptions"][ activeSubscriptionKey ] != null &&
								us_clientStripeData["stripe_data"]["prod_subscriptions"][ activeSubscriptionKey ]["current_period_end"] != null &&
								us_clientStripeData["stripe_data"]["prod_subscriptions"][ activeSubscriptionKey ]["current_period_end"] - ( 1000 * 60 * 60 * 12 ) > expirationCutoffDate
							){
								unsubscribeTextJSX = <>{ s_ARE_YOU_SURE_THAT_YOU_WANT_TO_CANCEL_YOUR_SUBSCRIPTION } { s_YOU_WILL_RETAIN_ACCESS_UNTIL } { returnFormattedDate( us_clientStripeData["stripe_data"]["prod_subscriptions"][ activeSubscriptionKey ]["current_period_end"] - ( 1000 * 60 * 60 * 12 ), "D MMM YY h:mma" ) }</>
							} else {
								unsubscribeTextJSX = <>{ s_ARE_YOU_SURE_THAT_YOU_WANT_TO_CANCEL_YOUR_SUBSCRIPTION }</>
							}
							// Open Dialog
							uc_setUserInterface_ConfirmDialogDisplay({
								display: true,
								confirm: {
									color: "error",
									header: s_CANCEL_YOUR_SUBSCRIPTION,
									icon: <Icon icon="cloud-xmark" type="solid"/>,
									submit_text: s_CANCEL_SUBSCRIPTION,
									text: unsubscribeTextJSX,
									submit_callback: () => {
										return new Promise( ( resolve, reject ) => {
											us_setLoadingPaymentUrl( true )
											let updateObject = {
												stripe_data: {
													prod_subscriptions: {
														[ activeSubscriptionKey as TsType_String ]: {
															database_status: "to_be_cancelled",
														}
													},
													pending_prod_cancellation: true,
												}
											}
											getClientKey( uc_RootData_ClientKey, uc_setRootData_ClientKey ).then(( res_GCK ) => {
												DatabaseSetMergeDocument( DatabaseRef_RootClient_Document( res_GCK.clientKey ), updateObject, {} ).then(( res_DSM ) => {
													us_setLoadingPaymentUrl( false )
													resolve( res_DSM )
												}).catch(( rej_DSM ) => {
													uc_setUserInterface_ErrorDialogDisplay({
														display: true,
														error: rej_DSM.error
													})
													us_setLoadingPaymentUrl( false )
													reject( rej_DSM )
												})
											}).catch(( rej_GCK ) => {
												uc_setUserInterface_ErrorDialogDisplay({
													display: true,
													error: rej_GCK.error
												})
												us_setLoadingPaymentUrl( false )
												reject( rej_GCK )
											})
										})
									},
								}
							})
						} else {
							uc_setUserInterface_ErrorDialogDisplay({
								display: true,
								error: {
									code: "ER-D-US-CS-01",
									details: "Failed to cancel subscription",
									message: "Please Contact Support",
								}
							})
						}
					}}
					variant="contained"
				>
					<Icon className="tw-mr-2" icon="cloud-xmark" />
					{ s_CANCEL_SUBSCRIPTION }
				</Button>
			} else if( stripeStatus === "to_be_cancelled" ){
				buttonJSX =
				<Button
					color="warning"
					disabled={ us_loadingPaymentUrl === true }
					onClick={ () => {
						if(
							subscriptionProduct != null &&
							subscriptionProduct.id != null
						){
							// Open Dialog
							uc_setUserInterface_ConfirmDialogDisplay({
								display: true,
								confirm: {
									color: "warning",
									header: s_CONTINUE_YOUR_SUBSCRIPTION,
									icon: <Icon icon="cloud-check" type="solid"/>,
									submit_text: s_CONTINUE_SUBSCRIPTION,
									text: s_ARE_YOU_SURE_THAT_YOU_WANT_TO_CONTINUE_YOUR_SUBSCRIPTION,
									submit_callback: () => {
										return new Promise( ( resolve, reject ) => {
											us_setLoadingPaymentUrl( true )
											let updateObject = {
												stripe_data: {
													prod_subscriptions: {
														[ toBeCancelledSubscriptionKey as TsType_String ]: {
															database_status: "active",
														}
													},
												}
											}
											getClientKey( uc_RootData_ClientKey, uc_setRootData_ClientKey ).then(( res_GCK ) => {
												DatabaseSetMergeDocument( DatabaseRef_RootClient_Document( res_GCK.clientKey ), updateObject, {} ).then(( res_DSM ) => {
													us_setLoadingPaymentUrl( false )
													resolve( res_DSM )
												}).catch(( rej_DSM ) => {
													uc_setUserInterface_ErrorDialogDisplay({
														display: true,
														error: rej_DSM.error
													})
													us_setLoadingPaymentUrl( false )
													reject( rej_DSM )
												})
											}).catch(( rej_GCK ) => {
												uc_setUserInterface_ErrorDialogDisplay({
													display: true,
													error: rej_GCK.error
												})
												us_setLoadingPaymentUrl( false )
												reject( rej_GCK )
											})
											// DEPRECATED - cronjob method used now
											// makeStripePostRequest("prod-cancel-subscription", { subscriptionId: us_priceKeysToSubKeysData[ subscriptionProduct.id ] }).then(( res_MSGR ) => {
											// 	console.log( res_MSGR )
											// 	window.location.reload()
											// }).catch(( rej_MSGR ) => {
											// 	us_setLoadingPaymentUrl( false )
											// 	console.error( rej_MSGR )
											// })
										})
									},
								}
							})
						}
					}}
					variant="contained"
				>
					<Icon className="tw-mr-2" icon="credit-card" />
					{ s_RESUBSCRIBE }
				</Button>
			} else if( stripeStatus === "inactive" ){
				let dateDisabled = false
				let freeTrialEndDate = getProp( us_clientStripeData, "timestamp_free_trial_end", null )
				if( freeTrialEndDate != null ){
					dateDisabled = areDatesMoreThan48HoursApart( new Date(), returnDateFromUnknownDateFormat( freeTrialEndDate ) )
				}
				let textJSX = <>{ s_SUBSCRIBE }</>
				if( dateDisabled === true ){
					textJSX = <>{ s_SUBSCRIBE_AFTER_FREE_TRIAL }</>
				}
				buttonJSX =
				<Button
					color="info"
					disabled={ us_loadingPaymentUrl === true || activeSubscriptionExists === true || dateDisabled === true }
					onClick={ () => {
						if(
							subscriptionProduct != null &&
							subscriptionProduct.id != null
						){
							us_setLoadingPaymentUrl( true )
							getClientKey( uc_RootData_ClientKey, uc_setRootData_ClientKey ).then(( res_GCK ) => {
								makeStripePostRequest("prod-create-checkout-section", { clientKey: res_GCK.clientKey, priceId: subscriptionProduct.id }).then(( res_MSGR ) => {
									if(
										res_MSGR != null &&
										// @ts-expect-error
										res_MSGR["data"] != null &&
										// @ts-expect-error
										res_MSGR["data"]["url"] != null &&
										// @ts-expect-error
										res_MSGR["data"]["url"]["url"] != null
									){
										// @ts-expect-error
										window.location.href = res_MSGR["data"]["url"]["url"]
									}
									// us_setLoadingPaymentUrl( false )
								}).catch(( rej_MSGR ) => {
									us_setLoadingPaymentUrl( false )
									console.error( rej_MSGR )
								})
							}).catch(( rej_GCK ) => {
								us_setLoadingPaymentUrl( false )
								console.error( rej_GCK )
							})
						}
					}}
					variant="contained"
				>
					<Icon className="tw-mr-2" icon="credit-card" />
					{ textJSX }
				</Button>
			}
			return buttonJSX
		}

		const returnJSX_StagingSubscribeOrCancelButton = (
			subscriptionProduct: TsInterface_UnspecifiedObject,
		): TsType_JSX => {
			let buttonJSX = <></>
			// Subscription Exists
			let stripeStatus = "inactive" // "active" | "to_be_cancelled" | "inactive
			let activeSubscriptionExists: TsType_Boolean = false
			let activeSubscriptionKey: TsType_String | TsType_Null = null
			let toBeCancelledSubscriptionKey: TsType_String | TsType_Null = null
			if(
				us_clientStripeData != null &&
				us_clientStripeData["stripe_data"] != null &&
				us_clientStripeData["stripe_data"]["staging_subscriptions"] != null
			){
				for( let loopSubscriptionID in us_clientStripeData["stripe_data"]["staging_subscriptions"] ){
					let loopSubscription: TsInterface_UnspecifiedObject = us_clientStripeData["stripe_data"]["staging_subscriptions"][loopSubscriptionID]
					if( loopSubscription["product_id"] === subscriptionProduct.id ){
						if( loopSubscription.database_status === "to_be_cancelled" ){
							toBeCancelledSubscriptionKey = loopSubscription.subscription_id
							if( stripeStatus === "inactive" || stripeStatus === "active" ){
								stripeStatus = "to_be_cancelled"
								activeSubscriptionExists = true
							}
						} else if( loopSubscription.stripe_status === "active" ){
							activeSubscriptionKey = loopSubscription.subscription_id
							if( stripeStatus === "inactive" ){
								stripeStatus = "active"
								activeSubscriptionExists = true
							}
						}
					}
				}
			}
			if( stripeStatus === "active" ){
				buttonJSX =
				<Button
					color="error"
					disabled={ us_loadingPaymentUrl === true }
					onClick={ () => {
						// Cancel Subscription
						if( activeSubscriptionKey != null ){
							let unsubscribeTextJSX = s_ARE_YOU_SURE_THAT_YOU_WANT_TO_CANCEL_YOUR_SUBSCRIPTION
							let expirationCutoffDate: TsType_Number = new Date().getTime()
							if(
								us_clientStripeData != null &&
								us_clientStripeData["stripe_data"] != null &&
								us_clientStripeData["stripe_data"]["staging_subscriptions"] != null &&
								us_clientStripeData["stripe_data"]["staging_subscriptions"][ activeSubscriptionKey ] != null &&
								us_clientStripeData["stripe_data"]["staging_subscriptions"][ activeSubscriptionKey ]["current_period_end"] != null &&
								us_clientStripeData["stripe_data"]["staging_subscriptions"][ activeSubscriptionKey ]["current_period_end"] - ( 1000 * 60 * 60 * 12 ) > expirationCutoffDate
							){
								unsubscribeTextJSX = <>{ s_ARE_YOU_SURE_THAT_YOU_WANT_TO_CANCEL_YOUR_SUBSCRIPTION } { s_YOU_WILL_RETAIN_ACCESS_UNTIL } { returnFormattedDate( us_clientStripeData["stripe_data"]["staging_subscriptions"][ activeSubscriptionKey ]["current_period_end"] - ( 1000 * 60 * 60 * 12 ), "D MMM YY h:mma" ) }</>
							} else {
								unsubscribeTextJSX = <>{ s_ARE_YOU_SURE_THAT_YOU_WANT_TO_CANCEL_YOUR_SUBSCRIPTION }</>
							}
							// Open Dialog
							uc_setUserInterface_ConfirmDialogDisplay({
								display: true,
								confirm: {
									color: "error",
									header: s_CANCEL_YOUR_SUBSCRIPTION,
									icon: <Icon icon="cloud-xmark" type="solid"/>,
									submit_text: s_CANCEL_SUBSCRIPTION,
									text: unsubscribeTextJSX,
									submit_callback: () => {
										return new Promise( ( resolve, reject ) => {
											us_setLoadingPaymentUrl( true )
											let updateObject = {
												stripe_data: {
													staging_subscriptions: {
														[ activeSubscriptionKey as TsType_String ]: {
															database_status: "to_be_cancelled",
														}
													},
													pending_staging_cancellation: true,
												}
											}
											getClientKey( uc_RootData_ClientKey, uc_setRootData_ClientKey ).then(( res_GCK ) => {
												DatabaseSetMergeDocument( DatabaseRef_RootClient_Document( res_GCK.clientKey ), updateObject, {} ).then(( res_DSM ) => {
													us_setLoadingPaymentUrl( false )
													resolve( res_DSM )
												}).catch(( rej_DSM ) => {
													uc_setUserInterface_ErrorDialogDisplay({
														display: true,
														error: rej_DSM.error
													})
													us_setLoadingPaymentUrl( false )
													reject( rej_DSM )
												})
											}).catch(( rej_GCK ) => {
												uc_setUserInterface_ErrorDialogDisplay({
													display: true,
													error: rej_GCK.error
												})
												us_setLoadingPaymentUrl( false )
												reject( rej_GCK )
											})
										})
									},
								}
							})
						} else {
							uc_setUserInterface_ErrorDialogDisplay({
								display: true,
								error: {
									code: "ER-D-US-CS-01",
									details: "Failed to cancel subscription",
									message: "Please Contact Support",
								}
							})
						}
					}}
					variant="contained"
				>
					<Icon className="tw-mr-2" icon="cloud-xmark" />
					{ s_CANCEL_SUBSCRIPTION }
				</Button>
			} else if( stripeStatus === "to_be_cancelled" ){
				buttonJSX =
				<Button
					color="warning"
					disabled={ us_loadingPaymentUrl === true }
					onClick={ () => {
						if(
							subscriptionProduct != null &&
							subscriptionProduct.id != null
						){
							// Open Dialog
							uc_setUserInterface_ConfirmDialogDisplay({
								display: true,
								confirm: {
									color: "warning",
									header: s_CONTINUE_YOUR_SUBSCRIPTION,
									icon: <Icon icon="cloud-check" type="solid"/>,
									submit_text: s_CONTINUE_SUBSCRIPTION,
									text: s_ARE_YOU_SURE_THAT_YOU_WANT_TO_CONTINUE_YOUR_SUBSCRIPTION,
									submit_callback: () => {
										return new Promise( ( resolve, reject ) => {
											us_setLoadingPaymentUrl( true )
											let updateObject = {
												stripe_data: {
													staging_subscriptions: {
														[ toBeCancelledSubscriptionKey as TsType_String ]: {
															database_status: "active",
														}
													},
												}
											}
											getClientKey( uc_RootData_ClientKey, uc_setRootData_ClientKey ).then(( res_GCK ) => {
												DatabaseSetMergeDocument( DatabaseRef_RootClient_Document( res_GCK.clientKey ), updateObject, {} ).then(( res_DSM ) => {
													us_setLoadingPaymentUrl( false )
													resolve( res_DSM )
												}).catch(( rej_DSM ) => {
													uc_setUserInterface_ErrorDialogDisplay({
														display: true,
														error: rej_DSM.error
													})
													us_setLoadingPaymentUrl( false )
													reject( rej_DSM )
												})
											}).catch(( rej_GCK ) => {
												uc_setUserInterface_ErrorDialogDisplay({
													display: true,
													error: rej_GCK.error
												})
												us_setLoadingPaymentUrl( false )
												reject( rej_GCK )
											})
											// DEPRECATED - cronjob method used now
											// makeStripePostRequest("staging-cancel-subscription", { subscriptionId: us_priceKeysToSubKeysData[ subscriptionProduct.id ] }).then(( res_MSGR ) => {
											// 	console.log( res_MSGR )
											// 	window.location.reload()
											// }).catch(( rej_MSGR ) => {
											// 	us_setLoadingPaymentUrl( false )
											// 	console.error( rej_MSGR )
											// })
										})
									},
								}
							})
						}
					}}
					variant="contained"
				>
					<Icon className="tw-mr-2" icon="credit-card" />
					{ s_RESUBSCRIBE }
				</Button>
			} else if( stripeStatus === "inactive" ){
				let dateDisabled = false
				let freeTrialEndDate = getProp( us_clientStripeData, "timestamp_free_trial_end", null )
				if( freeTrialEndDate != null ){
					dateDisabled = areDatesMoreThan48HoursApart( new Date(), returnDateFromUnknownDateFormat( freeTrialEndDate ) )
				}
				let textJSX = <>{ s_SUBSCRIBE }</>
				if( dateDisabled === true ){
					textJSX = <>{ s_SUBSCRIBE_AFTER_FREE_TRIAL }</>
				}
				buttonJSX =
				<Button
					color="info"
					disabled={ us_loadingPaymentUrl === true || activeSubscriptionExists === true || dateDisabled === true }
					onClick={ () => {
						if(
							subscriptionProduct != null &&
							subscriptionProduct.id != null
						){
							us_setLoadingPaymentUrl( true )
							getClientKey( uc_RootData_ClientKey, uc_setRootData_ClientKey ).then(( res_GCK ) => {
								makeStripePostRequest("staging-create-checkout-section", { clientKey: res_GCK.clientKey, priceId: subscriptionProduct.id }).then(( res_MSGR ) => {
									if(
										res_MSGR != null &&
										// @ts-expect-error
										res_MSGR["data"] != null &&
										// @ts-expect-error
										res_MSGR["data"]["url"] != null &&
										// @ts-expect-error
										res_MSGR["data"]["url"]["url"] != null
									){
										// @ts-expect-error
										window.location.href = res_MSGR["data"]["url"]["url"]
									}
									// us_setLoadingPaymentUrl( false )
								}).catch(( rej_MSGR ) => {
									us_setLoadingPaymentUrl( false )
									console.error( rej_MSGR )
								})
							}).catch(( rej_GCK ) => {
								us_setLoadingPaymentUrl( false )
								console.error( rej_GCK )
							})
						}
					}}
					variant="contained"
				>
					<Icon className="tw-mr-2" icon="credit-card" />
					{ textJSX }
				</Button>
			}
			return buttonJSX
		}

		const returnJSX_SubscribeOrCancelButton = (
			subscriptionProduct: TsInterface_UnspecifiedObject,
		): TsType_JSX => {
			let buttonJSX = <></>
			if(
				us_clientStripeData != null &&
				us_clientStripeData["email"] != null &&
				us_clientStripeData["use_staging_payments"] !== true
			){
				buttonJSX = returnJSX_ProdSubscribeOrCancelButton( subscriptionProduct )
			} else if(
				us_clientStripeData != null &&
				us_clientStripeData["email"] != null
			){
				buttonJSX = returnJSX_StagingSubscribeOrCancelButton( subscriptionProduct )
			}
			return buttonJSX
		}

		const returnJSX_ListOfAvailableSubscriptions = (): TsType_JSX => {
			// Data
			let activeSubscriptions: TsInterface_UnspecifiedObject[] = objectToArray( us_subscriptionOptionsData )
			// Breakpoints
			let breakpoints = {
				xs: 12,
				sm: 12,
				md: 4,
			}
			if( activeSubscriptions.length === 1 ){
				breakpoints = {
					xs: 12,
					sm: 12,
					md: 12
				}
			} else if( activeSubscriptions.length === 2 ){
				breakpoints = {
					xs: 12,
					sm: 12,
					md: 6,
				}
			} else if( activeSubscriptions.length === 3 ){
				breakpoints = {
					xs: 12,
					sm: 12,
					md: 4,
				}
			} else if( activeSubscriptions.length === 4 ){
				breakpoints = {
					xs: 12,
					sm: 12,
					md: 3,
				}
			}
			// JSX
			let subscriptionsListJSX =
			<Box className="tw-text-center">
				<Grid2 container spacing={1}>
					{activeSubscriptions.map(( subscriptionItem: TsInterface_UnspecifiedObject, index: TsType_Number ) => (
						<Grid2 xs={ breakpoints.xs } sm={ breakpoints.sm } md={ breakpoints.md } key={ index }>
							<Box
								sx={{
									border: checkIfSubscriptionIsActive( subscriptionItem.id ) === true ? "2px solid " + themeVariables.info_light : "2px solid rgba(0,0,0,0)"
								}}
								className="tw-rounded-xl tw-pt-3 tw-pb-1"
							>
								<Box className="tw-inline-block">
									<img
										src={
											getProp( getProp( subscriptionItem, "product", {} ), "images", [] )[0]
										}
										alt=""
										loading="lazy"
										width={"100px"}
										className="tw-mr-1"
									/>
								</Box>
								<Box className="tw-inline-block tw-align-top tw-text-left tw-ml-2">
									<Typography variant="h5" className="tw-mb-0">{ getProp( getProp( subscriptionItem, "product", {} ), "name", "" ) }</Typography>
									<Typography variant="subtitle1" className="tw-opacity-40">
										{ formatCurrency( subscriptionItem.unit_amount / 100 ) } { subscriptionItem.currency.toUpperCase() } /  { getProp( getProp( subscriptionItem, "recurring", {} ), "interval_count", 1 ) > 1 ? getProp( getProp( subscriptionItem, "recurring", {} ), "interval_count", 1 ) : "" } { getProp( getProp( subscriptionItem, "recurring", {} ), "interval", "" ) }
									</Typography>
									{ returnJSX_SubscribeOrCancelButton( subscriptionItem ) }
								</Box>
							</Box>
						</Grid2>
					))}
				</Grid2>
			</Box>
			return subscriptionsListJSX
		}

		const returnJSX_CurrentSubscription = (): TsType_JSX => {
			let subscriptionJSX = <></>
			switch( us_stripeSubscriptionViewMode ){
				case "loading":
					subscriptionJSX =
					<Box className="tw-text-center tw-p-4">
						<CircularProgress/>
					</Box>
					break
				case "customer_exists":
					subscriptionJSX =
					<Box className="tw-text-center tw-p-4">
						{ returnJSX_ListOfAvailableSubscriptions() }
					</Box>
					break
				case "customer_exists_unknown_subscription":
					subscriptionJSX =
					<Box className="tw-text-center tw-p-4">
						<CircularProgress/>
					</Box>
					break
			}
			return subscriptionJSX
		}

		const returnJSX_CurrentSubscriptionData = (): TsType_JSX => {
			let subscriptionJSX = <></>
			let subscriptionStatus = returnApplicationSubscriptionStatus( us_clientStripeData )
			let nextProdBillingDate = returnProdSubscriptionNextBillDate()
			let nextStagingBillingDate = returnStagingSubscriptionNextBillDate()
			let nextProdBillingDateJSX = s_UNKNOWN_DATE
			let nextStagingBillingDateJSX = s_UNKNOWN_DATE
			if( nextProdBillingDate != null ){
				nextProdBillingDateJSX = <>{ returnFormattedDate( nextProdBillingDate, "D MMM YYYY" ) }</>
			}
			if( nextStagingBillingDate != null ){
				nextStagingBillingDateJSX = <>{ returnFormattedDate( nextStagingBillingDate, "D MMM YYYY" ) }</>
			}
			let freeTrialEndDate = getProp( us_clientStripeData, "timestamp_free_trial_end", null )
			let freeTrialEndDateJSX = s_UNKNOWN_DATE
			if( freeTrialEndDate != null ){
				freeTrialEndDateJSX = <>{ returnFormattedDate( freeTrialEndDate, "D MMM YYYY" ) }</>
			}
			switch( subscriptionStatus ){
				case "unknown_subscription_status":
					subscriptionJSX = <>{ s_UNKNOWN_SUBSCRIPTION_STATUS }</>
					break
				case "active_prod_subscription":
					subscriptionJSX = <>{ s_ACTIVE_SUBSCRIPTION } ({ s_NEXT_BILLED_ON } { nextProdBillingDateJSX })</>
					break
				case "active_staging_subscription":
					subscriptionJSX = <>{ s_ACTIVE_STAGING_SUBSCRIPTION } ({ s_NEXT_BILLED_ON } { nextStagingBillingDateJSX })</>
					break
				case "active_prod_subscription_pending_cancellation":
					subscriptionJSX = <>{ s_SUBSCRIPTION_CANCELLING_ON } { nextProdBillingDateJSX } </>
					break
				case "active_staging_subscription_pending_cancellation":
					subscriptionJSX = <>{ s_STAGING_SUBSCRIPTION_CANCELLING_ON } { nextStagingBillingDateJSX }</>
					break
				case "active_free_trial":
					subscriptionJSX = <>{ s_FREE_TRIAL } ({ s_ACTIVE_UNTIL } { freeTrialEndDateJSX })</>
					break
				case "expired_free_trial":
					subscriptionJSX = <>{ s_EXPIRED_FREE_TRIAL }</>
					break
				case "no_subscription":
					subscriptionJSX = <>{ s_NO_CURRENT_SUBSCRIPTION }</>
					break
			}
			return subscriptionJSX
		}

		const returnJSX_PageContent = (): TsType_JSX => {
			let profileTabJSX =
			<Card variant="outlined">
				<Box className="tw-text-left">
					<Box className="tw-p-4 tw-text-left">
						<Typography variant="h5"><strong>{ s_NAME }:</strong> { displayUserName }</Typography>
						<Typography variant="h5"><strong>{ s_EMAIL }:</strong> { getProp( us_clientData, "name", "" ) }</Typography>
						<Button className="tw-mt-4" color="error" onClick={ () => { signOut() } } variant="contained">{ s_LOG_OUT }</Button>
						<Divider className="tw-my-4"/>
						<Typography variant="h5"><strong>{ s_CURRENT_SUBSCRIPTION }:</strong> {  returnJSX_CurrentSubscriptionData() }</Typography>
						<Divider className="tw-my-4"/>
						<Typography variant="h5"><strong>{ SUBSCRIPTION_OPTIONS }:</strong></Typography>
						{ returnJSX_CurrentSubscription() }


						{/* <Box>
							<Json data={ us_clientData } alphebetized={ true } />
							<Json data={ us_stripeActiveSubscriptionData } alphebetized={ true } />
						</Box> */}
					</Box>
				</Box>
			</Card>
			return profileTabJSX
		}

		const returnJSX_Page = (): TsType_JSX => {
			let pageJSX =
			<AuthenticatedContainer showRegardlessOfSubscriptionStatus={ true } pageHeader={ s_ACCOUNT_SETTINGS } pageKey={ pageKey } content={
				<Box>
					{ returnJSX_PageContent() }
				</Box>
			}/>
			return pageJSX
		}

		// Render
		return <>{ returnJSX_Page() }</>

	}