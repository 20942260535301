//======================================//
//		  ooOOOO USED BY BOILERPLATE    //
//		 oo		 _____					//
//		_I__n_n__||_|| ________			//
//	  >(_________|_7_|-|______|			//
//	   /o ()() ()() o   oo  oo			//
//======================================//

///////////////////////////////
// Description
///////////////////////////////

	/*
		DESCRIPTION / USAGE:
			Used to Initialize Firebase Instance

		TODO:

	*/

///////////////////////////////
// Imports
///////////////////////////////


import {
	initializeApp
} from 'firebase/app'

///////////////////////////////
// Variables
///////////////////////////////

	const firebaseConfig = {
		apiKey: "AIzaSyBPGxNvZX5OGvZN6K4C2Qwn4IWG53hnPzI",
		authDomain: "loadcall-last-mile.firebaseapp.com",
		projectId: "loadcall-last-mile",
		storageBucket: "loadcall-last-mile.appspot.com",
		messagingSenderId: "361742070654",
		appId: "1:361742070654:web:ca83300748fa1e08f7392f",
		measurementId: "G-4R4MDM49CD"
	}


///////////////////////////////
// Functions
///////////////////////////////

	initializeApp(firebaseConfig)


///////////////////////////////
// Exports
///////////////////////////////

	export default function FirebaseInit(){
		// Initialization occurs just from loading file BEFORE app.js file is loaded
	}