///////////////////////////////
// Description
///////////////////////////////

	/*
		DESCRIPTION / USAGE:
			containers are pages / views used in the app and are made up of components and can interact with services and models

		TODO:

	*/


///////////////////////////////
// Imports
///////////////////////////////

import {
	useContext,
	useEffect
} from 'react'
import {
	Trans
} from 'react-i18next'
import {
	useNavigate
} from 'react-router-dom'
import {
	themeVariables
} from 'rfbp_aux/config/app_theme'
import {
	AuthenticatedContainer
} from 'rfbp_aux/containers/authenticated_container'
import {
	ApplicationPages
} from 'rfbp_aux/data/application_structure'
import {
	DatabaseRef_TimecardAnalysis_Collection,
	DatabaseRef_TimecardAnalysis_Document
} from 'rfbp_aux/services/database_endpoints/analysis/timecard'
import {
	Icon
} from 'rfbp_core/components/icons'
import {
	TableCellBasic,
	TableCellTimestamp,
	TableDatabase,
	TsInterface_TableAdditionalData,
	TsInterface_TableColumns,
	TsInterface_TableDatabaseEndpointQueryObject,
	TsInterface_TableDatabaseSettings,
	TsInterface_TableDataRow,
	TsInterface_TableHooks
} from 'rfbp_core/components/table'
import {
	Context_RootData_ClientKey,
	Context_UserInterface_ErrorDialog,
	Context_UserInterface_PromptDialog
} from 'rfbp_core/services/context'
import {
	DatabaseAddDocument,
	DatabaseSetMergeDocument,
	generateDatabaseQuery,
	TsInterface_OrderByArray,
	TsInterface_QueryCursorsObject,
	TsInterface_QueryOperatorsArray
} from 'rfbp_core/services/database_management'
import {
	getProp
} from 'rfbp_core/services/helper_functions'
import {
	getClientKey
} from 'rfbp_core/services/user_authentication'
import {
	TsType_JSX,
	TsType_String,
	TsType_Void
} from 'rfbp_core/typescript/global_types'
import {
	Box,
	Button,
	Card
} from '@mui/material/'

///////////////////////////////
// Typescript
///////////////////////////////


///////////////////////////////
// Variables
///////////////////////////////

	// Authenticated Nav Data
	const pageKey: TsType_String = ApplicationPages["AuditTimecardsIndexPage"]["key"]

	// Displayed Translatable Strings
	// { sort-start } - displayed text - scoped sort plugin
	// const s_ARCHIVED: TsType_JSX = 												<Trans>Archived</Trans>
	// const s_DATA_CLEANUP: TsType_JSX = 											<Trans>Data Cleanup</Trans>
	// const s_FILE_UPLOAD: TsType_JSX = 											<Trans>File Upload</Trans>
	// const s_READY_FOR_REVIEW: TsType_JSX = 										<Trans>Ready for Review</Trans>
	// const s_STATUS: TsType_JSX = 												<Trans>Status</Trans>
	// const s_VIEW_ANALYSIS: TsType_JSX = 										<Trans>View Analysis</Trans>
	const s_ACTIVE: TsType_JSX = 												<Trans>Active</Trans>
	const s_ANALYSIS_NAME: TsType_JSX = 										<Trans>Analysis Name</Trans>
	const s_ARE_YOU_SURE_THAT_YOU_WANT_TO_DELETE_THIS_ANALYSIS: TsType_JSX = 	<Trans>Are you sure that you want to delete this analysis?</Trans>
	const s_CREATE_TIMECARD_AUDIT: TsType_JSX = 								<Trans>Create Timecard Audit</Trans>
	const s_DATE: TsType_JSX = 													<Trans>Date</Trans>
	const s_DELETE: TsType_JSX = 												<Trans>Delete</Trans>
	const s_DELETE_ANALYSIS: TsType_JSX = 										<Trans>Delete Analysis</Trans>
	const s_LOCKED: TsType_JSX = 												<Trans>Locked</Trans>
	const s_NEW_TIMECARD_AUDIT: TsType_JSX = 									<Trans>New Timecard Audit</Trans>
	const s_RENAME: TsType_JSX = 												<Trans>Rename</Trans>
	const s_RENAME_TIMECARD_AUDIT: TsType_JSX = 								<Trans>Rename Timecard Audit</Trans>
	const s_SAVE: TsType_JSX = 													<Trans>Save</Trans>
	const s_TIMECARD_AUDITS: TsType_JSX = 										<Trans>Timecard Audits</Trans>
	const s_VIEW: TsType_JSX = 													<Trans>View</Trans>
	const se_TIMECARD_AUDITS: TsType_String = 									"Timecard Audits"
	// { sort-end } - displayed text

	// Table
	const tableSettings_TimecardAnalyses: TsInterface_TableDatabaseSettings = {
		rows_per_page: 100,
		show_header: true,
		size: "small",
		sort_direction: "desc",
		sort_property: "timestamp_created",
		use_live_data: true,
	}

	const tableColumns_TimecardAnalyses: TsInterface_TableColumns = {
		TEMP_icon: {
			header: {
				header_css: ( tableAdditionalData: TsInterface_TableAdditionalData ) => { return "" },
				header_jsx: ( tableAdditionalData: TsInterface_TableAdditionalData ) => { return <></> },
				header_sort_by: null
			},
			cell: {
				cell_css: (
					rowData: TsInterface_TableDataRow,
					tableAdditionalData: TsInterface_TableAdditionalData
				) => { return "" },
				cell_jsx: (
					rowData: TsInterface_TableDataRow,
					tableAdditionalData: TsInterface_TableAdditionalData,
					tableHooks: TsInterface_TableHooks
				) => {
					let cellJSX = <></>
					if( rowData.locked === true ){
						cellJSX =
						<Icon
							icon="lock"
							type="solid"
							sx={{ color: themeVariables.warning_main, fontSize: "18px" }}
							tooltip={ s_LOCKED }
							tooltipPlacement='right'
						/>
					} else {
						cellJSX =
						<Icon
							icon="play"
							type="solid"
							sx={{ color: themeVariables.success_main, fontSize: "18px" }}
							tooltip={ s_ACTIVE }
							tooltipPlacement='right'
						/>
					}
					return cellJSX
				},
			},
		},
		name: TableCellBasic( "name", s_ANALYSIS_NAME, "name" ),
		timestamp_created: TableCellTimestamp( "timestamp_created", s_DATE, "timestamp_created", " D MMM YYYY - h:mm a", true ),
		// status: {
			// header: {
			// 	header_css: ( tableAdditionalData: TsInterface_TableAdditionalData ) => { return "" },
			// 	header_jsx: ( tableAdditionalData: TsInterface_TableAdditionalData ) => { return s_STATUS },
			// 	header_sort_by: null
			// },
			// cell: {
			// 	cell_css: (
			// 		rowData: TsInterface_TableDataRow,
			// 		tableAdditionalData: TsInterface_TableAdditionalData
			// 	) => { return "" },
			// 	cell_jsx: (
			// 		rowData: TsInterface_TableDataRow,
			// 		tableAdditionalData: TsInterface_TableAdditionalData,
			// 		tableHooks: TsInterface_TableHooks
			// 	) => {
			// 		let cellJSX = <></>
			// 		switch( rowData.substatus ){
			// 			case "file_upload":
			// 				cellJSX =
			// 				<Chip
			// 					variant='filled'
			// 					color="warning"
			// 					label={ s_FILE_UPLOAD }
			// 					icon={ <Icon icon="file-arrow-up" className="tw-pl-1"/> }
			// 				/>
			// 				break
			// 			case "data_cleanup":
			// 				cellJSX =
			// 				<Chip
			// 					variant='filled'
			// 					color="warning"
			// 					label={ s_DATA_CLEANUP }
			// 					icon={ <Icon icon="soap" className="tw-pl-1"/> }
			// 				/>
			// 				break
			// 			case "completed":
			// 				cellJSX =
			// 				<Chip
			// 					variant='filled'
			// 					color="success"
			// 					label={ s_READY_FOR_REVIEW }
			// 					icon={ <Icon icon="chart-simple" className="tw-pl-1"/> }
			// 				/>
			// 				break
			// 			// case "archived":
			// 				// cellJSX =
			// 				// <Chip
			// 				// 	variant='filled'
			// 				// 	color="error"
			// 				// 	label={ s_ARCHIVED }
			// 				// 	icon={ <Icon icon="box-archive" className="tw-pl-1"/> }
			// 				// />
			// 				// break
			// 			default:
			// 		}
			// 		return cellJSX
			// 	},
			// },
		// },
		TEMP_BUTTONS: {
			header: {
				header_css: ( tableAdditionalData: TsInterface_TableAdditionalData ) => { return "" },
				header_jsx: ( tableAdditionalData: TsInterface_TableAdditionalData ) => { return <></> },
				header_sort_by: null
			},
			cell: {
				cell_css: (
					rowData: TsInterface_TableDataRow,
					tableAdditionalData: TsInterface_TableAdditionalData
				) => { return "tw-text-right" },
				cell_jsx: (
					rowData: TsInterface_TableDataRow,
					tableAdditionalData: TsInterface_TableAdditionalData,
					tableHooks: TsInterface_TableHooks
				) => {
					let cellJSX = <></>
					cellJSX =
					<Box>
						<Button
							variant="outlined"
							sx={{ '&:hover': {
								backgroundColor: themeVariables.info_main,
								color: themeVariables.white,
							} }}
							size="small"
							color="info"
							className="tw-mr-1.5"
							onClick={() => {
								if ( rowData.key != null ){
									tableHooks.un_routerNaviation( ApplicationPages.AuditTimecardsViewPage.url( rowData.key as TsType_String ))
								}
							}}
						>
							<Icon icon="magnifying-glass" className="tw-mr-2" />
							{ s_VIEW }
						</Button>
						<Button
							variant="outlined"
							sx={{ '&:hover': {
								backgroundColor: themeVariables.success_main,
								color: themeVariables.white,
							} }}
							size="small"
							color="success"
							className="tw-mr-1.5"
							onClick={() => {
								if ( rowData.key != null ){
									tableHooks.uc_setUserInterface_PromptDialogDisplay({
										display: true,
										prompt: {
											color: "success",
											confirm_text: s_SAVE,
											default_value: null,
											header: s_RENAME_TIMECARD_AUDIT,
											icon: <Icon icon="pen-to-square" type="solid" />,
											input_label: s_ANALYSIS_NAME,
											input_type: "text",
											text: <></>,
											submit_callback: ( promptValue: TsType_String ) => {
												return new Promise( ( resolve, reject ) => {
													getClientKey( tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey ).then(( res_GCK ) => {
														let updateObject = {
															name: promptValue,
														}
														DatabaseSetMergeDocument( DatabaseRef_TimecardAnalysis_Document( res_GCK.clientKey, rowData.key as TsType_String ), updateObject, {} ).then( ( res_DAD ) => {
															resolve( res_DAD )
														}).catch( ( rej_DAD ) => {
															tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DAD.error })
															reject( rej_DAD )
														})
													}).catch(( rej_GCK ) => {
														tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
														reject( rej_GCK )
													})
												})
											}
										}
									})
								}
							}}
						>
							<Icon icon="pen-to-square" className="tw-mr-2" />
							{ s_RENAME }
						</Button>
						<Button
							variant="outlined"
							sx={{ '&:hover': {
								backgroundColor: themeVariables.error_main,
								color: themeVariables.white,
							} }}
							size="small"
							color="error"
							onClick={() => {
								if ( rowData.key != null ){
									tableHooks.uc_setUserInterface_ConfirmDialogDisplay({
										display: true,
										confirm: {
											color: "error",
											header: s_DELETE_ANALYSIS,
											icon: <Icon icon="trash" type="solid"/>,
											submit_text: s_DELETE,
											text: s_ARE_YOU_SURE_THAT_YOU_WANT_TO_DELETE_THIS_ANALYSIS,
											submit_callback: () => {
												return new Promise( ( resolve, reject ) => {
													getClientKey( tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey ).then(( res_GCK ) => {
														let updateObject = {
															status: "archived",
														}
														DatabaseSetMergeDocument( DatabaseRef_TimecardAnalysis_Document( res_GCK.clientKey, rowData.key as TsType_String ), updateObject, {} ).then( ( res_DAD ) => {
															resolve( res_DAD )
														}).catch( ( rej_DAD ) => {
															tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DAD.error })
															reject( rej_DAD )
														})
													}).catch(( rej_GCK ) => {
														tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
														reject( rej_GCK )
													})
												})
											},
										}
									})
								}
							}}
						>
							<Icon icon="trash" className="tw-mr-2" />
							{ s_DELETE }
						</Button>
					</Box>
					return cellJSX
				},
			},
		},
		// manage: TableCellManage({
			// view: {
			// 	icon: <Icon type="solid" icon="magnifying-glass" />,
			// 	label: <>{ s_VIEW }</>,
			// 	onClick: ( rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks ) => {
			// 		if ( rowData.key != null ){
			// 			tableHooks.un_routerNaviation( ApplicationPages.AuditTimecardsViewPage.url( rowData.key as TsType_String ))
			// 		}
			// 	}
			// },
			// rename: {
			// 	icon: <Icon type="solid" icon="pen-to-square" />,
			// 	label: <>{ s_RENAME }</>,
			// 	onClick: ( rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks ) => {
			// 		if ( rowData.key != null ){
			// 			tableHooks.uc_setUserInterface_PromptDialogDisplay({
			// 				display: true,
			// 				prompt: {
			// 					color: "success",
			// 					confirm_text: s_SAVE,
			// 					default_value: null,
			// 					header: s_RENAME_TIMECARD_AUDIT,
			// 					icon: <Icon icon="pen-to-square" type="solid" />,
			// 					input_label: s_ANALYSIS_NAME,
			// 					input_type: "text",
			// 					text: <></>,
			// 					submit_callback: ( promptValue: TsType_String ) => {
			// 						return new Promise( ( resolve, reject ) => {
			// 							getClientKey( tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey ).then(( res_GCK ) => {
			// 								let updateObject = {
			// 									name: promptValue,
			// 								}
			// 								DatabaseSetMergeDocument( DatabaseRef_TimecardAnalysis_Document( res_GCK.clientKey, rowData.key as TsType_String ), updateObject, {} ).then( ( res_DAD ) => {
			// 									resolve( res_DAD )
			// 								}).catch( ( rej_DAD ) => {
			// 									tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DAD.error })
			// 									reject( rej_DAD )
			// 								})
			// 							}).catch(( rej_GCK ) => {
			// 								tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
			// 								reject( rej_GCK )
			// 							})
			// 						})
			// 					}
			// 				}
			// 			})
			// 		}
			// 	}
			// },
			// delete: {
			// 	icon: <Icon type="solid" icon="trash" />,
			// 	label: <>{ s_DELETE }</>,
			// 	onClick: ( rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks ) => {
			// 		if ( rowData.key != null ){
			// 			tableHooks.uc_setUserInterface_ConfirmDialogDisplay({
			// 				display: true,
			// 				confirm: {
			// 					color: "error",
			// 					header: s_DELETE_ANALYSIS,
			// 					icon: <Icon icon="trash" type="solid"/>,
			// 					submit_text: s_DELETE,
			// 					text: s_ARE_YOU_SURE_THAT_YOU_WANT_TO_DELETE_THIS_ANALYSIS,
			// 					submit_callback: () => {
			// 						return new Promise( ( resolve, reject ) => {
			// 							getClientKey( tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey ).then(( res_GCK ) => {
			// 								let updateObject = {
			// 									status: "archived",
			// 								}
			// 								DatabaseSetMergeDocument( DatabaseRef_TimecardAnalysis_Document( res_GCK.clientKey, rowData.key as TsType_String ), updateObject, {} ).then( ( res_DAD ) => {
			// 									resolve( res_DAD )
			// 								}).catch( ( rej_DAD ) => {
			// 									tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DAD.error })
			// 									reject( rej_DAD )
			// 								})
			// 							}).catch(( rej_GCK ) => {
			// 								tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
			// 								reject( rej_GCK )
			// 							})
			// 						})
			// 					},
			// 				}
			// 			})
			// 		}
			// 	}
			// },
		// })
	}


///////////////////////////////
// Functions
///////////////////////////////


///////////////////////////////
// Container
///////////////////////////////

	export const Container: React.FC = (): TsType_JSX => {

		// Props
		// const params = useParams()
		// const itemKey: TsType_String = params.id as TsType_String

		// Hooks - useContext, useState, useReducer, other
		// { sort-start } - hooks
		// const [ items, setItems ] = 			useState<TsInterface_UnspecifiedObject>({})
		// const ur_forceRerender = 				useReducer(() => ({}), {})[1] as () => TsType_Void
		const un_routerNaviation = 														useNavigate()
		const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = 					useContext( Context_RootData_ClientKey )
		const { uc_setUserInterface_ErrorDialogDisplay } = 								useContext( Context_UserInterface_ErrorDialog )
		const { uc_setUserInterface_PromptDialogDisplay } = 							useContext( Context_UserInterface_PromptDialog )
		// { sort-end } - hooks

		// Hooks - useEffect
		useEffect(() => {
			document.title = se_TIMECARD_AUDITS
		}, [ ])

		useEffect(() => {
			getClientKey( uc_RootData_ClientKey, uc_setRootData_ClientKey )
			return () => { }
		}, [ uc_RootData_ClientKey, uc_setRootData_ClientKey ])

		// Other Variables

		// Functions
		const createAnalysis = (): TsType_Void => {
			uc_setUserInterface_PromptDialogDisplay({
				display: true,
				prompt: {
					color: "success",
					confirm_text: s_CREATE_TIMECARD_AUDIT,
					default_value: null,
					header: s_NEW_TIMECARD_AUDIT,
					icon: <Icon icon="pen-to-square" type="solid" />,
					input_label: s_ANALYSIS_NAME,
					input_type: "text",
					text: <></>,
					submit_callback: ( promptValue: TsType_String ) => {
						return new Promise( ( resolve, reject ) => {
							getClientKey( uc_RootData_ClientKey, uc_setRootData_ClientKey ).then(( res_GCK ) => {
								let updateObject = {
									name: promptValue,
									timestamp_created: new Date(),
									status: "active",
									substatus: "file_upload",
								}
								DatabaseAddDocument( DatabaseRef_TimecardAnalysis_Collection( res_GCK.clientKey ), updateObject, true, {} ).then( ( res_DAD ) => {
									un_routerNaviation( ApplicationPages.AuditTimecardsViewPage.url( res_DAD.key ) )
									resolve( res_DAD )
								}).catch( ( rej_DAD ) => {
									uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DAD.error })
									reject( rej_DAD )
								})
							}).catch(( rej_GCK ) => {
								uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
								reject( rej_GCK )
							})
						})
					}
				}
			})
		}

		const tableDatabaseEndpoint_TimecardAnalyses = ( queryGenerationData: TsInterface_TableDatabaseEndpointQueryObject, tableAdditionalData: TsInterface_TableAdditionalData ) => {
			// let queryOperatorsArray: TsInterface_QueryOperatorsArray = [ ]
			let queryOperatorsArray: TsInterface_QueryOperatorsArray = [ { prop: "status", comparator: "==", value: "active" } ]
			let orderByArray: TsInterface_OrderByArray = [ { prop: "timestamp_created", desc: true } ]
			let queryCursorsObject: TsInterface_QueryCursorsObject = {}
			if (queryGenerationData["startAfter"] != null){	 	queryCursorsObject["startAfter"] = queryGenerationData.startAfter }
			if (queryGenerationData["startAt"] != null){ 		queryCursorsObject["startAt"] = queryGenerationData.startAt }
			if (queryGenerationData["endAt"] != null){ 			queryCursorsObject["endAt"] = queryGenerationData.endAt }
			if (queryGenerationData["endBefore"] != null){ 		queryCursorsObject["endBefore"] = queryGenerationData.endBefore }
			let limit = getProp( queryGenerationData, "limit", 100 )
			return generateDatabaseQuery(
				DatabaseRef_TimecardAnalysis_Collection( uc_RootData_ClientKey as TsType_String ),
				queryOperatorsArray,
				orderByArray,
				queryCursorsObject,
				limit
			)
		}

		// JSX Generation
		const returnJSX_NewAnalysisButton = (): TsType_JSX => {
			let newAnalysisButtonJSX =
			<Button
				variant="contained"
				color="success"
				onClick={() => {
					createAnalysis()
				}}
			>
				<Icon icon="circle-plus" className="tw-mr-2" />
				{ s_NEW_TIMECARD_AUDIT }
			</Button>
			return newAnalysisButtonJSX
		}

		const returnJSX_VehicleTable = (): TsType_JSX => {
			let tableJSX = <></>
			if( uc_RootData_ClientKey != null){
				tableJSX =
				<Box>
					<Card className="">
						<TableDatabase
							tableAdditionalData={ {} }
							tableColumns={ tableColumns_TimecardAnalyses }
							tableDatabaseEndpoint={ tableDatabaseEndpoint_TimecardAnalyses }
							tableSettings={ tableSettings_TimecardAnalyses }
						/>
					</Card>
				</Box>
			} else {
				tableJSX = <></>
			}
			return tableJSX
		}

		const returnJSX_Page = (): TsType_JSX => {
			let pageJSX =
			<AuthenticatedContainer pageHeader={s_TIMECARD_AUDITS} pageKey={pageKey} content={
				<Box>
					<Box>
						{ returnJSX_NewAnalysisButton() }
					</Box>
					<Box className="tw-mt-2">
						{ returnJSX_VehicleTable() }
					</Box>
				</Box>
			}/>
			return pageJSX
		}

		// Render
		return <>{returnJSX_Page()}</>

	}