///////////////////////////////
// Imports
///////////////////////////////

import React from 'react'
import {
	TsType_Any,
	TsType_Unknown
} from 'rfbp_core/typescript/global_types'
import {
	Slide
} from '@mui/material'
import {
	TransitionProps
} from '@mui/material/transitions'

///////////////////////////////
// Exports
///////////////////////////////

export const TransitionSlide = React.forwardRef(function Transition(
	props: TransitionProps & {
		children: React.ReactElement< TsType_Any, TsType_Any >;
	},
	ref: React.Ref< TsType_Unknown >,
) {
	return <Slide direction="up" ref={ref} {...props} />;
})
