///////////////////////////////
// Description
///////////////////////////////

	/*
		DESCRIPTION / USAGE:
			Used to Initialize Firebase Instance

		TODO:

	*/

///////////////////////////////
// Imports
///////////////////////////////

import {
	generateDatabaseQuery,
	TsInterface_OrderByArray,
	TsInterface_QueryCursorsObject,
	TsInterface_QueryOperatorsArray
} from 'rfbp_core/services/database_management'
import {
	TsType_Any,
	TsType_Null,
	TsType_Number,
	TsType_String
} from 'rfbp_core/typescript/global_types'

///////////////////////////////
// Typescript
///////////////////////////////

interface TsInterface_SearchIndexObject {
	key: TsType_String
	type: "client" | "global"
	indexKey: TsType_String
	primaryKey: "id"
	indexedAttributes: {
		id: TsType_String
		[ propKey: TsType_String ]: TsType_String
	}
	filterableAttributes: TsType_String[],
	databaseDataEndpointOrderByProp: TsType_String
	databaseDataEndpoint: TsType_Any
	statusToSetIfNull?: TsType_String
	inactiveBoolToStatus?: TsType_String
}

interface TsInterface_SearchIndexList {
	[ indexKey: TsType_String ]: TsInterface_SearchIndexObject
}

///////////////////////////////
// Variables
///////////////////////////////

export const searchConfig = {
	host: "",
	apiKey: ""
}

export const searchIndexList: TsInterface_SearchIndexList = {
	// TODO
}

///////////////////////////////
// Functions
///////////////////////////////


///////////////////////////////
// Exports
///////////////////////////////