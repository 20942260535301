///////////////////////////////
// Description
///////////////////////////////

	/*
		DESCRIPTION / USAGE:
			containers are pages / views used in the app and are made up of components and can interact with services and models

		TODO:

			// SKIP FOR NOW
			// Checkbox to ignore driver mapping for non drivers... OR role like management overhead vs driver...
			// 	- switch to ignore mapping error
			// 	"Scheduled Overhead"

			Delete Files and Reupload Files

			Collaboration with limited access (no financials)
				- user accounts

			DOWNLOADS???

			List Page
				-- Better UI Revamp

			Other Pages
				-- Directory Page to edit rates... (need to think through how to save routes to db to be available...)
				- On lock can save the route + hours + station concatenate somewhere (not value, just that it exists)

			USER ACCOUNT CREATION

			USER MANAGEMENT VIEW (new client type to see all users and if they have paid or not / grant exceptions to access, etc)

			STRIPE PAYMENTS

			Page walkthrough video

	*/


///////////////////////////////
// Imports
///////////////////////////////

import {
	Chart as ChartJS,
	Legend,
	LinearScale,
	LineElement,
	PointElement,
	Tooltip
} from 'chart.js'
import React, {
	useContext,
	useEffect,
	useReducer,
	useState
} from 'react'
import {
	Scatter
} from 'react-chartjs-2'
import {
	Trans
} from 'react-i18next'
import {
	useCSVReader
} from 'react-papaparse'
import {
	useNavigate,
	useParams
} from 'react-router-dom'
import {
	themeVariables
} from 'rfbp_aux/config/app_theme'
import {
	AuthenticatedContainer
} from 'rfbp_aux/containers/authenticated_container'
import {
	ApplicationPages
} from 'rfbp_aux/data/application_structure'
import {
	DatabaseRef_TimecardAnalysis_DeliveryFile_Collection,
	DatabaseRef_TimecardAnalysis_DeliveryFile_Document,
	DatabaseRef_TimecardAnalysis_DeliveryReportColumnMapping_Document,
	DatabaseRef_TimecardAnalysis_Document,
	DatabaseRef_TimecardAnalysis_DriverNotes_Collection,
	DatabaseRef_TimecardAnalysis_DriverNotes_Document,
	DatabaseRef_TimecardAnalysis_LockedResults_Collection,
	DatabaseRef_TimecardAnalysis_LockedResults_Document,
	DatabaseRef_TimecardAnalysis_RoutePricing_Document,
	DatabaseRef_TimecardAnalysis_TimecardFile_Collection,
	DatabaseRef_TimecardAnalysis_TimecardFile_Document,
	DatabaseRef_TimecardAnalysis_TimecardReportColumnMapping_Document,
	DatabaseRef_TimecardAnalysis_TimecardReportPaycodeMultipliers_Document,
	DatabaseRef_TimecardAnalysis_UserMapping_Document
} from 'rfbp_aux/services/database_endpoints/analysis/timecard'
import {
	TransitionSlide
} from 'rfbp_core/components/dialog/transitions/slide'
import {
	TsInterface_FormAdditionalData,
	TsInterface_FormData,
	TsInterface_FormHooksObject,
	TsInterface_FormInputs,
	TsInterface_FormSettings,
	TsInterface_FormSubmittedData
} from 'rfbp_core/components/form'
import {
	Icon
} from 'rfbp_core/components/icons'
import {
	returnJSX_HighlightedSearchString
} from 'rfbp_core/components/search'
import {
	TableBasic,
	TableCellBasic,
	TsInterface_TableAdditionalData,
	TsInterface_TableColumns,
	TsInterface_TableDataRow,
	TsInterface_TableHooks,
	TsInterface_TableSettings
} from 'rfbp_core/components/table'
import {
	TabsBasic
} from 'rfbp_core/components/tabs'
import {
	Context_RootData_ClientKey,
	Context_UserInterface_ConfirmDialog,
	Context_UserInterface_CustomDialog,
	Context_UserInterface_ErrorDialog,
	Context_UserInterface_PromptDialog,
	UserInterface_Default_CustomDialogDisplayState
} from 'rfbp_core/services/context'
import {
	DatabaseBatchUpdate,
	DatabaseFieldDelete,
	DatabaseGetLiveCollection,
	DatabaseGetLiveDocument,
	DatabaseSetMergeDocument,
	DatabaseUpdateDocument,
	TsInterface_DatabaseBatchUpdatesArray
} from 'rfbp_core/services/database_management'
import {
	dynamicSort,
	getProp,
	objectToArray,
	returnDateFromUnknownDateFormat,
	returnFormattedDate,
	returnFormattedDateKey
} from 'rfbp_core/services/helper_functions'
import {
	getClientKey
} from 'rfbp_core/services/user_authentication'
import {
	TsInterface_UnspecifiedObject,
	TsType_Any,
	TsType_Boolean,
	TsType_JSX,
	TsType_MuiComponentColors,
	TsType_Null,
	TsType_Number,
	TsType_String,
	TsType_UnknownPromise,
	TsType_Void,
	TsType_VoidFunction
} from 'rfbp_core/typescript/global_types'
import {
	AppBar,
	Badge,
	Box,
	Button,
	Card,
	CircularProgress,
	Dialog,
	DialogContent,
	Divider,
	FormControl,
	FormControlLabel,
	IconButton,
	MenuItem,
	Radio,
	RadioGroup,
	Select,
	Stack,
	Switch,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableRow,
	Toolbar,
	Tooltip as MuiTooltip,
	Typography
} from '@mui/material/'
import Grid2 from '@mui/material/Unstable_Grid2'

///////////////////////////////
// Typescript
///////////////////////////////

// import json2mq from 'json2mq'
// import useMediaQuery from '@mui/material/useMediaQuery'

///////////////////////////////
// Variables
///////////////////////////////

	// Authenticated Nav Data
	const pageKey: TsType_String = ApplicationPages["AuditTimecardsViewPage"]["key"]

	// Displayed Translatable Strings
	// { sort-start } - displayed text - scoped sort plugin
	const s_ACTUAL_EXPECTED: TsType_JSX = 										<Trans>Actual / Expected</Trans>
	const s_ALL_HOURS: TsType_JSX = 											<Trans>All Hours</Trans>
	const s_ANALYSIS_RESULTS: TsType_JSX = 										<Trans>Analysis Results</Trans>
	const s_ARE_YOU_SURE_THAT_YOU_WANT_TO_DELETE_THIS_FILE: TsType_JSX = 		<Trans>Are you sure that you want to delete this file?</Trans>
	const s_ARE_YOU_SURE_THAT_YOU_WANT_TO_DELETE_THIS_NOTE: TsType_JSX = 		<Trans>Are you sure that you want to delete this note?</Trans>
	const s_ARE_YOU_SURE_THAT_YOU_WANT_TO_LOCK_THIS_ANALYSIS: TsType_JSX = 		<Trans>Are you sure that you want to lock this analysis?</Trans>
	const s_ARE_YOU_SURE_THAT_YOU_WANT_TO_UNLOCK_THIS_ANALYSIS: TsType_JSX = 	<Trans>Are you sure that you want to unlock this analysis?</Trans>
	const s_BACK: TsType_JSX = 													<Trans>Back</Trans>
	const s_CONFIRM_DELETION: TsType_JSX = 										<Trans>Confirm Deletion</Trans>
	const s_DATA_ERRORS: TsType_JSX = 											<Trans>Data Errors</Trans>
	const s_DATA_MAPPING: TsType_JSX = 											<Trans>Data Mapping</Trans>
	const s_DATA_TABLE_AND_NOTES: TsType_JSX = 									<Trans>Data Table and Notes</Trans>
	const s_DELETE: TsType_JSX = 												<Trans>Delete</Trans>
	const s_DELETE_FILE_UPLOAD_DATA: TsType_JSX = 								<Trans>Delete File Upload Data</Trans>
	const s_DELETE_NOTE: TsType_JSX = 											<Trans>Delete Note</Trans>
	const s_DELIVERED_PACKAGES: TsType_JSX = 									<Trans>Delivered Packages</Trans>
	const s_DELIVERY_ACTUAL: TsType_JSX = 										<Trans>Delivery Actual</Trans>
	const s_DELIVERY_DATA: TsType_JSX = 										<Trans>Delivery Data</Trans>
	const s_DELIVERY_DATE_RANGE: TsType_JSX = 									<Trans>Delivery Date Range</Trans>
	const s_DELIVERY_DRIVERS: TsType_JSX = 										<Trans>Delivery Drivers</Trans>
	const s_DELIVERY_EXPECTED: TsType_JSX = 									<Trans>Delivery Expected</Trans>
	const s_DELIVERY_EXPECTED_HOURS: TsType_JSX = 								<Trans>Delivery Expected Hours</Trans>
	const s_DELIVERY_HEADERS: TsType_JSX = 										<Trans>Delivery Headers</Trans>
	const s_DELTA: TsType_JSX = 												<Trans>Delta</Trans>
	const s_DISTANCE_ALLOWED: TsType_JSX = 										<Trans>Distance Allowed</Trans>
	const s_DISTANCE_PLANNED: TsType_JSX = 										<Trans>Distance Planned</Trans>
	const s_DRIVERS_NOT_LINKED_TO_PAYROLL: TsType_JSX = 						<Trans>Drivers not linked to payroll</Trans>
	const s_DRIVER_LINKING: TsType_JSX = 										<Trans>Driver Linking</Trans>
	const s_DURATION: TsType_JSX = 												<Trans>Duration</Trans>
	const s_EDIT: TsType_JSX = 													<Trans>Edit</Trans>
	const s_EDIT_NOTES: TsType_JSX = 											<Trans>Edit Notes</Trans>
	const s_EDIT_ROUTE_PRICE: TsType_JSX = 										<Trans>Edit Route Price</Trans>
	const s_EDIT_UPLOADED_DATA: TsType_JSX = 									<Trans>Edit Uploaded Data</Trans>
	const s_EMPLOYEES: TsType_JSX = 											<Trans>Employees</Trans>
	const s_EMPLOYEES_WITH_EXCLUDED_HOURS: TsType_JSX = 						<Trans>Employees with Excluded Hours</Trans>
	const s_EMPLOYEES_WITH_ROUTES: TsType_JSX = 								<Trans>Employees with Routes</Trans>
	const s_EMPLOYEES_WITH_WORK_HOURS: TsType_JSX = 							<Trans>Employees with Work Hours</Trans>
	const s_EMPLOYEES_WITH_WORK_HOURS_AND_NO_ROUTES: TsType_JSX = 				<Trans>Employees with Work Hours and No Routes</Trans>
	const s_EMPLOYEE_LINKED: TsType_JSX = 										<Trans>Employee Linked</Trans>
	const s_EMPLOYEE_MISSING_DELIVERY_DATA: TsType_JSX = 						<Trans>Employee Missing Delivery Data</Trans>
	const s_EMPLOYEE_MISSING_TIMECARD_DATA: TsType_JSX = 						<Trans>Employee Missing Timecard Data</Trans>
	const s_EMPLOYEE_NAME: TsType_JSX = 										<Trans>Employee Name</Trans>
	const s_ERRORS: TsType_JSX = 												<Trans>Errors</Trans>
	const s_EXCLUDED_HOURS: TsType_JSX = 										<Trans>Excluded Hours</Trans>
	const s_FAILED_TO_DELETE_FILE_UPLOAD_DATA: TsType_JSX = 					<Trans>Failed to delete file upload data</Trans>
	const s_FILE_UPLOADS: TsType_JSX = 											<Trans>File Uploads</Trans>
	const s_FIRST_AND_LAST_NAMES_IN_DIFFERENT_COLUMNS: TsType_JSX = 			<Trans>First and last names in different columns</Trans>
	const s_FULL_NAME_IN_A_SINGLE_COLUMN: TsType_JSX = 							<Trans>Full name in a single column</Trans>
	const s_GRAND_TOTAL: TsType_JSX = 											<Trans>Grand Total</Trans>
	const s_HRS: TsType_JSX = 													<Trans>hrs</Trans>
	const s_INCLUDE_IN_HOUR_DISCREPANCY_ANALYSIS: TsType_JSX = 					<Trans>Include in Hour Discrepancy Analysis</Trans>
	const s_LOCK_ANALYSIS: TsType_JSX = 										<Trans>Lock Analysis</Trans>
	const s_MAPPED: TsType_JSX = 												<Trans>Mapped</Trans>
	const s_NEW_VALUE: TsType_JSX = 											<Trans>New Value</Trans>
	const s_NOTES: TsType_JSX = 												<Trans>Notes</Trans>
	const s_NOT_MAPPED: TsType_JSX = 											<Trans>Not Mapped</Trans>
	const s_NO_NOTES: TsType_JSX = 												<Trans>No Notes</Trans>
	const s_NUMBER_OF_ROUTES_RUN: TsType_JSX = 									<Trans># of Routes Run</Trans>
	const s_NUMBER_OF_RUNS: TsType_JSX = 										<Trans>Number of Runs</Trans>
	const s_OPTIONAL: TsType_JSX = 												<Trans>Optional</Trans>
	const s_ORIGINAL_DELIVERY_UPLOAD_FILE: TsType_JSX = 						<Trans>Original Delivery Upload File</Trans>
	const s_ORIGINAl_PAYROLL_UPLOAD_FILE: TsType_JSX = 							<Trans>Original Payroll Upload File</Trans>
	const s_OTHERWISE_CLICK_DISMISS: TsType_JSX = 								<Trans>Otherwise click dismiss</Trans>
	const s_PACKAGE_BASE_RATE: TsType_JSX = 									<Trans>Package Base Rate</Trans>
	const s_PACKAGE_BONUS_RATE: TsType_JSX = 									<Trans>Package Bonus Rate</Trans>
	const s_PAYCODE: TsType_JSX = 												<Trans>Paycode</Trans>
	const s_PAYCODE_MULTIPLIERS: TsType_JSX = 									<Trans>Paycode Multipliers</Trans>
	const s_PAYROLL_DATE_RANGE: TsType_JSX = 									<Trans>Payroll Date Range</Trans>
	const s_RESCUED: TsType_JSX = 												<Trans>Rescued</Trans>
	const s_RESCURER: TsType_JSX = 												<Trans>Rescuer</Trans>
	const s_REVENUE_CALCULATION_ERRORS: TsType_JSX = 							<Trans>Revenue Calculation Errors</Trans>
	const s_REVENUE_FROM_PACKAGES: TsType_JSX = 								<Trans>Revenue from Packages</Trans>
	const s_REVENUE_FROM_ROUTES: TsType_JSX = 									<Trans>Revenue from Routes</Trans>
	const s_REVENUE_FROM_ROUTES_AND_PACKAGES: TsType_JSX = 						<Trans>Revenue from Routes and Packages</Trans>
	const s_REVENUE_PER_RUN: TsType_JSX = 										<Trans>Revenue per Run</Trans>
	const s_ROLES: TsType_JSX = 												<Trans>Roles</Trans>
	const s_ROUTE: TsType_JSX = 												<Trans>Route</Trans>
	const s_ROUTES: TsType_JSX = 												<Trans>Routes</Trans>
	const s_ROUTES_RUN: TsType_JSX = 											<Trans>Routes Run</Trans>
	const s_ROUTE_PRICE: TsType_JSX = 											<Trans>Route Price</Trans>
	const s_ROUTE_REVENUE: TsType_JSX = 										<Trans>Route Revenue</Trans>
	const s_ROUTE_TOTALS: TsType_JSX = 											<Trans>Route Totals</Trans>
	const s_ROUTE_TYPE: TsType_JSX = 											<Trans>Route Type</Trans>
	const s_SAVE: TsType_JSX = 													<Trans>Save</Trans>
	const s_SHIPMENTS_DELIVERED: TsType_JSX = 									<Trans>Shipments Delivered</Trans>
	const s_SHIPMENTS_RETURNED: TsType_JSX = 									<Trans>Shipments Returned</Trans>
	const s_STATION: TsType_JSX = 												<Trans>Station</Trans>
	const s_STATION_TOTALS: TsType_JSX = 										<Trans>Station Totals</Trans>
	const s_SUMMARY: TsType_JSX = 												<Trans>Summary</Trans>
	const s_TIMECARD_AUDIT: TsType_JSX = 										<Trans>Timecard Audit</Trans>
	const s_TIMECARD_DATA: TsType_JSX = 										<Trans>Timecard Data</Trans>
	const s_TIMECARD_EMPLOYEES: TsType_JSX = 									<Trans>Timecard Employees</Trans>
	const s_TIMECARD_HEADERS: TsType_JSX = 										<Trans>Timecard Headers</Trans>
	const s_TOTAL_EXCLUDED_HOURS: TsType_JSX = 									<Trans>Total Excluded Hours</Trans>
	const s_TOTAL_EXPECTED_ROUTE_HOURS: TsType_JSX = 							<Trans>Total Expected Route Hours</Trans>
	const s_TOTAL_PKG_BASE: TsType_JSX = 										<Trans>Total PKG Base</Trans>
	const s_TOTAL_PKG_BONUS: TsType_JSX = 										<Trans>Total PKG Bonus</Trans>
	const s_TOTAL_ROUTE_REVENUE: TsType_JSX = 									<Trans>Total Route Revenue</Trans>
	const s_TOTAL_WORK_HOURS: TsType_JSX = 										<Trans>Total Work Hours</Trans>
	const s_TYPE_DELETE_IN_ALL_UPPERCASE_TO_PROCEED: TsType_JSX = 				<Trans>Type "DELETE" in all upsercase to proceed</Trans>
	const s_UNLINK: TsType_JSX = 												<Trans>Unlink</Trans>
	const s_UNLOCK_ANALYSIS: TsType_JSX = 										<Trans>Unlock Analysis</Trans>
	const s_WAGE_MULTIPLIER: TsType_JSX = 										<Trans>Wage Multiplier</Trans>
	const s_WIPE_UPLOAD: TsType_JSX = 											<Trans>Wipe Upload</Trans>
	const s_WORK_HOURS: TsType_JSX = 											<Trans>Work Hours</Trans>
	const s_WORK_MINUS_ROUTE_HOURS: TsType_JSX = 								<Trans>Work Minus Route Hours</Trans>
	const s_YOU_MUST_ENTER_DELETE_IN_ORDER_TO_PROCEED: TsType_JSX = 			<Trans>You must enter DELETE in order to proceed</Trans>
	// { sort-end } - displayed text

	// Analysis Pages
	const analysisPageOptions: TsInterface_UnspecifiedObject = {
		main_summary: {
			key: "main_summary",
			name: s_SUMMARY,
			show_date_dropdown: true,
		},
		route_revenue: {
			key: "route_revenue",
			name: s_ROUTE_REVENUE,
			show_date_dropdown: true,
		},
		// driver_notes: {
			// key: "driver_notes",
			// name: s_EMPLOYEE_NOTES,
			// show_date_dropdown: true,
		// },
		full_data_table: {
			key: "full_data_table",
			name: s_DATA_TABLE_AND_NOTES,
			show_date_dropdown: true,
		},
		data_errors: {
			key: "data_errors",
			name: s_DATA_ERRORS,
			show_date_dropdown: false,
		},
		delivery_upload_file: {
			key: "delivery_upload_file",
			name: s_ORIGINAL_DELIVERY_UPLOAD_FILE,
			show_date_dropdown: false,
		},
		payroll_upload_file: {
			key: "payroll_upload_file",
			name: s_ORIGINAl_PAYROLL_UPLOAD_FILE,
			show_date_dropdown: false,
		},
	}

	// Default Mapping Objects
	const defaultDeliveryReportMappingData: TsInterface_UnspecifiedObject = {
		date: { key: "date", default_header: "Date", name: "Date" },
		station: { key: "station", default_header: "Station", name: "Station" },
		// dsp_code: { key: "dsp_code", default_header: "DSP Short Code", name: "DSP Short Code" },
		employee_name: { key: "employee_name", default_header: "Delivery Associate", name: "Delivery Associate" },
		route: { key: "route", default_header: "Route", name: "Route" },
		route_type: { key: "route_type", default_header: "Service Type", name: "Service Type" },
		duration: { key: "duration", default_header: "Planned Duration", name: "Planned Duration" },
		start_time: { key: "start_time", default_header: "Log in", name: "Log in" },
		end_time: { key: "end_time", default_header: "Log out", name: "Log out" },
		distance_planned: { key: "distance_planned", default_header: "Total Distance Planned", name: "Total Distance Planned" },
		distance_allowed: { key: "distance_allowed", default_header: "Total Distance Allowance", name: "Total Distance Allowance" },
		distance_units: { key: "distance_units", default_header: "Distance unit", name: "Distance unit" },
		packages_delivered: { key: "packages_delivered", default_header: "Shipments delivered", name: "Shipments delivered" },
		packages_returned: { key: "packages_returned", default_header: "Shipments returned", name: "Shipments returned" },
		// excluded: { key: "excluded", default_header: "Excluded?", name: "Excluded?" },
	}

	const defaultTimecardReportMappingData: TsInterface_UnspecifiedObject = {
		// company_code: { key: "company_code", default_header: "Company Code", name: "Company Code" },
		employee_name: { key: "employee_name", default_header: "Payroll Name", name: "Employee Name" },
		// employee_name_formatted: { key: "employee_name_formatted", default_header: "Name (Delivery Format)", name: "Name (Delivery Format)" },
		employee_role: { key: "employee_role", default_header: "Home Department Description", name: "Employee Role" },
		// file_number: { key: "file_number", default_header: "File Number", name: "File Number" },
		pay_code: { key: "pay_code", default_header: "Pay Code [Timecard]", name: "Pay Code" },
		date: { key: "date", default_header: "Payroll Pay Date", name: "Date" },
		hours: { key: "hours", default_header: "Hours (Payroll)", name: "Hours" },
		pay_rate: { key: "pay_rate", default_header: "Regular Pay Rate Amount", name: "Pay Rate" },
		// start_date: { key: "start_date", default_header: "Start Date", name: "Start Date" },
		// end_date: { key: "end_date", default_header: "End Date", name: "End Date" },
		// effective_employment_date: { key: "effective_employment_date", default_header: "Employment Profile - Effective Date", name: "Employment Profile - Effective Date" },
		// effective_pay_rate_date: { key: "effective_pay_rate_date", default_header: "Employment Profile - Pay Rates - Effective Date", name: "Employment Profile - Pay Rates - Effective Date" },
	}

	const firstAndLastNameTimecardReportMappingData: TsInterface_UnspecifiedObject = {
		// company_code: { key: "company_code", default_header: "Company Code", name: "Company Code" },
		first_name: { key: "first_name", default_header: "First Name", name: "Employee First Name" },
		last_name: { key: "last_name", default_header: "Last Name", name: "Employee Last Name" },
		// employee_name_formatted: { key: "employee_name_formatted", default_header: "Name (Delivery Format)", name: "Name (Delivery Format)" },
		employee_role: { key: "employee_role", default_header: "Home Department Description", name: "Employee Role" },
		// file_number: { key: "file_number", default_header: "File Number", name: "File Number" },
		pay_code: { key: "pay_code", default_header: "Pay Code [Timecard]", name: "Pay Code" },
		date: { key: "date", default_header: "Payroll Pay Date", name: "Date" },
		hours: { key: "hours", default_header: "Hours (Payroll)", name: "Hours" },
		pay_rate: { key: "pay_rate", default_header: "Regular Pay Rate Amount", name: "Pay Rate" },
		// start_date: { key: "start_date", default_header: "Start Date", name: "Start Date" },
		// end_date: { key: "end_date", default_header: "End Date", name: "End Date" },
		// effective_employment_date: { key: "effective_employment_date", default_header: "Employment Profile - Effective Date", name: "Employment Profile - Effective Date" },
		// effective_pay_rate_date: { key: "effective_pay_rate_date", default_header: "Employment Profile - Pay Rates - Effective Date", name: "Employment Profile - Pay Rates - Effective Date" },
	}

	// Tables
	const tableSettings_RawData: TsInterface_TableSettings = {
		paginated: false,
		show_header: true,
		size: "small",
		sort_direction: "asc",
		sort_property_default: "key",
		sortable: false,
		collapsible_columns: true,
	}

	const tableSettings_AnalaysisDefault: TsInterface_TableSettings = {
		paginated: false,
		show_header: true,
		size: "small",
		// sort_direction: "asc",
		sort_direction: "desc",
		// sort_property_default: "name",
		sort_property_default: "calculated_payroll_minus_route_hours_total",
		sortable: true,
		collapsible_columns: true,
	}

	// TODO - permissioned access
	const openRawDataDialog = (
		userData: TsInterface_UnspecifiedObject,
		tableHooks: TsInterface_TableHooks
	) => {
		// tableHooks.uc_setUserInterface_CustomDialogDisplay({
		// 	display: true,
		// 	dialog: {
		// 		dialog_jsx: <Json data={ userData } />,
		// 		settings: {
		// 			max_width: "lg"
		// 		}
		// 	},
		// })
	}

	const tableColumns_FullData: TsInterface_TableColumns = {
		TEMP_notes: {
			cell: {
				cell_css: (
					rowData: TsInterface_TableDataRow,
					tableAdditionalData: TsInterface_TableAdditionalData
				) => { return "" },
				cell_jsx: (
					rowData: TsInterface_TableDataRow,
					tableAdditionalData: TsInterface_TableAdditionalData,
					tableHooks: TsInterface_TableHooks
				) => {
					const returnJSX_EmployeeNotes = ( dayFilter: TsInterface_UnspecifiedObject ): TsType_JSX => {
						let notesJSX = <></>
						const returnJSX_DeleteNoteIcon = (
							employeeName: TsType_String,
							dateKey: TsType_String,
							tableHooks: TsInterface_TableHooks
						) => {
							let iconJSX = <></>
							iconJSX =
							<Icon
								icon="square-xmark"
								sx={{
									color: themeVariables.gray_400,
									'&:hover': {
										color: themeVariables.error_main,
									}
								}}
								className="tw-ml-2"
								tooltip={ s_DELETE_NOTE }
								onClick={ () => {
									tableHooks.uc_setUserInterface_ConfirmDialogDisplay({
										display: true,
										confirm: {
											color: "error",
											header: s_DELETE_NOTE,
											icon: <Icon icon="square-xmark" type="solid"/>,
											submit_text: s_DELETE,
											text: s_ARE_YOU_SURE_THAT_YOU_WANT_TO_DELETE_THIS_NOTE,
											submit_callback: () => {
												return new Promise( ( resolve, reject ) => {
													getClientKey( tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey ).then(( res_GCK ) => {
														let updateObject = {
															[ dateKey ]: null
														}
														DatabaseSetMergeDocument( DatabaseRef_TimecardAnalysis_DriverNotes_Document( res_GCK.clientKey, tableAdditionalData.analysisKey as TsType_String, employeeName ), updateObject, {} ).then(( res_DSM ) => {
															resolve( res_DSM )
														}).catch(( rej_DSM ) => {
															tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSM.error })
															reject( rej_DSM )
														})
													}).catch(( rej_GCK ) => {
														tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
														reject( rej_GCK )
													})
												})
											},
										}
									})
								}}
							/>
							return iconJSX
						}
						if(
							tableAdditionalData != null &&
							tableAdditionalData["us_selectedDateFilter"] != null &&
							dayFilter != null &&
							dayFilter.key != null &&
							tableAdditionalData["us_selectedDateFilter"] === dayFilter.key
						){
							let noteValueJSX: TsType_JSX | TsType_String = <></>
							if(
								rowData != null &&
								rowData.name != null &&
								tableAdditionalData != null &&
								tableAdditionalData["us_selectedDateFilter"]  != null &&
								tableAdditionalData.us_analysisDriverNotesData != null &&
								tableAdditionalData.us_analysisDriverNotesData[ rowData.name as TsType_String ] != null &&
								tableAdditionalData.us_analysisDriverNotesData[ rowData.name as TsType_String ][ tableAdditionalData["us_selectedDateFilter"] ] != null
							){
								noteValueJSX =
								<Box className="tw-inline-block" sx={{ color: themeVariables.info_light }}>
									{ tableAdditionalData.us_analysisDriverNotesData[ rowData.name as TsType_String ][ tableAdditionalData["us_selectedDateFilter"] ] }
									{ returnJSX_DeleteNoteIcon( rowData.name as TsType_String, tableAdditionalData["us_selectedDateFilter"], tableHooks ) }
								</Box>
							} else {
								noteValueJSX =
								<Box className="tw-inline-block tw-opacity-40 tw-italic">
									{ s_NO_NOTES }
								</Box>
							}
							notesJSX =
							<Box className="">
								<Box className="tw-inline-block tw-font-bold tw-mr-1">{ dayFilter.value }:</Box>
								<Box className="tw-inline-block">
									 { noteValueJSX }
								</Box>
								<Icon
									className='tw-ml-2 tw-opacity-40 tw-cursor-pointer hover:tw-opacity-100 hover:tw-text-green-700'
									icon="pen-to-square"
									tooltip={ s_EDIT_NOTES }
									tooltipPlacement='right'
									onClick={ () => {
										// Set Form Data
										let formData: TsInterface_UnspecifiedObject = {}
										if(
											rowData != null &&
											rowData.name != null &&
											tableAdditionalData != null &&
											tableAdditionalData["us_selectedDateFilter"]  != null &&
											tableAdditionalData.us_analysisDriverNotesData != null &&
											tableAdditionalData.us_analysisDriverNotesData[ rowData.name as TsType_String ] != null &&
											tableAdditionalData.us_analysisDriverNotesData[ rowData.name as TsType_String ][ tableAdditionalData["us_selectedDateFilter"] ] != null
										){
											formData = { notes: tableAdditionalData.us_analysisDriverNotesData[ rowData.name as TsType_String ][ tableAdditionalData["us_selectedDateFilter"] ] }
										}
										// Open Dialog
										tableHooks.uc_setUserInterface_FormDialogDisplay({
											display: true,
											form: {
												form: {
													formAdditionalData: {},
													formData: formData,
													formInputs: {
														notes: {
															data_type: "string",
															input_type: "text_multiline",
															key: "notes",
															label: s_NOTES,
															required: true,
														}
													},
													formOnChange: ( formAdditionalData: TsInterface_FormAdditionalData, formData: TsInterface_FormData, formInputs: TsInterface_FormInputs, formSettings: TsInterface_FormSettings ) => {},
													formSettings: {},
													formSubmission: ( formSubmittedData: TsInterface_FormSubmittedData, formAdditionalData: TsInterface_FormAdditionalData, formHooks: TsInterface_FormHooksObject ) => {
														return new Promise( ( resolve, reject ) => {
															getClientKey( formHooks.uc_RootData_ClientKey, formHooks.uc_setRootData_ClientKey ).then(( res_GCK ) => {
																let updateObject = {
																	[ tableAdditionalData["us_selectedDateFilter"] ]: formSubmittedData.notes
																}
																DatabaseSetMergeDocument( DatabaseRef_TimecardAnalysis_DriverNotes_Document( res_GCK.clientKey, tableAdditionalData.analysisKey as TsType_String, rowData.name as TsType_String ), updateObject, {} ).then(( res_DSM ) => {
																	resolve( res_DSM )
																}).catch(( rej_DSM ) => {
																	formHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSM.error })
																	reject( rej_DSM )
																})
															}).catch(( rej_GCK ) => {
																formHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
																reject( rej_GCK )
															})
														})
													},
												},
												dialog: {
													formDialogHeaderColor: "success",
													formDialogHeaderText: <>{ s_NOTES } - { rowData.name } ({ dayFilter.value })</>,
													formDialogIcon: <Icon type="solid" icon="pen-to-square"/>
												}
											}
										})
									}}
								/>
							</Box>
						} else {
							let noteValueJSX: TsType_JSX | TsType_String = <></>
							if(
								rowData != null &&
								rowData.name != null &&
								tableAdditionalData != null &&
								tableAdditionalData["us_selectedDateFilter"]  != null &&
								tableAdditionalData.us_analysisDriverNotesData != null &&
								tableAdditionalData.us_analysisDriverNotesData[ rowData.name as TsType_String ] != null &&
								tableAdditionalData.us_analysisDriverNotesData[ rowData.name as TsType_String ][ dayFilter.key ] != null
							){
								noteValueJSX =
								<Box className="tw-inline-block" sx={{ color: themeVariables.info_light }}>
									{ tableAdditionalData.us_analysisDriverNotesData[ rowData.name as TsType_String ][ dayFilter.key ] }
									{ returnJSX_DeleteNoteIcon( rowData.name as TsType_String, dayFilter.key, tableHooks ) }
								</Box>
							} else {
								noteValueJSX =
								<Box className="tw-inline-block tw-opacity-40 tw-italic">
									{ s_NO_NOTES }
								</Box>
							}
							if(
								rowData != null &&
								rowData.name != null &&
								dayFilter != null &&
								dayFilter.key != null &&
								tableAdditionalData != null &&
								tableAdditionalData.us_analysisDriverNotesData != null &&
								tableAdditionalData.us_analysisDriverNotesData[ rowData.name as TsType_String ] != null &&
								tableAdditionalData.us_analysisDriverNotesData[ rowData.name as TsType_String ][ dayFilter.key ] != null
							){
								notesJSX =
								<Box className="tw-opacity-40">
									{ dayFilter.value }: { noteValueJSX }
								</Box>
							}
						}
						return notesJSX
					}
					let cellJSX =
					<Box>
						{objectToArray( getProp( tableAdditionalData, "us_dateDropdownOptions", {} ) ).sort( dynamicSort("sort", "asc") ).map(( dayFilter: TsInterface_UnspecifiedObject, index: TsType_Number ) => (
							<Box key={ index } >
								{ returnJSX_EmployeeNotes( dayFilter ) }
							</Box>
						))}
					</Box>
					return cellJSX
				},
			},
			header: {
				header_css: ( tableAdditionalData: TsInterface_TableAdditionalData ) => { return "" },
				header_jsx: ( tableAdditionalData: TsInterface_TableAdditionalData ) => { return s_NOTES },
				header_sort_by: null
			}
		},
		TEMP_icons: {
			cell: {
				cell_css: (
					rowData: TsInterface_TableDataRow,
					tableAdditionalData: TsInterface_TableAdditionalData
				) => { return "" },
				cell_jsx: (
					rowData: TsInterface_TableDataRow,
					tableAdditionalData: TsInterface_TableAdditionalData,
					tableHooks: TsInterface_TableHooks
				) => {
					let missingDeliveryDataIconJSX = <></>
					let missingTimecardDataIconJSX = <></>
					let linkedIconJSX = <></>
					let showLinkedIcon = true
					if( getProp(rowData, "has_delivery_data", false) === false ){
						missingDeliveryDataIconJSX =
						<Icon
							icon="truck"
							sx={{ color: themeVariables.error_main }}
							tooltipPlacement='right'
							tooltip={ s_EMPLOYEE_MISSING_DELIVERY_DATA }
							className="tw-mr-2"
						/>
						showLinkedIcon = false
					}
					if( getProp(rowData, "has_payroll_data", false) === false ){
						missingTimecardDataIconJSX =
						<Icon
							icon="clock"
							sx={{ color: themeVariables.error_main }}
							tooltipPlacement='right'
							tooltip={ s_EMPLOYEE_MISSING_TIMECARD_DATA }
							className="tw-mr-2"
						/>
						showLinkedIcon = false
					}
					if( showLinkedIcon === true ){
						missingTimecardDataIconJSX =
						<Icon
							icon="circle-check"
							sx={{ color: themeVariables.success_main }}
							tooltipPlacement='right'
							tooltip={ s_EMPLOYEE_LINKED }
							className="tw-mr-2"
						/>
					}
					let cellJSX =
					<Box
						className="tw-cursor-default"
						onDoubleClick={ () => {
							openRawDataDialog( rowData, tableHooks )
						}}
					>
						{ missingDeliveryDataIconJSX }
						{ missingTimecardDataIconJSX }
						{ linkedIconJSX }
					</Box>
					return cellJSX
				},
			},
			header: {
				header_css: ( tableAdditionalData: TsInterface_TableAdditionalData ) => { return "" },
				header_jsx: ( tableAdditionalData: TsInterface_TableAdditionalData ) => { return s_ERRORS },
				header_sort_by: null
			}
		},
		name: TableCellBasic( "name", s_EMPLOYEE_NAME, "name" ),
		delivery_route_types_breakdown: {
			cell: {
				cell_css: (
					rowData: TsInterface_TableDataRow,
					tableAdditionalData: TsInterface_TableAdditionalData
				) => { return "" },
				cell_jsx: (
					rowData: TsInterface_TableDataRow,
					tableAdditionalData: TsInterface_TableAdditionalData,
					tableHooks: TsInterface_TableHooks
				) => {
					let cellJSX =
					<Box>
						{objectToArray( getProp( rowData, "delivery_route_types_breakdown", {} ) ).sort().map(( data: TsType_String, index: TsType_Number ) => (
							<Box key={ index } >
								{ data }
							</Box>
						))}
					</Box>
					return cellJSX
				},
			},
			header: {
				header_css: ( tableAdditionalData: TsInterface_TableAdditionalData ) => { return "" },
				header_jsx: ( tableAdditionalData: TsInterface_TableAdditionalData ) => { return s_ROUTES },
				header_sort_by: null
			}
		},
		payroll_role_breakdown: {
			cell: {
				cell_css: (
					rowData: TsInterface_TableDataRow,
					tableAdditionalData: TsInterface_TableAdditionalData
				) => { return "" },
				cell_jsx: (
					rowData: TsInterface_TableDataRow,
					tableAdditionalData: TsInterface_TableAdditionalData,
					tableHooks: TsInterface_TableHooks
				) => {
					let cellJSX =
					<Box>
						{objectToArray( getProp( rowData, "payroll_role_breakdown", {} ) ).sort().map(( data: TsType_String, index: TsType_Number ) => (
							<Box key={ index } >
								{ data }
							</Box>
						))}
					</Box>
					return cellJSX
				},
			},
			header: {
				header_css: ( tableAdditionalData: TsInterface_TableAdditionalData ) => { return "" },
				header_jsx: ( tableAdditionalData: TsInterface_TableAdditionalData ) => { return s_ROLES },
				header_sort_by: null
			}
		},
		payroll_clocked_hours_all_total: {
			cell: {
				cell_css: (
					rowData: TsInterface_TableDataRow,
					tableAdditionalData: TsInterface_TableAdditionalData
				) => { return "" },
				cell_jsx: (
					rowData: TsInterface_TableDataRow,
					tableAdditionalData: TsInterface_TableAdditionalData,
					tableHooks: TsInterface_TableHooks
				) => {
					let cellJSX =
					<Box>
						{ getProp(rowData, "payroll_clocked_hours_all_total", 0).toFixed(1) } { s_HRS }
					</Box>
					return cellJSX
				},
			},
			header: {
				header_css: ( tableAdditionalData: TsInterface_TableAdditionalData ) => { return "" },
				header_jsx: ( tableAdditionalData: TsInterface_TableAdditionalData ) => { return s_ALL_HOURS },
				header_sort_by: "payroll_clocked_hours_all_total"
			}
		},
		payroll_clocked_hours_included_total: {
			cell: {
				cell_css: (
					rowData: TsInterface_TableDataRow,
					tableAdditionalData: TsInterface_TableAdditionalData
				) => { return "" },
				cell_jsx: (
					rowData: TsInterface_TableDataRow,
					tableAdditionalData: TsInterface_TableAdditionalData,
					tableHooks: TsInterface_TableHooks
				) => {
					let boxSX: TsInterface_UnspecifiedObject = {}
					if(
						tableAdditionalData != null &&
						tableAdditionalData["us_userHourColors"] != null &&
						rowData != null &&
						rowData.name != null &&
						tableAdditionalData["us_userHourColors"][ rowData.name as TsType_String ] != null
					){
						boxSX = { background: tableAdditionalData["us_userHourColors"][ rowData.name as TsType_String ], color: themeVariables.white }
					}
					let cellJSX =
					<Box>
						<Box className="tw-rounded tw-px-2 tw-py-1 tw-inline-block" sx={ boxSX }>
							{ getProp(rowData, "payroll_clocked_hours_included_total", 0).toFixed(1) } { s_HRS }
						</Box>
					</Box>
					return cellJSX
				},
			},
			header: {
				header_css: ( tableAdditionalData: TsInterface_TableAdditionalData ) => { return "" },
				header_jsx: ( tableAdditionalData: TsInterface_TableAdditionalData ) => { return s_WORK_HOURS },
				header_sort_by: "payroll_clocked_hours_included_total"
			}
		},
		calculated_payroll_minus_route_hours_total: {
			cell: {
				cell_css: (
					rowData: TsInterface_TableDataRow,
					tableAdditionalData: TsInterface_TableAdditionalData
				) => { return "" },
				cell_jsx: (
					rowData: TsInterface_TableDataRow,
					tableAdditionalData: TsInterface_TableAdditionalData,
					tableHooks: TsInterface_TableHooks
				) => {
					let boxSX: TsInterface_UnspecifiedObject = {}
					if(
						tableAdditionalData != null &&
						tableAdditionalData["us_userHourColors"] != null &&
						rowData != null &&
						rowData.name != null &&
						tableAdditionalData["us_userHourColors"][ rowData.name as TsType_String ] != null
					){
						boxSX = {
							color: tableAdditionalData["us_userHourColors"][ rowData.name as TsType_String ],
							borderWidth: "1px",
							borderStyle: "solid",
							borderColor: tableAdditionalData["us_userHourColors"][ rowData.name as TsType_String ]
						}
					}
					let plusJSX = <></>
					if( getProp(rowData, "calculated_payroll_minus_route_hours_total", 0) >= 0){
						plusJSX = <>+</>
					}
					let cellJSX =
					<Box>
						<Box className="tw-rounded tw-px-2 tw-py-1 tw-inline-block tw-font-bold" sx={ boxSX }>
							{plusJSX}{ getProp(rowData, "calculated_payroll_minus_route_hours_total", 0).toFixed(1) } { s_HRS }
						</Box>
					</Box>
					return cellJSX
				},
			},
			header: {
				header_css: ( tableAdditionalData: TsInterface_TableAdditionalData ) => { return "" },
				header_jsx: ( tableAdditionalData: TsInterface_TableAdditionalData ) => { return s_DELTA },
				header_sort_by: "calculated_payroll_minus_route_hours_total"
			}
		},
		delivery_route_hours_total: {
			cell: {
				cell_css: (
					rowData: TsInterface_TableDataRow,
					tableAdditionalData: TsInterface_TableAdditionalData
				) => { return "" },
				cell_jsx: (
					rowData: TsInterface_TableDataRow,
					tableAdditionalData: TsInterface_TableAdditionalData,
					tableHooks: TsInterface_TableHooks
				) => {
					let cellJSX =
					<Box>
						{ getProp(rowData, "delivery_route_hours_total", 0).toFixed(1) } { s_HRS }
					</Box>
					return cellJSX
				},
			},
			header: {
				header_css: ( tableAdditionalData: TsInterface_TableAdditionalData ) => { return "" },
				header_jsx: ( tableAdditionalData: TsInterface_TableAdditionalData ) => { return s_DELIVERY_EXPECTED },
				header_sort_by: "delivery_route_hours_total"
			}
		},
		delivery_clocked_hours_total: {
			cell: {
				cell_css: (
					rowData: TsInterface_TableDataRow,
					tableAdditionalData: TsInterface_TableAdditionalData
				) => { return "" },
				cell_jsx: (
					rowData: TsInterface_TableDataRow,
					tableAdditionalData: TsInterface_TableAdditionalData,
					tableHooks: TsInterface_TableHooks
				) => {
					let cellJSX =
					<Box>
						{ getProp(rowData, "delivery_clocked_hours_total", 0).toFixed(1) } { s_HRS }
					</Box>
					return cellJSX
				},
			},
			header: {
				header_css: ( tableAdditionalData: TsInterface_TableAdditionalData ) => { return "" },
				header_jsx: ( tableAdditionalData: TsInterface_TableAdditionalData ) => { return s_DELIVERY_ACTUAL },
				header_sort_by: "delivery_clocked_hours_total"
			}
		},
		calculated_delivery_actual_divided_by_delivery_expected: {
			cell: {
				cell_css: (
					rowData: TsInterface_TableDataRow,
					tableAdditionalData: TsInterface_TableAdditionalData
				) => { return "" },
				cell_jsx: (
					rowData: TsInterface_TableDataRow,
					tableAdditionalData: TsInterface_TableAdditionalData,
					tableHooks: TsInterface_TableHooks
				) => {
					let boxSX: TsInterface_UnspecifiedObject = {}
					if( getProp(rowData, "calculated_delivery_actual_divided_by_delivery_expected", 0) > 100 ){
						boxSX = {
							color: themeVariables.error_main,
							borderWidth: "1px",
							borderStyle: "solid",
							borderColor: themeVariables.error_main
						}
					} else if( getProp(rowData, "calculated_delivery_actual_divided_by_delivery_expected", 0) > 0 ){
						boxSX = {
							color: themeVariables.success_main,
							borderWidth: "1px",
							borderStyle: "solid",
							borderColor: themeVariables.success_main
						}
					} else {
						boxSX = {
							color: themeVariables.gray_400,
							borderWidth: "1px",
							borderStyle: "solid",
							borderColor: themeVariables.gray_400
						}
					}


					let cellJSX =
					<Box>
						<Box className="tw-rounded tw-px-2 tw-py-1 tw-inline-block tw-font-bold" sx={ boxSX }>
							{ getProp(rowData, "calculated_delivery_actual_divided_by_delivery_expected", 0).toFixed(1) }%
						</Box>
					</Box>
					return cellJSX
				},
			},
			header: {
				header_css: ( tableAdditionalData: TsInterface_TableAdditionalData ) => { return "" },
				header_jsx: ( tableAdditionalData: TsInterface_TableAdditionalData ) => { return s_ACTUAL_EXPECTED },
				header_sort_by: "calculated_delivery_actual_divided_by_delivery_expected"
			}
		},
		payroll_clocked_hours_excluded_total: {
			cell: {
				cell_css: (
					rowData: TsInterface_TableDataRow,
					tableAdditionalData: TsInterface_TableAdditionalData
				) => { return "" },
				cell_jsx: (
					rowData: TsInterface_TableDataRow,
					tableAdditionalData: TsInterface_TableAdditionalData,
					tableHooks: TsInterface_TableHooks
				) => {
					let cellJSX =
					<Box>
						{ getProp(rowData, "payroll_clocked_hours_excluded_total", 0).toFixed(1) } { s_HRS }
					</Box>
					return cellJSX
				},
			},
			header: {
				header_css: ( tableAdditionalData: TsInterface_TableAdditionalData ) => { return "" },
				header_jsx: ( tableAdditionalData: TsInterface_TableAdditionalData ) => { return s_EXCLUDED_HOURS },
				header_sort_by: "payroll_clocked_hours_excluded_total"
			}
		},
		// payroll_pay_total: {
			// cell: {
			// 	cell_css: (
			// 		rowData: TsInterface_TableDataRow,
			// 		tableAdditionalData: TsInterface_TableAdditionalData
			// 	) => { return "" },
			// 	cell_jsx: (
			// 		rowData: TsInterface_TableDataRow,
			// 		tableAdditionalData: TsInterface_TableAdditionalData,
			// 		tableHooks: TsInterface_TableHooks
			// 	) => {
			// 		let cellJSX =
			// 		<Box>
			// 			{ formatCurrency( getProp(rowData, "payroll_pay_total", 0) ) }
			// 		</Box>
			// 		return cellJSX
			// 	},
			// },
			// header: {
			// 	header_css: ( tableAdditionalData: TsInterface_TableAdditionalData ) => { return "" },
			// 	header_jsx: ( tableAdditionalData: TsInterface_TableAdditionalData ) => { return s_TOTAL_PAY },
			// 	header_sort_by: "delivery_clocked_hours_total"
			// }
		// },
		// delivery_route_revenue_total: {
			// cell: {
			// 	cell_css: (
			// 		rowData: TsInterface_TableDataRow,
			// 		tableAdditionalData: TsInterface_TableAdditionalData
			// 	) => { return "" },
			// 	cell_jsx: (
			// 		rowData: TsInterface_TableDataRow,
			// 		tableAdditionalData: TsInterface_TableAdditionalData,
			// 		tableHooks: TsInterface_TableHooks
			// 	) => {
			// 		let cellJSX =
			// 		<Box>
			// 			{ formatCurrency( getProp(rowData, "delivery_route_revenue_total", 0) ) }
			// 		</Box>
			// 		return cellJSX
			// 	},
			// },
			// header: {
			// 	header_css: ( tableAdditionalData: TsInterface_TableAdditionalData ) => { return "" },
			// 	header_jsx: ( tableAdditionalData: TsInterface_TableAdditionalData ) => { return s_TOTAL_ROUTE_REVENUE },
			// 	header_sort_by: "delivery_route_revenue_total"
			// }
		// },
		// calculated_net_route_revenue: {
			// cell: {
			// 	cell_css: (
			// 		rowData: TsInterface_TableDataRow,
			// 		tableAdditionalData: TsInterface_TableAdditionalData
			// 	) => { return "" },
			// 	cell_jsx: (
			// 		rowData: TsInterface_TableDataRow,
			// 		tableAdditionalData: TsInterface_TableAdditionalData,
			// 		tableHooks: TsInterface_TableHooks
			// 	) => {
			// 		let boxSX: TsInterface_UnspecifiedObject = {}
			// 		if(
			// 			rowData != null &&
			// 			rowData.calculated_net_route_revenue != null &&
			// 			getProp(rowData, "calculated_net_route_revenue", 0 ) > 0
			// 		){
			// 			boxSX = { background: themeVariables.success_main, color: themeVariables.white }
			// 		} else if(
			// 			rowData != null &&
			// 			rowData.calculated_net_route_revenue != null &&
			// 			getProp(rowData, "calculated_net_route_revenue", 0 ) < 0
			// 		){
			// 			boxSX = { background: themeVariables.error_main, color: themeVariables.white }
			// 		}
			// 		let cellJSX =
			// 		<Box className="tw-rounded tw-px-2 tw-py-1 tw-inline-block" sx={ boxSX }>
			// 			{ formatCurrency( getProp(rowData, "calculated_net_route_revenue", 0) ) }
			// 		</Box>
			// 		return cellJSX
			// 	},
			// },
			// header: {
			// 	header_css: ( tableAdditionalData: TsInterface_TableAdditionalData ) => { return "" },
			// 	header_jsx: ( tableAdditionalData: TsInterface_TableAdditionalData ) => { return s_TOTAL_ROUTE_REVENUE },
			// 	header_sort_by: "calculated_net_route_revenue"
			// }
		// },
		delivery_routes_count: TableCellBasic( "delivery_routes_count", s_ROUTES_RUN, "delivery_routes_count" ),
		delivery_rescued_count: TableCellBasic( "delivery_rescued_count", s_RESCUED, "delivery_rescued_count" ),
		delivery_rescuer_count: TableCellBasic( "delivery_rescuer_count", s_RESCURER, "delivery_rescuer_count" ),
		delivery_distance_allowance_total: TableCellBasic( "delivery_distance_allowance_total", s_DISTANCE_ALLOWED, "delivery_distance_allowance_total" ),
		delivery_distance_planned_total: TableCellBasic( "delivery_distance_planned_total", s_DISTANCE_PLANNED, "delivery_distance_planned_total" ),
		delivery_shipments_delivered_total: TableCellBasic( "delivery_shipments_delivered_total", s_SHIPMENTS_DELIVERED, "delivery_shipments_delivered_total" ),
		delivery_shipments_returned_total: TableCellBasic( "delivery_shipments_returned_total", s_SHIPMENTS_RETURNED, "delivery_shipments_returned_total" ),
	}

	const tableSettings_RouteRevenue: TsInterface_TableSettings = {
		paginated: false,
		show_header: true,
		size: "small",
		sort_direction: "asc",
		sort_property_default: "route_type",
		sortable: false,
		collapsible_columns: true,
	}

	const tableColumns_RouteRevenue: TsInterface_TableColumns = {
		station: TableCellBasic( "station", s_STATION, "station" ),
		duration: TableCellBasic( "duration", s_DURATION, "duration" ),
		route_type: TableCellBasic( "route_type", s_ROUTE_TYPE, "route_type" ),
		revenue_grand_total: {
			cell: {
				cell_css: (
					rowData: TsInterface_TableDataRow,
					tableAdditionalData: TsInterface_TableAdditionalData
				) => { return "tw-bg-zinc-200" },
				cell_jsx: (
					rowData: TsInterface_TableDataRow,
					tableAdditionalData: TsInterface_TableAdditionalData,
					tableHooks: TsInterface_TableHooks
				) => {
					let cellSX: TsInterface_UnspecifiedObject = {}
					if( getProp(rowData, "revenue_grand_total", 0) === 0 ){
						cellSX = { background: themeVariables.warning_main }
					}
					let cellJSX =
					<Box sx={ cellSX }>
						{ formatCurrency( getProp(rowData, "revenue_grand_total", 0) ) }
					</Box>
					return cellJSX
				},
			},
			header: {
				header_css: ( tableAdditionalData: TsInterface_TableAdditionalData ) => { return "tw-bg-zinc-200" },
				header_jsx: ( tableAdditionalData: TsInterface_TableAdditionalData ) => {
					let total = 0
					for( let loopRouteKey in getProp( tableAdditionalData, "allData", {}) ){
						if( !isNaN( tableAdditionalData["allData"][ loopRouteKey ]["revenue_grand_total"] ) ){
							let copyOfRouteData = { ...tableAdditionalData["allData"][ loopRouteKey ] }
							total += copyOfRouteData["revenue_grand_total"]
						}
					}
					let headerJSX =
					<Box className="tw-inline-block">
						<Box>{ s_GRAND_TOTAL }</Box>
						<Box className="tw-font-bold">{ formatCurrency( total ) }</Box>
					</Box>
					return headerJSX
				},
				header_sort_by: "revenue_grand_total"
			}
		},
		route_revenue_per_run: {
			cell: {
				cell_css: (
					rowData: TsInterface_TableDataRow,
					tableAdditionalData: TsInterface_TableAdditionalData
				) => {
					return ""
				},
				cell_jsx: (
					rowData: TsInterface_TableDataRow,
					tableAdditionalData: TsInterface_TableAdditionalData,
					tableHooks: TsInterface_TableHooks
				) => {
					let cellSX: TsInterface_UnspecifiedObject = {}
					if( getProp(rowData, "route_revenue_per_run", 0) === 0 ){
						cellSX = { background: themeVariables.warning_main }
					}
					let cellJSX =
					<Box sx={ cellSX }>
						{ formatCurrency( getProp(rowData, "route_revenue_per_run", 0) ) }
					</Box>
					return cellJSX
				},
			},
			header: {
				header_css: ( tableAdditionalData: TsInterface_TableAdditionalData ) => { return "" },
				header_jsx: ( tableAdditionalData: TsInterface_TableAdditionalData ) => {
					return s_REVENUE_PER_RUN
				},
				header_sort_by: "route_revenue_per_run"
			}
		},
		route_run_count: {
			cell: {
				cell_css: (
					rowData: TsInterface_TableDataRow,
					tableAdditionalData: TsInterface_TableAdditionalData
				) => {
					return ""
				},
				cell_jsx: (
					rowData: TsInterface_TableDataRow,
					tableAdditionalData: TsInterface_TableAdditionalData,
					tableHooks: TsInterface_TableHooks
				) => {
					let cellJSX =
					<Box>
						{ getProp(rowData, "route_run_count", 0) }
					</Box>
					return cellJSX
				},
			},
			header: {
				header_css: ( tableAdditionalData: TsInterface_TableAdditionalData ) => { return "" },
				header_jsx: ( tableAdditionalData: TsInterface_TableAdditionalData ) => {
					let total = 0
					for( let loopRouteKey in getProp( tableAdditionalData, "allData", {}) ){
						if( !isNaN( tableAdditionalData["allData"][ loopRouteKey ]["route_run_count"] ) ){
							total += tableAdditionalData["allData"][ loopRouteKey ]["route_run_count"]
						}
					}
					let headerJSX =
					<Box className="tw-inline-block">
						<Box>{ s_NUMBER_OF_RUNS }</Box>
						<Box className="tw-font-bold">{ total.toFixed(0) }</Box>
					</Box>
					return headerJSX
				},
				header_sort_by: "route_run_count"
			}
		},
		route_total_revenue: {
			cell: {
				cell_css: (
					rowData: TsInterface_TableDataRow,
					tableAdditionalData: TsInterface_TableAdditionalData
				) => { return "" },
				cell_jsx: (
					rowData: TsInterface_TableDataRow,
					tableAdditionalData: TsInterface_TableAdditionalData,
					tableHooks: TsInterface_TableHooks
				) => {
					let cellSX: TsInterface_UnspecifiedObject = {}
					if( getProp(rowData, "route_revenue_per_run", 0) === 0 ){
						cellSX = { background: themeVariables.warning_main }
					}
					let cellJSX =
					<Box sx={ cellSX }>
						{ formatCurrency( getProp(rowData, "route_total_revenue", 0) ) }
					</Box>
					return cellJSX
				},
			},
			header: {
				header_css: ( tableAdditionalData: TsInterface_TableAdditionalData ) => { return "" },
				header_jsx: ( tableAdditionalData: TsInterface_TableAdditionalData ) => {
					let total = 0
					for( let loopRouteKey in getProp( tableAdditionalData, "allData", {}) ){
						if( !isNaN( tableAdditionalData["allData"][ loopRouteKey ]["route_total_revenue"] ) ){
							total += tableAdditionalData["allData"][ loopRouteKey ]["route_total_revenue"]
						}
					}
					let headerJSX =
					<Box className="tw-inline-block">
						<Box>{ s_TOTAL_ROUTE_REVENUE }</Box>
						<Box className="tw-font-bold">{ formatCurrency( total ) }</Box>
					</Box>
					return headerJSX
				},
				header_sort_by: "route_total_revenue"
			}
		},
		route_packages_delivered_count: {
			cell: {
				cell_css: (
					rowData: TsInterface_TableDataRow,
					tableAdditionalData: TsInterface_TableAdditionalData
				) => { return "" },
				cell_jsx: (
					rowData: TsInterface_TableDataRow,
					tableAdditionalData: TsInterface_TableAdditionalData,
					tableHooks: TsInterface_TableHooks
				) => {
					let cellJSX =
					<Box>
						{ getProp(rowData, "route_packages_delivered_count", 0 ) }
					</Box>
					return cellJSX
				},
			},
			header: {
				header_css: ( tableAdditionalData: TsInterface_TableAdditionalData ) => { return "" },
				header_jsx: ( tableAdditionalData: TsInterface_TableAdditionalData ) => {
					let total = 0
					for( let loopRouteKey in getProp( tableAdditionalData, "allData", {}) ){
						if( !isNaN( tableAdditionalData["allData"][ loopRouteKey ]["route_packages_delivered_count"] ) ){
							total += tableAdditionalData["allData"][ loopRouteKey ]["route_packages_delivered_count"]
						}
					}
					let headerJSX =
					<Box className="tw-inline-block">
						<Box>{ s_DELIVERED_PACKAGES }</Box>
						<Box className="tw-font-bold">{ total.toFixed(0) }</Box>
					</Box>
					return headerJSX
				},
				header_sort_by: "route_packages_delivered_count"
			}
		},
		package_total_base_rate_pay: {
			cell: {
				cell_css: (
					rowData: TsInterface_TableDataRow,
					tableAdditionalData: TsInterface_TableAdditionalData
				) => { return "" },
				cell_jsx: (
					rowData: TsInterface_TableDataRow,
					tableAdditionalData: TsInterface_TableAdditionalData,
					tableHooks: TsInterface_TableHooks
				) => {
					let cellSX: TsInterface_UnspecifiedObject = {}
					if( getProp(rowData, "package_total_base_rate_pay", 0) === 0 ){
						cellSX = { background: themeVariables.warning_main }
					}
					let cellJSX =
					<Box sx={ cellSX }>
						{ formatCurrency( getProp(rowData, "package_total_base_rate_pay", 0) ) }
					</Box>
					return cellJSX
				},
			},
			header: {
				header_css: ( tableAdditionalData: TsInterface_TableAdditionalData ) => { return "" },
				header_jsx: ( tableAdditionalData: TsInterface_TableAdditionalData ) => {
					let total = 0
					for( let loopRouteKey in getProp( tableAdditionalData, "allData", {}) ){
						if( !isNaN( tableAdditionalData["allData"][ loopRouteKey ]["package_total_base_rate_pay"] ) ){
							total += tableAdditionalData["allData"][ loopRouteKey ]["package_total_base_rate_pay"]
						}
					}
					let headerJSX =
					<Box className="tw-inline-block">
						<Box>{ s_TOTAL_PKG_BASE }</Box>
						<Box className="tw-font-bold">{ formatCurrency( total ) }</Box>
					</Box>
					return headerJSX
				},
				header_sort_by: "package_total_base_rate_pay"
			}
		},
		package_total_bonus_rate_pay: {
			cell: {
				cell_css: (
					rowData: TsInterface_TableDataRow,
					tableAdditionalData: TsInterface_TableAdditionalData
				) => { return "" },
				cell_jsx: (
					rowData: TsInterface_TableDataRow,
					tableAdditionalData: TsInterface_TableAdditionalData,
					tableHooks: TsInterface_TableHooks
				) => {
					let cellSX: TsInterface_UnspecifiedObject = {}
					if( getProp(rowData, "package_total_bonus_rate_pay", 0) === 0 ){
						cellSX = { background: themeVariables.warning_main }
					}
					let cellJSX =
					<Box sx={ cellSX }>
						{ formatCurrency( getProp(rowData, "package_total_bonus_rate_pay", 0) ) }
					</Box>
					return cellJSX
				},
			},
			header: {
				header_css: ( tableAdditionalData: TsInterface_TableAdditionalData ) => { return "" },
				header_jsx: ( tableAdditionalData: TsInterface_TableAdditionalData ) => {
					let total = 0
					for( let loopRouteKey in getProp( tableAdditionalData, "allData", {}) ){
						if( !isNaN( tableAdditionalData["allData"][ loopRouteKey ]["package_total_bonus_rate_pay"] ) ){
							total += tableAdditionalData["allData"][ loopRouteKey ]["package_total_bonus_rate_pay"]
						}
					}
					let headerJSX =
					<Box className="tw-inline-block">
						<Box>{ s_TOTAL_PKG_BONUS }</Box>
						<Box className="tw-font-bold">{ formatCurrency( total ) }</Box>
					</Box>
					return headerJSX
				},
				header_sort_by: "package_total_bonus_rate_pay"
			}
		},
	}

	const tableSettings_StationRevenue: TsInterface_TableSettings = {
		paginated: false,
		show_header: true,
		size: "small",
		sort_direction: "asc",
		sort_property_default: "station",
		sortable: false,
		collapsible_columns: true,
	}

	const tableColumns_StationRevenue: TsInterface_TableColumns = {
		station: TableCellBasic( "station", s_STATION, "station" ),
		// duration: TableCellBasic( "duration", s_DURATION, "duration" ),
		// route_type: TableCellBasic( "route_type", s_ROUTE_TYPE, "route_type" ),
		// route_revenue_per_run: TableCellBasic( "route_revenue_per_run", s_REVENUE_PER_RUN, "route_revenue_per_run" ),
		revenue_grand_total: {
			cell: {
				cell_css: (
					rowData: TsInterface_TableDataRow,
					tableAdditionalData: TsInterface_TableAdditionalData
				) => { return "tw-bg-zinc-200" },
				cell_jsx: (
					rowData: TsInterface_TableDataRow,
					tableAdditionalData: TsInterface_TableAdditionalData,
					tableHooks: TsInterface_TableHooks
				) => {
					let cellSX: TsInterface_UnspecifiedObject = {}
					if( getProp(rowData, "revenue_grand_total", 0) === 0 ){
						cellSX = { background: themeVariables.warning_main }
					}
					let cellJSX =
					<Box sx={ cellSX }>
						{ formatCurrency( getProp(rowData, "revenue_grand_total", 0) ) }
					</Box>
					return cellJSX
				},
			},
			header: {
				header_css: ( tableAdditionalData: TsInterface_TableAdditionalData ) => { return "tw-bg-zinc-200" },
				header_jsx: ( tableAdditionalData: TsInterface_TableAdditionalData ) => {
					let total = 0
					for( let loopRouteKey in getProp( tableAdditionalData, "allData", {}) ){
						if( !isNaN( tableAdditionalData["allData"][ loopRouteKey ]["revenue_grand_total"] ) ){
							let copyOfRouteData = { ...tableAdditionalData["allData"][ loopRouteKey ] }
							total += copyOfRouteData["revenue_grand_total"]
						}
					}
					let headerJSX =
					<Box className="tw-inline-block">
						<Box>{ s_GRAND_TOTAL }</Box>
						<Box className="tw-font-bold">{ formatCurrency( total ) }</Box>
					</Box>
					return headerJSX
				},
				header_sort_by: "revenue_grand_total"
			}
		},
		route_run_count: {
			cell: {
				cell_css: (
					rowData: TsInterface_TableDataRow,
					tableAdditionalData: TsInterface_TableAdditionalData
				) => {
					return ""
				},
				cell_jsx: (
					rowData: TsInterface_TableDataRow,
					tableAdditionalData: TsInterface_TableAdditionalData,
					tableHooks: TsInterface_TableHooks
				) => {
					let cellJSX =
					<Box>
						{ getProp(rowData, "route_run_count", 0) }
					</Box>
					return cellJSX
				},
			},
			header: {
				header_css: ( tableAdditionalData: TsInterface_TableAdditionalData ) => { return "" },
				header_jsx: ( tableAdditionalData: TsInterface_TableAdditionalData ) => {
					let total = 0
					for( let loopRouteKey in getProp( tableAdditionalData, "allData", {}) ){
						if( !isNaN( tableAdditionalData["allData"][ loopRouteKey ]["route_run_count"] ) ){
							total += tableAdditionalData["allData"][ loopRouteKey ]["route_run_count"]
						}
					}
					let headerJSX =
					<Box className="tw-inline-block">
						<Box>{ s_NUMBER_OF_RUNS }</Box>
						<Box className="tw-font-bold">{ total.toFixed(0) }</Box>
					</Box>
					return headerJSX
				},
				header_sort_by: "route_run_count"
			}
		},
		route_total_revenue: {
			cell: {
				cell_css: (
					rowData: TsInterface_TableDataRow,
					tableAdditionalData: TsInterface_TableAdditionalData
				) => { return "" },
				cell_jsx: (
					rowData: TsInterface_TableDataRow,
					tableAdditionalData: TsInterface_TableAdditionalData,
					tableHooks: TsInterface_TableHooks
				) => {
					let cellSX: TsInterface_UnspecifiedObject = {}
					if( getProp(rowData, "route_revenue_per_run", 0) === 0 ){
						cellSX = { background: themeVariables.warning_main }
					}
					let cellJSX =
					<Box sx={ cellSX }>
						{ formatCurrency( getProp(rowData, "route_total_revenue", 0) ) }
					</Box>
					return cellJSX
				},
			},
			header: {
				header_css: ( tableAdditionalData: TsInterface_TableAdditionalData ) => { return "" },
				header_jsx: ( tableAdditionalData: TsInterface_TableAdditionalData ) => {
					let total = 0
					for( let loopRouteKey in getProp( tableAdditionalData, "allData", {}) ){
						if( !isNaN( tableAdditionalData["allData"][ loopRouteKey ]["route_total_revenue"] ) ){
							total += tableAdditionalData["allData"][ loopRouteKey ]["route_total_revenue"]
						}
					}
					let headerJSX =
					<Box className="tw-inline-block">
						<Box>{ s_TOTAL_ROUTE_REVENUE }</Box>
						<Box className="tw-font-bold">{ formatCurrency( total ) }</Box>
					</Box>
					return headerJSX
				},
				header_sort_by: "route_total_revenue"
			}
		},
		route_packages_delivered_count: {
			cell: {
				cell_css: (
					rowData: TsInterface_TableDataRow,
					tableAdditionalData: TsInterface_TableAdditionalData
				) => { return "" },
				cell_jsx: (
					rowData: TsInterface_TableDataRow,
					tableAdditionalData: TsInterface_TableAdditionalData,
					tableHooks: TsInterface_TableHooks
				) => {
					let cellJSX =
					<Box>
						{ getProp(rowData, "route_packages_delivered_count", 0 ) }
					</Box>
					return cellJSX
				},
			},
			header: {
				header_css: ( tableAdditionalData: TsInterface_TableAdditionalData ) => { return "" },
				header_jsx: ( tableAdditionalData: TsInterface_TableAdditionalData ) => {
					let total = 0
					for( let loopRouteKey in getProp( tableAdditionalData, "allData", {}) ){
						if( !isNaN( tableAdditionalData["allData"][ loopRouteKey ]["route_packages_delivered_count"] ) ){
							total += tableAdditionalData["allData"][ loopRouteKey ]["route_packages_delivered_count"]
						}
					}
					let headerJSX =
					<Box className="tw-inline-block">
						<Box>{ s_DELIVERED_PACKAGES }</Box>
						<Box className="tw-font-bold">{ total.toFixed(0) }</Box>
					</Box>
					return headerJSX
				},
				header_sort_by: "route_packages_delivered_count"
			}
		},
		package_total_base_rate_pay: {
			cell: {
				cell_css: (
					rowData: TsInterface_TableDataRow,
					tableAdditionalData: TsInterface_TableAdditionalData
				) => { return "" },
				cell_jsx: (
					rowData: TsInterface_TableDataRow,
					tableAdditionalData: TsInterface_TableAdditionalData,
					tableHooks: TsInterface_TableHooks
				) => {
					let cellSX: TsInterface_UnspecifiedObject = {}
					if( getProp(rowData, "package_total_base_rate_pay", 0) === 0 ){
						cellSX = { background: themeVariables.warning_main }
					}
					let cellJSX =
					<Box sx={ cellSX }>
						{ formatCurrency( getProp(rowData, "package_total_base_rate_pay", 0) ) }
					</Box>
					return cellJSX
				},
			},
			header: {
				header_css: ( tableAdditionalData: TsInterface_TableAdditionalData ) => { return "" },
				header_jsx: ( tableAdditionalData: TsInterface_TableAdditionalData ) => {
					let total = 0
					for( let loopRouteKey in getProp( tableAdditionalData, "allData", {}) ){
						if( !isNaN( tableAdditionalData["allData"][ loopRouteKey ]["package_total_base_rate_pay"] ) ){
							total += tableAdditionalData["allData"][ loopRouteKey ]["package_total_base_rate_pay"]
						}
					}
					let headerJSX =
					<Box className="tw-inline-block">
						<Box>{ s_TOTAL_PKG_BASE }</Box>
						<Box className="tw-font-bold">{ formatCurrency( total ) }</Box>
					</Box>
					return headerJSX
				},
				header_sort_by: "package_total_base_rate_pay"
			}
		},
		package_total_bonus_rate_pay: {
			cell: {
				cell_css: (
					rowData: TsInterface_TableDataRow,
					tableAdditionalData: TsInterface_TableAdditionalData
				) => { return "" },
				cell_jsx: (
					rowData: TsInterface_TableDataRow,
					tableAdditionalData: TsInterface_TableAdditionalData,
					tableHooks: TsInterface_TableHooks
				) => {
					let cellSX: TsInterface_UnspecifiedObject = {}
					if( getProp(rowData, "package_total_bonus_rate_pay", 0) === 0 ){
						cellSX = { background: themeVariables.warning_main }
					}
					let cellJSX =
					<Box sx={ cellSX }>
						{ formatCurrency( getProp(rowData, "package_total_bonus_rate_pay", 0) ) }
					</Box>
					return cellJSX
				},
			},
			header: {
				header_css: ( tableAdditionalData: TsInterface_TableAdditionalData ) => { return "" },
				header_jsx: ( tableAdditionalData: TsInterface_TableAdditionalData ) => {
					let total = 0
					for( let loopRouteKey in getProp( tableAdditionalData, "allData", {}) ){
						if( !isNaN( tableAdditionalData["allData"][ loopRouteKey ]["package_total_bonus_rate_pay"] ) ){
							total += tableAdditionalData["allData"][ loopRouteKey ]["package_total_bonus_rate_pay"]
						}
					}
					let headerJSX =
					<Box className="tw-inline-block">
						<Box>{ s_TOTAL_PKG_BONUS }</Box>
						<Box className="tw-font-bold">{ formatCurrency( total ) }</Box>
					</Box>
					return headerJSX
				},
				header_sort_by: "package_total_bonus_rate_pay"
			}
		},
	}

	const tableColumns_DriverNotes: TsInterface_TableColumns = {
		name: TableCellBasic( "name", s_EMPLOYEE_NAME, "name" ),
		payroll_clocked_hours_all_total: {
			cell: {
				cell_css: (
					rowData: TsInterface_TableDataRow,
					tableAdditionalData: TsInterface_TableAdditionalData
				) => { return "" },
				cell_jsx: (
					rowData: TsInterface_TableDataRow,
					tableAdditionalData: TsInterface_TableAdditionalData,
					tableHooks: TsInterface_TableHooks
				) => {
					let cellJSX =
					<Box>
						{ getProp(rowData, "payroll_clocked_hours_all_total", 0).toFixed(1) } { s_HRS }
					</Box>
					return cellJSX
				},
			},
			header: {
				header_css: ( tableAdditionalData: TsInterface_TableAdditionalData ) => { return "" },
				header_jsx: ( tableAdditionalData: TsInterface_TableAdditionalData ) => { return s_ALL_HOURS },
				header_sort_by: "payroll_clocked_hours_all_total"
			}
		},
		payroll_clocked_hours_included_total: {
			cell: {
				cell_css: (
					rowData: TsInterface_TableDataRow,
					tableAdditionalData: TsInterface_TableAdditionalData
				) => { return "" },
				cell_jsx: (
					rowData: TsInterface_TableDataRow,
					tableAdditionalData: TsInterface_TableAdditionalData,
					tableHooks: TsInterface_TableHooks
				) => {
					let boxSX: TsInterface_UnspecifiedObject = {}
					if(
						tableAdditionalData != null &&
						tableAdditionalData["us_userHourColors"] != null &&
						rowData != null &&
						rowData.name != null &&
						tableAdditionalData["us_userHourColors"][ rowData.name as TsType_String ] != null
					){
						boxSX = { background: tableAdditionalData["us_userHourColors"][ rowData.name as TsType_String ], color: themeVariables.white }
					}
					let cellJSX =
					<Box>
						<Box className="tw-rounded tw-px-2 tw-py-1 tw-inline-block" sx={ boxSX }>
							{ getProp(rowData, "payroll_clocked_hours_included_total", 0).toFixed(1) } { s_HRS }
						</Box>
					</Box>
					return cellJSX
				},
			},
			header: {
				header_css: ( tableAdditionalData: TsInterface_TableAdditionalData ) => { return "" },
				header_jsx: ( tableAdditionalData: TsInterface_TableAdditionalData ) => { return s_WORK_HOURS },
				header_sort_by: "payroll_clocked_hours_included_total"
			}
		},
		payroll_clocked_hours_excluded_total: {
			cell: {
				cell_css: (
					rowData: TsInterface_TableDataRow,
					tableAdditionalData: TsInterface_TableAdditionalData
				) => { return "" },
				cell_jsx: (
					rowData: TsInterface_TableDataRow,
					tableAdditionalData: TsInterface_TableAdditionalData,
					tableHooks: TsInterface_TableHooks
				) => {
					let cellJSX =
					<Box>
						{ getProp(rowData, "payroll_clocked_hours_excluded_total", 0).toFixed(1) } { s_HRS }
					</Box>
					return cellJSX
				},
			},
			header: {
				header_css: ( tableAdditionalData: TsInterface_TableAdditionalData ) => { return "" },
				header_jsx: ( tableAdditionalData: TsInterface_TableAdditionalData ) => { return s_EXCLUDED_HOURS },
				header_sort_by: "payroll_clocked_hours_excluded_total"
			}
		},
		delivery_route_hours_total: {
			cell: {
				cell_css: (
					rowData: TsInterface_TableDataRow,
					tableAdditionalData: TsInterface_TableAdditionalData
				) => { return "" },
				cell_jsx: (
					rowData: TsInterface_TableDataRow,
					tableAdditionalData: TsInterface_TableAdditionalData,
					tableHooks: TsInterface_TableHooks
				) => {
					let cellJSX =
					<Box>
						{ getProp(rowData, "delivery_route_hours_total", 0).toFixed(1) } { s_HRS }
					</Box>
					return cellJSX
				},
			},
			header: {
				header_css: ( tableAdditionalData: TsInterface_TableAdditionalData ) => { return "" },
				header_jsx: ( tableAdditionalData: TsInterface_TableAdditionalData ) => { return s_DELIVERY_EXPECTED_HOURS },
				header_sort_by: "delivery_route_hours_total"
			}
		},
		calculated_payroll_minus_route_hours_total: {
			cell: {
				cell_css: (
					rowData: TsInterface_TableDataRow,
					tableAdditionalData: TsInterface_TableAdditionalData
				) => { return "" },
				cell_jsx: (
					rowData: TsInterface_TableDataRow,
					tableAdditionalData: TsInterface_TableAdditionalData,
					tableHooks: TsInterface_TableHooks
				) => {
					let boxSX: TsInterface_UnspecifiedObject = {}
					if(
						tableAdditionalData != null &&
						tableAdditionalData["us_userHourColors"] != null &&
						rowData != null &&
						rowData.name != null &&
						tableAdditionalData["us_userHourColors"][ rowData.name as TsType_String ] != null
					){
						boxSX = {
							color: tableAdditionalData["us_userHourColors"][ rowData.name as TsType_String ],
							borderWidth: "1px",
							borderStyle: "solid",
							borderColor: tableAdditionalData["us_userHourColors"][ rowData.name as TsType_String ]
						}
					}
					let plusJSX = <></>
					if( getProp(rowData, "calculated_payroll_minus_route_hours_total", 0) >= 0){
						plusJSX = <>+</>
					}
					let cellJSX =
					<Box>
						<Box className="tw-rounded tw-px-2 tw-py-1 tw-inline-block tw-font-bold" sx={ boxSX }>
							{plusJSX}{ getProp(rowData, "calculated_payroll_minus_route_hours_total", 0).toFixed(1) } { s_HRS }
						</Box>
					</Box>
					return cellJSX
				},
			},
			header: {
				header_css: ( tableAdditionalData: TsInterface_TableAdditionalData ) => { return "" },
				header_jsx: ( tableAdditionalData: TsInterface_TableAdditionalData ) => { return s_DELTA },
				header_sort_by: "calculated_payroll_minus_route_hours_total"
			}
		},
		TEMP_notes: {
			cell: {
				cell_css: (
					rowData: TsInterface_TableDataRow,
					tableAdditionalData: TsInterface_TableAdditionalData
				) => { return "" },
				cell_jsx: (
					rowData: TsInterface_TableDataRow,
					tableAdditionalData: TsInterface_TableAdditionalData,
					tableHooks: TsInterface_TableHooks
				) => {
					const returnJSX_EmployeeNotes = ( dayFilter: TsInterface_UnspecifiedObject ): TsType_JSX => {
						let notesJSX = <></>
						const returnJSX_DeleteNoteIcon = (
							employeeName: TsType_String,
							dateKey: TsType_String,
							tableHooks: TsInterface_TableHooks
						) => {
							let iconJSX = <></>
							iconJSX =
							<Icon
								icon="square-xmark"
								sx={{
									color: themeVariables.gray_400,
									'&:hover': {
										color: themeVariables.error_main,
									}
								}}
								className="tw-ml-2"
								tooltip={ s_DELETE_NOTE }
								onClick={ () => {
									tableHooks.uc_setUserInterface_ConfirmDialogDisplay({
										display: true,
										confirm: {
											color: "error",
											header: s_DELETE_NOTE,
											icon: <Icon icon="square-xmark" type="solid"/>,
											submit_text: s_DELETE,
											text: s_ARE_YOU_SURE_THAT_YOU_WANT_TO_DELETE_THIS_NOTE,
											submit_callback: () => {
												return new Promise( ( resolve, reject ) => {
													getClientKey( tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey ).then(( res_GCK ) => {
														let updateObject = {
															[ dateKey ]: null
														}
														DatabaseSetMergeDocument( DatabaseRef_TimecardAnalysis_DriverNotes_Document( res_GCK.clientKey, tableAdditionalData.analysisKey as TsType_String, employeeName ), updateObject, {} ).then(( res_DSM ) => {
															resolve( res_DSM )
														}).catch(( rej_DSM ) => {
															tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSM.error })
															reject( rej_DSM )
														})
													}).catch(( rej_GCK ) => {
														tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
														reject( rej_GCK )
													})
												})
											},
										}
									})
								}}
							/>
							return iconJSX
						}
						if(
							tableAdditionalData != null &&
							tableAdditionalData["us_selectedDateFilter"] != null &&
							dayFilter != null &&
							dayFilter.key != null &&
							tableAdditionalData["us_selectedDateFilter"] === dayFilter.key
						){
							let noteValueJSX: TsType_JSX | TsType_String = <></>
							if(
								rowData != null &&
								rowData.name != null &&
								tableAdditionalData != null &&
								tableAdditionalData["us_selectedDateFilter"]  != null &&
								tableAdditionalData.us_analysisDriverNotesData != null &&
								tableAdditionalData.us_analysisDriverNotesData[ rowData.name as TsType_String ] != null &&
								tableAdditionalData.us_analysisDriverNotesData[ rowData.name as TsType_String ][ tableAdditionalData["us_selectedDateFilter"] ] != null
							){
								noteValueJSX =
								<Box className="tw-inline-block" sx={{ color: themeVariables.info_light }}>
									{ tableAdditionalData.us_analysisDriverNotesData[ rowData.name as TsType_String ][ tableAdditionalData["us_selectedDateFilter"] ] }
									{ returnJSX_DeleteNoteIcon( rowData.name as TsType_String, tableAdditionalData["us_selectedDateFilter"], tableHooks ) }
								</Box>
							} else {
								noteValueJSX =
								<Box className="tw-inline-block tw-opacity-40 tw-italic">
									{ s_NO_NOTES }
								</Box>
							}
							notesJSX =
							<Box className="">
								<Box className="tw-inline-block tw-font-bold tw-mr-1">{ dayFilter.value }:</Box>
								<Box className="tw-inline-block">
									 { noteValueJSX }
								</Box>
								<Icon
									className='tw-ml-2 tw-opacity-40 tw-cursor-pointer hover:tw-opacity-100 hover:tw-text-green-700'
									icon="pen-to-square"
									tooltip={ s_EDIT_NOTES }
									tooltipPlacement='right'
									onClick={ () => {
										// Set Form Data
										let formData: TsInterface_UnspecifiedObject = {}
										if(
											rowData != null &&
											rowData.name != null &&
											tableAdditionalData != null &&
											tableAdditionalData["us_selectedDateFilter"]  != null &&
											tableAdditionalData.us_analysisDriverNotesData != null &&
											tableAdditionalData.us_analysisDriverNotesData[ rowData.name as TsType_String ] != null &&
											tableAdditionalData.us_analysisDriverNotesData[ rowData.name as TsType_String ][ tableAdditionalData["us_selectedDateFilter"] ] != null
										){
											formData = { notes: tableAdditionalData.us_analysisDriverNotesData[ rowData.name as TsType_String ][ tableAdditionalData["us_selectedDateFilter"] ] }
										}
										// Open Dialog
										tableHooks.uc_setUserInterface_FormDialogDisplay({
											display: true,
											form: {
												form: {
													formAdditionalData: {},
													formData: formData,
													formInputs: {
														notes: {
															data_type: "string",
															input_type: "text_multiline",
															key: "notes",
															label: s_NOTES,
															required: true,
														}
													},
													formOnChange: ( formAdditionalData: TsInterface_FormAdditionalData, formData: TsInterface_FormData, formInputs: TsInterface_FormInputs, formSettings: TsInterface_FormSettings ) => {},
													formSettings: {},
													formSubmission: ( formSubmittedData: TsInterface_FormSubmittedData, formAdditionalData: TsInterface_FormAdditionalData, formHooks: TsInterface_FormHooksObject ) => {
														return new Promise( ( resolve, reject ) => {
															getClientKey( formHooks.uc_RootData_ClientKey, formHooks.uc_setRootData_ClientKey ).then(( res_GCK ) => {
																let updateObject = {
																	[ tableAdditionalData["us_selectedDateFilter"] ]: formSubmittedData.notes
																}
																DatabaseSetMergeDocument( DatabaseRef_TimecardAnalysis_DriverNotes_Document( res_GCK.clientKey, tableAdditionalData.analysisKey as TsType_String, rowData.name as TsType_String ), updateObject, {} ).then(( res_DSM ) => {
																	resolve( res_DSM )
																}).catch(( rej_DSM ) => {
																	formHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSM.error })
																	reject( rej_DSM )
																})
															}).catch(( rej_GCK ) => {
																formHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
																reject( rej_GCK )
															})
														})
													},
												},
												dialog: {
													formDialogHeaderColor: "success",
													formDialogHeaderText: <>{ s_NOTES } - { rowData.name } ({ dayFilter.value })</>,
													formDialogIcon: <Icon type="solid" icon="pen-to-square"/>
												}
											}
										})
									}}
								/>
							</Box>
						} else {
							let noteValueJSX: TsType_JSX | TsType_String = <></>
							if(
								rowData != null &&
								rowData.name != null &&
								tableAdditionalData != null &&
								tableAdditionalData["us_selectedDateFilter"]  != null &&
								tableAdditionalData.us_analysisDriverNotesData != null &&
								tableAdditionalData.us_analysisDriverNotesData[ rowData.name as TsType_String ] != null &&
								tableAdditionalData.us_analysisDriverNotesData[ rowData.name as TsType_String ][ dayFilter.key ] != null
							){
								noteValueJSX =
								<Box className="tw-inline-block" sx={{ color: themeVariables.info_light }}>
									{ tableAdditionalData.us_analysisDriverNotesData[ rowData.name as TsType_String ][ dayFilter.key ] }
									{ returnJSX_DeleteNoteIcon( rowData.name as TsType_String, dayFilter.key, tableHooks ) }
								</Box>
							} else {
								noteValueJSX =
								<Box className="tw-inline-block tw-opacity-40 tw-italic">
									{ s_NO_NOTES }
								</Box>
							}
							if(
								rowData != null &&
								rowData.name != null &&
								dayFilter != null &&
								dayFilter.key != null &&
								tableAdditionalData != null &&
								tableAdditionalData.us_analysisDriverNotesData != null &&
								tableAdditionalData.us_analysisDriverNotesData[ rowData.name as TsType_String ] != null &&
								tableAdditionalData.us_analysisDriverNotesData[ rowData.name as TsType_String ][ dayFilter.key ] != null
							){
								notesJSX =
								<Box className="tw-opacity-40">
									{ dayFilter.value }: { noteValueJSX }
								</Box>
							}
						}
						return notesJSX
					}
					let cellJSX =
					<Box>
						{objectToArray( getProp( tableAdditionalData, "us_dateDropdownOptions", {} ) ).sort( dynamicSort("sort", "asc") ).map(( dayFilter: TsInterface_UnspecifiedObject, index: TsType_Number ) => (
							<Box key={ index } >
								{ returnJSX_EmployeeNotes( dayFilter ) }
							</Box>
						))}
					</Box>
					return cellJSX
				},
			},
			header: {
				header_css: ( tableAdditionalData: TsInterface_TableAdditionalData ) => { return "" },
				header_jsx: ( tableAdditionalData: TsInterface_TableAdditionalData ) => { return s_NOTES },
				header_sort_by: null
			}
		}
	}

	const tableSettings_DriverNotes: TsInterface_TableSettings = {
		paginated: false,
		show_header: true,
		size: "small",
		sort_direction: "desc",
		sort_property_default: "calculated_payroll_minus_route_hours_total",
		sortable: true,
		collapsible_columns: true,
	}

	// Charts
	const scatterPlotOptions = {
		elements: {
			point: {
				radius: 8,
				hoverRadius: 10
			}
		},
		scales: {
			x: {
				beginAtZero: true,
				title: {
					display: true,
					text: "Payroll Hours"
				}
			},
			y: {
				beginAtZero: true,
				title: {
					display: true,
					text: "Route Hours"
				}
			},
		},
		plugins: {
			tooltip: {
				callbacks: {
					label: function(ctx: TsType_Any) {
						let label = ""
						if(
							ctx != null &&
							ctx.raw != null &&
							ctx.raw.employee_name != null
						){
							label = ctx.raw.employee_name + " - (Route: " + ctx.raw.delivery_route_hours_total + " | Payroll: " + ctx.raw.payroll_clocked_hours_included_total + ")"
						}
						return label;
					}
				}
			}
		}
	}

	const darkRedColor = "#790f18"

///////////////////////////////
// Functions
///////////////////////////////

	function calculateHoursBetweenDates(dateStr1: TsType_String, dateStr2: TsType_String) {
		const date1 = new Date(dateStr1);
		const date2 = new Date(dateStr2);
		// @ts-expect-error
		const millisecondsDifference = Math.abs(date1 - date2);
		const hoursDifference = millisecondsDifference / (1000 * 60 * 60);
		return hoursDifference;
	}

	const returnReportMappingKey = (
		propKey: TsType_String,
		reportMappingDatabaseData: TsInterface_UnspecifiedObject,
		defaultReportMappingData: TsInterface_UnspecifiedObject,
	): TsType_String | TsType_Null => {
		if(
			reportMappingDatabaseData != null &&
			reportMappingDatabaseData[ propKey ] != null
		){
			return reportMappingDatabaseData[ propKey ]
		} else if(
			defaultReportMappingData != null &&
			defaultReportMappingData[ propKey ] != null &&
			defaultReportMappingData[ propKey ]["default_header"] != null
		){
			return defaultReportMappingData[ propKey ]["default_header"]
		} else {
			return null
		}
	}

	function isValidDate(inputString: TsType_String) {
		// Attempt to create a Date object from the input string
		const date = new Date(inputString);
		// Check if the created date is valid and the input string is not empty
		//@ts-expect-error
		return !isNaN(date) && inputString.trim() !== "";
	}

	const formatCurrency = (number: TsType_Number) => {
		return '$' + number.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
	}

	const parseTimeDuration = ( duration: TsType_String ) => {
		// Regex to match strings like "10 hr" or "8 hr 15 min" or "15 min"
		const regex = /(?:(\d+)\s*hr\s*)?(?:(\d+)\s*min)?/;
		const matches = duration.match(regex);
		// Initialize hours and minutes with 0
		let hours = 0;
		let minutes = 0;
		// If the hour's group matched, parse it to a number
		if (matches && matches[1]) {
		 	hours = parseInt(matches[1], 10);
		}
		// If the minute's group matched, parse it to a number and convert to hours
		if (matches && matches[2]) {
			minutes = parseInt(matches[2], 10) / 60;
		}
		// Return the total hours
		return hours + minutes;
	}

	function formatDateString(dateString: TsType_String) {
		// Check if the date is already in YYYY-MM-DD format
		const isoDateRegex = /^\d{4}-\d{2}-\d{2}$/;
		if (isoDateRegex.test(dateString)) {
			  return dateString; // Return as it is
		}

		// Check if the date is in M/D/YYYY format and convert it
		const usDateRegex = /^(1[0-2]|0?[1-9])\/(3[01]|[12][0-9]|0?[1-9])\/\d{4}$/;
		if (usDateRegex.test(dateString)) {
			const parts = dateString.split('/');
			const year = parts[2];
			const month = parts[0].padStart(2, '0'); // Ensure month is 2 digits
			const day = parts[1].padStart(2, '0');   // Ensure day is 2 digits
			return `${year}-${month}-${day}`; // Return in YYYY-MM-DD format
		}

		// If the date is in another format, use Date object to parse and format it
		const date = new Date(dateString);
		if (!isNaN(date.getTime())) { // Valid date object created
			const year = date.getFullYear();
			const month = (date.getMonth() + 1).toString().padStart(2, '0');
			const day = date.getDate().toString().padStart(2, '0');
			return `${year}-${month}-${day}`; // Return in YYYY-MM-DD format
		} else {
		  	throw new Error('Invalid date format');
		}
	}


///////////////////////////////
// Container
///////////////////////////////

	export const Container: React.FC = (): TsType_JSX => {

		// Props
		const params = useParams()
		const analysisKey: TsType_String = params.id as TsType_String
		ChartJS.register(LinearScale, PointElement, LineElement, Tooltip, Legend)

		// Hooks - useContext, useState, useReducer, other
		// { sort-start } - hooks
		// const umq_largerThanSmBreakpoint = 													useMediaQuery( json2mq({ minWidth: 600, }) )
		const [ us_analysisCalculatedData, us_setAnalysisCalculatedData ] = 				useState<TsInterface_UnspecifiedObject>({})
		const [ us_analysisDriverNotesData, us_setAnalysisDriverNotesData ] = 				useState<TsInterface_UnspecifiedObject>({})
		const [ us_analysisErrorData, us_setAnalysisErrorData ] = 							useState<TsInterface_UnspecifiedObject>({})
		const [ us_analysisErrorDataNames, us_setAnalysisErrorDataNames ] = 				useState<TsInterface_UnspecifiedObject>({})
		const [ us_colorHourCutoff, us_setColorHourCutoff ] = 								useState<TsType_Number>(1)
		const [ us_dataFileDates, us_setDataFileDates ] = 									useState<TsInterface_UnspecifiedObject>({})
		const [ us_dateDropdownOptions, us_setDateDropdownOptions ] = 						useState<TsInterface_UnspecifiedObject>({})
		const [ us_deletingData, us_setDeletingData ] = 									useState<TsType_Boolean>(false)
		const [ us_deliveryDrivers, us_setDeliveryDrivers ] = 								useState<TsInterface_UnspecifiedObject>({})
		const [ us_deliveryFileData, us_setDeliveryFileData ] = 							useState<TsInterface_UnspecifiedObject>({})
		const [ us_deliveryFileHeaders, us_setDeliveryFileHeaders ] = 						useState<TsInterface_UnspecifiedObject>({})
		const [ us_deliveryReportMappingData, us_setDeliveryReportMappingData ] = 			useState<TsInterface_UnspecifiedObject>({})
		const [ us_deliveryRouteTypes, us_setDeliveryRouteTypes ] = 						useState<TsInterface_UnspecifiedObject>({})
		const [ us_filteredRouteRevenueBreakdown, us_setFilteredRouteRevenueBreakdown ] = 	useState<TsInterface_UnspecifiedObject>({})
		const [ us_filteredRouteStationBreakdown, us_setFilteredRouteStationBreakdown ] = 	useState<TsInterface_UnspecifiedObject>({})
		const [ us_filteredTableData, us_setFilteredTableData ] = 							useState<TsInterface_UnspecifiedObject>({})
		const [ us_initialDataLoaded, us_setInitialDataLoaded ] = 							useState<TsType_Boolean>( false )
		const [ us_initialSectionSelectionMade, us_setInitialSectionSelectionMade ] = 		useState<TsType_Boolean>( false )
		const [ us_lockedAnalysisDataResults, us_setLockedAnalysisDataResults ] = 			useState<TsInterface_UnspecifiedObject>({})
		const [ us_lockingAnalysis, us_setLockingAnalysis ] = 								useState<TsType_Boolean>( false )
		const [ us_mappingMissingCounts, us_setMappingMissingCounts ] = 					useState<TsInterface_UnspecifiedObject>({})
		const [ us_openToAutoChangingNavSection, us_setOpenToAutoChangingNavSection ] = 	useState<TsType_Boolean>(false)
		const [ us_payCodesList, us_setPayCodesList ] = 									useState<TsInterface_UnspecifiedObject>({})
		const [ us_paycodeMultiplierMappingData, us_setPaycodeMultiplierMappingData ] = 	useState<TsInterface_UnspecifiedObject>({})
		const [ us_rootAnalysis, us_setRootAnalysis ] = 									useState<TsInterface_UnspecifiedObject>({})
		const [ us_routePricingMappingData, us_setRoutePricingMappingData ] = 				useState<TsInterface_UnspecifiedObject>({})
		const [ us_selectedAnalysisPage, us_setSelectedAnalysisPage ] = 					useState<TsType_String>("main_summary")
		const [ us_selectedDateFilter, us_setSelectedDateFilter ] = 						useState<TsType_String>("week")
		const [ us_selectedSection, us_setSelectedSection ] = 								useState<TsType_String>("file_upload")
		const [ us_summaryData, us_setSummaryData ] = 										useState<TsInterface_UnspecifiedObject>({})
		const [ us_summaryDataToSaveOnLock, us_setSummaryDataToSaveOnLock ] = 				useState<TsInterface_UnspecifiedObject>({})
		const [ us_timecardEmployees, us_setTimecardEmployees ] = 							useState<TsInterface_UnspecifiedObject>({})
		const [ us_timecardFileData, us_setTimecardFileData ] = 							useState<TsInterface_UnspecifiedObject>({})
		const [ us_timecardFileHeaders, us_setTimecardFileHeaders ] = 						useState<TsInterface_UnspecifiedObject>({})
		const [ us_timecardReportMappingData, us_setTimecardReportMappingData ] = 			useState<TsInterface_UnspecifiedObject>({})
		const [ us_unmappedDrivers, us_setUnmappedDrivers ] = 								useState<TsType_String[]>([])
		const [ us_uploadingData, us_setUploadingData ] = 									useState<TsType_Boolean>(false)
		const [ us_userHourColors, us_setUserHourColors ] = 								useState<TsInterface_UnspecifiedObject>({})
		const [ us_userInvertedMappingData, us_setUserInvertedMappingData ] = 				useState<TsInterface_UnspecifiedObject>({})
		const [ us_userMappingData, us_setUserMappingData ] = 								useState<TsInterface_UnspecifiedObject>({})
		const un_routerNaviation = 															useNavigate()
		const ur_forceRerender = 															useReducer(() => ({}), {})[1] as () => TsType_Void
		const { CSVReader } = 																useCSVReader()
		const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = 						useContext( Context_RootData_ClientKey )
		const { uc_setUserInterface_ConfirmDialogDisplay } = 								useContext( Context_UserInterface_ConfirmDialog )
		const { uc_setUserInterface_CustomDialogDisplay } = 								useContext( Context_UserInterface_CustomDialog )
		const { uc_setUserInterface_ErrorDialogDisplay } = 									useContext( Context_UserInterface_ErrorDialog )
		const { uc_setUserInterface_PromptDialogDisplay } = 								useContext( Context_UserInterface_PromptDialog )
		// { sort-end } - hooks

		// Hooks - useEffect
		useEffect(() => {
			us_setColorHourCutoff(1)
			us_setSelectedDateFilter("week")
		}, [])

		useEffect(() => {
			let unsubscribeLiveData: TsType_VoidFunction
			const updateLiveData = ( newData: TsInterface_UnspecifiedObject ) => {
				us_setRootAnalysis( newData )
				us_setInitialDataLoaded( true )
				ur_forceRerender()
			}
			getClientKey( uc_RootData_ClientKey, uc_setRootData_ClientKey ).then(( res_GCK ) => {
				unsubscribeLiveData = DatabaseGetLiveDocument( DatabaseRef_TimecardAnalysis_Document( res_GCK.clientKey, analysisKey ), updateLiveData )
			}).catch(( rej_GCK ) => {
				console.error( rej_GCK )
			})
			return () => {
				if (typeof unsubscribeLiveData === 'function'){
					unsubscribeLiveData()
				}
			}
		}, [analysisKey, uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender, us_setRootAnalysis])

		useEffect(() => {
			let unsubscribeLiveData: TsType_VoidFunction
			const updateLiveData = ( newData: TsInterface_UnspecifiedObject ) => {
				us_setDeliveryFileData( newData )
				ur_forceRerender()
			}
			getClientKey( uc_RootData_ClientKey, uc_setRootData_ClientKey ).then(( res_GCK ) => {
				unsubscribeLiveData = DatabaseGetLiveCollection( DatabaseRef_TimecardAnalysis_DeliveryFile_Collection( res_GCK.clientKey, analysisKey ), updateLiveData )
			}).catch(( rej_GCK ) => {
				console.error( rej_GCK )
			})
			return () => {
				if (typeof unsubscribeLiveData === 'function'){
					unsubscribeLiveData()
				}
			}
		}, [analysisKey, uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender, us_setDeliveryFileData])

		useEffect(() => {
			let unsubscribeLiveData: TsType_VoidFunction
			const updateLiveData = ( newData: TsInterface_UnspecifiedObject ) => {
				us_setTimecardFileData( newData )
				ur_forceRerender()
			}
			getClientKey( uc_RootData_ClientKey, uc_setRootData_ClientKey ).then(( res_GCK ) => {
				unsubscribeLiveData = DatabaseGetLiveCollection( DatabaseRef_TimecardAnalysis_TimecardFile_Collection( res_GCK.clientKey, analysisKey ), updateLiveData )
			}).catch(( rej_GCK ) => {
				console.error( rej_GCK )
			})
			return () => {
				if (typeof unsubscribeLiveData === 'function'){
					unsubscribeLiveData()
				}
			}
		}, [analysisKey, uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender])

		useEffect(() => {
			let unsubscribeLiveData: TsType_VoidFunction
			const updateLiveData = ( newData: TsInterface_UnspecifiedObject ) => {
				us_setUserMappingData( newData )
				let invertedMappingData: TsInterface_UnspecifiedObject = {}
				for( let key in newData ){
					let value = newData[ key ]
					invertedMappingData[ value ] = key
				}
				us_setUserInvertedMappingData( invertedMappingData )
				ur_forceRerender()
			}
			getClientKey( uc_RootData_ClientKey, uc_setRootData_ClientKey ).then(( res_GCK ) => {
				unsubscribeLiveData = DatabaseGetLiveDocument( DatabaseRef_TimecardAnalysis_UserMapping_Document( res_GCK.clientKey ), updateLiveData )
			}).catch(( rej_GCK ) => {
				console.error( rej_GCK )
			})
			return () => {
				if (typeof unsubscribeLiveData === 'function'){
					unsubscribeLiveData()
				}
			}
		}, [analysisKey, uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender])

		useEffect(() => {
			let unsubscribeLiveData: TsType_VoidFunction
			const updateLiveData = ( newData: TsInterface_UnspecifiedObject ) => {
				us_setDeliveryReportMappingData( newData )
				ur_forceRerender()
			}
			getClientKey( uc_RootData_ClientKey, uc_setRootData_ClientKey ).then(( res_GCK ) => {
				unsubscribeLiveData = DatabaseGetLiveDocument( DatabaseRef_TimecardAnalysis_DeliveryReportColumnMapping_Document( res_GCK.clientKey ), updateLiveData )
			}).catch(( rej_GCK ) => {
				console.error( rej_GCK )
			})
			return () => {
				if (typeof unsubscribeLiveData === 'function'){
					unsubscribeLiveData()
				}
			}
		}, [analysisKey, uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender])

		useEffect(() => {
			let unsubscribeLiveData: TsType_VoidFunction
			const updateLiveData = ( newData: TsInterface_UnspecifiedObject ) => {
				us_setTimecardReportMappingData( newData )
				ur_forceRerender()
			}
			getClientKey( uc_RootData_ClientKey, uc_setRootData_ClientKey ).then(( res_GCK ) => {
				unsubscribeLiveData = DatabaseGetLiveDocument( DatabaseRef_TimecardAnalysis_TimecardReportColumnMapping_Document( res_GCK.clientKey ), updateLiveData )
			}).catch(( rej_GCK ) => {
				console.error( rej_GCK )
			})
			return () => {
				if (typeof unsubscribeLiveData === 'function'){
					unsubscribeLiveData()
				}
			}
		}, [analysisKey, uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender])

		useEffect(() => {
			let unsubscribeLiveData: TsType_VoidFunction
			const updateLiveData = ( newData: TsInterface_UnspecifiedObject ) => {
				us_setPaycodeMultiplierMappingData( newData )
				ur_forceRerender()
			}
			getClientKey( uc_RootData_ClientKey, uc_setRootData_ClientKey ).then(( res_GCK ) => {
				unsubscribeLiveData = DatabaseGetLiveDocument( DatabaseRef_TimecardAnalysis_TimecardReportPaycodeMultipliers_Document( res_GCK.clientKey ), updateLiveData )
			}).catch(( rej_GCK ) => {
				console.error( rej_GCK )
			})
			return () => {
				if (typeof unsubscribeLiveData === 'function'){
					unsubscribeLiveData()
				}
			}
		}, [analysisKey, uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender])

		useEffect(() => {
			let unsubscribeLiveData: TsType_VoidFunction
			const updateLiveData = ( newData: TsInterface_UnspecifiedObject ) => {
				us_setRoutePricingMappingData( newData )
				ur_forceRerender()
			}
			getClientKey( uc_RootData_ClientKey, uc_setRootData_ClientKey ).then(( res_GCK ) => {
				unsubscribeLiveData = DatabaseGetLiveDocument( DatabaseRef_TimecardAnalysis_RoutePricing_Document( res_GCK.clientKey ), updateLiveData )
			}).catch(( rej_GCK ) => {
				console.error( rej_GCK )
			})
			return () => {
				if (typeof unsubscribeLiveData === 'function'){
					unsubscribeLiveData()
				}
			}
		}, [analysisKey, uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender])

		useEffect(() => {
			let unsubscribeLiveData: TsType_VoidFunction
			const updateLiveData = ( newData: TsInterface_UnspecifiedObject ) => {
				us_setAnalysisDriverNotesData( newData )
				ur_forceRerender()
			}
			getClientKey( uc_RootData_ClientKey, uc_setRootData_ClientKey ).then(( res_GCK ) => {
				unsubscribeLiveData = DatabaseGetLiveCollection( DatabaseRef_TimecardAnalysis_DriverNotes_Collection( res_GCK.clientKey, analysisKey ), updateLiveData )
			}).catch(( rej_GCK ) => {
				console.error( rej_GCK )
			})
			return () => {
				if (typeof unsubscribeLiveData === 'function'){
					unsubscribeLiveData()
				}
			}
		}, [analysisKey, uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender])

		useEffect(() => {
			let unsubscribeLiveData: TsType_VoidFunction
			const updateLiveData = ( newData: TsInterface_UnspecifiedObject ) => {
				us_setLockedAnalysisDataResults( newData )
				ur_forceRerender()
			}
			getClientKey( uc_RootData_ClientKey, uc_setRootData_ClientKey ).then(( res_GCK ) => {
				unsubscribeLiveData = DatabaseGetLiveCollection( DatabaseRef_TimecardAnalysis_LockedResults_Collection( res_GCK.clientKey, analysisKey ), updateLiveData )
			}).catch(( rej_GCK ) => {
				console.error( rej_GCK )
			})
			return () => {
				if (typeof unsubscribeLiveData === 'function'){
					unsubscribeLiveData()
				}
			}
		}, [analysisKey, uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender])

		// Set Active Section
		useEffect(() => {
			// Setting active nav section
			if(
				us_initialDataLoaded === true &&
				us_initialSectionSelectionMade === false
			){
				// Page Load Nav Selection
				// TODO - more sitations
				if(
					us_rootAnalysis != null &&
					us_rootAnalysis.locked === true
				){
					us_setSelectedSection( "results" )
					us_setInitialSectionSelectionMade( true )
				} else if(
					us_rootAnalysis != null &&
					us_rootAnalysis.file_upload_delivery === true &&
					us_rootAnalysis.file_upload_timecard === true
				){
					// TODO - skip data mapping if it's already been done
					// us_setSelectedSection( "data_mapping" )
					us_setSelectedSection( "results" )
					us_setInitialSectionSelectionMade( true )
				} else {
					us_setSelectedSection( "file_upload" )
					us_setInitialSectionSelectionMade( true )
				}
			} else if( us_openToAutoChangingNavSection === true ){
				// Event Driven Nav Selection
				// TODO - more sitations
				if(
					us_rootAnalysis != null &&
					us_rootAnalysis.locked === true
				){
					us_setSelectedSection( "results" )
					us_setInitialSectionSelectionMade( true )
				} else if(
					us_rootAnalysis != null &&
					us_rootAnalysis.file_upload_delivery === true &&
					us_rootAnalysis.file_upload_timecard === true
				){
					// TODO - skip data mapping if it's already been done (probably determine in loop of data???)
					// us_setSelectedSection( "data_mapping" )
					us_setSelectedSection( "results" )
					us_setOpenToAutoChangingNavSection( false )
				}
			}
			return () => {}
		}, [us_initialDataLoaded, us_initialSectionSelectionMade, us_openToAutoChangingNavSection, us_rootAnalysis])

		// Large Analysis
		useEffect(() => {
			if(
				us_rootAnalysis != null &&
				us_rootAnalysis.locked === true
			){
				us_setDateDropdownOptions( getProp( us_lockedAnalysisDataResults, "dateDropdownOptions", {} ) )
				us_setDataFileDates( getProp( us_lockedAnalysisDataResults, "dataFileDates", {} ) )
				us_setDeliveryDrivers( getProp( us_lockedAnalysisDataResults, "deliveryDrivers", {} ) )
				us_setTimecardEmployees( getProp( us_lockedAnalysisDataResults, "timecardEmployees", {} ) )
				us_setPayCodesList( getProp( us_lockedAnalysisDataResults, "payCodesList", {} ) )
				us_setDeliveryFileHeaders( getProp( us_lockedAnalysisDataResults, "deliveryFileHeaders", {} ) )
				us_setTimecardFileHeaders( getProp( us_lockedAnalysisDataResults, "timecardFileHeaders", {} ) )
				us_setDeliveryRouteTypes( getProp( us_lockedAnalysisDataResults, "deliveryRouteTypes", {} ) )
				us_setAnalysisErrorData( getProp( us_lockedAnalysisDataResults, "analysisErrorData", {} ) )
				us_setAnalysisErrorDataNames( getProp( us_lockedAnalysisDataResults, "analysisErrorDataNames", {} ) )
				let combinedAnalysisCalculatedData: TsInterface_UnspecifiedObject = {}
				for( let loopPropKey in us_lockedAnalysisDataResults ){
					if( loopPropKey.includes( "analysisCalculatedData_" ) ){
						for( let loopSubPropKey in us_lockedAnalysisDataResults[ loopPropKey ] ){
							combinedAnalysisCalculatedData[ loopSubPropKey ] = us_lockedAnalysisDataResults[ loopPropKey ][ loopSubPropKey ]
						}
					}
				}
				us_setAnalysisCalculatedData( combinedAnalysisCalculatedData )
			} else {

				/////////////////////////////////
				// Instantiate Variables
				/////////////////////////////////

				let deliveryFirstDate: TsType_Null | TsType_String = null
				let deliveryLastDate: TsType_Null | TsType_String = null
				let timecardFirstDate: TsType_Null | TsType_String = null
				let timecardLastDate: TsType_Null | TsType_String = null
				let combinedData: TsInterface_UnspecifiedObject = {}
				let deliveryFileHeaders: TsInterface_UnspecifiedObject = {}
				let timecardFileHeaders: TsInterface_UnspecifiedObject = {}
				let deliveryDrivers: TsInterface_UnspecifiedObject = {}
				let timecardEmployees: TsInterface_UnspecifiedObject = {}
				let timecardPayCodes: TsInterface_UnspecifiedObject = {}
				let deliveryRoutes: TsInterface_UnspecifiedObject = {}
				const errorDataNames: TsInterface_UnspecifiedObject = {
					// Delivery
					delivery_MissingDrivers: { type: "delivery", key: "delivery_MissingDrivers", name: "Missing Drivers" },
					delivery_InvalidDates: { type: "delivery", key: "delivery_InvalidDates", name: "Invalid Delivery Dates" },
					delivery_MissingDates: { type: "delivery", key: "delivery_MissingDates", name: "Missing Delivery Dates" },
					delivery_InvalidRoutes: { type: "delivery", key: "delivery_InvalidRoutes", name: "Invalid Routes" },
					delivery_MissingRoute: { type: "delivery", key: "delivery_MissingRoute", name: "Missing Routes" },
					delivery_MissingOrInvalidRouteDuration: { type: "delivery", key: "delivery_MissingOrInvalidRouteDuration", name: "Missing or Invalid Route Duration" },
					delivery_MissingDeliveryEndTime: { type: "delivery", key: "delivery_MissingDeliveryEndTime", name: "Missing Delivery End Time" },
					delivery_MissingDeliveryStartTime: { type: "delivery", key: "delivery_MissingDeliveryStartTime", name: "Missing Delivery Start Time" },
					delivery_MissingStationNames: { type: "delivery", key: "delivery_MissingStationNames", name: "Missing Station Names" },
					delivery_MissingRouteNames: { type: "delivery", key: "delivery_MissingRouteNames", name: "Missing Route Names" },
					delivery_MissingRouteTypes: { type: "delivery", key: "delivery_MissingRouteTypes", name: "Missing Route Types" },
					delivery_MissingShipmentsDelivered: { type: "delivery", key: "delivery_MissingShipmentsDelivered", name: "Missing Shipments Delivered" },
					delivery_MissingShipmentsReturned: { type: "delivery", key: "delivery_MissingShipmentsReturned", name: "Missing Shipments Returned" },
					delivery_MissingPlannedDistance: { type: "delivery", key: "delivery_MissingPlannedDistance", name: "Missing Planned Distance" },
					delivery_MissingAllowedDistance: { type: "delivery", key: "delivery_MissingAllowedDistance", name: "Missing Allowed Distance" },
					// Timecard
					timecard_MissingPayCodes: { type: "timecard", key: "timecard_MissingPayCodes", name: "Missing Paycodes" },
					timecard_MissingEmployeeRole: { type: "timecard", key: "timecard_MissingEmployeeRole", name: "Missing Employee Role" },
					timecard_InvalidDates: { type: "timecard", key: "timecard_InvalidDates", name: "Invalid Timecard Dates" },
					timecard_MissingDates: { type: "timecard", key: "timecard_MissingDates", name: "Missing Timecard Dates" },
					timecard_MissingEmployees: { type: "timecard", key: "timecard_MissingEmployees", name: "Missing Employees" },
				}
				let errorData: TsInterface_UnspecifiedObject = {
					// Delivery
					delivery_MissingDrivers: {},
					delivery_InvalidDates: {},
					delivery_MissingDates: {},
					delivery_InvalidRoutes: {},
					delivery_MissingRoute: {},
					delivery_MissingOrInvalidRouteDuration: {},
					delivery_MissingDeliveryEndTime: {},
					delivery_MissingDeliveryStartTime: {},
					delivery_MissingStationNames: {},
					delivery_MissingRouteNames: {},
					delivery_MissingRouteTypes: {},
					delivery_MissingShipmentsDelivered: {},
					delivery_MissingShipmentsReturned: {},
					delivery_MissingPlannedDistance: {},
					delivery_MissingAllowedDistance: {},
					// Timecard
					timecard_MissingPayCodes: {},
					timecard_MissingEmployeeRole: {},
					timecard_InvalidDates: {},
					timecard_MissingDates: {},
					timecard_MissingEmployees: {},
				}
				let dateDropdownOptions: TsInterface_UnspecifiedObject = {
					week: {
						key: "week",
						value: "All Dates",
						sort: 0,
					}
				}

				/////////////////////////////////
				// Helper Functions
				/////////////////////////////////

				const potentiallyAddErrorToErrorData = (
					errorGroupKey: TsType_String,
					dataRow: TsInterface_UnspecifiedObject
				) => {
					let reportDataCharacterCount = 0
					for( let loopPropKey in dataRow ){
						let loopPropValue = dataRow[ loopPropKey ]
						if( loopPropKey !== "key" ){
							reportDataCharacterCount += loopPropValue.toString().length
						}
					}
					if(
						reportDataCharacterCount > 0 &&
						dataRow.key != null
					){
						if( errorData[ errorGroupKey ] == null ){
							errorData[ errorGroupKey ] = {}
						}
						errorData[ errorGroupKey ][ dataRow.key ] = dataRow
					}
				}

				const replaceSpecialCharactersWithUnderscores = ( inputString: TsType_String ) => {
					// Use regular expressions to replace the specified characters with underscores
					const replacedString = inputString.replace(/[ ()\-.,]/g, '_')
					return replacedString
				}

				/////////////////////////////////
				// Report Prop Mapping
				/////////////////////////////////

				let deliveryDateProp = returnReportMappingKey( "date", us_deliveryReportMappingData, defaultDeliveryReportMappingData )
				let deliveryStationProp = returnReportMappingKey( "station", us_deliveryReportMappingData, defaultDeliveryReportMappingData )
				let deliveryEmployeeNameProp = returnReportMappingKey( "employee_name", us_deliveryReportMappingData, defaultDeliveryReportMappingData )
				let deliveryRouteProp = returnReportMappingKey( "route", us_deliveryReportMappingData, defaultDeliveryReportMappingData )
				let deliveryRouteTypeProp = returnReportMappingKey( "route_type", us_deliveryReportMappingData, defaultDeliveryReportMappingData )
				let deliveryDurationProp = returnReportMappingKey( "duration", us_deliveryReportMappingData, defaultDeliveryReportMappingData )
				let deliveryStartTimeProp = returnReportMappingKey( "start_time", us_deliveryReportMappingData, defaultDeliveryReportMappingData )
				let deliveryEndTimeProp = returnReportMappingKey( "end_time", us_deliveryReportMappingData, defaultDeliveryReportMappingData )
				let deliveryDistancePlannedProp = returnReportMappingKey( "distance_planned", us_deliveryReportMappingData, defaultDeliveryReportMappingData )
				let deliveryDistanceAllowedProp = returnReportMappingKey( "distance_allowed", us_deliveryReportMappingData, defaultDeliveryReportMappingData )
				let deliveryDistanceUnitsProp = returnReportMappingKey( "distance_units", us_deliveryReportMappingData, defaultDeliveryReportMappingData )
				let deliveryPackagesDeliveredProp = returnReportMappingKey( "packages_delivered", us_deliveryReportMappingData, defaultDeliveryReportMappingData )
				let deliveryPackagesReturnedProp = returnReportMappingKey( "packages_returned", us_deliveryReportMappingData, defaultDeliveryReportMappingData )
				let timecardEmployeeFirstNameProp = returnReportMappingKey( "first_name", us_timecardReportMappingData, firstAndLastNameTimecardReportMappingData )
				let timecardEmployeeLastNameProp = returnReportMappingKey( "last_name", us_timecardReportMappingData, firstAndLastNameTimecardReportMappingData )
				let timecardEmployeeNameProp = returnReportMappingKey( "employee_name", us_timecardReportMappingData, defaultTimecardReportMappingData )
				let timecardEmployeeRoleProp = returnReportMappingKey( "employee_role", us_timecardReportMappingData, defaultTimecardReportMappingData )
				let timecardPayCodeProp = returnReportMappingKey( "pay_code", us_timecardReportMappingData, defaultTimecardReportMappingData )
				let timecardDateProp = returnReportMappingKey( "date", us_timecardReportMappingData, defaultTimecardReportMappingData )
				let timecardHoursProp = returnReportMappingKey( "hours", us_timecardReportMappingData, defaultTimecardReportMappingData )
				let timecardPayRateProp = returnReportMappingKey( "pay_rate", us_timecardReportMappingData, defaultTimecardReportMappingData )

				/////////////////////////////////
				// Determine Name Mapping Type
				/////////////////////////////////
				let nameMappingType = "full_name_in_a_single_column"
				if(
					us_rootAnalysis != null &&
					us_rootAnalysis.name_mapping_type != null
				){
					nameMappingType = us_rootAnalysis.name_mapping_type
				} else if(
					us_timecardReportMappingData != null &&
					us_timecardReportMappingData.name_mapping_type != null
				){
					nameMappingType = us_timecardReportMappingData.name_mapping_type
				}

				/////////////////////////////////
				// Determine Rescues vs Primary
				/////////////////////////////////

					// QUESTION - Can Training Days be rescued?
					// QUESTION - How do I know what driver is assigned to a training day?
					// QUESTION - If they don't fill it out, how do you want it handled?

				let driversPerUniqueRoute: TsInterface_UnspecifiedObject = {}
				for( let loopDeliveryRowIndex in us_deliveryFileData ){
					let loopDeliveryRow = us_deliveryFileData[ loopDeliveryRowIndex ]
					if(
						deliveryRouteProp != null &&
						loopDeliveryRow[ deliveryRouteProp ] != null
					){
						if(
							deliveryDateProp != null &&
							loopDeliveryRow[ deliveryDateProp ] != null &&
							isValidDate( loopDeliveryRow[ deliveryDateProp ] ) === true
						){
							let deliveryDateKey = formatDateString( loopDeliveryRow[ deliveryDateProp ] )
							// Set Dates
							if(
								deliveryFirstDate == null ||
								new Date( deliveryDateKey ) < new Date( deliveryFirstDate )
							){
								deliveryFirstDate = deliveryDateKey
							}
							if(
								deliveryLastDate == null ||
								new Date( deliveryDateKey ) > new Date( deliveryLastDate )
							){
								deliveryLastDate = deliveryDateKey
							}
							if( driversPerUniqueRoute[ loopDeliveryRow[ deliveryRouteProp ] + "_" + deliveryDateKey ] == null ){
								driversPerUniqueRoute[ loopDeliveryRow[ deliveryRouteProp ] + "_" + deliveryDateKey ] = {
									count: 0,
									drivers: {}
								}
							}
							driversPerUniqueRoute[ loopDeliveryRow[ deliveryRouteProp ] + "_" + deliveryDateKey ]["count"]++
							if(
								deliveryEmployeeNameProp != null &&
								loopDeliveryRow[ deliveryEmployeeNameProp ] != null &&
								loopDeliveryRow[ deliveryEmployeeNameProp ] !== "" &&
								deliveryDistanceUnitsProp != null
							){
								if(
									loopDeliveryRow[ deliveryDistanceUnitsProp ] !== ""
								){
									driversPerUniqueRoute[ loopDeliveryRow[ deliveryRouteProp ] + "_" + deliveryDateKey ]["drivers"][ loopDeliveryRow[ deliveryEmployeeNameProp ] ] = "primary"
								} else {
									driversPerUniqueRoute[ loopDeliveryRow[ deliveryRouteProp ] + "_" + deliveryDateKey ]["drivers"][ loopDeliveryRow[ deliveryEmployeeNameProp ] ] = "rescuer"
								}
							} else {
								potentiallyAddErrorToErrorData( "delivery_MissingDrivers", loopDeliveryRow )
							}
						} else if( deliveryDateProp != null ) {
							potentiallyAddErrorToErrorData( "delivery_InvalidDates", loopDeliveryRow )
						} else {
							potentiallyAddErrorToErrorData( "delivery_MissingDates", loopDeliveryRow )
						}
					} else if( deliveryRouteProp != null ) {
						potentiallyAddErrorToErrorData( "delivery_InvalidRoutes", loopDeliveryRow )
					} else {
						potentiallyAddErrorToErrorData( "delivery_MissingRoute", loopDeliveryRow )
					}
				}

				/////////////////////////////////
				// Loop through Delivery Data
				/////////////////////////////////

				for( let loopDeliveryRowIndex in us_deliveryFileData ){
					let loopDeliveryRow = us_deliveryFileData[ loopDeliveryRowIndex ]

					// Determine if Route is Primary or Rescuer
					let routePrimaryOrRescuer = null
					if(
						deliveryDateProp != null &&
						loopDeliveryRow[ deliveryDateProp ] != null &&
						isValidDate( loopDeliveryRow[ deliveryDateProp ] ) === true
					){
						let reportDateString = loopDeliveryRow[ deliveryDateProp ]
						let reportDateObject = returnDateFromUnknownDateFormat( reportDateString )
						let correctedDateObject = new Date( reportDateObject.setHours( reportDateObject.getHours() + 12 ) )
						let dateKey = returnFormattedDateKey( correctedDateObject )
						if(
							deliveryEmployeeNameProp != null &&
							loopDeliveryRow[ deliveryEmployeeNameProp ] != null &&
							loopDeliveryRow[ deliveryEmployeeNameProp ] !== ""
						){
							let driverNameKey = loopDeliveryRow[ deliveryEmployeeNameProp ]
							if(
								deliveryRouteProp != null &&
								loopDeliveryRow[ deliveryRouteProp ] != null
							){
								if( dateKey != null ){
									if(
										driversPerUniqueRoute != null &&
										driversPerUniqueRoute[ loopDeliveryRow[ deliveryRouteProp ] + "_" + dateKey ] != null &&
										driversPerUniqueRoute[ loopDeliveryRow[ deliveryRouteProp ] + "_" + dateKey ]["drivers"] != null &&
										driversPerUniqueRoute[ loopDeliveryRow[ deliveryRouteProp ] + "_" + dateKey ]["drivers"][ driverNameKey ] != null
									){
										routePrimaryOrRescuer = driversPerUniqueRoute[ loopDeliveryRow[ deliveryRouteProp ] + "_" + dateKey ]["drivers"][ driverNameKey ]
									} else {

									}
								} else {
									potentiallyAddErrorToErrorData( "delivery_InvalidDates", loopDeliveryRow )
								}
							} else {
								potentiallyAddErrorToErrorData( "delivery_MissingRoute", loopDeliveryRow )
							}
						} else {
							// Handled Above
							// potentiallyAddErrorToErrorData( "delivery_MissingDrivers", loopDeliveryRow )
						}
					} else {
						// Handled Above
						// potentiallyAddErrorToErrorData( "delivery_InvalidDates", loopDeliveryRow )
						// potentiallyAddErrorToErrorData( "delivery_MissingDates", loopDeliveryRow )
					}

					/////////////////////////////////
					// Determine Headers
					/////////////////////////////////

					for( let loopPropKey in loopDeliveryRow ){
						if( loopPropKey !== "key" ){
							deliveryFileHeaders[ loopPropKey ] = loopPropKey
						}
					}

					/////////////////////////////////
					// Route Types and pricing
					/////////////////////////////////

					if( routePrimaryOrRescuer !== "rescuer" ){
						if(
							deliveryRouteTypeProp != null &&
							loopDeliveryRow[ deliveryRouteTypeProp ] != null &&
							loopDeliveryRow[ deliveryRouteTypeProp ] !== ""
						){
							if(
								deliveryDurationProp != null &&
								loopDeliveryRow[ deliveryDurationProp ] != null &&
								loopDeliveryRow[ deliveryDurationProp ] !== ""
							){
								if(
									deliveryStationProp != null &&
									loopDeliveryRow[ deliveryStationProp ] != null &&
									loopDeliveryRow[ deliveryStationProp ] !== ""
								){

									let routeKey = replaceSpecialCharactersWithUnderscores( loopDeliveryRow[ deliveryStationProp ] + "_" + loopDeliveryRow[ deliveryDurationProp ] + "_" + loopDeliveryRow[ deliveryRouteTypeProp ] )
									// Determine Route Revenue
									let routeRevenue = 0
									if(
										us_routePricingMappingData != null &&
										us_routePricingMappingData[ routeKey ] != null
									){
										routeRevenue = us_routePricingMappingData[ routeKey ]
									}
									// Determine Package Rates
									let packageBaseRate = 0
									let packageBonusRate = 0
									if(
										us_routePricingMappingData != null &&
										us_routePricingMappingData[ routeKey + "_pkg_base_price" ] != null
									){
										packageBaseRate = us_routePricingMappingData[ routeKey + "_pkg_base_price" ]
									}
									if(
										us_routePricingMappingData != null &&
										us_routePricingMappingData[ routeKey + "_pkg_bonus_price" ] != null
									){
										packageBonusRate = us_routePricingMappingData[ routeKey + "_pkg_bonus_price" ]
									}
									// Week and Base for Mapping
									let deliveredPackageCount = 0
									let returnedPackageCount = 0
									if(
										loopDeliveryRow != null &&
										deliveryPackagesDeliveredProp != null &&
										loopDeliveryRow[ deliveryPackagesDeliveredProp ] != null &&
										loopDeliveryRow[ deliveryPackagesDeliveredProp ] !== "" &&
										isNaN( parseInt( loopDeliveryRow[ deliveryPackagesDeliveredProp ] ) ) === false
									){
										deliveredPackageCount = parseInt( getProp( loopDeliveryRow, deliveryPackagesDeliveredProp, 0 ) )
									}
									if(
										loopDeliveryRow != null &&
										deliveryPackagesReturnedProp != null &&
										loopDeliveryRow[ deliveryPackagesReturnedProp ] != null &&
										loopDeliveryRow[ deliveryPackagesReturnedProp ] !== "" &&
										isNaN( parseInt( loopDeliveryRow[ deliveryPackagesReturnedProp ] ) ) === false
									){
										returnedPackageCount = parseInt( getProp( loopDeliveryRow, deliveryPackagesReturnedProp, 0 ) )
									}
									if( deliveryRoutes[ routeKey ] == null ){
										// Create Route on List
										deliveryRoutes[ routeKey ] = {
											key: routeKey,
											station: loopDeliveryRow[ deliveryStationProp ],
											duration: loopDeliveryRow[ deliveryDurationProp ],
											route_type: loopDeliveryRow[ deliveryRouteTypeProp ],
											route_revenue_per_run: routeRevenue,
											week: {
												key: routeKey,
												station: loopDeliveryRow[ deliveryStationProp ],
												duration: loopDeliveryRow[ deliveryDurationProp ],
												route_type: loopDeliveryRow[ deliveryRouteTypeProp ],
												route_revenue_per_run: routeRevenue,
												route_run_count: 1,
												route_packages_delivered_count: deliveredPackageCount,
												route_packages_returned_count: returnedPackageCount,
												package_base_rate: packageBaseRate,
												package_bonus_rate: packageBonusRate,
											}
										}
									} else {
										deliveryRoutes[ routeKey ]["week"]["route_run_count"]++
										deliveryRoutes[ routeKey ]["week"]["route_packages_delivered_count"] += deliveredPackageCount
										deliveryRoutes[ routeKey ]["week"]["route_packages_returned_count"] += returnedPackageCount
									}
									// Divided by day
									if(
										deliveryDateProp != null &&
										loopDeliveryRow[ deliveryDateProp ] != null &&
										isValidDate( loopDeliveryRow[ deliveryDateProp ] ) === true
									){
										// Correct and Format Date
										let reportDateString = loopDeliveryRow[ deliveryDateProp ]
										let reportDateObject = returnDateFromUnknownDateFormat( reportDateString )
										let correctedDateObject = new Date( reportDateObject.setHours( reportDateObject.getHours() + 12 ) )
										let dateKey = returnFormattedDateKey( correctedDateObject )
										if( deliveryRoutes[ routeKey ]["day_" + dateKey ] == null ){
											deliveryRoutes[ routeKey ]["day_" + dateKey ] = {
												key: routeKey,
												station: loopDeliveryRow[ deliveryStationProp ],
												duration: loopDeliveryRow[ deliveryDurationProp ],
												route_type: loopDeliveryRow[ deliveryRouteTypeProp ],
												route_revenue_per_run: routeRevenue,
												route_run_count: 1,
												route_packages_delivered_count: deliveredPackageCount,
												route_packages_returned_count: returnedPackageCount,
												package_base_rate: packageBaseRate,
												package_bonus_rate: packageBonusRate,
											}
										} else {
											deliveryRoutes[ routeKey ]["day_" + dateKey ]["route_run_count"]++
											deliveryRoutes[ routeKey ]["day_" + dateKey ]["route_packages_delivered_count"] += deliveredPackageCount
											deliveryRoutes[ routeKey ]["day_" + dateKey ]["route_packages_returned_count"] += returnedPackageCount
										}
									}
								} else {
									potentiallyAddErrorToErrorData( "delivery_MissingStationNames", loopDeliveryRow )
								}
							} else {
								potentiallyAddErrorToErrorData( "delivery_MissingRouteTypes", loopDeliveryRow )
							}
						} else {
							potentiallyAddErrorToErrorData( "delivery_MissingRouteDuration", loopDeliveryRow )
						}
					} else {
						// No error - training days?
					}

					/////////////////////////////////
					// Main Delivery Data Analysis
					/////////////////////////////////

						// QUESTION - How to handle training days???

					// if(
					// 	deliveryRouteTypeProp != null &&
					// 	loopDeliveryRow[ deliveryRouteTypeProp ] === "Training Day"
					// ){

					// TODO - training routes - don't have a Delivery Associate for some reason...

					// } else
					if(
						deliveryEmployeeNameProp != null &&
						loopDeliveryRow[ deliveryEmployeeNameProp ] != null &&
						loopDeliveryRow[ deliveryEmployeeNameProp ] !== ""
					){
						let driverNameKey = loopDeliveryRow[ deliveryEmployeeNameProp ]
						if(
							driverNameKey != null &&
							driverNameKey !== ""
						){
							// Used to build user links
							deliveryDrivers[ driverNameKey ] = driverNameKey
							// Compound Data
							if( combinedData[ driverNameKey ] == null ){
								// Create User
								combinedData[ driverNameKey ] = {
									name: driverNameKey,
									week: {
										delivery_clocked_hours_total: 0,
										delivery_distance_allowance_total: 0,
										delivery_distance_planned_total: 0,
										delivery_rescued_count: 0,
										delivery_rescuer_count: 0,
										delivery_route_hours_total: 0,
										delivery_route_names_breakdown: {},
										delivery_route_revenue_total: 0,
										delivery_route_types_breakdown: {},
										delivery_routes_count: 0,
										delivery_routes_missing_financial_mapping: 0,
										delivery_shipments_delivered_total: 0,
										delivery_shipments_returned_total: 0,
										delivery_station_names_breakdown: {},
										has_delivery_data: true,
										has_payroll_data: false,
										name: driverNameKey,
										payroll_clocked_hours_all_by_paycode_breakdown: {},
										payroll_clocked_hours_all_by_role_breakdown: {},
										payroll_clocked_hours_all_total: 0,
										payroll_clocked_hours_excluded_by_paycode_breakdown: {},
										payroll_clocked_hours_excluded_by_role_breakdown: {},
										payroll_clocked_hours_excluded_total: 0,
										payroll_clocked_hours_included_by_paycode_breakdown: {},
										payroll_clocked_hours_included_by_role_breakdown: {},
										payroll_clocked_hours_included_total: 0,
										payroll_pay_by_paycode_breakdown: {},
										payroll_pay_by_role_breakdown: {},
										payroll_pay_errors_total: 0,
										payroll_pay_total: 0,
										payroll_paycode_breakdown: {},
										payroll_payrate_by_paycode_breakdown: {},
										payroll_role_breakdown: {},
									},
								}
							}
							// Handle Days
							if(
								deliveryDateProp != null &&
								loopDeliveryRow[ deliveryDateProp ] != null &&
								isValidDate( loopDeliveryRow[ deliveryDateProp ] ) === true
							){
								// Correct and Format Date
								let reportDateString = loopDeliveryRow[ deliveryDateProp ]
								let reportDateObject = returnDateFromUnknownDateFormat( reportDateString )
								let correctedDateObject = new Date( reportDateObject.setHours( reportDateObject.getHours() + 12 ) )
								let dateKey = returnFormattedDateKey( correctedDateObject )

								// Create Day Record if it doesn't exist
								dateDropdownOptions["day_" + dateKey ] = {
									key: "day_" + dateKey,
									value: dateKey + " (" + returnFormattedDate( new Date(dateKey).getTime() + 86400000 / 2 , "ddd") + ")",
									sort: new Date(dateKey).getTime()
								}
								if( combinedData[ driverNameKey ]["day_" + dateKey ] == null ){
									combinedData[ driverNameKey ]["day_" + dateKey ] = {
										delivery_clocked_hours_total: 0,
										delivery_distance_allowance_total: 0,
										delivery_distance_planned_total: 0,
										delivery_rescued_count: 0,
										delivery_rescuer_count: 0,
										delivery_route_hours_total: 0,
										delivery_route_names_breakdown: {},
										delivery_route_revenue_total: 0,
										delivery_route_types_breakdown: {},
										delivery_routes_count: 0,
										delivery_routes_missing_financial_mapping: 0,
										delivery_shipments_delivered_total: 0,
										delivery_shipments_returned_total: 0,
										delivery_station_names_breakdown: {},
										has_delivery_data: true,
										has_payroll_data: false,
										name: driverNameKey,
										payroll_clocked_hours_all_by_paycode_breakdown: {},
										payroll_clocked_hours_all_by_role_breakdown: {},
										payroll_clocked_hours_all_total: 0,
										payroll_clocked_hours_excluded_by_paycode_breakdown: {},
										payroll_clocked_hours_excluded_by_role_breakdown: {},
										payroll_clocked_hours_excluded_total: 0,
										payroll_clocked_hours_included_by_paycode_breakdown: {},
										payroll_clocked_hours_included_by_role_breakdown: {},
										payroll_clocked_hours_included_total: 0,
										payroll_pay_by_paycode_breakdown: {},
										payroll_pay_by_role_breakdown: {},
										payroll_pay_errors_total: 0,
										payroll_pay_total: 0,
										payroll_paycode_breakdown: {},
										payroll_payrate_by_paycode_breakdown: {},
										payroll_role_breakdown: {},
									}
								}

								// Handle Rescuer and Rescued Routes
								if(
									deliveryRouteProp != null &&
									loopDeliveryRow[ deliveryRouteProp ] != null
								){
									// If there are multiple drivers on a route, handle rescues
									if( driversPerUniqueRoute[ loopDeliveryRow[ deliveryRouteProp ] + "_" + dateKey ]["count"] > 1 ){
										if( routePrimaryOrRescuer === "primary" ){
											combinedData[ driverNameKey ]["week"]["delivery_rescued_count"]++
											combinedData[ driverNameKey ]["day_" + dateKey ]["delivery_rescued_count"]++
										}
										if( routePrimaryOrRescuer === "rescuer" ){
											combinedData[ driverNameKey ]["week"]["delivery_rescuer_count"]++
											combinedData[ driverNameKey ]["day_" + dateKey ]["delivery_rescuer_count"]++
										}
									}
								} else {
									potentiallyAddErrorToErrorData( "delivery_MissingRoute", loopDeliveryRow )
								}

								// Compile Delivery Route Data
								combinedData[ driverNameKey ]["week"]["delivery_routes_count"]++
								combinedData[ driverNameKey ]["day_" + dateKey ]["delivery_routes_count"]++

								// Calculate Route Hours and Delivery Hours (only primary drivers)
								if( routePrimaryOrRescuer === "primary" ){
									if(
										deliveryDurationProp != null &&
										loopDeliveryRow[ deliveryDurationProp ] != null &&
										loopDeliveryRow[ deliveryDurationProp ] !== ""
									){
										let timeDuration = parseTimeDuration( loopDeliveryRow[ deliveryDurationProp ] )
										if( !isNaN( timeDuration ) ){
											combinedData[ driverNameKey ]["week"]["delivery_route_hours_total"] += timeDuration
											combinedData[ driverNameKey ]["day_" + dateKey ]["delivery_route_hours_total"] += timeDuration
										} else {
											potentiallyAddErrorToErrorData( "delivery_MissingOrInvalidRouteDuration", loopDeliveryRow )
										}
									} else {
										potentiallyAddErrorToErrorData( "delivery_MissingOrInvalidRouteDuration", loopDeliveryRow )
									}
									if(
										deliveryStartTimeProp != null &&
										loopDeliveryRow[ deliveryStartTimeProp ] != null &&
										loopDeliveryRow[ deliveryStartTimeProp ] !== ""
									){
										if(
											deliveryEndTimeProp != null &&
											loopDeliveryRow[ deliveryEndTimeProp ] != null &&
											loopDeliveryRow[ deliveryEndTimeProp ] !== ""
										){
											combinedData[ driverNameKey ]["week"]["delivery_clocked_hours_total"] += calculateHoursBetweenDates( loopDeliveryRow[ deliveryStartTimeProp ], loopDeliveryRow[ deliveryEndTimeProp ] )
											combinedData[ driverNameKey ]["day_" + dateKey ]["delivery_clocked_hours_total"] += calculateHoursBetweenDates( loopDeliveryRow[ deliveryStartTimeProp ], loopDeliveryRow[ deliveryEndTimeProp ] )
										} else {
											potentiallyAddErrorToErrorData( "delivery_MissingDeliveryEndTime", loopDeliveryRow )
										}
									} else {
										potentiallyAddErrorToErrorData( "delivery_MissingDeliveryStartTime", loopDeliveryRow )
										if(
											deliveryStartTimeProp != null &&
											loopDeliveryRow[ deliveryStartTimeProp ] != null &&
											loopDeliveryRow[ deliveryStartTimeProp ] !== ""
										){
											// Nothing
										} else {
											potentiallyAddErrorToErrorData( "delivery_MissingDeliveryEndTime", loopDeliveryRow )
										}
									}
								}

								// Handle all the other data
								if(
									deliveryStationProp != null &&
									loopDeliveryRow[ deliveryStationProp ] != null &&
									loopDeliveryRow[ deliveryStationProp ] !== ""
								){
									combinedData[ driverNameKey ]["week"]["delivery_station_names_breakdown"][ loopDeliveryRow[ deliveryStationProp ] ] = loopDeliveryRow[ deliveryStationProp ]
									combinedData[ driverNameKey ]["day_" + dateKey ]["delivery_station_names_breakdown"][ loopDeliveryRow[ deliveryStationProp ] ] = loopDeliveryRow[ deliveryStationProp ]
								} else {
									potentiallyAddErrorToErrorData( "delivery_MissingStationNames", loopDeliveryRow )
								}

								if(
									deliveryRouteProp != null &&
									loopDeliveryRow[ deliveryRouteProp ] != null &&
									loopDeliveryRow[ deliveryRouteProp ] !== ""
								){
									combinedData[ driverNameKey ]["week"]["delivery_route_names_breakdown"][ loopDeliveryRow[ deliveryRouteProp ] ] = loopDeliveryRow[ deliveryRouteProp ]
									combinedData[ driverNameKey ]["day_" + dateKey ]["delivery_route_names_breakdown"][ loopDeliveryRow[ deliveryRouteProp ] ] = loopDeliveryRow[ deliveryRouteProp ]
								} else {
									potentiallyAddErrorToErrorData( "delivery_MissingRouteNames", loopDeliveryRow )
								}

								// Route Revenue is only calculated for primary drivers
								if( routePrimaryOrRescuer === "primary" ){
									if(
										deliveryRouteTypeProp != null &&
										loopDeliveryRow[ deliveryRouteTypeProp ] != null &&
										loopDeliveryRow[ deliveryRouteTypeProp ] !== ""
									){
										combinedData[ driverNameKey ]["week"]["delivery_route_types_breakdown"][ loopDeliveryRow[ deliveryRouteTypeProp ] ] = loopDeliveryRow[ deliveryRouteTypeProp ]
										combinedData[ driverNameKey ]["day_" + dateKey ]["delivery_route_types_breakdown"][ loopDeliveryRow[ deliveryRouteTypeProp ] ] = loopDeliveryRow[ deliveryRouteTypeProp ]
										// Route Pricing
										if(
											deliveryDurationProp != null &&
											loopDeliveryRow[ deliveryDurationProp ] != null &&
											loopDeliveryRow[ deliveryDurationProp ] !== ""
										){
											if(
												deliveryStationProp != null &&
												loopDeliveryRow[ deliveryStationProp ] != null &&
												loopDeliveryRow[ deliveryStationProp ] !== ""
											){
												let routeKey = replaceSpecialCharactersWithUnderscores( loopDeliveryRow[ deliveryStationProp ] + "_" + loopDeliveryRow[ deliveryDurationProp ] + "_" + loopDeliveryRow[ deliveryRouteTypeProp ] )
												if(
													us_routePricingMappingData != null &&
													us_routePricingMappingData[ routeKey ] != null &&
													!isNaN( us_routePricingMappingData[ routeKey ] )
												){
													combinedData[ driverNameKey ]["week"]["delivery_route_revenue_total"] += us_routePricingMappingData[ routeKey ]
													combinedData[ driverNameKey ]["day_" + dateKey ]["delivery_route_revenue_total"] += us_routePricingMappingData[ routeKey ]
												} else {
													combinedData[ driverNameKey ]["week"]["delivery_routes_missing_financial_mapping"]++
													combinedData[ driverNameKey ]["day_" + dateKey ]["delivery_routes_missing_financial_mapping"]++
												}
											} else {
												// Error Handled Elsewere
											}
										} else {
											// Error Handled Elsewere
										}
									} else {
										combinedData[ driverNameKey ]["week"]["delivery_routes_missing_financial_mapping"]++
										combinedData[ driverNameKey ]["day_" + dateKey ]["delivery_routes_missing_financial_mapping"]++
										potentiallyAddErrorToErrorData( "delivery_MissingRouteTypes", loopDeliveryRow )
									}
								}

								if(
									deliveryPackagesDeliveredProp != null &&
									loopDeliveryRow[ deliveryPackagesDeliveredProp ] != null &&
									loopDeliveryRow[ deliveryPackagesDeliveredProp ] !== "" &&
									!isNaN( parseInt( loopDeliveryRow[ deliveryPackagesDeliveredProp ] ) )
								){
									combinedData[ driverNameKey ]["week"]["delivery_shipments_delivered_total"] += parseInt( loopDeliveryRow[ deliveryPackagesDeliveredProp ] )
									combinedData[ driverNameKey ]["day_" + dateKey ]["delivery_shipments_delivered_total"] += parseInt( loopDeliveryRow[ deliveryPackagesDeliveredProp ] )
								} else {
									potentiallyAddErrorToErrorData( "delivery_MissingShipmentsDelivered", loopDeliveryRow )
								}

								if(
									deliveryPackagesReturnedProp != null &&
									loopDeliveryRow[ deliveryPackagesReturnedProp ] != null &&
									loopDeliveryRow[ deliveryPackagesReturnedProp ] !== "" &&
									!isNaN( parseInt( loopDeliveryRow[ deliveryPackagesReturnedProp ] ) )
								){
									combinedData[ driverNameKey ]["week"]["delivery_shipments_returned_total"] += parseInt( loopDeliveryRow[ deliveryPackagesReturnedProp ] )
									combinedData[ driverNameKey ]["day_" + dateKey ]["delivery_shipments_returned_total"] += parseInt( loopDeliveryRow[ deliveryPackagesReturnedProp ] )
								} else {
									potentiallyAddErrorToErrorData( "delivery_MissingShipmentsReturned", loopDeliveryRow )
								}

								if(
									deliveryDistancePlannedProp != null &&
									loopDeliveryRow[ deliveryDistancePlannedProp ] != null &&
									loopDeliveryRow[ deliveryDistancePlannedProp ] !== "" &&
									!isNaN( parseInt( loopDeliveryRow[ deliveryDistancePlannedProp ] ) )
								){
									combinedData[ driverNameKey ]["week"]["delivery_distance_planned_total"] += parseInt( loopDeliveryRow[ deliveryDistancePlannedProp ] )
									combinedData[ driverNameKey ]["day_" + dateKey ]["delivery_distance_planned_total"] += parseInt( loopDeliveryRow[ deliveryDistancePlannedProp ] )
								} else {
									if(
										deliveryDistanceUnitsProp != null &&
										loopDeliveryRow[ deliveryDistanceUnitsProp ] != null &&
										loopDeliveryRow[ deliveryDistanceUnitsProp ] !== ""
										// Don't show error if distance units are not included for this field
									){
										potentiallyAddErrorToErrorData( "delivery_MissingPlannedDistance", loopDeliveryRow )
									}
								}

								if(
									deliveryDistanceAllowedProp != null &&
									loopDeliveryRow[ deliveryDistanceAllowedProp ] != null &&
									loopDeliveryRow[ deliveryDistanceAllowedProp ] !== "" &&
									!isNaN( parseInt( loopDeliveryRow[ deliveryDistanceAllowedProp ] ) )
								){
									combinedData[ driverNameKey ]["week"]["delivery_distance_allowance_total"] += parseInt( loopDeliveryRow[ deliveryDistanceAllowedProp ] )
									combinedData[ driverNameKey ]["day_" + dateKey ]["delivery_distance_allowance_total"] += parseInt( loopDeliveryRow[ deliveryDistanceAllowedProp ] )
								} else {
									if(
										deliveryDistanceUnitsProp != null &&
										loopDeliveryRow[ deliveryDistanceUnitsProp ] != null &&
										loopDeliveryRow[ deliveryDistanceUnitsProp ] !== ""
										// Don't show error if distance units are not included for this field
									){
										potentiallyAddErrorToErrorData( "delivery_MissingAllowedDistance", loopDeliveryRow )
									}
								}
							} else if( deliveryDateProp != null ) {
								potentiallyAddErrorToErrorData( "delivery_InvalidDates", loopDeliveryRow )
							} else {
								potentiallyAddErrorToErrorData( "delivery_MissingDates", loopDeliveryRow )
							}
						} else {
							potentiallyAddErrorToErrorData( "delivery_MissingDrivers", loopDeliveryRow )
						}
					} else {
						potentiallyAddErrorToErrorData( "delivery_MissingDrivers", loopDeliveryRow )
					}
				}

				/////////////////////////////////
				// Loop through Timecard Data
				/////////////////////////////////

				for( let loopTimecardRowIndex in us_timecardFileData ){
					let loopTimecardRow = us_timecardFileData[ loopTimecardRowIndex ]

					/////////////////////////////////
					// Determine Headers
					/////////////////////////////////

					for( let loopPropKey in loopTimecardRow ){
						if( loopPropKey !== "key" ){
							timecardFileHeaders[ loopPropKey ] = loopPropKey
						}
					}

					/////////////////////////////////
					// Main Timecard Data Analysis
					/////////////////////////////////

					if(
						(
							timecardEmployeeNameProp != null &&
							nameMappingType === "full_name_in_a_single_column" &&
							loopTimecardRow[ timecardEmployeeNameProp ] != null &&
							loopTimecardRow[ timecardEmployeeNameProp ] !== ""
						) ||
						(
							timecardEmployeeFirstNameProp != null &&
							timecardEmployeeLastNameProp != null &&
							nameMappingType === "first_and_last_name_in_different_columns" &&
							loopTimecardRow[ timecardEmployeeFirstNameProp ] != null &&
							loopTimecardRow[ timecardEmployeeFirstNameProp ] !== "" &&
							loopTimecardRow[ timecardEmployeeLastNameProp ] != null &&
							loopTimecardRow[ timecardEmployeeLastNameProp ] !== ""
						)
					){
						// Determine If Hours should be included in the analysis
						let includeHoursInAnalysis = true
						if(
							timecardPayCodeProp != null &&
							loopTimecardRow[ timecardPayCodeProp ] != null &&
							loopTimecardRow[ timecardPayCodeProp ] !== ""
						){
							let payCodeKey = loopTimecardRow[ timecardPayCodeProp ]
							if(
								us_paycodeMultiplierMappingData != null &&
								us_paycodeMultiplierMappingData[ "EXCLUDE_FROM_ANALYSIS_" + payCodeKey ] === false
							){
								includeHoursInAnalysis = false
							}
						} else {
							potentiallyAddErrorToErrorData( "timecard_MissingPayCodes", loopTimecardRow )
						}

						// Get Driver Key that is hopefully in the user mapping
						let driverNameKey = ""
						if(
							nameMappingType === "full_name_in_a_single_column" &&
							timecardEmployeeNameProp != null
						){
							driverNameKey = loopTimecardRow[ timecardEmployeeNameProp ]
							timecardEmployees[ driverNameKey ] = driverNameKey
							if(
								us_userMappingData != null &&
								loopTimecardRow != null &&
								loopTimecardRow[ timecardEmployeeNameProp ] != null &&
								us_userMappingData[ loopTimecardRow[ timecardEmployeeNameProp ] ] != null
							){
								driverNameKey = us_userMappingData[ loopTimecardRow[ timecardEmployeeNameProp ] ]
							}
						} else if(
							nameMappingType === "first_and_last_name_in_different_columns" &&
							timecardEmployeeFirstNameProp != null &&
							timecardEmployeeLastNameProp != null
						){
							driverNameKey = loopTimecardRow[ timecardEmployeeFirstNameProp ] + " " + loopTimecardRow[ timecardEmployeeLastNameProp ]
							timecardEmployees[ driverNameKey ] = driverNameKey
							if(
								us_userMappingData != null &&
								loopTimecardRow != null &&
								loopTimecardRow[ timecardEmployeeFirstNameProp ] != null &&
								loopTimecardRow[ timecardEmployeeLastNameProp ] != null &&
								us_userMappingData[ loopTimecardRow[ timecardEmployeeFirstNameProp ] + " " + loopTimecardRow[ timecardEmployeeLastNameProp ] ] != null
							){
								driverNameKey = us_userMappingData[ loopTimecardRow[ timecardEmployeeFirstNameProp ] + " " + loopTimecardRow[ timecardEmployeeLastNameProp ] ]
							}

						}
						// Need a date to proceed
						if(
							timecardDateProp != null &&
							loopTimecardRow[ timecardDateProp ] != null &&
							isValidDate( loopTimecardRow[ timecardDateProp ] ) === true
						){
							// Set Dates
							if(
								timecardFirstDate == null ||
								new Date( loopTimecardRow[ timecardDateProp ] ) < new Date( timecardFirstDate )
							){
								timecardFirstDate = loopTimecardRow[ timecardDateProp ]
							}
							if(
								timecardLastDate == null ||
								new Date( loopTimecardRow[ timecardDateProp ] ) > new Date( timecardLastDate )
							){
								timecardLastDate = loopTimecardRow[ timecardDateProp ]
							}
							// timecardEmployees[ driverNameKey ] = driverNameKey
							// Compound Data
							if( combinedData[ driverNameKey ] == null ){
								// Create User
								combinedData[ driverNameKey ] = {
									// days: {},
									name: driverNameKey,
									week: {
										delivery_clocked_hours_total: 0,
										delivery_distance_allowance_total: 0,
										delivery_distance_planned_total: 0,
										delivery_rescued_count: 0,
										delivery_rescuer_count: 0,
										delivery_route_hours_total: 0,
										delivery_route_names_breakdown: {},
										delivery_route_revenue_total: 0,
										delivery_route_types_breakdown: {},
										delivery_routes_count: 0,
										delivery_routes_missing_financial_mapping: 0,
										delivery_shipments_delivered_total: 0,
										delivery_shipments_returned_total: 0,
										delivery_station_names_breakdown: {},
										has_delivery_data: false,
										has_payroll_data: true,
										name: driverNameKey,
										payroll_clocked_hours_all_by_paycode_breakdown: {},
										payroll_clocked_hours_all_by_role_breakdown: {},
										payroll_clocked_hours_all_total: 0,
										payroll_clocked_hours_excluded_by_paycode_breakdown: {},
										payroll_clocked_hours_excluded_by_role_breakdown: {},
										payroll_clocked_hours_excluded_total: 0,
										payroll_clocked_hours_included_by_paycode_breakdown: {},
										payroll_clocked_hours_included_by_role_breakdown: {},
										payroll_clocked_hours_included_total: 0,
										payroll_pay_by_paycode_breakdown: {},
										payroll_pay_by_role_breakdown: {},
										payroll_pay_errors_total: 0,
										payroll_pay_total: 0,
										payroll_paycode_breakdown: {},
										payroll_payrate_by_paycode_breakdown: {},
										payroll_role_breakdown: {},
									},
								}
							} else {
								combinedData[ driverNameKey ]["week"]["has_payroll_data"] = true
							}

							// Compile Timecard Data
							let reportDateString = loopTimecardRow[ timecardDateProp ]
							let reportDateObject = returnDateFromUnknownDateFormat( reportDateString )
							let correctedDateObject = new Date( reportDateObject.setHours( reportDateObject.getHours() + 12 ) )
							let dateKey = returnFormattedDateKey( correctedDateObject )
							// Create Day Record if it doesn't exist
							dateDropdownOptions["day_" + dateKey ] = {
								key: "day_" + dateKey,
								value: dateKey + " (" + returnFormattedDate( new Date(dateKey).getTime() + 86400000 / 2 , "ddd") + ")",
								sort: new Date(dateKey).getTime()
							}
							if( combinedData[ driverNameKey ]["day_" + dateKey ] == null ){
								combinedData[ driverNameKey ]["day_" + dateKey ] = {
									delivery_clocked_hours_total: 0,
									delivery_distance_allowance_total: 0,
									delivery_distance_planned_total: 0,
									delivery_rescued_count: 0,
									delivery_rescuer_count: 0,
									delivery_route_hours_total: 0,
									delivery_route_names_breakdown: {},
									delivery_route_revenue_total: 0,
									delivery_route_types_breakdown: {},
									delivery_routes_count: 0,
									delivery_routes_missing_financial_mapping: 0,
									delivery_shipments_delivered_total: 0,
									delivery_shipments_returned_total: 0,
									delivery_station_names_breakdown: {},
									has_delivery_data: false,
									has_payroll_data: true,
									name: driverNameKey,
									payroll_clocked_hours_all_by_paycode_breakdown: {},
									payroll_clocked_hours_all_by_role_breakdown: {},
									payroll_clocked_hours_all_total: 0,
									payroll_clocked_hours_excluded_by_paycode_breakdown: {},
									payroll_clocked_hours_excluded_by_role_breakdown: {},
									payroll_clocked_hours_excluded_total: 0,
									payroll_clocked_hours_included_by_paycode_breakdown: {},
									payroll_clocked_hours_included_by_role_breakdown: {},
									payroll_clocked_hours_included_total: 0,
									payroll_pay_by_paycode_breakdown: {},
									payroll_pay_by_role_breakdown: {},
									payroll_pay_errors_total: 0,
									payroll_pay_total: 0,
									payroll_paycode_breakdown: {},
									payroll_payrate_by_paycode_breakdown: {},
									payroll_role_breakdown: {},
								}
							} else {
								combinedData[ driverNameKey ]["day_" + dateKey ]["has_payroll_data"] = true
							}
							// Compile Timecard Data
							if(
								timecardPayCodeProp != null &&
								loopTimecardRow[ timecardPayCodeProp ] != null &&
								loopTimecardRow[ timecardPayCodeProp ] !== ""
							){
								// Global Grouping
								timecardPayCodes[ loopTimecardRow[ timecardPayCodeProp ] ] = loopTimecardRow[ timecardPayCodeProp ]
								combinedData[ driverNameKey ]["week"]["payroll_paycode_breakdown"][ loopTimecardRow[ timecardPayCodeProp ] ] = loopTimecardRow[ timecardPayCodeProp ]
								combinedData[ driverNameKey ]["day_" + dateKey ]["payroll_paycode_breakdown"][ loopTimecardRow[ timecardPayCodeProp ] ] = loopTimecardRow[ timecardPayCodeProp ]
							} else {
								potentiallyAddErrorToErrorData( "timecard_MissingPayCodes", loopTimecardRow )
							}

							if(
								timecardEmployeeRoleProp != null &&
								loopTimecardRow[ timecardEmployeeRoleProp ] != null &&
								loopTimecardRow[ timecardEmployeeRoleProp ] !== ""
							){
								combinedData[ driverNameKey ]["week"]["payroll_role_breakdown"][ loopTimecardRow[ timecardEmployeeRoleProp ] ] = loopTimecardRow[ timecardEmployeeRoleProp ]
								combinedData[ driverNameKey ]["day_" + dateKey ]["payroll_role_breakdown"][ loopTimecardRow[ timecardEmployeeRoleProp ] ] = loopTimecardRow[ timecardEmployeeRoleProp ]
							} else {
								potentiallyAddErrorToErrorData( "timecard_MissingEmployeeRole", loopTimecardRow )
							}

							if(
								timecardHoursProp != null &&
								loopTimecardRow[ timecardHoursProp ] != null &&
								loopTimecardRow[ timecardHoursProp ] !== "" &&
								!isNaN( parseFloat( loopTimecardRow[ timecardHoursProp ] ) )
							){
								combinedData[ driverNameKey ]["week"]["payroll_clocked_hours_all_total"] += parseFloat( loopTimecardRow[ timecardHoursProp ] )
								combinedData[ driverNameKey ]["day_" + dateKey ]["payroll_clocked_hours_all_total"] += parseFloat( loopTimecardRow[ timecardHoursProp ] )
								if( includeHoursInAnalysis === true ){
									combinedData[ driverNameKey ]["week"]["payroll_clocked_hours_included_total"] += parseFloat( loopTimecardRow[ timecardHoursProp ] )
									combinedData[ driverNameKey ]["day_" + dateKey ]["payroll_clocked_hours_included_total"] += parseFloat( loopTimecardRow[ timecardHoursProp ] )
								} else {
									combinedData[ driverNameKey ]["week"]["payroll_clocked_hours_excluded_total"] += parseFloat( loopTimecardRow[ timecardHoursProp ] )
									combinedData[ driverNameKey ]["day_" + dateKey ]["payroll_clocked_hours_excluded_total"] += parseFloat( loopTimecardRow[ timecardHoursProp ] )
								}
								// Breakdown by Pay Code
								if(
									timecardPayCodeProp != null &&
									loopTimecardRow[ timecardPayCodeProp ] != null &&
									loopTimecardRow[ timecardPayCodeProp ] !== ""
								){
									let payCodeKey = loopTimecardRow[ timecardPayCodeProp ]
									if( combinedData[ driverNameKey ]["week"]["payroll_clocked_hours_all_by_paycode_breakdown"][ payCodeKey ] == null ){
										combinedData[ driverNameKey ]["week"]["payroll_clocked_hours_all_by_paycode_breakdown"][ payCodeKey ] = 0
									}
									combinedData[ driverNameKey ]["week"]["payroll_clocked_hours_all_by_paycode_breakdown"][ payCodeKey ] += parseFloat( loopTimecardRow[ timecardHoursProp ] )
									if( combinedData[ driverNameKey ]["day_" + dateKey ]["payroll_clocked_hours_all_by_paycode_breakdown"][ payCodeKey ] == null ){
										combinedData[ driverNameKey ]["day_" + dateKey ]["payroll_clocked_hours_all_by_paycode_breakdown"][ payCodeKey ] = 0
									}
									combinedData[ driverNameKey ]["day_" + dateKey ]["payroll_clocked_hours_all_by_paycode_breakdown"][ payCodeKey ] += parseFloat( loopTimecardRow[ timecardHoursProp ] )
									if( includeHoursInAnalysis === true ){
										if( combinedData[ driverNameKey ]["week"]["payroll_clocked_hours_included_by_paycode_breakdown"][ payCodeKey ] == null ){
											combinedData[ driverNameKey ]["week"]["payroll_clocked_hours_included_by_paycode_breakdown"][ payCodeKey ] = 0
										}
										combinedData[ driverNameKey ]["week"]["payroll_clocked_hours_included_by_paycode_breakdown"][ payCodeKey ] += parseFloat( loopTimecardRow[ timecardHoursProp ] )
										if( combinedData[ driverNameKey ]["day_" + dateKey ]["payroll_clocked_hours_included_by_paycode_breakdown"][ payCodeKey ] == null ){
											combinedData[ driverNameKey ]["day_" + dateKey ]["payroll_clocked_hours_included_by_paycode_breakdown"][ payCodeKey ] = 0
										}
										combinedData[ driverNameKey ]["day_" + dateKey ]["payroll_clocked_hours_included_by_paycode_breakdown"][ payCodeKey ] += parseFloat( loopTimecardRow[ timecardHoursProp ] )
									} else {
										if( combinedData[ driverNameKey ]["week"]["payroll_clocked_hours_excluded_by_paycode_breakdown"][ payCodeKey ] == null ){
											combinedData[ driverNameKey ]["week"]["payroll_clocked_hours_excluded_by_paycode_breakdown"][ payCodeKey ] = 0
										}
										combinedData[ driverNameKey ]["week"]["payroll_clocked_hours_excluded_by_paycode_breakdown"][ payCodeKey ] += parseFloat( loopTimecardRow[ timecardHoursProp ] )
										if( combinedData[ driverNameKey ]["day_" + dateKey ]["payroll_clocked_hours_excluded_by_paycode_breakdown"][ payCodeKey ] == null ){
											combinedData[ driverNameKey ]["day_" + dateKey ]["payroll_clocked_hours_excluded_by_paycode_breakdown"][ payCodeKey ] = 0
										}
										combinedData[ driverNameKey ]["day_" + dateKey ]["payroll_clocked_hours_excluded_by_paycode_breakdown"][ payCodeKey ] += parseFloat( loopTimecardRow[ timecardHoursProp ] )
									}
								} else {
									potentiallyAddErrorToErrorData( "timecard_MissingPayCodes", loopTimecardRow )
								}
								// Breakdown by Role
								if(
									timecardEmployeeRoleProp != null &&
									loopTimecardRow[ timecardEmployeeRoleProp ] != null &&
									loopTimecardRow[ timecardEmployeeRoleProp ] !== ""
								){
									let userRoleKey = loopTimecardRow[ timecardEmployeeRoleProp ]
									if( combinedData[ driverNameKey ]["week"]["payroll_clocked_hours_all_by_role_breakdown"][ userRoleKey ] == null ){
										combinedData[ driverNameKey ]["week"]["payroll_clocked_hours_all_by_role_breakdown"][ userRoleKey ] = 0
									}
									combinedData[ driverNameKey ]["week"]["payroll_clocked_hours_all_by_role_breakdown"][ userRoleKey ] += parseFloat( loopTimecardRow[ timecardHoursProp ] )
									if( combinedData[ driverNameKey ]["day_" + dateKey ]["payroll_clocked_hours_all_by_role_breakdown"][ userRoleKey ] == null ){
										combinedData[ driverNameKey ]["day_" + dateKey ]["payroll_clocked_hours_all_by_role_breakdown"][ userRoleKey ] = 0
									}
									combinedData[ driverNameKey ]["day_" + dateKey ]["payroll_clocked_hours_all_by_role_breakdown"][ userRoleKey ] += parseFloat( loopTimecardRow[ timecardHoursProp ] )
									if( includeHoursInAnalysis === true ){
										if( combinedData[ driverNameKey ]["week"]["payroll_clocked_hours_included_by_role_breakdown"][ userRoleKey ] == null ){
											combinedData[ driverNameKey ]["week"]["payroll_clocked_hours_included_by_role_breakdown"][ userRoleKey ] = 0
										}
										combinedData[ driverNameKey ]["week"]["payroll_clocked_hours_included_by_role_breakdown"][ userRoleKey ] += parseFloat( loopTimecardRow[ timecardHoursProp ] )
										if( combinedData[ driverNameKey ]["day_" + dateKey ]["payroll_clocked_hours_included_by_role_breakdown"][ userRoleKey ] == null ){
											combinedData[ driverNameKey ]["day_" + dateKey ]["payroll_clocked_hours_included_by_role_breakdown"][ userRoleKey ] = 0
										}
										combinedData[ driverNameKey ]["day_" + dateKey ]["payroll_clocked_hours_included_by_role_breakdown"][ userRoleKey ] += parseFloat( loopTimecardRow[ timecardHoursProp ] )
									} else {
										if( combinedData[ driverNameKey ]["week"]["payroll_clocked_hours_excluded_by_role_breakdown"][ userRoleKey ] == null ){
											combinedData[ driverNameKey ]["week"]["payroll_clocked_hours_excluded_by_role_breakdown"][ userRoleKey ] = 0
										}
										combinedData[ driverNameKey ]["week"]["payroll_clocked_hours_excluded_by_role_breakdown"][ userRoleKey ] += parseFloat( loopTimecardRow[ timecardHoursProp ] )
										if( combinedData[ driverNameKey ]["day_" + dateKey ]["payroll_clocked_hours_excluded_by_role_breakdown"][ userRoleKey ] == null ){
											combinedData[ driverNameKey ]["day_" + dateKey ]["payroll_clocked_hours_excluded_by_role_breakdown"][ userRoleKey ] = 0
										}
										combinedData[ driverNameKey ]["day_" + dateKey ]["payroll_clocked_hours_excluded_by_role_breakdown"][ userRoleKey ] += parseFloat( loopTimecardRow[ timecardHoursProp ] )
									}
								} else {
									potentiallyAddErrorToErrorData( "timecard_MissingEmployeeRole", loopTimecardRow )
								}
							}  else {
								potentiallyAddErrorToErrorData( "timecard_MissingTimecardHours", loopTimecardRow )
							}

							if(
								timecardPayRateProp != null &&
								loopTimecardRow[ timecardPayRateProp ] != null &&
								loopTimecardRow[ timecardPayRateProp ] !== "" &&
								!isNaN( parseFloat( loopTimecardRow[ timecardPayRateProp ].replace("$", "") ) )
							){
								let payRate = parseFloat( loopTimecardRow[ timecardPayRateProp ].replace("$", "") )
								// Handle Pay Rate Multiplier
								let payCodeMultiplier = 1
								if(
									timecardPayCodeProp != null &&
									loopTimecardRow[ timecardPayCodeProp ] != null &&
									loopTimecardRow[ timecardPayCodeProp ] !== ""
								){
									let payCodeKey = loopTimecardRow[ timecardPayCodeProp ]
									combinedData[ driverNameKey ]["week"]["payroll_payrate_by_paycode_breakdown"][ payCodeKey ] = payRate
									combinedData[ driverNameKey ]["day_" + dateKey ]["payroll_payrate_by_paycode_breakdown"][ payCodeKey ] = payRate
									if(
										us_paycodeMultiplierMappingData != null &&
										us_paycodeMultiplierMappingData[ loopTimecardRow[ timecardPayCodeProp ] ] != null
									){
										payCodeMultiplier = us_paycodeMultiplierMappingData[ loopTimecardRow[ timecardPayCodeProp ] ]
									}
								} else {
									potentiallyAddErrorToErrorData( "timecard_MissingPayCodes", loopTimecardRow )
								}
								if(
									timecardHoursProp != null &&
									loopTimecardRow[ timecardHoursProp ] != null &&
									loopTimecardRow[ timecardHoursProp ] !== "" &&
									!isNaN( parseFloat( loopTimecardRow[ timecardHoursProp ] ) )
								){
									let hoursWorked = parseFloat( loopTimecardRow[ timecardHoursProp ] )


									let totalPay = payRate * hoursWorked * payCodeMultiplier


									combinedData[ driverNameKey ]["week"]["payroll_pay_total"] += totalPay
									combinedData[ driverNameKey ]["day_" + dateKey ]["payroll_pay_total"] += totalPay
									// Breakdown by Pay Code
									if(
										timecardPayCodeProp != null &&
										loopTimecardRow[ timecardPayCodeProp ] != null &&
										loopTimecardRow[ timecardPayCodeProp ] !== ""
									){
										let payCodeKey = loopTimecardRow[ timecardPayCodeProp ]
										if( combinedData[ driverNameKey ]["week"]["payroll_pay_by_paycode_breakdown"][ payCodeKey ] == null ){
											combinedData[ driverNameKey ]["week"]["payroll_pay_by_paycode_breakdown"][ payCodeKey ] = 0
										}
										combinedData[ driverNameKey ]["week"]["payroll_pay_by_paycode_breakdown"][ payCodeKey ] += totalPay
										if( combinedData[ driverNameKey ]["day_" + dateKey ]["payroll_pay_by_paycode_breakdown"][ payCodeKey ] == null ){
											combinedData[ driverNameKey ]["day_" + dateKey ]["payroll_pay_by_paycode_breakdown"][ payCodeKey ] = 0
										}
										combinedData[ driverNameKey ]["day_" + dateKey ]["payroll_pay_by_paycode_breakdown"][ payCodeKey ] += totalPay
									} else {
										potentiallyAddErrorToErrorData( "timecard_MissingPayCodes", loopTimecardRow )
									}
									// Breakdown by Role
									if(
										timecardEmployeeRoleProp != null &&
										loopTimecardRow[ timecardEmployeeRoleProp ] != null &&
										loopTimecardRow[ timecardEmployeeRoleProp ] !== ""
									){
										let userRoleKey = loopTimecardRow[ timecardEmployeeRoleProp ]
										if( combinedData[ driverNameKey ]["week"]["payroll_pay_by_role_breakdown"][ userRoleKey ] == null ){
											combinedData[ driverNameKey ]["week"]["payroll_pay_by_role_breakdown"][ userRoleKey ] = 0
										}
										combinedData[ driverNameKey ]["week"]["payroll_pay_by_role_breakdown"][ userRoleKey ] += totalPay
										if( combinedData[ driverNameKey ]["day_" + dateKey ]["payroll_pay_by_role_breakdown"][ userRoleKey ] == null ){
											combinedData[ driverNameKey ]["day_" + dateKey ]["payroll_pay_by_role_breakdown"][ userRoleKey ] = 0
										}
										combinedData[ driverNameKey ]["day_" + dateKey ]["payroll_pay_by_role_breakdown"][ userRoleKey ] += totalPay
									} else {
										potentiallyAddErrorToErrorData( "timecard_MissingEmployeeRole", loopTimecardRow )
									}
								} else {
									potentiallyAddErrorToErrorData( "timecard_MissingTimecardHours", loopTimecardRow )
									combinedData[ driverNameKey ]["week"]["payroll_pay_errors_total"] += 1
									combinedData[ driverNameKey ]["day_" + dateKey ]["payroll_pay_errors_total"] += 1
								}
							} else {
								combinedData[ driverNameKey ]["week"]["payroll_pay_errors_total"] += 1
								combinedData[ driverNameKey ]["day_" + dateKey ]["payroll_pay_errors_total"] += 1
								potentiallyAddErrorToErrorData( "timecard_MissingPayRate", loopTimecardRow )
							}
						} else if( timecardDateProp != null ) {
							potentiallyAddErrorToErrorData( "timecard_InvalidDates", loopTimecardRow )
						} else {
							potentiallyAddErrorToErrorData( "timecard_MissingDates", loopTimecardRow )
						}
					} else {
						potentiallyAddErrorToErrorData( "timecard_MissingEmployees", loopTimecardRow )
					}
				}

				/////////////////////////////////
				// Corrected Dates
				/////////////////////////////////

				let correctedDeliveryFirstDate: TsType_Null | TsType_String = null
				let correctedDeliveryLastDate: TsType_Null | TsType_String = null
				let correctedTimecardFirstDate: TsType_Null | TsType_String = null
				let correctedTimecardLastDate: TsType_Null | TsType_String = null
				if( deliveryFirstDate != null ){
					correctedDeliveryFirstDate = returnFormattedDateKey( new Date( returnDateFromUnknownDateFormat( deliveryFirstDate ).setHours( returnDateFromUnknownDateFormat( deliveryFirstDate ).getHours() + 12 ) ) )
				}
				if( deliveryLastDate != null ){
					correctedDeliveryLastDate = returnFormattedDateKey( new Date( returnDateFromUnknownDateFormat( deliveryLastDate ).setHours( returnDateFromUnknownDateFormat( deliveryLastDate ).getHours() + 12 ) ) )
				}
				if( timecardFirstDate != null ){
					correctedTimecardFirstDate = returnFormattedDateKey( new Date( returnDateFromUnknownDateFormat( timecardFirstDate ).setHours( returnDateFromUnknownDateFormat( timecardFirstDate ).getHours() + 12 ) ) )
				}
				if( timecardLastDate != null ){
					correctedTimecardLastDate = returnFormattedDateKey( new Date( returnDateFromUnknownDateFormat( timecardLastDate ).setHours( returnDateFromUnknownDateFormat( timecardLastDate ).getHours() + 12 ) ) )
				}

				/////////////////////////////////
				// Set data to State
				/////////////////////////////////

				us_setDateDropdownOptions( dateDropdownOptions )
				us_setDataFileDates({
					deliveryFirstDate: correctedDeliveryFirstDate,
					deliveryLastDate: correctedDeliveryLastDate,
					timecardFirstDate: correctedTimecardFirstDate,
					timecardLastDate: correctedTimecardLastDate,
				})
				us_setDeliveryDrivers( deliveryDrivers )
				us_setTimecardEmployees( timecardEmployees )
				us_setPayCodesList( timecardPayCodes )
				us_setDeliveryFileHeaders( deliveryFileHeaders )
				us_setTimecardFileHeaders( timecardFileHeaders )
				us_setAnalysisCalculatedData( combinedData )
				us_setDeliveryRouteTypes( deliveryRoutes )
				us_setAnalysisErrorData( errorData )
				us_setAnalysisErrorDataNames( errorDataNames )

			}
			return () => {}
		}, [us_timecardFileData, us_deliveryFileData, us_deliveryReportMappingData, us_timecardReportMappingData, us_routePricingMappingData, us_paycodeMultiplierMappingData, us_userMappingData, us_rootAnalysis, us_lockedAnalysisDataResults])

		// Summary
		useEffect(() => {

			/////////////////////////////////
			// Post Data processing
			/////////////////////////////////

			// Instatiate Variables
			let filteredTableData: TsInterface_UnspecifiedObject = {}
			let summaryDataFiltered: TsInterface_UnspecifiedObject = {
				// Numbers
				total_routes: 0,
				total_employees_with_routes: 0,
				total_employess_with_payroll: 0,
				total_expected_route_hours: 0,
				total_actual_route_hours: 0,
				total_work_minus_expected_route_hours: 0,
				total_all_hours: 0,
				total_excluded_hours: 0,
				total_included_work_hours: 0,
				total_employess_with_work_hours_and_no_routes: 0,
				total_employess_with_work_hours: 0,
				total_employess_with_excluded_hours: 0,
				// Bar Chart
				green_hour_employees: 0,
				yellow_hour_employees: 0,
				red_hour_employees: 0,
				dark_red_hour_employees: 0,
				// Financials
				total_payroll_dollars: 0,
				total_payroll_dollars_missing_mapping: 0,
				total_route_dollars: 0,
				total_route_dollars_missing_mapping: 0,
				total_route_package_base_dollars: 0,
				total_route_package_base_dollars_missing_mapping: 0,
				total_route_package_bonus_dollars: 0,
				total_route_package_bonus_dollars_missing_mapping: 0,
				// Scatter Plot
				scatterplot_data: {
					datasets: []
				},
			}
			let summaryDataToSaveOnLock: TsInterface_UnspecifiedObject = {
				// Numbers
				total_routes: 0,
				total_employees_with_routes: 0,
				total_employess_with_payroll: 0,
				total_expected_route_hours: 0,
				total_actual_route_hours: 0,
				total_work_minus_expected_route_hours: 0,
				total_all_hours: 0,
				total_excluded_hours: 0,
				total_included_work_hours: 0,
				total_employess_with_work_hours_and_no_routes: 0,
				total_employess_with_work_hours: 0,
				total_employess_with_excluded_hours: 0,
				// Bar Chart
				green_hour_employees: 0,
				yellow_hour_employees: 0,
				red_hour_employees: 0,
				dark_red_hour_employees: 0,
				// Financials
				total_payroll_dollars: 0,
				total_payroll_dollars_missing_mapping: 0,
				total_route_dollars: 0,
				total_route_dollars_missing_mapping: 0,
				total_route_package_base_dollars: 0,
				total_route_package_base_dollars_missing_mapping: 0,
				total_route_package_bonus_dollars: 0,
				total_route_package_bonus_dollars_missing_mapping: 0,
			}
			let scatterDarkRedDataset: TsInterface_UnspecifiedObject = {
				label: "Payroll without Route Hours",
				data: [],
				backgroundColor: darkRedColor
			}
			let scatterRedDataset: TsInterface_UnspecifiedObject = {
				label: "Payroll > Route Hours",
				data: [],
				backgroundColor: themeVariables.error_main
			}
			let scatterYellowDataset: TsInterface_UnspecifiedObject = {
				label: "Payroll ~ Route Hours",
				data: [],
				backgroundColor: themeVariables.warning_main
			}
			let scatterGreenDataset: TsInterface_UnspecifiedObject = {
				label: "Payroll < Route Hours",
				data: [],
				backgroundColor: themeVariables.success_main
			}
			let userHourColors: TsInterface_UnspecifiedObject = {}
			let filteredRouteRevenueBreakdown: TsInterface_UnspecifiedObject = {}
			let filteredRouteStationBreakdown: TsInterface_UnspecifiedObject = {}

			/////////////////////////////////
			// Filtered Data
			/////////////////////////////////
			for( let loopRouteKey in us_deliveryRouteTypes ){
				let loopRoute = us_deliveryRouteTypes[ loopRouteKey ]
				if(
					loopRoute != null &&
					loopRoute[ us_selectedDateFilter ] != null
				){
					filteredRouteRevenueBreakdown[ loopRouteKey ] = loopRoute[ us_selectedDateFilter ]
				}
				// Route Revenue
				if(
					loopRoute != null &&
					loopRoute["station"] != null &&
					filteredRouteStationBreakdown[ loopRoute["station"] ] == null
				){
					filteredRouteStationBreakdown[ loopRoute["station"] ] = {
						station: loopRoute["station"],
						duration: "all",
						route_type: "all",
						route_revenue_per_run: "all",
						route_run_count: 0,
						route_total_revenue: 0,
						route_packages_delivered_count: 0,
						package_total_base_rate_pay: 0,
						package_total_bonus_rate_pay: 0,
						revenue_grand_total: 0,
					}
				}
				if( filteredRouteRevenueBreakdown[ loopRouteKey ] == null ){
					filteredRouteRevenueBreakdown[ loopRouteKey ] = {}
				}
				if( filteredRouteRevenueBreakdown[ loopRouteKey ]["revenue_grand_total"] == null ){
					filteredRouteRevenueBreakdown[ loopRouteKey ]["revenue_grand_total"] = 0
				}
				if(
					us_rootAnalysis != null &&
					us_rootAnalysis.locked === true
				){
					// For some reason locked analysis is doubling this up so we need to wipe it
					filteredRouteRevenueBreakdown[ loopRouteKey ]["revenue_grand_total"] = 0
				}
				if(
					loopRoute != null &&
					loopRoute[ us_selectedDateFilter ] != null &&
					loopRoute[ us_selectedDateFilter ]["route_run_count"] != null &&
					!isNaN(loopRoute[ us_selectedDateFilter ]["route_run_count"])
				){
					filteredRouteStationBreakdown[ loopRoute["station"] ]["route_run_count"] += loopRoute[ us_selectedDateFilter ]["route_run_count"]
				}
				if(
					loopRoute != null &&
					loopRoute[ us_selectedDateFilter ] != null &&
					loopRoute[ us_selectedDateFilter ]["route_revenue_per_run"] != null &&
					!isNaN(loopRoute[ us_selectedDateFilter ]["route_revenue_per_run"]) &&
					loopRoute[ us_selectedDateFilter ]["route_run_count"] != null &&
					!isNaN(loopRoute[ us_selectedDateFilter ]["route_run_count"])
				){
					filteredRouteRevenueBreakdown[ loopRouteKey ]["route_total_revenue"] = loopRoute[ us_selectedDateFilter ]["route_revenue_per_run"] * loopRoute[ us_selectedDateFilter ]["route_run_count"]
					filteredRouteRevenueBreakdown[ loopRouteKey ]["revenue_grand_total"] += loopRoute[ us_selectedDateFilter ]["route_revenue_per_run"] * loopRoute[ us_selectedDateFilter ]["route_run_count"]
					filteredRouteStationBreakdown[ loopRoute["station"] ]["route_total_revenue"] += loopRoute[ us_selectedDateFilter ]["route_revenue_per_run"] * loopRoute[ us_selectedDateFilter ]["route_run_count"]
					filteredRouteStationBreakdown[ loopRoute["station"] ]["revenue_grand_total"] += loopRoute[ us_selectedDateFilter ]["route_revenue_per_run"] * loopRoute[ us_selectedDateFilter ]["route_run_count"]
				}
				if(
					loopRoute != null &&
					loopRoute[ us_selectedDateFilter ] != null &&
					loopRoute[ us_selectedDateFilter ]["route_run_count"] != null &&
					!isNaN(loopRoute[ us_selectedDateFilter ]["route_run_count"]) &&
					(
						loopRoute[ us_selectedDateFilter ]["route_revenue_per_run"] == null ||
						loopRoute[ us_selectedDateFilter ]["route_revenue_per_run"] === 0
					)
				){
					summaryDataFiltered["total_route_dollars_missing_mapping"] ++
				}
				// Package Rates
				if(
					loopRoute != null &&
					loopRoute[ us_selectedDateFilter ] != null &&
					loopRoute[ us_selectedDateFilter ]["route_packages_delivered_count"] != null &&
					!isNaN(loopRoute[ us_selectedDateFilter ]["route_packages_delivered_count"])
				){
					filteredRouteStationBreakdown[ loopRoute["station"] ]["route_packages_delivered_count"] += loopRoute[ us_selectedDateFilter ]["route_packages_delivered_count"]
				}
				if(
					loopRoute != null &&
					loopRoute[ us_selectedDateFilter ] != null &&
					loopRoute[ us_selectedDateFilter ]["route_packages_delivered_count"] != null &&
					!isNaN(loopRoute[ us_selectedDateFilter ]["route_packages_delivered_count"]) &&
					loopRoute[ us_selectedDateFilter ]["package_base_rate"] != null &&
					!isNaN(loopRoute[ us_selectedDateFilter ]["package_base_rate"])
				){
					// Base Rate
					filteredRouteRevenueBreakdown[ loopRouteKey ]["package_total_base_rate_pay"] = loopRoute[ us_selectedDateFilter ]["route_packages_delivered_count"] * loopRoute[ us_selectedDateFilter ]["package_base_rate"]
					filteredRouteRevenueBreakdown[ loopRouteKey ]["revenue_grand_total"] += loopRoute[ us_selectedDateFilter ]["route_packages_delivered_count"] * loopRoute[ us_selectedDateFilter ]["package_base_rate"]
					summaryDataFiltered["total_route_package_base_dollars"] += loopRoute[ us_selectedDateFilter ]["route_packages_delivered_count"] * loopRoute[ us_selectedDateFilter ]["package_base_rate"]
					filteredRouteStationBreakdown[ loopRoute["station"] ]["package_total_base_rate_pay"] += loopRoute[ us_selectedDateFilter ]["route_packages_delivered_count"] * loopRoute[ us_selectedDateFilter ]["package_base_rate"]
					filteredRouteStationBreakdown[ loopRoute["station"] ]["revenue_grand_total"] += loopRoute[ us_selectedDateFilter ]["route_packages_delivered_count"] * loopRoute[ us_selectedDateFilter ]["package_base_rate"]
				}
				if(
					loopRoute != null &&
					loopRoute[ us_selectedDateFilter ] != null &&
					loopRoute[ us_selectedDateFilter ]["route_packages_delivered_count"] != null &&
					!isNaN(loopRoute[ us_selectedDateFilter ]["route_packages_delivered_count"]) &&
					(
						loopRoute[ us_selectedDateFilter ]["package_bonus_rate"] == null ||
						loopRoute[ us_selectedDateFilter ]["package_bonus_rate"] === 0
					)
				){
					summaryDataFiltered["total_route_package_base_dollars_missing_mapping"]++
				}
				if(
					loopRoute != null &&
					loopRoute[ us_selectedDateFilter ] != null &&
					loopRoute[ us_selectedDateFilter ]["route_packages_delivered_count"] != null &&
					!isNaN(loopRoute[ us_selectedDateFilter ]["route_packages_delivered_count"]) &&
					loopRoute[ us_selectedDateFilter ]["package_bonus_rate"] != null &&
					!isNaN(loopRoute[ us_selectedDateFilter ]["package_bonus_rate"])
				){
					// Bonus Rate
					filteredRouteRevenueBreakdown[ loopRouteKey ]["package_total_bonus_rate_pay"] = loopRoute[ us_selectedDateFilter ]["route_packages_delivered_count"] * loopRoute[ us_selectedDateFilter ]["package_bonus_rate"]
					filteredRouteRevenueBreakdown[ loopRouteKey ]["revenue_grand_total"] += loopRoute[ us_selectedDateFilter ]["route_packages_delivered_count"] * loopRoute[ us_selectedDateFilter ]["package_bonus_rate"]
					summaryDataFiltered["total_route_package_bonus_dollars"] += loopRoute[ us_selectedDateFilter ]["route_packages_delivered_count"] * loopRoute[ us_selectedDateFilter ]["package_bonus_rate"]
					filteredRouteStationBreakdown[ loopRoute["station"] ]["package_total_bonus_rate_pay"] += loopRoute[ us_selectedDateFilter ]["route_packages_delivered_count"] * loopRoute[ us_selectedDateFilter ]["package_bonus_rate"]
					filteredRouteStationBreakdown[ loopRoute["station"] ]["revenue_grand_total"] += loopRoute[ us_selectedDateFilter ]["route_packages_delivered_count"] * loopRoute[ us_selectedDateFilter ]["package_bonus_rate"]
				}
				if(
					loopRoute != null &&
					loopRoute[ us_selectedDateFilter ] != null &&
					loopRoute[ us_selectedDateFilter ]["route_packages_delivered_count"] != null &&
					!isNaN(loopRoute[ us_selectedDateFilter ]["route_packages_delivered_count"]) &&
					(
						loopRoute[ us_selectedDateFilter ]["package_base_rate"] == null ||
						loopRoute[ us_selectedDateFilter ]["package_bonus_rate"] === 0
					)
				){
					summaryDataFiltered["total_route_package_bonus_dollars_missing_mapping"]++
				}
			}

			// Users - Filtered Date
			for( let loopUserKey in us_analysisCalculatedData ){
				let loopUser = us_analysisCalculatedData[ loopUserKey ]
				if(
					loopUser != null &&
					loopUser[ us_selectedDateFilter ] != null
				){
					// Filtered Table Data
					filteredTableData[ loopUserKey ] = loopUser[ us_selectedDateFilter ]
					// Adjustments made after all data aggregated
					if(
						loopUser[ us_selectedDateFilter ]["payroll_clocked_hours_included_total"] != null &&
						loopUser[ us_selectedDateFilter ]["delivery_route_hours_total"] != null
					){
						filteredTableData[ loopUserKey ]["calculated_payroll_minus_route_hours_total"] = loopUser[ us_selectedDateFilter ]["payroll_clocked_hours_included_total"] - loopUser[ us_selectedDateFilter ]["delivery_route_hours_total"]
					}
					if(
						loopUser[ us_selectedDateFilter ]["delivery_route_hours_total"] != null &&
						loopUser[ us_selectedDateFilter ]["delivery_clocked_hours_total"] != null
					){
						if( loopUser[ us_selectedDateFilter ]["delivery_clocked_hours_total"] === 0 ){
							filteredTableData[ loopUserKey ]["calculated_delivery_actual_divided_by_delivery_expected"] = 0
						} else {
							filteredTableData[ loopUserKey ]["calculated_delivery_actual_divided_by_delivery_expected"] = ( loopUser[ us_selectedDateFilter ]["delivery_clocked_hours_total"] / loopUser[ us_selectedDateFilter ]["delivery_route_hours_total"] ) * 100
						}
					}
					if(
						loopUser[ us_selectedDateFilter ]["payroll_pay_total"] != null &&
						loopUser[ us_selectedDateFilter ]["delivery_route_revenue_total"] != null
					){
						filteredTableData[ loopUserKey ]["calculated_net_route_revenue"] = loopUser[ us_selectedDateFilter ]["delivery_route_revenue_total"] - loopUser[ us_selectedDateFilter ]["payroll_pay_total"]
					}
					// Summary Numbers
					if( loopUser[ us_selectedDateFilter ]["has_delivery_data"] === true ){
						summaryDataFiltered["total_employees_with_routes"]++
					}
					if( loopUser[ us_selectedDateFilter ]["has_payroll_data"] === true ){
						summaryDataFiltered["total_employess_with_payroll"]++
					}

					if(
						loopUser[ us_selectedDateFilter ]["payroll_clocked_hours_included_total"] != null &&
						loopUser[ us_selectedDateFilter ]["payroll_clocked_hours_included_total"] > 0
					){
						summaryDataFiltered["total_employess_with_work_hours"]++
						if( loopUser[ us_selectedDateFilter ]["has_delivery_data"] !== true ){
							summaryDataFiltered["total_employess_with_work_hours_and_no_routes"]++
						}
					}
					if(
						loopUser[ us_selectedDateFilter ]["payroll_clocked_hours_excluded_total"] != null &&
						loopUser[ us_selectedDateFilter ]["payroll_clocked_hours_excluded_total"] > 0
					){
						summaryDataFiltered["total_employess_with_excluded_hours"]++
					}
					if( loopUser[ us_selectedDateFilter ]["delivery_routes_count"] != null ){
						summaryDataFiltered["total_routes"] += loopUser[ us_selectedDateFilter ]["delivery_routes_count"]
					}
					if( loopUser[ us_selectedDateFilter ]["delivery_route_hours_total"] != null ){
						summaryDataFiltered["total_expected_route_hours"] += loopUser[ us_selectedDateFilter ]["delivery_route_hours_total"]
					}
					if( loopUser[ us_selectedDateFilter ]["delivery_clocked_hours_total"] != null ){
						summaryDataFiltered["total_actual_route_hours"] += loopUser[ us_selectedDateFilter ]["delivery_clocked_hours_total"]
					}
					if( loopUser[ us_selectedDateFilter ]["payroll_clocked_hours_included_total"] != null ){
						summaryDataFiltered["total_included_work_hours"] += loopUser[ us_selectedDateFilter ]["payroll_clocked_hours_included_total"]
						summaryDataFiltered["total_all_hours"] += loopUser[ us_selectedDateFilter ]["payroll_clocked_hours_included_total"]
					}
					if( loopUser[ us_selectedDateFilter ]["payroll_clocked_hours_excluded_total"] != null ){
						summaryDataFiltered["total_excluded_hours"] += loopUser[ us_selectedDateFilter ]["payroll_clocked_hours_excluded_total"]
						summaryDataFiltered["total_all_hours"] += loopUser[ us_selectedDateFilter ]["payroll_clocked_hours_excluded_total"]
					}
					// Scatter Chart
					if(
						loopUser[ us_selectedDateFilter ]["delivery_route_hours_total"] != null &&
						loopUser[ us_selectedDateFilter ]["payroll_clocked_hours_included_total"] != null
					){
						if(
							loopUser[ us_selectedDateFilter ]["delivery_route_hours_total"] === 0 &&
							loopUser[ us_selectedDateFilter ]["payroll_clocked_hours_included_total"] > 0
						){
							summaryDataFiltered["dark_red_hour_employees"]++
							userHourColors[ loopUserKey ] = darkRedColor
							scatterDarkRedDataset["data"].push({
								x: loopUser[ us_selectedDateFilter ]["payroll_clocked_hours_included_total"],
								y: loopUser[ us_selectedDateFilter ]["delivery_route_hours_total"],
								r: 5,
								employee_name: loopUser[ us_selectedDateFilter ]["name"],
								payroll_clocked_hours_included_total: loopUser[ us_selectedDateFilter ]["payroll_clocked_hours_included_total"].toFixed(1),
								delivery_route_hours_total: loopUser[ us_selectedDateFilter ]["delivery_route_hours_total"].toFixed(1),
							})
						} else if( loopUser[ us_selectedDateFilter ]["delivery_route_hours_total"] < loopUser[ us_selectedDateFilter ]["payroll_clocked_hours_included_total"] - us_colorHourCutoff ){
							summaryDataFiltered["red_hour_employees"]++
							userHourColors[ loopUserKey ] = themeVariables.error_main
							scatterRedDataset["data"].push({
								x: loopUser[ us_selectedDateFilter ]["payroll_clocked_hours_included_total"],
								y: loopUser[ us_selectedDateFilter ]["delivery_route_hours_total"],
								r: 5,
								employee_name: loopUser[ us_selectedDateFilter ]["name"],
								payroll_clocked_hours_included_total: loopUser[ us_selectedDateFilter ]["payroll_clocked_hours_included_total"].toFixed(1),
								delivery_route_hours_total: loopUser[ us_selectedDateFilter ]["delivery_route_hours_total"].toFixed(1),
							})
						} else if( loopUser[ us_selectedDateFilter ]["delivery_route_hours_total"] < ( loopUser[ us_selectedDateFilter ]["payroll_clocked_hours_included_total"] + us_colorHourCutoff ) ) {
							summaryDataFiltered["yellow_hour_employees"]++
							userHourColors[ loopUserKey ] = themeVariables.warning_main
							scatterYellowDataset["data"].push({
								x: loopUser[ us_selectedDateFilter ]["payroll_clocked_hours_included_total"],
								y: loopUser[ us_selectedDateFilter ]["delivery_route_hours_total"],
								r: 5,
								employee_name: loopUser[ us_selectedDateFilter ]["name"],
								payroll_clocked_hours_included_total: loopUser[ us_selectedDateFilter ]["payroll_clocked_hours_included_total"].toFixed(1),
								delivery_route_hours_total: loopUser[ us_selectedDateFilter ]["delivery_route_hours_total"].toFixed(1),
							})
						} else {
							summaryDataFiltered["green_hour_employees"]++
							userHourColors[ loopUserKey ] = themeVariables.success_main
							scatterGreenDataset["data"].push({
								x: loopUser[ us_selectedDateFilter ]["payroll_clocked_hours_included_total"],
								y: loopUser[ us_selectedDateFilter ]["delivery_route_hours_total"],
								r: 5,
								employee_name: loopUser[ us_selectedDateFilter ]["name"],
								payroll_clocked_hours_included_total: loopUser[ us_selectedDateFilter ]["payroll_clocked_hours_included_total"].toFixed(1),
								delivery_route_hours_total: loopUser[ us_selectedDateFilter ]["delivery_route_hours_total"].toFixed(1),
							})
						}
					}
					// Financials
					if(
						loopUser != null &&
						loopUser[ us_selectedDateFilter ]["payroll_pay_total"] != null &&
						!isNaN( loopUser[ us_selectedDateFilter ]["payroll_pay_total"] )
					){
						summaryDataFiltered["total_payroll_dollars"] += loopUser[ us_selectedDateFilter ]["payroll_pay_total"]
					}
					if(
						loopUser != null &&
						loopUser[ us_selectedDateFilter ]["payroll_pay_errors_total"] != null &&
						!isNaN( loopUser[ us_selectedDateFilter ]["payroll_pay_errors_total"] )
					){
						summaryDataFiltered["total_payroll_dollars_missing_mapping"] += loopUser[ us_selectedDateFilter ]["payroll_pay_errors_total"]
					}
					if(
						loopUser != null &&
						loopUser[ us_selectedDateFilter ]["delivery_route_revenue_total"] != null &&
						!isNaN( loopUser[ us_selectedDateFilter ]["delivery_route_revenue_total"] )
					){
						summaryDataFiltered["total_route_dollars"] += loopUser[ us_selectedDateFilter ]["delivery_route_revenue_total"]
					}
				}
			}

			// Cleanup - Filtered Data
			summaryDataFiltered["scatterplot_data"]["datasets"].push( scatterDarkRedDataset )
			summaryDataFiltered["scatterplot_data"]["datasets"].push( scatterRedDataset )
			summaryDataFiltered["scatterplot_data"]["datasets"].push( scatterYellowDataset )
			summaryDataFiltered["scatterplot_data"]["datasets"].push( scatterGreenDataset )
			summaryDataFiltered["total_expected_route_hours"] = Math.round( summaryDataFiltered["total_expected_route_hours"] * 100 ) / 100
			summaryDataFiltered["total_actual_route_hours"] = Math.round( summaryDataFiltered["total_actual_route_hours"] * 100 ) / 100
			summaryDataFiltered["total_included_work_hours"] = Math.round( summaryDataFiltered["total_included_work_hours"] * 100 ) / 100
			summaryDataFiltered["total_work_minus_expected_route_hours"] = Math.round( (summaryDataFiltered["total_included_work_hours"] - summaryDataFiltered["total_expected_route_hours"]) * 100 ) / 100

			/////////////////////////////////
			// Week Lock Data
			/////////////////////////////////

			const hardCodeWeekKey = "week"

			// Routes - Week Lock Data
			for( let loopRouteKey in us_deliveryRouteTypes ){
				let loopRoute = us_deliveryRouteTypes[ loopRouteKey ]
				// Route Revenue
				if(
					loopRoute != null &&
					loopRoute[ hardCodeWeekKey ] != null &&
					loopRoute[ hardCodeWeekKey ]["route_run_count"] != null &&
					!isNaN(loopRoute[ hardCodeWeekKey ]["route_run_count"]) &&
					(
						loopRoute[ hardCodeWeekKey ]["route_revenue_per_run"] == null ||
						loopRoute[ hardCodeWeekKey ]["route_revenue_per_run"] === 0
					)
				){
					summaryDataToSaveOnLock["total_route_dollars_missing_mapping"] ++
				}
				// Package Rates
				if(
					loopRoute != null &&
					loopRoute[ hardCodeWeekKey ] != null &&
					loopRoute[ hardCodeWeekKey ]["route_packages_delivered_count"] != null &&
					!isNaN(loopRoute[ hardCodeWeekKey ]["route_packages_delivered_count"]) &&
					loopRoute[ hardCodeWeekKey ]["package_base_rate"] != null &&
					!isNaN(loopRoute[ hardCodeWeekKey ]["package_base_rate"])
				){
					// Base Rate
					summaryDataToSaveOnLock["total_route_package_base_dollars"] += loopRoute[ hardCodeWeekKey ]["route_packages_delivered_count"] * loopRoute[ hardCodeWeekKey ]["package_base_rate"]
				}
				if(
					loopRoute != null &&
					loopRoute[ hardCodeWeekKey ] != null &&
					loopRoute[ hardCodeWeekKey ]["route_packages_delivered_count"] != null &&
					!isNaN(loopRoute[ hardCodeWeekKey ]["route_packages_delivered_count"]) &&
					(
						loopRoute[ hardCodeWeekKey ]["package_bonus_rate"] == null ||
						loopRoute[ hardCodeWeekKey ]["package_bonus_rate"] === 0
					)
				){
					summaryDataToSaveOnLock["total_route_package_base_dollars_missing_mapping"]++
				}
				if(
					loopRoute != null &&
					loopRoute[ hardCodeWeekKey ] != null &&
					loopRoute[ hardCodeWeekKey ]["route_packages_delivered_count"] != null &&
					!isNaN(loopRoute[ hardCodeWeekKey ]["route_packages_delivered_count"]) &&
					loopRoute[ hardCodeWeekKey ]["package_bonus_rate"] != null &&
					!isNaN(loopRoute[ hardCodeWeekKey ]["package_bonus_rate"])
				){
					// Bonus Rate
					summaryDataToSaveOnLock["total_route_package_bonus_dollars"] += loopRoute[ hardCodeWeekKey ]["route_packages_delivered_count"] * loopRoute[ hardCodeWeekKey ]["package_bonus_rate"]
				}
				if(
					loopRoute != null &&
					loopRoute[ hardCodeWeekKey ] != null &&
					loopRoute[ hardCodeWeekKey ]["route_packages_delivered_count"] != null &&
					!isNaN(loopRoute[ hardCodeWeekKey ]["route_packages_delivered_count"]) &&
					(
						loopRoute[ hardCodeWeekKey ]["package_base_rate"] == null ||
						loopRoute[ hardCodeWeekKey ]["package_bonus_rate"] === 0
					)
				){
					summaryDataToSaveOnLock["total_route_package_bonus_dollars_missing_mapping"]++
				}
			}

			// Users - Week Lock Data
			for( let loopUserKey in us_analysisCalculatedData ){
				let loopUser = us_analysisCalculatedData[ loopUserKey ]
				if(
					loopUser != null &&
					loopUser[ hardCodeWeekKey ] != null
				){
					// Summary Numbers
					if( loopUser[ hardCodeWeekKey ]["has_delivery_data"] === true ){
						summaryDataToSaveOnLock["total_employees_with_routes"]++
					}
					if( loopUser[ hardCodeWeekKey ]["has_payroll_data"] === true ){
						summaryDataToSaveOnLock["total_employess_with_payroll"]++
					}

					if(
						loopUser[ hardCodeWeekKey ]["payroll_clocked_hours_included_total"] != null &&
						loopUser[ hardCodeWeekKey ]["payroll_clocked_hours_included_total"] > 0
					){
						summaryDataToSaveOnLock["total_employess_with_work_hours"]++
						if( loopUser[ hardCodeWeekKey ]["has_delivery_data"] !== true ){
							summaryDataToSaveOnLock["total_employess_with_work_hours_and_no_routes"]++
						}
					}
					if(
						loopUser[ hardCodeWeekKey ]["payroll_clocked_hours_excluded_total"] != null &&
						loopUser[ hardCodeWeekKey ]["payroll_clocked_hours_excluded_total"] > 0
					){
						summaryDataToSaveOnLock["total_employess_with_excluded_hours"]++
					}
					if( loopUser[ hardCodeWeekKey ]["delivery_routes_count"] != null ){
						summaryDataToSaveOnLock["total_routes"] += loopUser[ hardCodeWeekKey ]["delivery_routes_count"]
					}
					if( loopUser[ hardCodeWeekKey ]["delivery_route_hours_total"] != null ){
						summaryDataToSaveOnLock["total_expected_route_hours"] += loopUser[ hardCodeWeekKey ]["delivery_route_hours_total"]
					}
					if( loopUser[ hardCodeWeekKey ]["delivery_clocked_hours_total"] != null ){
						summaryDataToSaveOnLock["total_actual_route_hours"] += loopUser[ hardCodeWeekKey ]["delivery_clocked_hours_total"]
					}
					if( loopUser[ hardCodeWeekKey ]["payroll_clocked_hours_included_total"] != null ){
						summaryDataToSaveOnLock["total_included_work_hours"] += loopUser[ hardCodeWeekKey ]["payroll_clocked_hours_included_total"]
						summaryDataToSaveOnLock["total_all_hours"] += loopUser[ hardCodeWeekKey ]["payroll_clocked_hours_included_total"]
					}
					if( loopUser[ hardCodeWeekKey ]["payroll_clocked_hours_excluded_total"] != null ){
						summaryDataToSaveOnLock["total_excluded_hours"] += loopUser[ hardCodeWeekKey ]["payroll_clocked_hours_excluded_total"]
						summaryDataToSaveOnLock["total_all_hours"] += loopUser[ hardCodeWeekKey ]["payroll_clocked_hours_excluded_total"]
					}
					// Scatter Chart
					if(
						loopUser[ hardCodeWeekKey ]["delivery_route_hours_total"] != null &&
						loopUser[ hardCodeWeekKey ]["payroll_clocked_hours_included_total"] != null
					){
						if(
							loopUser[ hardCodeWeekKey ]["delivery_route_hours_total"] === 0 &&
							loopUser[ hardCodeWeekKey ]["payroll_clocked_hours_included_total"] > 0
						){
							summaryDataToSaveOnLock["dark_red_hour_employees"]++
						} else if( loopUser[ hardCodeWeekKey ]["delivery_route_hours_total"] < loopUser[ hardCodeWeekKey ]["payroll_clocked_hours_included_total"] - us_colorHourCutoff ){
							summaryDataToSaveOnLock["red_hour_employees"]++
						} else if( loopUser[ hardCodeWeekKey ]["delivery_route_hours_total"] < ( loopUser[ hardCodeWeekKey ]["payroll_clocked_hours_included_total"] + us_colorHourCutoff ) ) {
							summaryDataToSaveOnLock["yellow_hour_employees"]++
						} else {
							summaryDataToSaveOnLock["green_hour_employees"]++
						}
					}
					// Financials
					if(
						loopUser != null &&
						loopUser[ hardCodeWeekKey ]["payroll_pay_total"] != null &&
						!isNaN( loopUser[ hardCodeWeekKey ]["payroll_pay_total"] )
					){
						summaryDataToSaveOnLock["total_payroll_dollars"] += loopUser[ hardCodeWeekKey ]["payroll_pay_total"]
					}
					if(
						loopUser != null &&
						loopUser[ hardCodeWeekKey ]["payroll_pay_errors_total"] != null &&
						!isNaN( loopUser[ hardCodeWeekKey ]["payroll_pay_errors_total"] )
					){
						summaryDataToSaveOnLock["total_payroll_dollars_missing_mapping"] += loopUser[ hardCodeWeekKey ]["payroll_pay_errors_total"]
					}
					if(
						loopUser != null &&
						loopUser[ hardCodeWeekKey ]["delivery_route_revenue_total"] != null &&
						!isNaN( loopUser[ hardCodeWeekKey ]["delivery_route_revenue_total"] )
					){
						summaryDataToSaveOnLock["total_route_dollars"] += loopUser[ hardCodeWeekKey ]["delivery_route_revenue_total"]
					}
				}
			}

			// Cleanup - Week Lock Data
			summaryDataToSaveOnLock["total_expected_route_hours"] = Math.round( summaryDataToSaveOnLock["total_expected_route_hours"] * 100 ) / 100
			summaryDataToSaveOnLock["total_actual_route_hours"] = Math.round( summaryDataToSaveOnLock["total_actual_route_hours"] * 100 ) / 100
			summaryDataToSaveOnLock["total_included_work_hours"] = Math.round( summaryDataToSaveOnLock["total_included_work_hours"] * 100 ) / 100
			summaryDataToSaveOnLock["total_work_minus_expected_route_hours"] = Math.round( (summaryDataToSaveOnLock["total_included_work_hours"] - summaryDataToSaveOnLock["total_expected_route_hours"]) * 100 ) / 100

			/////////////////////////////////
			// Set To State
			/////////////////////////////////

			us_setUserHourColors( userHourColors )
			us_setFilteredTableData( filteredTableData )
			us_setFilteredRouteRevenueBreakdown( filteredRouteRevenueBreakdown )
			us_setFilteredRouteStationBreakdown( filteredRouteStationBreakdown )
			us_setSummaryData( summaryDataFiltered )
			us_setSummaryDataToSaveOnLock( summaryDataToSaveOnLock )

			return () => {}
		}, [us_analysisCalculatedData, us_colorHourCutoff, us_selectedDateFilter, us_deliveryRouteTypes, us_rootAnalysis, us_lockedAnalysisDataResults])

		useEffect(() => {
			// Mapping Counts Calculation - Used for Nav and Tabs Badges
			let columnMappingMissingCounts: TsInterface_UnspecifiedObject = {
				// Individuals
				delivery_headers: 0,
				timecard_headers: 0,
				driver_linking: 0,
				paycode_multipiers: 0,
				route_revenue: 0,
				// Totals
				total_red: 0,
				total_yellow: 0,
			}
			let driversMissingMapping: TsInterface_UnspecifiedObject = {}
			for( let loopKey in defaultDeliveryReportMappingData ){
				let mappingField = defaultDeliveryReportMappingData[ loopKey ]
				if(
					us_deliveryReportMappingData != null &&
					mappingField != null &&
					mappingField.key != null &&
					us_deliveryReportMappingData[ mappingField.key ] != null
				){
					// Database
				} else if(
					mappingField != null &&
					mappingField.default_header != null &&
					us_deliveryFileHeaders != null &&
					us_deliveryFileHeaders[ mappingField.default_header ] != null
				){
					// Default and Exists
				} else {
					// Missing
					columnMappingMissingCounts["delivery_headers"]++
					columnMappingMissingCounts["total_red"]++
				}
			}
			// Determine Name Mapping Type
			let tableColumns = defaultTimecardReportMappingData
			let nameMappingType = "full_name_in_a_single_column"
			if(
				us_rootAnalysis != null &&
				us_rootAnalysis.name_mapping_type != null
			){
				nameMappingType = us_rootAnalysis.name_mapping_type
			} else if(
				us_timecardReportMappingData != null &&
				us_timecardReportMappingData.name_mapping_type != null
			){
				nameMappingType = us_timecardReportMappingData.name_mapping_type
			}
			if( nameMappingType === "full_name_in_a_single_column" ){
				tableColumns = defaultTimecardReportMappingData
			} else if( nameMappingType === "first_and_last_name_in_different_columns" ){
				tableColumns = firstAndLastNameTimecardReportMappingData
			} else {
				tableColumns = defaultTimecardReportMappingData
			}
			// End Determine Name Mapping Type
			for( let loopKey in tableColumns ){
				let mappingField = tableColumns[ loopKey ]
				if(
					us_timecardReportMappingData != null &&
					mappingField != null &&
					mappingField.key != null &&
					us_timecardReportMappingData[ mappingField.key ] != null
				){
					// Database
				} else if(
					mappingField != null &&
					mappingField.default_header != null &&
					us_timecardFileHeaders != null &&
					us_timecardFileHeaders[ mappingField.default_header ] != null
				){
					// Default and Exists
				} else {
					// Missing
					columnMappingMissingCounts["timecard_headers"]++
					columnMappingMissingCounts["total_red"]++
				}
			}
			for( let loopKey in us_deliveryRouteTypes ){
				if(
					us_routePricingMappingData == null ||
					us_routePricingMappingData[ loopKey ] == null
				){
					columnMappingMissingCounts["route_revenue"]++
					columnMappingMissingCounts["total_yellow"]++
				}

				if(
					us_routePricingMappingData == null ||
					us_routePricingMappingData[ loopKey + "_pkg_base_price" ] == null
				){
					columnMappingMissingCounts["route_revenue"]++
					columnMappingMissingCounts["total_yellow"]++
				}

				if(
					us_routePricingMappingData == null ||
					us_routePricingMappingData[ loopKey + "_pkg_bonus_price" ] == null
				){
					columnMappingMissingCounts["route_revenue"]++
					columnMappingMissingCounts["total_yellow"]++
				}

			}
			for( let driverKey in us_deliveryDrivers ){
				if(
					us_userInvertedMappingData != null &&
					us_userInvertedMappingData[ driverKey ] != null
				){
					// Linked
				} else if(
					us_timecardEmployees != null &&
					us_timecardEmployees[ driverKey ] != null
				){
					// Identical Match Already
				} else {
					// Not Linked
					driversMissingMapping[ driverKey ] = driverKey
				}
			}
			columnMappingMissingCounts["driver_linking"] = objectToArray( driversMissingMapping ).length
			columnMappingMissingCounts["total_red"] = objectToArray( driversMissingMapping ).length
			us_setUnmappedDrivers( objectToArray( driversMissingMapping ) )
			us_setMappingMissingCounts( columnMappingMissingCounts )
			return () => {}
		}, [us_deliveryDrivers, us_deliveryFileHeaders, us_deliveryReportMappingData, us_deliveryRouteTypes, us_rootAnalysis, us_routePricingMappingData, us_timecardEmployees, us_timecardFileHeaders, us_timecardReportMappingData, us_userInvertedMappingData, us_userMappingData])

		// Other Variables

		// Functions
		const uploadReport = (
			reportKey: "delivery" | "timecard",
			data: TsInterface_UnspecifiedObject[],
		): TsType_Void => {
			let formattedData: TsInterface_UnspecifiedObject = {}
			let uploadedFileColumns: TsType_String[] = []
			us_setUploadingData( true )
			// let dataMapping: TsType_String[] = []
			// Loop through and format data
			for ( let cellIndex = 0; cellIndex < data[0].length; cellIndex++ ) {
				uploadedFileColumns[ cellIndex ] = data[0][ cellIndex ]
			}
			for ( let rowIndex = 1; rowIndex < data.length; rowIndex++ ) {
				if(
					data != null &&
					data[ rowIndex ] != null
				){
					let loopLog = data[ rowIndex ]
					let mappedLoopLog: TsInterface_UnspecifiedObject = {}
					for ( let cellIndex = 0; cellIndex < loopLog.length; cellIndex++ ) {
						if(
							loopLog != null &&
							loopLog[ cellIndex ] != null &&
							uploadedFileColumns != null &&
							uploadedFileColumns[ cellIndex ] != null
						){
							mappedLoopLog[ uploadedFileColumns[ cellIndex ] ] = loopLog[ cellIndex ]
						}
					}
					mappedLoopLog["key"] = rowIndex
					formattedData[ rowIndex ] = mappedLoopLog
				}
			}
			getClientKey( uc_RootData_ClientKey, uc_setRootData_ClientKey ).then(( res_GCK ) => {
				uploadReportProper( res_GCK.clientKey, reportKey, objectToArray( formattedData ), uploadedFileColumns, 0 )
			}).catch(( rej_GCK ) => {
				uc_setUserInterface_ErrorDialogDisplay( { display: true, error: rej_GCK.error } )
			})
		}

		const uploadReportProper = (
			clientKey: TsType_String,
			reportKey: "delivery" | "timecard",
			formattedData: TsInterface_UnspecifiedObject[],
			uploadedFileColumns: TsType_String[],
			startingIndex: TsType_Number,
		): TsType_Void => {
			let batchLimit = 500
			let timeoutMilliseconds = 1500
			let updateArray: TsInterface_DatabaseBatchUpdatesArray = []
			for( let loopIndex = startingIndex; loopIndex < startingIndex + batchLimit; loopIndex++ ){
				if( formattedData[ loopIndex ] != null ){
					switch( reportKey ){
						case "delivery":
							updateArray.push({
								type: "setMerge",
								ref: DatabaseRef_TimecardAnalysis_DeliveryFile_Document( clientKey, analysisKey, formattedData[ loopIndex ].key.toString() ),
								data: formattedData[ loopIndex ]
							})
							break
						case "timecard":
							updateArray.push({
								type: "setMerge",
								ref: DatabaseRef_TimecardAnalysis_TimecardFile_Document( clientKey, analysisKey, formattedData[ loopIndex ].key.toString() ),
								data: formattedData[ loopIndex ]
							})
							break
					}
				}
			}
			if( formattedData.length > startingIndex + batchLimit ){
				DatabaseBatchUpdate( updateArray, {} ).then(( res_DBU ) => {
					setTimeout( () => {
						uploadReportProper( clientKey, reportKey, formattedData, uploadedFileColumns, startingIndex + batchLimit )
					}, timeoutMilliseconds)
				}).catch(( rej_DBU ) => {
					us_setUploadingData( false )
					uc_setUserInterface_ErrorDialogDisplay( { display: true, error: rej_DBU.error } )
				})
			} else {
				DatabaseBatchUpdate( updateArray, {} ).then(( res_DBU ) => {
					let updateObject = {
						[ "column_headers_" + reportKey ]: uploadedFileColumns,
						[ "file_upload_" + reportKey ]: true,
						[ "file_upload_" + reportKey + "_item_count" ]: formattedData.length
					}
					DatabaseSetMergeDocument( DatabaseRef_TimecardAnalysis_Document( clientKey, analysisKey ), updateObject, {} ).then(( res_DSMD ) => {
						us_setOpenToAutoChangingNavSection( true )
						us_setUploadingData( false )
					}).catch(( rej_DSMD ) => {
						us_setUploadingData( false )
						uc_setUserInterface_ErrorDialogDisplay( { display: true, error: rej_DSMD.error } )
					})
				}).catch(( rej_DBU ) => {
					us_setUploadingData( false )
					uc_setUserInterface_ErrorDialogDisplay( { display: true, error: rej_DBU.error } )
				})
			}
		}

		const wipeReport = (
			reportKey: "delivery" | "timecard",
		): TsType_Void => {
			uc_setUserInterface_PromptDialogDisplay({
				display: true,
				prompt: {
					color: "error",
					confirm_text: s_CONFIRM_DELETION,
					default_value: "",
					header: s_DELETE_FILE_UPLOAD_DATA,
					icon: <Icon icon="siren-on" type="solid" />,
					input_label: s_TYPE_DELETE_IN_ALL_UPPERCASE_TO_PROCEED,
					input_type: "text",
					text: s_ARE_YOU_SURE_THAT_YOU_WANT_TO_DELETE_THIS_FILE,
					submit_callback: (  promptValue: TsType_String ) => {
						return new Promise( ( resolve, reject ) => {
							if( promptValue === "DELETE" ){
								getClientKey( uc_RootData_ClientKey, uc_setRootData_ClientKey ).then(( res_GCK ) => {
									let keysToDelete: TsType_String[] = []
									switch( reportKey ){
										case "delivery":
											keysToDelete = Object.keys( us_deliveryFileData )
											break
										case "timecard":
											keysToDelete = Object.keys( us_timecardFileData )
											break
									}
									us_setDeletingData( true )
									wipeReportProper(
										res_GCK.clientKey,
										reportKey,
										keysToDelete,
										0
									).then( ( res_WRP ) => {
										us_setDeletingData( false )
										resolve({ close_dialog: true })
									}).catch( ( rej_WRP ) => {
										uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_WRP.error })
										us_setDeletingData( false )
										resolve({ close_dialog: true })
									})
								}).catch((rej_GCK) => {
									uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
									resolve({ close_dialog: false })
								})
							} else {
								uc_setUserInterface_ErrorDialogDisplay({ display: true, error: {
									message: s_FAILED_TO_DELETE_FILE_UPLOAD_DATA,
									details: <>{s_YOU_MUST_ENTER_DELETE_IN_ORDER_TO_PROCEED} {s_OTHERWISE_CLICK_DISMISS}</>,
									code: "ER-D-ATV-WR-01"
								}})
								resolve({ close_dialog: false })
							}
						})
					}
				}
			})
		}

		const wipeReportProper = (
			clientKey: TsType_String,
			reportKey: "delivery" | "timecard",
			keysToDelete: TsType_String[],
			startingIndex: TsType_Number,
		): TsType_UnknownPromise => {
			return new Promise( ( resolve, reject ) => {
				let batchLimit = 500
				let timeoutMilliseconds = 1500
				let updateArray: TsInterface_DatabaseBatchUpdatesArray = []
				for( let loopIndex = startingIndex; loopIndex < startingIndex + batchLimit; loopIndex++ ){
					if( keysToDelete[ loopIndex ] != null ){
						switch( reportKey ){
							case "delivery":
								updateArray.push({
									type: "delete",
									ref: DatabaseRef_TimecardAnalysis_DeliveryFile_Document( clientKey, analysisKey, keysToDelete[ loopIndex ] ),
									// data: formattedData[ loopIndex ]
								})
								break
							case "timecard":
								updateArray.push({
									type: "delete",
									ref: DatabaseRef_TimecardAnalysis_TimecardFile_Document( clientKey, analysisKey, keysToDelete[ loopIndex ] ),
									// data: formattedData[ loopIndex ]
								})
								break
						}
					}
				}
				if( keysToDelete.length > startingIndex + batchLimit ){
					DatabaseBatchUpdate( updateArray, {} ).then(( res_DBU ) => {
						setTimeout( () => {
							wipeReportProper( clientKey, reportKey, keysToDelete, startingIndex + batchLimit ).then( ( res_WRP ) => {
								resolve( res_WRP )
							}).catch( ( rej_WRP ) => {
								reject( rej_WRP )
							})
						}, timeoutMilliseconds)
					}).catch(( rej_DBU ) => {
						reject( rej_DBU )
					})
				} else {
					DatabaseBatchUpdate( updateArray, {} ).then(( res_DBU ) => {
						let updateObject = {
							[ "column_headers_" + reportKey ]: null,
							[ "file_upload_" + reportKey ]: false,
							[ "file_upload_" + reportKey + "_item_count" ]: null
						}
						DatabaseSetMergeDocument( DatabaseRef_TimecardAnalysis_Document( clientKey, analysisKey ), updateObject, {} ).then(( res_DSMD ) => {
							resolve( res_DSMD )
						}).catch(( rej_DSMD ) => {
							reject( rej_DSMD )
						})
					}).catch(( rej_DBU ) => {
						reject( rej_DBU )

					})
				}
			})

		}

		const unlockAnalysis = () => {
			uc_setUserInterface_ConfirmDialogDisplay({
				display: true,
				confirm: {
					color: "warning",
					header: <>{ s_UNLOCK_ANALYSIS }</>,
					icon: <Icon icon="unlock" type="solid"/>,
					submit_text: s_UNLOCK_ANALYSIS,
					text: <>{ s_ARE_YOU_SURE_THAT_YOU_WANT_TO_UNLOCK_THIS_ANALYSIS }</>,
					submit_callback: () => {
						return new Promise( ( resolve, reject ) => {
							unlockAnalysisProper().then( () => {
								resolve({ close_dialog: true })
							}).catch( () => {
								resolve({ close_dialog: true })
							})
						})
					},
				}
			})
		}

		const unlockAnalysisProper = (): TsType_UnknownPromise => {
			return new Promise( ( resolve, reject ) => {
				getClientKey( uc_RootData_ClientKey, uc_setRootData_ClientKey ).then(( res_GCK ) => {
					us_setLockingAnalysis( true )
					let updateArray: TsInterface_DatabaseBatchUpdatesArray = [{
						type: "setMerge",
						ref: DatabaseRef_TimecardAnalysis_Document( res_GCK.clientKey, analysisKey ),
						data: {
							locked: false,
							// TODO - stuff for home page
							locked_summary_data: null,
							locked_summary_dates: null,
							timestamp_locked: null,
						}
					}]

					// TODO - wipe this in case the file is changed and is smaller

					// for( let loopIndex = 0; loopIndex < splitAnalysisCalculatedDataArray.length; loopIndex++ ){
					// 	updateArray.push({
					// 		type: "setOverwrite",
					// 		ref: DatabaseRef_TimecardAnalysis_LockedResults_Document( res_GCK.clientKey, analysisKey, "analysisCalculatedData_" + loopIndex ),
					// 		data: splitAnalysisCalculatedDataArray[ loopIndex ]
					// 	})
					// }

					// Save to Database
					DatabaseBatchUpdate( updateArray, {} ).then(( res_DBU ) => {
						us_setLockingAnalysis( false )
						resolve( res_DBU )
					}).catch(( rej_DBU ) => {
						us_setLockingAnalysis( false )
						uc_setUserInterface_ErrorDialogDisplay( { display: true, error: rej_DBU.error } )
						reject( rej_DBU )
					})
				}).catch(( rej_GCK ) => {
					console.error( rej_GCK )
					uc_setUserInterface_ErrorDialogDisplay( { display: true, error: rej_GCK.error } )
					reject( rej_GCK )
				})
			})
		}

		function splitObject(maxPropsPerSubobject: TsType_Number, originalObject: TsInterface_UnspecifiedObject) {
			const keys = Object.keys(originalObject);
			const numKeys = keys.length;
			if (numKeys === 0 || maxPropsPerSubobject <= 0) {
				return [];
			}
			const objects = [];
			let currentIndex = 0;
			while (currentIndex < numKeys) {
				const subObject: TsInterface_UnspecifiedObject = {};
				for (let i = 0; i < maxPropsPerSubobject && currentIndex < numKeys; i++) {
				const key = keys[currentIndex];
				subObject[key] = originalObject[key];
					currentIndex++;
				}
				objects.push(subObject);
			}
			return objects;
		}

		const lockAnalysis = () => {
			uc_setUserInterface_ConfirmDialogDisplay({
				display: true,
				confirm: {
					color: "warning",
					header: <>{ s_LOCK_ANALYSIS }</>,
					icon: <Icon icon="lock" type="solid"/>,
					submit_text: s_LOCK_ANALYSIS,
					text: <>{ s_ARE_YOU_SURE_THAT_YOU_WANT_TO_LOCK_THIS_ANALYSIS }</>,
					submit_callback: () => {
						return new Promise( ( resolve, reject ) => {
							lockAnalysisProper().then( () => {
								resolve({ close_dialog: true })
							}).catch( () => {
								resolve({ close_dialog: true })
							})
						})
					},
				}
			})
		}

		const lockAnalysisProper = (): TsType_UnknownPromise => {
			return new Promise( ( resolve, reject ) => {
				getClientKey( uc_RootData_ClientKey, uc_setRootData_ClientKey ).then(( res_GCK ) => {
					us_setLockingAnalysis( true )
					let splitAnalysisCalculatedDataArray = splitObject( 50, us_analysisCalculatedData )

					let lockedSummaryDates: TsInterface_UnspecifiedObject = {
						delivery_first_date: null,
						delivery_last_date: null,
						timecard_first_date: null,
						timecard_last_date: null,
					}
					if( us_dataFileDates != null ){
						if( us_dataFileDates["deliveryFirstDate"] != null ){
							let deliveryFirstDate = new Date( us_dataFileDates["deliveryFirstDate"] )
							deliveryFirstDate.setMinutes( deliveryFirstDate.getMinutes() + deliveryFirstDate.getTimezoneOffset() )
							lockedSummaryDates["delivery_first_date"] = deliveryFirstDate
						}
						if( us_dataFileDates["deliveryLastDate"] != null ){
							let deliveryLastDate = new Date( us_dataFileDates["deliveryLastDate"] )
							deliveryLastDate.setMinutes( deliveryLastDate.getMinutes() + deliveryLastDate.getTimezoneOffset() )
							lockedSummaryDates["delivery_last_date"] = deliveryLastDate
						}
						if( us_dataFileDates["timecardFirstDate"] != null ){
							let timecardFirstDate = new Date( us_dataFileDates["timecardFirstDate"] )
							timecardFirstDate.setMinutes( timecardFirstDate.getMinutes() + timecardFirstDate.getTimezoneOffset() )
							lockedSummaryDates["timecard_first_date"] = timecardFirstDate
						}
						if( us_dataFileDates["timecardLastDate"] != null ){
							let timecardLastDate = new Date( us_dataFileDates["timecardLastDate"] )
							timecardLastDate.setMinutes( timecardLastDate.getMinutes() + timecardLastDate.getTimezoneOffset() )
							// timecardLastDate.setMinutes(timecardLastDate.getMinutes() + ( 60 * timecardLastDate.getTimezoneOffset() ) )
							lockedSummaryDates["timecard_last_date"] = timecardLastDate
						}
					}
					let updateArray: TsInterface_DatabaseBatchUpdatesArray = [
						{
							type: "setMerge",
							ref: DatabaseRef_TimecardAnalysis_Document( res_GCK.clientKey, analysisKey ),
							data: {
								locked: true,
								locked_number_of_user_objects: objectToArray( splitAnalysisCalculatedDataArray ).length,
								// TODO - stuff for home page
								locked_summary_data: us_summaryDataToSaveOnLock,
								locked_summary_dates: lockedSummaryDates,
								timestamp_locked: new Date(),
							}
						},
						{
							type: "setOverwrite",
							ref: DatabaseRef_TimecardAnalysis_LockedResults_Document( res_GCK.clientKey, analysisKey, "dateDropdownOptions" ),
							data: us_dateDropdownOptions
						},
						{
							type: "setOverwrite",
							ref: DatabaseRef_TimecardAnalysis_LockedResults_Document( res_GCK.clientKey, analysisKey, "dataFileDates" ),
							data: us_dataFileDates
						},
						{
							type: "setOverwrite",
							ref: DatabaseRef_TimecardAnalysis_LockedResults_Document( res_GCK.clientKey, analysisKey, "deliveryDrivers" ),
							data: us_deliveryDrivers
						},
						{
							type: "setOverwrite",
							ref: DatabaseRef_TimecardAnalysis_LockedResults_Document( res_GCK.clientKey, analysisKey, "timecardEmployees" ),
							data: us_timecardEmployees
						},
						{
							type: "setOverwrite",
							ref: DatabaseRef_TimecardAnalysis_LockedResults_Document( res_GCK.clientKey, analysisKey, "payCodesList" ),
							data: us_payCodesList
						},
						{
							type: "setOverwrite",
							ref: DatabaseRef_TimecardAnalysis_LockedResults_Document( res_GCK.clientKey, analysisKey, "deliveryFileHeaders" ),
							data: us_deliveryFileHeaders
						},
						{
							type: "setOverwrite",
							ref: DatabaseRef_TimecardAnalysis_LockedResults_Document( res_GCK.clientKey, analysisKey, "timecardFileHeaders" ),
							data: us_timecardFileHeaders
						},
						// {
							// type: "setOverwrite",
							// ref: DatabaseRef_TimecardAnalysis_LockedResults_Document( res_GCK.clientKey, analysisKey, "analysisCalculatedData" ),
							// data: us_analysisCalculatedData
						// },
						{
							type: "setOverwrite",
							ref: DatabaseRef_TimecardAnalysis_LockedResults_Document( res_GCK.clientKey, analysisKey, "deliveryRouteTypes" ),
							data: us_deliveryRouteTypes
						},
						{
							type: "setOverwrite",
							ref: DatabaseRef_TimecardAnalysis_LockedResults_Document( res_GCK.clientKey, analysisKey, "analysisErrorData" ),
							data: us_analysisErrorData
						},
						{
							type: "setOverwrite",
							ref: DatabaseRef_TimecardAnalysis_LockedResults_Document( res_GCK.clientKey, analysisKey, "analysisErrorDataNames" ),
							data: us_analysisErrorDataNames
						},
					]
					// Split up analysisCalculatedData
					for( let loopIndex = 0; loopIndex < splitAnalysisCalculatedDataArray.length; loopIndex++ ){
						updateArray.push({
							type: "setOverwrite",
							ref: DatabaseRef_TimecardAnalysis_LockedResults_Document( res_GCK.clientKey, analysisKey, "analysisCalculatedData_" + loopIndex ),
							data: splitAnalysisCalculatedDataArray[ loopIndex ]
						})
					}
					// Save to Database
					DatabaseBatchUpdate( updateArray, {} ).then(( res_DBU ) => {
						us_setLockingAnalysis( false )
						resolve( res_DBU )
					}).catch(( rej_DBU ) => {
						us_setLockingAnalysis( false )
						uc_setUserInterface_ErrorDialogDisplay( { display: true, error: rej_DBU.error } )
						reject( rej_DBU )
					})
				}).catch(( rej_GCK ) => {
					console.error( rej_GCK )
					uc_setUserInterface_ErrorDialogDisplay( { display: true, error: rej_GCK.error } )
					reject( rej_GCK )
				})
			})
		}

		// Call Functions

		// JSX Generation

		// General
		const returnJSX_BackButton = (): TsType_JSX => {
			let buttonJSX =
			<Button
				color="inherit"
				variant="outlined"
				onClick={ () => { un_routerNaviation( ApplicationPages.AuditTimecardsIndexPage.url() ) } }
				disableElevation
				startIcon={ <Icon icon="chevron-left" /> }
				className="tw-mr-2"
			>
				{ s_BACK }
			</Button>
			return buttonJSX
		}

		// Navigation
		// const returnJSX_NavInstructionsButton = (): TsType_JSX => {
			// // Variant
			// let selectedVariant: "outlined" | "contained" = "outlined"
			// if( us_selectedSection === "instructions" ){
			// 	selectedVariant = "contained"
			// }
			// // Color
			// let selectedColor: TsType_MuiComponentColors = "primary"
			// // JSX
			// let buttonJSX =
			// <Button
			// 	color={ selectedColor }
			// 	variant={ selectedVariant }
			// 	onClick={ () => {
			// 		us_setSelectedSection( "instructions" )
			// 	} }
			// 	disableElevation
			// 	startIcon={ <Icon icon="square-info" /> }
			// 	className="tw-mr-3 tw-mb-2"
			// >
			// 	{ s_INSTRUCTIONS }
			// </Button>
			// return buttonJSX
		// }

		const returnJSX_NavFileUploadButton = (): TsType_JSX => {
			// Variant
			let selectedVariant: "outlined" | "contained" = "outlined"
			if( us_selectedSection === "file_upload" ){
				selectedVariant = "contained"
			}
			// Color
			let selectedColor: TsType_MuiComponentColors = "primary"
			// Icon
			let iconJSX = <Icon icon="cloud-arrow-up" />
			if(
				us_rootAnalysis.file_upload_delivery === true &&
				us_rootAnalysis.file_upload_timecard === true
			){
				iconJSX = <Icon icon="badge-check" />
				selectedColor = "success"
			}
			// Badge
			let badgeInvisibile: TsType_Boolean = true
			let badgeContent: TsType_Number = 0
			if( us_rootAnalysis.file_upload_delivery !== true ){
				badgeInvisibile = false
				badgeContent++
			}
			if( us_rootAnalysis.file_upload_timecard !== true ){
				badgeInvisibile = false
				badgeContent++
			}
			// JSX
			let buttonJSX =
			<Badge
				color="error"
				invisible={ badgeInvisibile }
				badgeContent={ badgeContent }
				anchorOrigin={{
					vertical: 'top',
					horizontal: 'left',
				}}
			>
				<Button
					color={ selectedColor }
					variant={ selectedVariant }
					onClick={ () => {
						us_setSelectedSection( "file_upload" )
					} }
					disableElevation
					startIcon={ iconJSX }
					className="tw-mr-3 tw-mb-2"
				>
					{ s_FILE_UPLOADS }
				</Button>
			</Badge>
			return buttonJSX
		}

		const returnJSX_NavMappingButton = (): TsType_JSX => {
			// Variant
			let selectedVariant: "outlined" | "contained" = "outlined"
			if( us_selectedSection === "data_mapping" ){
				selectedVariant = "contained"
			}
			// Color
			let selectedColor: TsType_MuiComponentColors = "success"
			// Icon

			// Badge

			// Disabled
			let iconJSX = <Icon icon="badge-check" />
			let disabled: TsType_Boolean = true
			let yellowBadgeCss: TsType_String = ""


			if(
				us_rootAnalysis.file_upload_delivery === true &&
				us_rootAnalysis.file_upload_timecard === true
			){
				disabled = false
			}
			// Badge
			let redBadgeInvisibile: TsType_Boolean = true
			let redBadgeContent: TsType_Number = 0
			let yellowBadgeInvisibile: TsType_Boolean = true
			let yellowBadgeContent: TsType_Number = 0
			if(
				us_mappingMissingCounts.total_red !== true &&
				us_mappingMissingCounts.total_red > 0
			){
				redBadgeInvisibile = false
				redBadgeContent = us_mappingMissingCounts.total_red
				iconJSX = <Icon icon="link" />
				selectedColor = "primary"
				yellowBadgeCss = "#yellowbadge .MuiBadge-badge { left: 18px }"
			}
			if(
				us_mappingMissingCounts.total_yellow !== true &&
				us_mappingMissingCounts.total_yellow > 0
			){
				yellowBadgeInvisibile = false
				yellowBadgeContent = us_mappingMissingCounts.total_yellow
				iconJSX = <Icon icon="link" />
				selectedColor = "primary"
			}
			if( disabled === true ) {
				redBadgeInvisibile = true
				yellowBadgeInvisibile = true
			}
			// JSX
			let buttonJSX =
			<Badge
				color="error"
				invisible={ redBadgeInvisibile }
				badgeContent={ redBadgeContent }
				anchorOrigin={{
					vertical: 'top',
					horizontal: 'left',
				}}
				id="redbadge"
			>
				<Badge
					color="warning"
					invisible={ yellowBadgeInvisibile }
					badgeContent={ yellowBadgeContent }
					anchorOrigin={{
						vertical: 'top',
						horizontal: 'left',
					}}
					id="yellowbadge"
				>
					<Button
						color={ selectedColor }
						variant={ selectedVariant }
						onClick={ () => {
							us_setSelectedSection( "data_mapping" )
						} }
						disableElevation
						disabled={ disabled }
						startIcon={ iconJSX }
						className="tw-mr-3 tw-mb-2"
					>
						{ s_DATA_MAPPING }
					</Button>
					<style>{ yellowBadgeCss }</style>
				</Badge>
			</Badge>
			return buttonJSX
		}

		const returnJSX_NavResultsButton = (): TsType_JSX => {
			// Variant
			let selectedVariant: "outlined" | "contained" = "outlined"
			if( us_selectedSection === "results" ){
				selectedVariant = "contained"
			}
			// Color
			// let selectedColor: TsType_MuiComponentColors = "primary"
			let selectedColor: TsType_MuiComponentColors = "success"
			// Disabled
			let disabled: TsType_Boolean = true
			if(
				us_rootAnalysis.file_upload_delivery === true &&
				us_rootAnalysis.file_upload_timecard === true
			){
				disabled = false
			}
			// JSX
			let buttonJSX =
			<Button
				color={ selectedColor }
				variant={ selectedVariant }
				onClick={ () => {
					us_setSelectedSection( "results" )
				} }
				disabled={ disabled }
				disableElevation
				startIcon={ <Icon icon="chart-simple" /> }
				className="tw-mr-3 tw-mb-2"
			>
				{ s_ANALYSIS_RESULTS }
			</Button>
			return buttonJSX
		}

		const returnJSX_NavUnlockButton = () => {
			let buttonJSX = <></>
			let iconJSX = <Icon icon="unlock" sx={{ fontSize: "18px" }} />
			if( us_lockingAnalysis === true ){
				iconJSX = <Icon icon="arrows-rotate" className="bp_spin" sx={{ fontSize: "18px" }} />
			}
			buttonJSX =
			<MuiTooltip
				title={ s_UNLOCK_ANALYSIS }
				placement="top"
			>
				<Box component="span">
					<Button
						color="warning"
						variant="outlined"
						onClick={ () => {
							unlockAnalysis()
						} }
						disabled={ us_lockingAnalysis === true }
						disableElevation
						className="tw-mr-3 tw-mb-2"
						sx={{ height: "36px" }}
					>
						{ iconJSX }
					</Button>
				</Box>
			</MuiTooltip>
			return buttonJSX
		}

		const returnJSX_NavLockButton = () => {
			let buttonJSX = <></>
			let iconJSX = <Icon icon="lock" sx={{ fontSize: "18px" }} />
			if( us_lockingAnalysis === true ){
				iconJSX = <Icon icon="arrows-rotate" className="bp_spin" sx={{ fontSize: "18px" }} />
			}
			buttonJSX =
			<MuiTooltip
				title={ s_LOCK_ANALYSIS }
				placement="top"
			>
				<Box component="span">
					<Button
						color="warning"
						variant="outlined"
						onClick={ () => {
							lockAnalysis()
						} }
						disabled={ us_lockingAnalysis === true }
						disableElevation
						className="tw-mr-3 tw-mb-2"
						sx={{ height: "36px" }}
					>
						{ iconJSX }
					</Button>
				</Box>
			</MuiTooltip>
			return buttonJSX
		}

		const returnJSX_NavContainer = (): TsType_JSX => {
			let navJSX = <></>
			if(
				us_rootAnalysis != null &&
				us_rootAnalysis.locked === true
			){
				navJSX =
				<Box className="tw-mt-2">
					{ returnJSX_NavResultsButton() }
					{ returnJSX_NavUnlockButton() }
					<Divider className="tw-mt-2" />
				</Box>
			} else {
				navJSX =
				<Box className="tw-mt-2">
					{/* { returnJSX_NavInstructionsButton() } */}
					{ returnJSX_NavFileUploadButton() }
					{ returnJSX_NavResultsButton() }
					{ returnJSX_NavMappingButton() }
					{ returnJSX_NavLockButton() }
					<Divider className="tw-mt-2" />
				</Box>
			}
			return navJSX
		}

		// Instructions
		const returnJSX_InstructionsSection = (): TsType_JSX => {
			let sectionJSX = <></>
			sectionJSX =
			<Box className="tw-mt-2 tw-text-center">
				<Typography variant="h4" className="tw-mb-2">
					TODO - write instructions
				</Typography>
			</Box>
			return sectionJSX
		}

		// File Upload Phase
		const returnJSX_UploadButtonIcon = (): TsType_JSX => {
			let iconJSX = <Icon icon="cloud-arrow-up" className="tw-mr-2" />
			if( us_uploadingData === true ){
				iconJSX = <Icon icon="arrows-rotate" className="bp_spin tw-mr-2"/>
			}
			return iconJSX
		}
		const returnJSX_DeleteButtonIcon = (): TsType_JSX => {
			let iconJSX = <Icon icon="trash" className="tw-mr-2" />
			if( us_deletingData === true ){
				iconJSX = <Icon icon="arrows-rotate" className="bp_spin tw-mr-2"/>
			}
			return iconJSX
		}

		const returnJSX_DeleteFileUploadDataButton = (
			reportKey: "delivery" | "timecard",
			databaseProp: TsType_String
		): TsType_JSX => {
			let buttonJSX = <></>
			buttonJSX =
			<Button
				color="error"
				variant="outlined"
				disableElevation
				className="tw-mr-2 tw-mb-2"
				disabled={ getProp( us_rootAnalysis, "locked", false ) || us_uploadingData === true || us_deletingData === true }
				onClick={ () => {
					wipeReport( reportKey )
				}}
			>
				<Typography variant="body1">
					{ returnJSX_DeleteButtonIcon() }
					{ s_WIPE_UPLOAD }
				</Typography>
			</Button>
			return buttonJSX
		}

		const returnJSX_FileUploadButtonContainer = (
			reportKey: "delivery" | "timecard",
			icon: TsType_String,
			buttonText: TsType_JSX,
			databaseProp: TsType_String
		): TsType_JSX => {
			let buttonJSX = <></>
			if(
				databaseProp != null &&
				us_rootAnalysis != null &&
				us_rootAnalysis[ databaseProp ] === true
			){
				buttonJSX =
				<Grid2 xs={12} sm={6} >
					<Box className="tw-rounded-lg tw-p-4 tw-my-2" sx={{ border: "2px solid " + themeVariables.success_main }}>
						<Typography variant="h5" className="tw-mb-4" sx={{ color: themeVariables.success_main }}>
							{ buttonText }
							<Icon icon="badge-check" className="tw-ml-2" />
						</Typography>
						<Box>
							<Icon
								icon={ icon }
								sx={{ color: themeVariables.success_main, fontSize: "100px" }}
								type="thin"
								className="tw-mb-4"
							/>
						</Box>
						<Box>
							<Button
								color="success"
								variant="outlined"
								disableElevation
								disabled={ true }
								className="tw-mr-2 tw-mb-2"
							>
								<Typography variant="body1">
									<Icon icon="badge-check" className="tw-mr-2" />
									{ buttonText }
								</Typography>
							</Button>
						</Box>
						<Box>
							{ returnJSX_DeleteFileUploadDataButton( reportKey, databaseProp ) }
						</Box>
					</Box>
				</Grid2>
			} else {
				buttonJSX =
				<Grid2 xs={12} sm={6} >
					<Box className="tw-rounded-lg tw-p-4 tw-my-2" sx={{ border: "2px solid " + themeVariables.info_main }}>
						<Typography variant="h5" className="tw-mb-4" sx={{ color: themeVariables.info_main }}>
							{ buttonText }
						</Typography>
						<Box>
							<Icon
								icon={ icon }
								sx={{ color: themeVariables.info_main, fontSize: "100px" }}
								type="thin"
								className="tw-mb-4"
							/>
						</Box>
						<Box>
							<CSVReader
								onUploadAccepted={(results: TsType_Any) => { uploadReport( reportKey, results.data ) }}
								noDrag
							>
								{({
									getRootProps,
									acceptedFile,
									ProgressBar,
									getRemoveFileProps,
								}: TsType_Any) => (
									<Button
										color="info"
										variant="contained"
										disableElevation
										disabled={ getProp( us_rootAnalysis, "locked", false ) || us_uploadingData === true || us_deletingData === true  }
										className="tw-mr-2 tw-mb-2"
										{...getRootProps()}
									>
										<Typography variant="body1">
											{ returnJSX_UploadButtonIcon() }
											{ buttonText }
										</Typography>
									</Button>
								)}
							</CSVReader>
						</Box>
					</Box>
				</Grid2>
			}
			return buttonJSX
		}

		const returnJSX_FileUploadSection = (): TsType_JSX => {
			let sectionJSX = <></>
			sectionJSX =
			<Box className="tw-mt-2 tw-text-center">
				<Box sx={{ maxWidth: "800px" }} className="tw-m-auto">
					<Grid2 container spacing={2}>
						{ returnJSX_FileUploadButtonContainer( "delivery", "box-open", s_DELIVERY_DATA, "file_upload_delivery" ) }
						{ returnJSX_FileUploadButtonContainer( "timecard", "clock", s_TIMECARD_DATA, "file_upload_timecard" ) }
					</Grid2>
				</Box>
			</Box>
			return sectionJSX
		}

		// Data Cleanup
		const returnDefaultMappingDropdownValue = (
			mappingField: TsInterface_UnspecifiedObject,
			fileHeaders: TsInterface_UnspecifiedObject
		): TsType_String => {
			let dropdownValue = ""
			if(
				mappingField != null &&
				mappingField.default_header != null &&
				fileHeaders != null &&
				fileHeaders[ mappingField.default_header ] != null
			){
				dropdownValue = fileHeaders[ mappingField.default_header ]
			}
			return dropdownValue
		}

		const returnJSX_MappingIcon = (
			databaseMappingData: TsInterface_UnspecifiedObject,
			mappingField: TsInterface_UnspecifiedObject,
			fileHeaders: TsInterface_UnspecifiedObject
		): TsType_JSX => {
			let iconJSX = <></>
			if(
				databaseMappingData != null &&
				mappingField != null &&
				mappingField.key != null &&
				databaseMappingData[ mappingField.key ] != null
			){
				iconJSX = <Icon icon="circle-check" sx={{ color: themeVariables.success_main, fontSize: "18px" }} tooltip={ s_MAPPED } tooltipPlacement='right' />
			} else if(
				mappingField != null &&
				mappingField.default_header != null &&
				fileHeaders != null &&
				fileHeaders[ mappingField.default_header ] != null
			){
				iconJSX = <Icon icon="circle-check" sx={{ color: themeVariables.success_main, fontSize: "18px" }} tooltip={ s_MAPPED } tooltipPlacement='right' />
			} else {
				iconJSX = <Icon icon="circle-question" sx={{ color: themeVariables.error_main, fontSize: "18px" }} tooltip={ s_NOT_MAPPED } tooltipPlacement='right' />
			}
			return iconJSX
		}

		const returnJSX_OptionalText = (): TsType_JSX => {
			let missingTextJSX =
			<Box component={'span'} className="tw-opacity-30 tw-italic">
				{ s_OPTIONAL }
			</Box>
			return missingTextJSX
		}

		const returnJSX_RoutePriceMappingCell = (
			route: TsInterface_UnspecifiedObject
		): TsType_JSX => {
			let cellJSX = <></>
			let editIcon =
			<Icon
				icon="pen-to-square"
				className="tw-ml-2 tw-cursor-pointer tw-opacity-30 hover:tw-opacity-100"
				sx={{
					fontSize: "18px",
					'&:hover': {
						color: themeVariables.success_main,
					}
				}}
				tooltip={ s_EDIT }
				tooltipPlacement='right'
				onClick={ () => {
					uc_setUserInterface_PromptDialogDisplay({
						display: true,
						prompt: {
							color: "success",
							confirm_text: s_SAVE,
							default_value: getProp( us_routePricingMappingData, route.key, "" ),
							header: s_EDIT_ROUTE_PRICE,
							icon: <Icon icon="pen-to-square" type="solid" />,
							input_label: s_ROUTE_PRICE,
							input_type: "number",
							text: <></>,
							submit_callback: ( promptValue: TsType_String ) => {
								return new Promise( ( resolve, reject ) => {
									getClientKey( uc_RootData_ClientKey, uc_setRootData_ClientKey ).then(( res_GCK ) => {
										let updateObject = {
											[ route.key ]: parseFloat( promptValue )
										}
										DatabaseSetMergeDocument( DatabaseRef_TimecardAnalysis_RoutePricing_Document( res_GCK.clientKey ), updateObject, {} ).then(( res_DSMD ) => {
											resolve( res_DSMD )
										}).catch( ( rej_DSMD ) => {
											console.error( rej_DSMD )
											reject( rej_DSMD )
										})
									}).catch( ( rej_GCK ) => {
										console.error( rej_GCK )
										reject( rej_GCK )
									})
								})
							}
						}
					})
				}}
			/>
			let deleteIcon =
			<Icon
				icon="square-xmark"
				className="tw-ml-2 tw-cursor-pointer tw-opacity-30 hover:tw-opacity-100"
				sx={{
					fontSize: "18px",
					'&:hover': {
						color: themeVariables.error_main,
					}
				}}
				tooltip={ s_DELETE }
				tooltipPlacement='right'
				onClick={ () => {
					getClientKey( uc_RootData_ClientKey, uc_setRootData_ClientKey ).then(( res_GCK ) => {
						let updateObject = {
							[ route.key ]: DatabaseFieldDelete
						}
						DatabaseUpdateDocument( DatabaseRef_TimecardAnalysis_RoutePricing_Document( res_GCK.clientKey ), updateObject, {} ).then(( res_DUD ) => {
							// Nothing
							// console.log("DONE")
						}).catch( ( rej_DUD ) => {
							console.error( rej_DUD )
						})
					}).catch( ( rej_GCK ) => {
						console.error( rej_GCK )
					})
				}}
			/>
			if(
				us_routePricingMappingData != null &&
				us_routePricingMappingData[ route.key ] != null
			){
				cellJSX =
				<Box>
					<Typography className="tw-inline-block" variant="body1">{ formatCurrency( us_routePricingMappingData[ route.key ] ) }</Typography>
					{ editIcon }
					{ deleteIcon }
				</Box>
			} else {
				cellJSX =
				<Box>
					<Typography className="tw-inline-block" variant="body1">{ returnJSX_OptionalText() }</Typography>
					{ editIcon }
				</Box>
			}
			return cellJSX
		}

		const returnJSX_RoutePriceBasePackageCell = (
			route: TsInterface_UnspecifiedObject
		): TsType_JSX => {
			let cellJSX = <></>
			let editIcon =
			<Icon
				icon="pen-to-square"
				className="tw-ml-2 tw-cursor-pointer tw-opacity-30 hover:tw-opacity-100"
				sx={{
					fontSize: "18px",
					'&:hover': {
						color: themeVariables.success_main,
					}
				}}
				tooltip={ s_EDIT }
				tooltipPlacement='right'
				onClick={ () => {
					uc_setUserInterface_PromptDialogDisplay({
						display: true,
						prompt: {
							color: "success",
							confirm_text: s_SAVE,
							default_value: getProp( us_routePricingMappingData, route.key + "_pkg_base_price", "" ),
							header: s_EDIT_ROUTE_PRICE,
							icon: <Icon icon="pen-to-square" type="solid" />,
							input_label: s_ROUTE_PRICE,
							input_type: "number",
							text: <></>,
							submit_callback: ( promptValue: TsType_String ) => {
								return new Promise( ( resolve, reject ) => {
									getClientKey( uc_RootData_ClientKey, uc_setRootData_ClientKey ).then(( res_GCK ) => {
										let updateObject = {
											[ route.key + "_pkg_base_price" ]: parseFloat( promptValue )
										}
										DatabaseSetMergeDocument( DatabaseRef_TimecardAnalysis_RoutePricing_Document( res_GCK.clientKey ), updateObject, {} ).then(( res_DSMD ) => {
											resolve( res_DSMD )
										}).catch( ( rej_DSMD ) => {
											console.error( rej_DSMD )
											reject( rej_DSMD )
										})
									}).catch( ( rej_GCK ) => {
										console.error( rej_GCK )
										reject( rej_GCK )
									})
								})
							}
						}
					})
				}}
			/>
			let deleteIcon =
			<Icon
				icon="square-xmark"
				className="tw-ml-2 tw-cursor-pointer tw-opacity-30 hover:tw-opacity-100"
				sx={{
					fontSize: "18px",
					'&:hover': {
						color: themeVariables.error_main,
					}
				}}
				tooltip={ s_DELETE }
				tooltipPlacement='right'
				onClick={ () => {
					getClientKey( uc_RootData_ClientKey, uc_setRootData_ClientKey ).then(( res_GCK ) => {
						let updateObject = {
							[ route.key + "_pkg_base_price" ]: DatabaseFieldDelete
						}
						DatabaseUpdateDocument( DatabaseRef_TimecardAnalysis_RoutePricing_Document( res_GCK.clientKey ), updateObject, {} ).then(( res_DUD ) => {
							// Nothing
							// console.log("DONE")
						}).catch( ( rej_DUD ) => {
							console.error( rej_DUD )
						})
					}).catch( ( rej_GCK ) => {
						console.error( rej_GCK )
					})
				}}
			/>
			if(
				us_routePricingMappingData != null &&
				us_routePricingMappingData[ route.key + "_pkg_base_price" ] != null
			){
				cellJSX =
				<Box>
					<Typography className="tw-inline-block" variant="body1">{ formatCurrency( us_routePricingMappingData[ route.key + "_pkg_base_price" ] ) }</Typography>
					{ editIcon }
					{ deleteIcon }
				</Box>
			} else {
				cellJSX =
				<Box>
					<Typography className="tw-inline-block" variant="body1">{ returnJSX_OptionalText() }</Typography>
					{ editIcon }
				</Box>
			}
			return cellJSX
		}

		const returnJSX_RoutePriceBonusPackageCell = (
			route: TsInterface_UnspecifiedObject
		): TsType_JSX => {
			let cellJSX = <></>
			let editIcon =
			<Icon
				icon="pen-to-square"
				className="tw-ml-2 tw-cursor-pointer tw-opacity-30 hover:tw-opacity-100"
				sx={{
					fontSize: "18px",
					'&:hover': {
						color: themeVariables.success_main,
					}
				}}
				tooltip={ s_EDIT }
				tooltipPlacement='right'
				onClick={ () => {
					uc_setUserInterface_PromptDialogDisplay({
						display: true,
						prompt: {
							color: "success",
							confirm_text: s_SAVE,
							default_value: getProp( us_routePricingMappingData, route.key + "_pkg_bonus_price", "" ),
							header: s_EDIT_ROUTE_PRICE,
							icon: <Icon icon="pen-to-square" type="solid" />,
							input_label: s_ROUTE_PRICE,
							input_type: "number",
							text: <></>,
							submit_callback: ( promptValue: TsType_String ) => {
								return new Promise( ( resolve, reject ) => {
									getClientKey( uc_RootData_ClientKey, uc_setRootData_ClientKey ).then(( res_GCK ) => {
										let updateObject = {
											[ route.key + "_pkg_bonus_price" ]: parseFloat( promptValue )
										}
										DatabaseSetMergeDocument( DatabaseRef_TimecardAnalysis_RoutePricing_Document( res_GCK.clientKey ), updateObject, {} ).then(( res_DSMD ) => {
											resolve( res_DSMD )
										}).catch( ( rej_DSMD ) => {
											console.error( rej_DSMD )
											reject( rej_DSMD )
										})
									}).catch( ( rej_GCK ) => {
										console.error( rej_GCK )
										reject( rej_GCK )
									})
								})
							}
						}
					})
				}}
			/>
			let deleteIcon =
			<Icon
				icon="square-xmark"
				className="tw-ml-2 tw-cursor-pointer tw-opacity-30 hover:tw-opacity-100"
				sx={{
					fontSize: "18px",
					'&:hover': {
						color: themeVariables.error_main,
					}
				}}
				tooltip={ s_DELETE }
				tooltipPlacement='right'
				onClick={ () => {
					getClientKey( uc_RootData_ClientKey, uc_setRootData_ClientKey ).then(( res_GCK ) => {
						let updateObject = {
							[ route.key + "_pkg_bonus_price" ]: DatabaseFieldDelete
						}
						DatabaseUpdateDocument( DatabaseRef_TimecardAnalysis_RoutePricing_Document( res_GCK.clientKey ), updateObject, {} ).then(( res_DUD ) => {
							// Nothing
							// console.log("DONE")
						}).catch( ( rej_DUD ) => {
							console.error( rej_DUD )
						})
					}).catch( ( rej_GCK ) => {
						console.error( rej_GCK )
					})
				}}
			/>
			if(
				us_routePricingMappingData != null &&
				us_routePricingMappingData[ route.key + "_pkg_bonus_price" ] != null
			){
				cellJSX =
				<Box>
					<Typography className="tw-inline-block" variant="body1">{ formatCurrency( us_routePricingMappingData[ route.key + "_pkg_bonus_price" ] ) }</Typography>
					{ editIcon }
					{ deleteIcon }
				</Box>
			} else {
				cellJSX =
				<Box>
					<Typography className="tw-inline-block" variant="body1">{ returnJSX_OptionalText() }</Typography>
					{ editIcon }
				</Box>
			}
			return cellJSX
		}

		const returnJSX_DeliveryHeaderMappingTab = (): TsType_JSX => {
			let tabJSX =
			<Box>
				<Card>
					<TableContainer>
						<Table size="small">
							<TableBody>
								{objectToArray( defaultDeliveryReportMappingData ).map(( mappingField: TsInterface_UnspecifiedObject, mappingFieldIndex: TsType_Number ) => (
									<TableRow key={ mappingFieldIndex }>
										<TableCell sx={{ width: "32px" }}>
											{ returnJSX_MappingIcon( us_deliveryReportMappingData, mappingField, us_deliveryFileHeaders, ) }
										</TableCell>
										<TableCell>
											<Typography variant="body1">{ mappingField.name }</Typography>
										</TableCell>
										<TableCell>
											<FormControl className="bp_thin_select_input tw-mr-2">
												<Select
													color="primary"
													value={ getProp(us_deliveryReportMappingData, mappingField.key, returnDefaultMappingDropdownValue( mappingField, us_deliveryFileHeaders ) ) }
													onChange={ (event: TsType_Any) => {
														if ( event != null && event.target != null && event.target.value != null ){
															getClientKey( uc_RootData_ClientKey, uc_setRootData_ClientKey ).then(( res_GCK ) => {
																let updateObject = {
																	[ mappingField.key ]: event.target.value
																}
																DatabaseSetMergeDocument( DatabaseRef_TimecardAnalysis_DeliveryReportColumnMapping_Document( res_GCK.clientKey ), updateObject, {} ).then(( res_DSMD ) => {
																	// Done
																}).catch( ( rej_DSMD ) => {
																	console.error( rej_DSMD )
																})
															}).catch( ( rej_GCK ) => {
																console.error( rej_GCK )
															})
														}
													} }
													variant="outlined"
												>
													{objectToArray( us_deliveryFileHeaders ).sort().map(( option: TsType_String, index: TsType_Number ) => (
														<MenuItem key={ index } value={ option } >
															{ option }
														</MenuItem>
													))}
												</Select>
											</FormControl>
										</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
					</TableContainer>
				</Card>
			</Box>
			return tabJSX
		}

		const returnJSX_nameMappingTypeRadioGroup = (): TsType_JSX => {
			let nameMappingType = null
			if(
				us_rootAnalysis != null &&
				us_rootAnalysis.name_mapping_type != null
			){
				nameMappingType = us_rootAnalysis.name_mapping_type
			} else if(
				us_timecardReportMappingData != null &&
				us_timecardReportMappingData.name_mapping_type != null
			){
				nameMappingType = us_timecardReportMappingData.name_mapping_type
			}
			let radioGroupJSX =
			<FormControl fullWidth>
				<RadioGroup
					onChange={ (event, value) => {
						if ( value != null ){
							getClientKey( uc_RootData_ClientKey, uc_setRootData_ClientKey ).then(( res_GCK ) => {
								let updateObject = {
									name_mapping_type: value
								}
								let updateArray: TsInterface_DatabaseBatchUpdatesArray = [
									{
										type: "setMerge",
										ref: DatabaseRef_TimecardAnalysis_Document( res_GCK.clientKey, analysisKey ),
										data: updateObject
									},
									{
										type: "setMerge",
										ref: DatabaseRef_TimecardAnalysis_TimecardReportColumnMapping_Document( res_GCK.clientKey ),
										data: updateObject
									}
								]
								DatabaseBatchUpdate( updateArray, {} ).then(( res_DBU ) => {
									// Done
								}).catch(( rej_DBU ) => {
									uc_setUserInterface_ErrorDialogDisplay( { display: true, error: rej_DBU.error } )
								})
							}).catch( ( rej_GCK ) => {
								console.error( rej_GCK )
							})
						}
					} }
					value={ nameMappingType }
					sx={{ display: "block" }}
				>
					<FormControlLabel
						sx={{ display: "flex" }}
						control={<Radio />}
						label={ s_FULL_NAME_IN_A_SINGLE_COLUMN }
						value={ "full_name_in_a_single_column" }
					/>
					<FormControlLabel
						sx={{ display: "flex" }}
						control={<Radio />}
						label={ s_FIRST_AND_LAST_NAMES_IN_DIFFERENT_COLUMNS }
						value={ "first_and_last_name_in_different_columns" }
					/>
				</RadioGroup>
			</FormControl>
			return radioGroupJSX
		}

		const returnJSX_TimecardHeaderMappingTab = (): TsType_JSX => {
			// Determine Name Mapping Type
			let tableColumns = defaultTimecardReportMappingData
			let nameMappingType = "full_name_in_a_single_column"
			if(
				us_rootAnalysis != null &&
				us_rootAnalysis.name_mapping_type != null
			){
				nameMappingType = us_rootAnalysis.name_mapping_type
			} else if(
				us_timecardReportMappingData != null &&
				us_timecardReportMappingData.name_mapping_type != null
			){
				nameMappingType = us_timecardReportMappingData.name_mapping_type
			}
			if( nameMappingType === "full_name_in_a_single_column" ){
				tableColumns = defaultTimecardReportMappingData
			} else if( nameMappingType === "first_and_last_name_in_different_columns" ){
				tableColumns = firstAndLastNameTimecardReportMappingData
			} else {
				tableColumns = defaultTimecardReportMappingData
			}
			// End Determine Name Mapping Type
			let tabJSX =
			<Box>
				<Card>
					<TableContainer>
						<Table size="small">
							<TableBody>
								{objectToArray( tableColumns ).map(( mappingField: TsInterface_UnspecifiedObject, mappingFieldIndex: TsType_Number ) => (
									<TableRow key={ mappingFieldIndex }>
										<TableCell sx={{ width: "32px" }}>
											{ returnJSX_MappingIcon( us_timecardReportMappingData, mappingField, us_timecardFileHeaders, ) }
										</TableCell>
										<TableCell>
											<Typography variant="body1">{ mappingField.name }</Typography>
										</TableCell>
										<TableCell>
											<FormControl className="bp_thin_select_input tw-mr-2">
												<Select
													color="primary"
													value={ getProp(us_timecardReportMappingData, mappingField.key, returnDefaultMappingDropdownValue( mappingField, us_timecardFileHeaders ) ) }
													onChange={ (event: TsType_Any) => {
														if ( event != null && event.target != null && event.target.value != null ){
															getClientKey( uc_RootData_ClientKey, uc_setRootData_ClientKey ).then(( res_GCK ) => {
																let updateObject = {
																	[ mappingField.key ]: event.target.value
																}
																DatabaseSetMergeDocument( DatabaseRef_TimecardAnalysis_TimecardReportColumnMapping_Document( res_GCK.clientKey ), updateObject, {} ).then(( res_DSMD ) => {
																	// Done
																}).catch( ( rej_DSMD ) => {
																	console.error( rej_DSMD )
																})
															}).catch( ( rej_GCK ) => {
																console.error( rej_GCK )
															})
														}
													} }
													variant="outlined"
												>
													{objectToArray( us_timecardFileHeaders ).sort().map(( option: TsType_String, index: TsType_Number ) => (
														<MenuItem key={ index } value={ option } >
															{ option }
														</MenuItem>
													))}
												</Select>
											</FormControl>
										</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
					</TableContainer>
				</Card>
				<Box className="tw-mt-2 tw-ml-4">
					{ returnJSX_nameMappingTypeRadioGroup() }
				</Box>
			</Box>
			return tabJSX
		}

		const returnJSX_UserMappingIcon = (
			timecardEmployee: TsType_String
		): TsType_JSX => {
			let iconJSX = <></>
			if(
				timecardEmployee != null &&
				us_userMappingData != null &&
				us_userMappingData[ timecardEmployee ] != null
			){
				if(
					us_deliveryDrivers != null &&
					us_deliveryDrivers[ timecardEmployee ] != null
				){
					// Linked and in the timecard data set
					iconJSX = <Icon icon="circle-check" sx={{ color: themeVariables.success_main, fontSize: "18px" }} tooltip={ s_MAPPED } tooltipPlacement='right' />
				} else {
					// Linked and not in the timescard data set
					iconJSX = <Icon icon="circle-check" sx={{ color: themeVariables.warning_main, fontSize: "18px" }} tooltip={ s_MAPPED } tooltipPlacement='right' />
				}
			} else if(
				timecardEmployee != null &&
				us_deliveryDrivers != null &&
				us_deliveryDrivers[ timecardEmployee ] != null
			){
				// Not linked but in the timecard data set
				iconJSX = <Icon icon="circle-check" sx={{ color: themeVariables.success_main, fontSize: "18px" }} tooltip={ s_MAPPED } tooltipPlacement='right' />
			} else {
				// Not linked and no identical match in timecard data set
				iconJSX = <Icon icon="circle-question" sx={{ color: themeVariables.error_main, fontSize: "18px" }} tooltip={ s_NOT_MAPPED } tooltipPlacement='right' />
			}
			return iconJSX
		}

		const returnJSX_DriverLinkingSelect = (
			timecardEmployee: TsType_String
		): TsType_JSX => {
			let selectJSX = <></>
			let deleteAssociationJSX = <></>
			// Determine Select Value
			let selectValue = ""
			// let disabledSelect: TsType_Boolean = false
			if(
				timecardEmployee != null &&
				us_userMappingData != null &&
				us_userMappingData[ timecardEmployee ] != null
			){
				deleteAssociationJSX =
				<Box className="tw-mt-1 tw-inline-block">
					<Icon
						icon="square-xmark"
						sx={{
							color: themeVariables.gray_300,
							"&:hover": {
								color: themeVariables.error_main,
							},
							fontSize: "28px"
						}}
						className="tw-cursor-pointer"
						tooltip={ s_UNLINK }
						tooltipPlacement='right'
						onClick={ () => {
							getClientKey( uc_RootData_ClientKey, uc_setRootData_ClientKey ).then(( res_GCK ) => {
								let updateObject = {
									[ timecardEmployee ]: DatabaseFieldDelete
								}
								DatabaseUpdateDocument( DatabaseRef_TimecardAnalysis_UserMapping_Document( res_GCK.clientKey ), updateObject, {} ).then(( res_DUD ) => {
									// Done
								}).catch( ( rej_DUD ) => {
									console.error( rej_DUD )
								})
							}).catch( ( rej_GCK ) => {
								console.error( rej_GCK )
							})
						}}
					/>
				</Box>
				if(
					us_deliveryDrivers != null &&
					us_deliveryDrivers[ timecardEmployee ] != null
				){
					// Linked and in the timecard data set
					selectValue = us_userMappingData[ timecardEmployee ]
					// disabledSelect = true
				} else {
					// Linked and not in the timescard data set
					selectValue = us_userMappingData[ timecardEmployee ]
					// selectValue = ""
				}
			} else if(
				timecardEmployee != null &&
				us_deliveryDrivers != null &&
				us_deliveryDrivers[ timecardEmployee ] != null
			){
				// Not linked but in the timecard data set
				selectValue = us_deliveryDrivers[ timecardEmployee ]
			} else {
				// Not linked and no identical match in timecard data set
				selectValue = ""
			}

			// JSX
			selectJSX =
			<Box>
				<FormControl className="bp_thin_select_input tw-mr-2">
					<Select
						color="primary"
						value={ selectValue }
						onChange={ (event: TsType_Any) => {
							if ( event != null && event.target != null && event.target.value != null ){
								getClientKey( uc_RootData_ClientKey, uc_setRootData_ClientKey ).then(( res_GCK ) => {
									let updateObject = {
										[ timecardEmployee ]: event.target.value
									}
									DatabaseSetMergeDocument( DatabaseRef_TimecardAnalysis_UserMapping_Document( res_GCK.clientKey ), updateObject, {} ).then(( res_DUD ) => {
										// Done
									}).catch( ( rej_DSMD ) => {
										console.error( rej_DSMD )
									})
								}).catch( ( rej_GCK ) => {
									console.error( rej_GCK )
								})
							}
						} }
						variant="outlined"
					>
						{objectToArray( us_deliveryDrivers ).sort().map(( deliveryDriver: TsType_String, deliveryDriverIndex: TsType_Number ) => (
							<MenuItem key={ deliveryDriverIndex } value={ deliveryDriver } >
								{ returnJSX_HighlightedSearchString( timecardEmployee, deliveryDriver ) }
							</MenuItem>
						))}
					</Select>
				</FormControl>
				{ deleteAssociationJSX }
			</Box>
			return selectJSX
		}

		const returnJSX_DriverLinkingTab = (): TsType_JSX => {
			let unmappedDriversBannerJSX = <></>
			if( us_unmappedDrivers.length > 0 ){
				unmappedDriversBannerJSX =
				<Box className="tw-p-2 tw-my-2 tw-rounded-lg" sx={{ background: themeVariables.error_main, color: themeVariables.white }}>
					<Typography variant="body1">
						<Box component="span" className="tw-font-bold tw-mr-2">
							{ us_unmappedDrivers.length } { s_DRIVERS_NOT_LINKED_TO_PAYROLL }:
						</Box>
						<Box component="span" className="tw-opacity-60">
							{ us_unmappedDrivers.join(", ") }
						</Box>
					</Typography>
				</Box>
			}
			let tabJSX =
			<Box>
				{ unmappedDriversBannerJSX }
				<Card className="tw-mb-2">
					<TableContainer>
						<Table size="small">
							<TableBody>
								<TableRow>
									<TableCell sx={{ width: "32px" }}></TableCell>
									<TableCell>
										<Typography variant="body1" className="tw-opacity-40">{ s_TIMECARD_EMPLOYEES }</Typography>
									</TableCell>
									<TableCell>
										<Typography variant="body1" className="tw-opacity-40">{ s_DELIVERY_DRIVERS }</Typography>
									</TableCell>
								</TableRow>
								{objectToArray( us_timecardEmployees ).sort().map(( timecardEmployee: TsType_String, timecardEmployeeIndex: TsType_Number ) => (
									<TableRow key={ timecardEmployeeIndex }>
										<TableCell sx={{ width: "32px" }}>
											{ returnJSX_UserMappingIcon( timecardEmployee ) }
										</TableCell>
										<TableCell>
											<Typography variant="body1">{ timecardEmployee }</Typography>
										</TableCell>
										<TableCell>
											{ returnJSX_DriverLinkingSelect( timecardEmployee ) }
										</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
					</TableContainer>
				</Card>
			</Box>
			return tabJSX
		}

		const returnJSX_PaycodeMultipliersTab = (): TsType_JSX => {
			let tabJSX =
			<Box>
				<Card>
					<TableContainer>
						<Table size="small">
							<TableBody>
								<TableRow>
									<TableCell sx={{ width: "32px" }}></TableCell>
									<TableCell>
										{ s_PAYCODE }
									</TableCell>
									<TableCell>
										{ s_WAGE_MULTIPLIER }
									</TableCell>
									<TableCell>
										{ s_INCLUDE_IN_HOUR_DISCREPANCY_ANALYSIS }
									</TableCell>
								</TableRow>
								{objectToArray( us_payCodesList ).map(( paycode: TsType_String, paycodeIndex: TsType_Number ) => (
									<TableRow key={ paycodeIndex }>
										<TableCell sx={{ width: "32px" }}>
											<Icon icon="circle-check" sx={{ color: themeVariables.success_main, fontSize: "18px" }} tooltip={ s_MAPPED } tooltipPlacement='right' />
										</TableCell>
										<TableCell>
											<Typography variant="body1">{ paycode }</Typography>
										</TableCell>
										<TableCell>
											<FormControl className="bp_thin_select_input tw-mr-2">
												<Select
													color="primary"
													value={ getProp(us_paycodeMultiplierMappingData, paycode, 1) }
													onChange={ (event: TsType_Any) => {
														if ( event != null && event.target != null && event.target.value != null ){
															getClientKey( uc_RootData_ClientKey, uc_setRootData_ClientKey ).then(( res_GCK ) => {
																let updateObject = {
																	[ paycode ]: event.target.value
																}
																DatabaseSetMergeDocument( DatabaseRef_TimecardAnalysis_TimecardReportPaycodeMultipliers_Document( res_GCK.clientKey ), updateObject, {} ).then(( res_DSMD ) => {
																	// Done
																}).catch( ( rej_DSMD ) => {
																	console.error( rej_DSMD )
																})
															}).catch( ( rej_GCK ) => {
																console.error( rej_GCK )
															})
														}
													} }
													variant="outlined"
												>
													<MenuItem key={ 1 } value={ 1 } >1</MenuItem>
													<MenuItem key={ 1.5 } value={ 1.5 } >1.5</MenuItem>
												</Select>
											</FormControl>
										</TableCell>
										<TableCell>
											<Switch
												color="info"
												checked={ getProp(us_paycodeMultiplierMappingData, "EXCLUDE_FROM_ANALYSIS_" + paycode, true) }
												onChange={ (event, value) => {
													let updateObject: TsInterface_UnspecifiedObject = {}
													if ( value === true ){
														updateObject[ "EXCLUDE_FROM_ANALYSIS_" + paycode ] = true

													} else if( value === false ){
														updateObject[ "EXCLUDE_FROM_ANALYSIS_" + paycode ] = false
													}
													getClientKey( uc_RootData_ClientKey, uc_setRootData_ClientKey ).then(( res_GCK ) => {
														DatabaseSetMergeDocument( DatabaseRef_TimecardAnalysis_TimecardReportPaycodeMultipliers_Document( res_GCK.clientKey ), updateObject, {} ).then(( res_DSMD ) => {
															// Done
														}).catch( ( rej_DSMD ) => {
															console.error( rej_DSMD )
														})
													}).catch( ( rej_GCK ) => {
														console.error( rej_GCK )
													})
												} }
											/>
											<Box className="tw-inline-block">
												{ getProp(us_paycodeMultiplierMappingData, "EXCLUDE_FROM_ANALYSIS_" + paycode, true) ? s_WORK_HOURS : s_EXCLUDED_HOURS }
											</Box>
										</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
					</TableContainer>
				</Card>
			</Box>
			return tabJSX
		}

		const returnJSX_RouteRevenueMappingTab = (): TsType_JSX => {
			let tabJSX =
			<Box>
				<Card>
					<TableContainer>
						<Table size="small">
							<TableBody>
								<TableRow>
									<TableCell sx={{ width: "32px" }}></TableCell>
									<TableCell className="tw-opacity-40">{ s_STATION }</TableCell>
									<TableCell className="tw-opacity-40">{ s_DURATION }</TableCell>
									<TableCell className="tw-opacity-40">{ s_ROUTE }</TableCell>
									<TableCell className="tw-opacity-40">{ s_ROUTE_REVENUE }</TableCell>
									<TableCell className="tw-opacity-40">{ s_PACKAGE_BASE_RATE }</TableCell>
									<TableCell className="tw-opacity-40">{ s_PACKAGE_BONUS_RATE }</TableCell>
								</TableRow>
								{objectToArray( us_deliveryRouteTypes ).sort( dynamicSort( "route_type", "asc" ) ).map(( route: TsInterface_UnspecifiedObject, routeIndex: TsType_Number ) => (
									<TableRow key={ routeIndex }>
										<TableCell sx={{ width: "32px" }}>
											{
												getProp( us_routePricingMappingData, route.key, null ) != null ?
												<Icon icon="circle-check" sx={{ color: themeVariables.success_main, fontSize: "18px" }} tooltip={ s_MAPPED } tooltipPlacement='right' /> :
												<Icon icon="circle-question" sx={{ color: themeVariables.warning_main, fontSize: "18px" }} tooltip={ s_MAPPED } tooltipPlacement='right' />
											}
										</TableCell>
										<TableCell>
											<Typography variant="body1">{ route.station }</Typography>
										</TableCell>
										<TableCell>
											<Typography variant="body1">{ route.duration }</Typography>
										</TableCell>
										<TableCell>
											<Typography variant="body1">{ route.route_type }</Typography>
										</TableCell>
										<TableCell>
											{ returnJSX_RoutePriceMappingCell( route ) }
										</TableCell>
										<TableCell>
											{ returnJSX_RoutePriceBasePackageCell( route ) }
										</TableCell>
										<TableCell>
											{ returnJSX_RoutePriceBonusPackageCell( route ) }
										</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
					</TableContainer>
				</Card>
			</Box>
			return tabJSX
		}

		const returnJSX_DataMappingSection = (): TsType_JSX => {
			let sectionJSX = <></>
			sectionJSX =
			<Box>
				<TabsBasic
					tabsSettings={ {} }
					tabs={[
						{
						tabHeader:
							<Box>
								<Badge
									color="error"
									variant="dot"
									invisible={ us_mappingMissingCounts["delivery_headers"] === 0 }
									anchorOrigin={{
										vertical: 'top',
										horizontal: 'left',
									}}
								>
									{ s_DELIVERY_HEADERS }
								</Badge>
							</Box>,
							tabContent: returnJSX_DeliveryHeaderMappingTab()
						},
						{
							tabHeader:
							<Box>
								<Badge
									color="error"
									variant="dot"
									invisible={ us_mappingMissingCounts["timecard_headers"] === 0 }
									anchorOrigin={{
										vertical: 'top',
										horizontal: 'left',
									}}
								>
									{ s_TIMECARD_HEADERS }
								</Badge>
							</Box>,
							tabContent: returnJSX_TimecardHeaderMappingTab()
						},
						{
							tabHeader:
							<Box>
								<Badge
									color="error"
									variant="dot"
									invisible={ us_mappingMissingCounts["driver_linking"] === 0 }
									anchorOrigin={{
										vertical: 'top',
										horizontal: 'left',
									}}
								>
									{ s_DRIVER_LINKING }
								</Badge>
							</Box>,
							tabContent: returnJSX_DriverLinkingTab()
						},
						{
							tabHeader:
							<Box>
								<Badge
									color="error"
									variant="dot"
									invisible={ us_mappingMissingCounts["paycode_multipiers"] === 0 }
									anchorOrigin={{
										vertical: 'top',
										horizontal: 'left',
									}}
								>
									{ s_PAYCODE_MULTIPLIERS }
								</Badge>
							</Box>,
							tabContent: returnJSX_PaycodeMultipliersTab()
						},
						{
							tabHeader:
							<Box>
								<Badge
									color="warning"
									variant="dot"
									invisible={ us_mappingMissingCounts["route_revenue"] === 0 }
									anchorOrigin={{
										vertical: 'top',
										horizontal: 'left',
									}}
								>
									{ s_ROUTE_REVENUE }
								</Badge>
							</Box>,
							tabContent: returnJSX_RouteRevenueMappingTab()
						},
					]}
				/>
			</Box>
			return sectionJSX
		}

		// Ready for Review
		const returnJSX_ResultsSection = (): TsType_JSX => {
			let sectionJSX = <></>
			let contentJSX = <></>
			switch( us_selectedAnalysisPage ){
				case "main_summary": 				contentJSX = returnJSX_AnaylysisResults_HoursSummary(); break
				case "route_revenue":				contentJSX = returnJSX_AnaylysisResults_RouteRevenue(); break
				case "payroll_expenses":			contentJSX = returnJSX_AnaylysisResults_PayrollExpenses(); break
				case "full_data_table":				contentJSX = returnJSX_AnaylysisResults_FullDataTable(); break
				case "driver_notes":				contentJSX = returnJSX_AnaylysisResults_DriverNotesTable(); break
				case "delivery_upload_file":		contentJSX = returnJSX_AnaylysisResults_DeliveryUploadFile(); break
				case "payroll_upload_file":			contentJSX = returnJSX_AnaylysisResults_PayrollUploadFile(); break
				case "data_errors":					contentJSX = returnJSX_AnaylysisResults_DataErrors(); break
			}
			sectionJSX =
			<Box>
				<Box className="tw-my-2">
					{ returnJSX_AnalysisSelectionDropdown() }
					{ returnJSX_AnalysisDateSelectionDropdown() }
				</Box>
				<Box className="tw-my-2">
					{ returnJSX_ErrorBanner() }
				</Box>
				<Box>
					{ contentJSX }
				</Box>
			</Box>
			return sectionJSX
		}

		const returnJSX_AnalysisSelectionDropdown = (): TsType_JSX => {
			let dropdownJSX =
			<FormControl className="bp_thin_select_input tw-mr-2">
				<Select
					color="primary"
					value={ us_selectedAnalysisPage }
					onChange={ (event: TsType_Any) => {
						if ( event != null && event.target != null && event.target.value != null ){
							us_setSelectedAnalysisPage( event.target.value )
						}
					} }
					variant="outlined"
				>
					{objectToArray( analysisPageOptions ).sort().map(( option: TsInterface_UnspecifiedObject, index: TsType_Number ) => (
						<MenuItem key={ index } value={ option.key } >
							{ option.name }
						</MenuItem>
					))}
				</Select>
			</FormControl>
			return dropdownJSX
		}

		const returnJSX_AnalysisDateSelectionDropdown = (): TsType_JSX => {
			let dropdownJSX = <></>
			if(
				analysisPageOptions != null &&
				analysisPageOptions[ us_selectedAnalysisPage ] != null &&
				analysisPageOptions[ us_selectedAnalysisPage ].show_date_dropdown === true
			){
				dropdownJSX =
				<FormControl className="bp_thin_select_input tw-mr-2">
					<Select
						color="primary"
						value={ us_selectedDateFilter }
						onChange={ (event: TsType_Any) => {
							if ( event != null && event.target != null && event.target.value != null ){
								us_setSelectedDateFilter( event.target.value )
							}
						} }
						variant="outlined"
					>
						{objectToArray( us_dateDropdownOptions ).sort( dynamicSort("sort", "asc") ).map(( option: TsInterface_UnspecifiedObject, index: TsType_Number ) => (
							<MenuItem key={ index } value={ option.key } >
								{ option.value }
							</MenuItem>
						))}
					</Select>
				</FormControl>
			}
			return dropdownJSX
		}

		const returnJSX_ErrorBanner = (): TsType_JSX => {
			let bannerJSX = <></>

			// TODO - error banner from us_analysisErrorData

			return bannerJSX
		}

		// Individual Analysis Pages
		const returnJSX_HoursScatterPlot = (): TsType_JSX => {
			let scatterPlotJSX = <></>
			if(
				us_summaryData != null &&
				us_summaryData.scatterplot_data != null
			){
				scatterPlotJSX =
				<Card className="tw-mt-2">
					<Scatter options={ scatterPlotOptions } data={ us_summaryData.scatterplot_data } />
				</Card>
			}
			return scatterPlotJSX
		}

		const returnJSX_HourColorBarChart = (): TsType_JSX => {
			let barChartJSX = <></>
			if( us_summaryData != null ){
				let totalEmployees = us_summaryData.green_hour_employees + us_summaryData.yellow_hour_employees + us_summaryData.red_hour_employees + us_summaryData.dark_red_hour_employees
				barChartJSX =
				<Box className="tw-mt-2">
					<Box sx={{ width: "100%", height: "40px", minWidth: "300px"}} className="tw-rounded-lg tw-overflow-auto">
						<Stack direction="row">
							<MuiTooltip title={ <>{ getProp(us_summaryData, "green_hour_employees", 0) } { s_EMPLOYEES } </> } placement="top">
								<Box
									className="tw-text-center tw-py-2"
									sx={{
										color: themeVariables.white,
										background: themeVariables.success_main,
										width: (getProp(us_summaryData, "green_hour_employees", 0) / totalEmployees * 100) + "%",
										height: "40px"
								}}>
									{ (getProp(us_summaryData, "green_hour_employees", 0) / totalEmployees * 100).toFixed(1) + "%" }
								</Box>
							</MuiTooltip>
							<MuiTooltip title={ <>{ getProp(us_summaryData, "yellow_hour_employees", 0) } { s_EMPLOYEES } </> } placement="top">
								<Box
									className="tw-text-center tw-py-2"
									sx={{
										color: themeVariables.white,
										background: themeVariables.warning_main,
										width: (getProp(us_summaryData, "yellow_hour_employees", 0) / totalEmployees * 100) + "%",
										height: "40px"
								}}>
									{ (getProp(us_summaryData, "yellow_hour_employees", 0) / totalEmployees * 100).toFixed(1) + "%" }
								</Box>
							</MuiTooltip>
							<MuiTooltip title={ <>{ getProp(us_summaryData, "red_hour_employees", 0) } { s_EMPLOYEES } </> } placement="top">
								<Box
									className="tw-text-center tw-py-2"
									sx={{
										color: themeVariables.white,
										background: themeVariables.error_main,
										width: (getProp(us_summaryData, "red_hour_employees", 0) / totalEmployees * 100) + "%",
										height: "40px"
								}}>
									{ (getProp(us_summaryData, "red_hour_employees", 0) / totalEmployees * 100).toFixed(1) + "%" }
								</Box>
							</MuiTooltip>
							<MuiTooltip title={ <>{ getProp(us_summaryData, "dark_red_hour_employees", 0) } { s_EMPLOYEES } </> } placement="top">
								<Box
									className="tw-text-center tw-py-2"
									sx={{
										color: themeVariables.white,
										background: darkRedColor,
										width: (getProp(us_summaryData, "dark_red_hour_employees", 0) / totalEmployees * 100) + "%",
										height: "40px"
								}}>
									{ (getProp(us_summaryData, "dark_red_hour_employees", 0) / totalEmployees * 100).toFixed(1) + "%" }
								</Box>
							</MuiTooltip>
						</Stack>
					</Box>
				</Box>
			}
			return barChartJSX
		}

		const returnJSX_DateRange = (
			text: TsType_JSX,
			startDate: TsType_String | TsType_Null,
			endDate: TsType_String | TsType_Null
		): TsType_JSX => {
			let dateRangeJSX = <></>
			if(
				startDate != null &&
				endDate != null
			){
				dateRangeJSX =
				<Box className="tw-text-right tw-opacity-30">
					{ text }: { startDate } - { endDate }
				</Box>
			}
			return dateRangeJSX
		}

		const returnJSX_AnaylysisResults_HoursSummary = (): TsType_JSX => {
			let resultsJSX = <></>
			// Dates
			let dateRangeJSX = <></>
			dateRangeJSX =
			<Box className="tw-text-right tw-mb-2">
				{ returnJSX_DateRange( s_DELIVERY_DATE_RANGE, us_dataFileDates.deliveryFirstDate, us_dataFileDates.deliveryLastDate ) }
				{ returnJSX_DateRange( s_PAYROLL_DATE_RANGE, us_dataFileDates.timecardFirstDate, us_dataFileDates.timecardLastDate ) }
			</Box>
			// Numbers
			let numbersJSX =
			<Grid2 container spacing={1}>
				<Grid2 xs={6} sm={4} md={4} >
					<Box className="tw-text-center tw-p-1 tw-rounded-xl" sx={{ borderStyle: "solid", borderWidth: "2px", color: themeVariables.info_main, borderColor: themeVariables.info_main }}>
						<Typography sx={{ fontSize: "32px" }}>{ getProp( us_summaryData, "total_routes", 0 ) }</Typography>
						<Typography variant="body1">{ s_NUMBER_OF_ROUTES_RUN }</Typography>
					</Box>
				</Grid2>
				<Grid2 xs={6} sm={4} md={4} >
					<Box className="tw-text-center tw-p-1 tw-rounded-xl" sx={{ borderStyle: "solid", borderWidth: "2px", color: themeVariables.success_main, borderColor: themeVariables.success_main }}>
						<Typography sx={{ fontSize: "32px" }}>{ getProp( us_summaryData, "total_employees_with_routes", 0 ) }</Typography>
						<Typography variant="body1">{ s_EMPLOYEES_WITH_ROUTES }</Typography>
					</Box>
				</Grid2>
				<Grid2 xs={6} sm={4} md={4} >
					<Box className="tw-text-center tw-p-1 tw-rounded-xl" sx={{ borderStyle: "solid", borderWidth: "2px", color: themeVariables.warning_dark, borderColor: themeVariables.warning_dark }}>
						<Typography sx={{ fontSize: "32px" }}>{ getProp( us_summaryData, "total_employess_with_work_hours", 0 ) }</Typography>
						<Typography variant="body1">{ s_EMPLOYEES_WITH_WORK_HOURS }</Typography>
					</Box>
				</Grid2>
				<Grid2 xs={6} sm={4} md={4} >
					<Box className="tw-text-center tw-p-1 tw-rounded-xl" sx={{ borderStyle: "solid", borderWidth: "2px", color: themeVariables.gray_500, borderColor: themeVariables.gray_500 }}>
						<Typography sx={{ fontSize: "32px" }}>{ getProp( us_summaryData, "total_employess_with_excluded_hours", 0 ) }</Typography>
						<Typography variant="body1">{ s_EMPLOYEES_WITH_EXCLUDED_HOURS }</Typography>
					</Box>
				</Grid2>
				<Grid2 xs={6} sm={4} md={4} >
					<Box className="tw-text-center tw-p-1 tw-rounded-xl" sx={{ borderStyle: "solid", borderWidth: "2px", color: themeVariables.error_main, borderColor: themeVariables.error_main }}>
						<Typography sx={{ fontSize: "32px" }}>{ getProp( us_summaryData, "total_employess_with_work_hours_and_no_routes", 0 ) }</Typography>
						<Typography variant="body1">{ s_EMPLOYEES_WITH_WORK_HOURS_AND_NO_ROUTES }</Typography>
					</Box>
				</Grid2>
				<Grid2 xs={6} sm={4} md={4} >
					<Box className="tw-text-center tw-p-1 tw-rounded-xl" sx={{ borderStyle: "solid", borderWidth: "2px", color: themeVariables.success_main, borderColor: themeVariables.success_main }}>
						<Typography sx={{ fontSize: "32px" }}>{ getProp( us_summaryData, "total_expected_route_hours", 0 ).toFixed(1) }</Typography>
						<Typography variant="body1">{ s_TOTAL_EXPECTED_ROUTE_HOURS }</Typography>
					</Box>
				</Grid2>
				<Grid2 xs={6} sm={4} md={4} >
					<Box className="tw-text-center tw-p-1 tw-rounded-xl" sx={{ borderStyle: "solid", borderWidth: "2px", color: themeVariables.warning_dark, borderColor: themeVariables.warning_dark }}>
						<Typography sx={{ fontSize: "32px" }}>{ getProp( us_summaryData, "total_included_work_hours", 0 ).toFixed(1) }</Typography>
						<Typography variant="body1">{ s_TOTAL_WORK_HOURS }</Typography>
					</Box>
				</Grid2>
				<Grid2 xs={6} sm={4} md={4} >
					<Box
						className="tw-text-center tw-p-1 tw-rounded-xl"
						sx={{
							borderStyle: "solid",
							borderWidth: "2px",
							color: ( getProp( us_summaryData, "total_work_minus_expected_route_hours", 0) > 0 ? themeVariables.error_dark: themeVariables.success_main ),
							borderColor: ( getProp( us_summaryData, "total_work_minus_expected_route_hours", 0) > 0 ? themeVariables.error_dark: themeVariables.success_main )
						}}
					>
						<Typography sx={{ fontSize: "32px" }}>
							{ getProp( us_summaryData, "total_work_minus_expected_route_hours", 0) > 0 ? <>+</>: <></> }
							{ getProp( us_summaryData, "total_work_minus_expected_route_hours", 0).toFixed(1) }
						</Typography>
						<Typography variant="body1">{ s_WORK_MINUS_ROUTE_HOURS }</Typography>
					</Box>
				</Grid2>
				<Grid2 xs={6} sm={4} md={4} >
					<Box className="tw-text-center tw-p-1 tw-rounded-xl" sx={{ borderStyle: "solid", borderWidth: "2px", color: themeVariables.gray_500, borderColor: themeVariables.gray_500 }}>
						<Typography sx={{ fontSize: "32px" }}>{ getProp( us_summaryData, "total_excluded_hours", 0 ).toFixed(1) }</Typography>
						<Typography variant="body1">{ s_TOTAL_EXCLUDED_HOURS }</Typography>
					</Box>
				</Grid2>
			</Grid2>
			// Payroll
			// let expenseColor = themeVariables.error_main
			// let expenseErrorJSX = <></>
			// if( getProp( us_summaryData, "total_payroll_dollars_missing_mapping", 0 ) > 0 ){
			// 	expenseColor = themeVariables.gray_500
			// 	expenseErrorJSX =
			// 	<Box sx={{ color: themeVariables.error_main }}>
			// 		<Typography variant="body1">
			// 			<Icon icon="triangle-exclamation" className="tw-mr-2" />
			// 			{ getProp( us_summaryData, "total_payroll_dollars_missing_mapping", 0 )  } { s_PAYROLL_CALCULATION_ERRORS }
			// 		</Typography>
			// 	</Box>
			// }
			// Route Financial Numbers
			let combinedRevenueColor = themeVariables.success_main
			let routeRevenueColor = themeVariables.success_main
			let combinedRevenueErrorJSX = <></>
			let routeRevenueErrorJSX = <></>
			if( getProp( us_summaryData, "total_route_dollars_missing_mapping", 0 ) > 0 ){
				routeRevenueColor = themeVariables.gray_500
				combinedRevenueColor = themeVariables.gray_500
				routeRevenueErrorJSX =
				<Box sx={{ color: themeVariables.error_main }}>
					<Typography variant="body1">
						<Icon icon="triangle-exclamation" className="tw-mr-2" />
						{ getProp( us_summaryData, "total_route_dollars_missing_mapping", 0 ) } { s_REVENUE_CALCULATION_ERRORS }
					</Typography>
				</Box>
				combinedRevenueErrorJSX =
				<Box sx={{ color: themeVariables.error_main }}>
					<Typography variant="body1">
						<Icon icon="triangle-exclamation" className="tw-mr-2" />
						{  getProp( us_summaryData, "total_route_dollars_missing_mapping", 0 ) + getProp( us_summaryData, "total_route_package_base_dollars_missing_mapping", 0 ) + getProp( us_summaryData, "total_route_package_bonus_dollars_missing_mapping", 0 ) } { s_REVENUE_CALCULATION_ERRORS }
					</Typography>
				</Box>
			}
			// Package Financial Numbers
			let packageRevenueColor = themeVariables.success_main
			let packageRevenueErrorJSX = <></>
			if( ( getProp( us_summaryData, "total_route_package_base_dollars_missing_mapping", 0 ) + getProp( us_summaryData, "total_route_package_bonus_dollars_missing_mapping", 0 ) ) > 0 ){
				packageRevenueColor = themeVariables.gray_500
				combinedRevenueColor = themeVariables.gray_500
				packageRevenueErrorJSX =
				<Box sx={{ color: themeVariables.error_main }}>
					<Typography variant="body1">
						<Icon icon="triangle-exclamation" className="tw-mr-2" />
						{ getProp( us_summaryData, "total_route_package_base_dollars_missing_mapping", 0 ) + getProp( us_summaryData, "total_route_package_bonus_dollars_missing_mapping", 0 ) } { s_REVENUE_CALCULATION_ERRORS }
					</Typography>
				</Box>
				combinedRevenueErrorJSX =
				<Box sx={{ color: themeVariables.error_main }}>
					<Typography variant="body1">
						<Icon icon="triangle-exclamation" className="tw-mr-2" />
						{  getProp( us_summaryData, "total_route_dollars_missing_mapping", 0 ) + getProp( us_summaryData, "total_route_package_base_dollars_missing_mapping", 0 ) + getProp( us_summaryData, "total_route_package_bonus_dollars_missing_mapping", 0 ) } { s_REVENUE_CALCULATION_ERRORS }
					</Typography>
				</Box>
			}
			let financialsJSX =
			<Box className="tw-mt-2">
				<Grid2 container spacing={1}>
					{/* <Grid2 xs={6} sm={4} md={4} >
						<Box className="tw-text-center tw-p-1 tw-rounded-xl" sx={{ borderStyle: "solid", borderWidth: "2px", color: expenseColor, borderColor: expenseColor }}>
							<Typography sx={{ fontSize: "32px" }}>{ formatCurrency( getProp( us_summaryData, "total_payroll_dollars", 0 ) ) }</Typography>
							<Typography variant="body1">{ s_EXPENSES_FROM_PAYROLL }</Typography>
							{ expenseErrorJSX }
						</Box>
					</Grid2> */}
					<Grid2 xs={6} sm={4} md={4} >
						<Box className="tw-text-center tw-p-1 tw-rounded-xl" sx={{ borderStyle: "solid", borderWidth: "2px", color: routeRevenueColor, borderColor: routeRevenueColor }}>
							<Typography sx={{ fontSize: "32px" }}>{ formatCurrency( getProp( us_summaryData, "total_route_dollars", 0 ) ) }</Typography>
							<Typography variant="body1">{ s_REVENUE_FROM_ROUTES }</Typography>
							{ routeRevenueErrorJSX }
						</Box>
					</Grid2>
					<Grid2 xs={6} sm={4} md={4} >
						<Box className="tw-text-center tw-p-1 tw-rounded-xl" sx={{ borderStyle: "solid", borderWidth: "2px", color: packageRevenueColor, borderColor: packageRevenueColor }}>
							<Typography sx={{ fontSize: "32px" }}>{ formatCurrency( getProp( us_summaryData, "total_route_package_base_dollars", 0 ) + getProp( us_summaryData, "total_route_package_bonus_dollars", 0 ) ) }</Typography>
							<Typography variant="body1">{ s_REVENUE_FROM_PACKAGES }</Typography>
							{ packageRevenueErrorJSX }
						</Box>
					</Grid2>

					<Grid2 xs={6} sm={4} md={4} >
						<Box className="tw-text-center tw-p-1 tw-rounded-xl" sx={{ borderStyle: "solid", borderWidth: "2px", color: combinedRevenueColor, borderColor: combinedRevenueColor }}>
							<Typography sx={{ fontSize: "32px" }}>{ formatCurrency( getProp( us_summaryData, "total_route_dollars", 0 ) + getProp( us_summaryData, "total_route_package_base_dollars", 0 ) + getProp( us_summaryData, "total_route_package_bonus_dollars", 0 ) ) }</Typography>
							<Typography variant="body1">{ s_REVENUE_FROM_ROUTES_AND_PACKAGES }</Typography>
							{ combinedRevenueErrorJSX }
						</Box>
					</Grid2>

				</Grid2>
			</Box>
			// Results
			resultsJSX =
			<Box sx={{ maxWidth: "1000px" }} className="tw-m-auto">
				{ dateRangeJSX }
				{ numbersJSX }
				{ returnJSX_HoursScatterPlot() }
				{ returnJSX_HourColorBarChart() }
				{ financialsJSX }
			</Box>
			return resultsJSX
		}

		const returnJSX_AnaylysisResults_FullDataTable = (): TsType_JSX => {
			let resultsJSX = <></>
			resultsJSX =
			<Card className="tw-mb-2">
				<TableBasic
					tableAdditionalData={ {
						us_userHourColors: us_userHourColors,
						us_selectedDateFilter: us_selectedDateFilter,
						us_analysisDriverNotesData: us_analysisDriverNotesData,
						us_dateDropdownOptions: us_dateDropdownOptions,
						analysisKey: analysisKey,
					} }
					tableColumns={ tableColumns_FullData }
					tableData={ objectToArray( us_filteredTableData ) }
					tableSettings={ tableSettings_AnalaysisDefault }
				/>
			</Card>
			return resultsJSX
		}

		const returnJSX_AnaylysisResults_DriverNotesTable = (): TsType_JSX => {
			let resultsJSX = <></>
			resultsJSX =
			<Card className="tw-mb-2">
				<TableBasic
					tableAdditionalData={ {
						analysisKey: analysisKey,
						us_userHourColors: us_userHourColors,
						us_analysisDriverNotesData: us_analysisDriverNotesData,
						us_dateDropdownOptions: us_dateDropdownOptions,
						us_selectedDateFilter: us_selectedDateFilter,
					} }
					tableColumns={ tableColumns_DriverNotes }
					tableData={ objectToArray( us_filteredTableData ) }
					tableSettings={ tableSettings_DriverNotes }
				/>
			</Card>
			return resultsJSX
		}

		const returnJSX_AnaylysisResults_DeliveryUploadFile = (): TsType_JSX => {
			let tableColumns: TsInterface_TableColumns = {
				key: {
					cell: {
						cell_css: (
							rowData: TsInterface_TableDataRow,
							tableAdditionalData: TsInterface_TableAdditionalData
						) => { return "" },
						cell_jsx: (
							rowData: TsInterface_TableDataRow,
							tableAdditionalData: TsInterface_TableAdditionalData,
							tableHooks: TsInterface_TableHooks
						) => {
							let cellJSX =
							<Box className="tw-opacity-30 tw-italic">
								{ rowData.key }
							</Box>
							return cellJSX
						},
					},
					header: {
						header_css: ( tableAdditionalData: TsInterface_TableAdditionalData ) => { return "" },
						header_jsx: ( tableAdditionalData: TsInterface_TableAdditionalData ) => { return <></> },
						header_sort_by: "key"
					}
				}
			}
			let columnHeaders = getProp( us_rootAnalysis, "column_headers_delivery", [] )
			for( let loopHeaderIndex in columnHeaders ){
				let loopHeader = columnHeaders[ loopHeaderIndex ]
				// tableColumns[ loopHeader ] = TableCellBasic( loopHeader, loopHeader, loopHeader )
				tableColumns[ loopHeader ] = {
					cell: {
						cell_css: (
							rowData: TsInterface_TableDataRow,
							tableAdditionalData: TsInterface_TableAdditionalData
						) => { return "" },
						cell_jsx: (
							rowData: TsInterface_TableDataRow,
							tableAdditionalData: TsInterface_TableAdditionalData,
							tableHooks: TsInterface_TableHooks
						) => {
							let cellJSX =
							<Box
								sx={{ minHeight: "20px", cursor: "pointer" }}
								onDoubleClick={ () => {
									if(
										us_rootAnalysis != null &&
										us_rootAnalysis.locked !== true
									){
										tableHooks.uc_setUserInterface_PromptDialogDisplay({
											display: true,
											prompt: {
												color: "success",
												confirm_text: s_SAVE,
												default_value: getProp( rowData, loopHeader, "" ),
												header: s_EDIT_UPLOADED_DATA,
												icon: <Icon icon="pen-to-square" type="solid" />,
												input_label: s_NEW_VALUE,
												input_type: "text",
												text: <></>,
												submit_callback: (  promptValue: TsType_String ) => {
													return new Promise( ( resolve, reject ) => {
														let updateObject = {
															[ loopHeader ]: promptValue
														}
														if(
															rowData != null &&
															rowData.key != null
														){
															let rowDataKey = rowData.key.toString()
															getClientKey( tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey ).then(( res_GCK ) => {
																DatabaseSetMergeDocument( DatabaseRef_TimecardAnalysis_DeliveryFile_Document( res_GCK.clientKey, analysisKey, rowDataKey ), updateObject, {} ).then( ( res_DSD ) => {
																	resolve( res_DSD )
																}).catch( ( rej_DSD ) => {
																	reject( rej_DSD )
																	tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSD.error })
																})
															}).catch( ( rej_GCK ) => {
																reject( rej_GCK )
																tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
															})
														}
													})
												}
											}
										})
									}
								}}
							>
								{ rowData[ loopHeader ] }
							</Box>
							return cellJSX
						},
					},
					header: {
						header_css: ( tableAdditionalData: TsInterface_TableAdditionalData ) => { return "" },
						header_jsx: ( tableAdditionalData: TsInterface_TableAdditionalData ) => { return <>{ loopHeader }</> },
						header_sort_by: loopHeader
					}
				}
			}
			let resultsJSX =
			<Card className="tw-mb-2">
				<TableBasic
					tableAdditionalData={ {} }
					tableColumns={ tableColumns }
					tableData={ objectToArray( us_deliveryFileData ) }
					tableSettings={ tableSettings_RawData }
				/>
			</Card>
			return resultsJSX
		}

		const returnJSX_AnaylysisResults_PayrollUploadFile = (): TsType_JSX => {
			let tableColumns: TsInterface_TableColumns = {
				key: {
					cell: {
						cell_css: (
							rowData: TsInterface_TableDataRow,
							tableAdditionalData: TsInterface_TableAdditionalData
						) => { return "" },
						cell_jsx: (
							rowData: TsInterface_TableDataRow,
							tableAdditionalData: TsInterface_TableAdditionalData,
							tableHooks: TsInterface_TableHooks
						) => {
							let cellJSX =
							<Box className="tw-opacity-30 tw-italic">
								{ rowData.key }
							</Box>
							return cellJSX
						},
					},
					header: {
						header_css: ( tableAdditionalData: TsInterface_TableAdditionalData ) => { return "" },
						header_jsx: ( tableAdditionalData: TsInterface_TableAdditionalData ) => { return <></> },
						header_sort_by: "key"
					}
				}
			}
			let columnHeaders = getProp( us_rootAnalysis, "column_headers_timecard", [])
			for( let loopHeaderIndex in columnHeaders ){
				let loopHeader = columnHeaders[ loopHeaderIndex ]
				// tableColumns[ loopHeader ] = TableCellBasic( loopHeader, loopHeader, loopHeader )
				tableColumns[ loopHeader ] = {
					cell: {
						cell_css: (
							rowData: TsInterface_TableDataRow,
							tableAdditionalData: TsInterface_TableAdditionalData
						) => { return "" },
						cell_jsx: (
							rowData: TsInterface_TableDataRow,
							tableAdditionalData: TsInterface_TableAdditionalData,
							tableHooks: TsInterface_TableHooks
						) => {
							let cellJSX =
							<Box
								sx={{ minHeight: "20px", cursor: "pointer" }}
								onDoubleClick={ () => {
									if(
										us_rootAnalysis != null &&
										us_rootAnalysis.locked !== true
									){
										tableHooks.uc_setUserInterface_PromptDialogDisplay({
											display: true,
											prompt: {
												color: "success",
												confirm_text: s_SAVE,
												default_value: getProp( rowData, loopHeader, "" ),
												header: s_EDIT_UPLOADED_DATA,
												icon: <Icon icon="pen-to-square" type="solid" />,
												input_label: s_NEW_VALUE,
												input_type: "text",
												text: <></>,
												submit_callback: (  promptValue: TsType_String ) => {
													return new Promise( ( resolve, reject ) => {
														let updateObject = {
															[ loopHeader ]: promptValue
														}
														if(
															rowData != null &&
															rowData.key != null
														){
															let rowDataKey = rowData.key.toString()
															getClientKey( tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey ).then(( res_GCK ) => {
																DatabaseSetMergeDocument( DatabaseRef_TimecardAnalysis_TimecardFile_Document( res_GCK.clientKey, analysisKey, rowDataKey ), updateObject, {} ).then( ( res_DSD ) => {
																	resolve( res_DSD )
																}).catch( ( rej_DSD ) => {
																	reject( rej_DSD )
																	tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSD.error })
																})
															}).catch( ( rej_GCK ) => {
																reject( rej_GCK )
																tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
															})
														}
													})
												}
											}
										})
									}
								}}
							>
								{ rowData[ loopHeader ] }
							</Box>
							return cellJSX
						},
					},
					header: {
						header_css: ( tableAdditionalData: TsInterface_TableAdditionalData ) => { return "" },
						header_jsx: ( tableAdditionalData: TsInterface_TableAdditionalData ) => { return <>{ loopHeader }</> },
						header_sort_by: loopHeader
					}
				}
			}
			let resultsJSX =
			<Card className="tw-mb-2">
				<TableBasic
					tableAdditionalData={ {} }
					tableColumns={ tableColumns }
					tableData={ objectToArray( us_timecardFileData ) }
					tableSettings={ tableSettings_RawData }
				/>
			</Card>
			return resultsJSX
		}

		const returnJSX_ErrorSection = (
			errorCategory: TsInterface_UnspecifiedObject
		): TsType_JSX => {
			let sectionJSX = <></>
			if(
				errorCategory != null &&
				errorCategory.key != null &&
				us_analysisErrorData != null &&
				us_analysisErrorData[ errorCategory.key ] != null &&
				objectToArray( us_analysisErrorData[ errorCategory.key ] ).length > 0
			){
				sectionJSX =
				<Box>
					<Typography className="tw-inline-block" variant="body1" sx={{ color: themeVariables.error_main }}>
						<Icon icon="triangle-exclamation" className="tw-mr-2" />
						{ errorCategory.name } ({ objectToArray( us_analysisErrorData[ errorCategory.key ] ).length })
					</Typography>
					<Button
						className="tw-ml-2"
						variant="outlined"
						color="info"
						sx={{ padding: "6px", minWidth: "40px" }}
						onClick={ () => {
							// Generate Table Headers
							let tableColumns: TsInterface_TableColumns = {
								key: {
									cell: {
										cell_css: (
											rowData: TsInterface_TableDataRow,
											tableAdditionalData: TsInterface_TableAdditionalData
										) => { return "" },
										cell_jsx: (
											rowData: TsInterface_TableDataRow,
											tableAdditionalData: TsInterface_TableAdditionalData,
											tableHooks: TsInterface_TableHooks
										) => {
											let cellJSX =
											<Box className="tw-opacity-30 tw-italic">
												{ rowData.key }
											</Box>
											return cellJSX
										},
									},
									header: {
										header_css: ( tableAdditionalData: TsInterface_TableAdditionalData ) => { return "" },
										header_jsx: ( tableAdditionalData: TsInterface_TableAdditionalData ) => { return <></> },
										header_sort_by: "key"
									}
								}
							}
							if(
								errorCategory != null &&
								errorCategory.type === "delivery"
							){
								let columnHeaders = getProp( us_rootAnalysis, "column_headers_delivery", [] )
								for( let loopHeaderIndex in columnHeaders ){
									let loopHeader = columnHeaders[ loopHeaderIndex ]
									tableColumns[ loopHeader ] = TableCellBasic( loopHeader, loopHeader, loopHeader )
								}
							} else if(
								errorCategory != null &&
								errorCategory.type === "timecard"
							) {
								let columnHeaders = getProp( us_rootAnalysis, "column_headers_timecard", [])
								for( let loopHeaderIndex in columnHeaders ){
									let loopHeader = columnHeaders[ loopHeaderIndex ]
									tableColumns[ loopHeader ] = TableCellBasic( loopHeader, loopHeader, loopHeader )
								}
							}
							// Open Dialog
							uc_setUserInterface_CustomDialogDisplay({
								display: true,
								dialog: {
									dialog_jsx:
									<Dialog
										TransitionComponent={ TransitionSlide }
										className="bp_dialog_xl_width"
										keepMounted
										onClose={ () => {
											uc_setUserInterface_CustomDialogDisplay( UserInterface_Default_CustomDialogDisplayState )
										} }
										open={ true }
									>
										<AppBar position="static" color="error">
											<Toolbar>
												<IconButton
													aria-label="menu"
													color="inherit"
													disabled
													edge="start"
													size="large"
													sx={{ mr: 2, color: "#fff !important" }}
												>
													<Icon icon="triangle-exclamation" />
												</IconButton>
												<Typography component={'span'} variant={ 'h6' } sx={{ flexGrow: 1 }}>{ errorCategory.name }</Typography>
											</Toolbar>
										</AppBar>
										<DialogContent sx={{ padding: "0px" }}>
											<Card className="tw-mb-2">
												<TableBasic
													tableAdditionalData={ {} }
													tableColumns={ tableColumns }
													tableData={ objectToArray( us_analysisErrorData[ errorCategory.key ] ) }
													tableSettings={ tableSettings_RawData }
												/>
											</Card>
										</DialogContent>
									</Dialog>,
									settings: {
										max_width: "lg"
									}
								},
							})
						} }
					>
						<Icon icon="magnifying-glass" />
					</Button>
				</Box>
			} else {
				sectionJSX =
				<Box>
					<Typography variant="body1" sx={{ color: themeVariables.success_main }}>
						<Icon icon="circle-check" className="tw-mr-2" />
						{ errorCategory.name }
					</Typography>
				</Box>
			}
			return sectionJSX
		}

		const returnJSX_AnaylysisResults_DataErrors = (): TsType_JSX => {
			let resultsJSX =
			<Card className="tw-mt-2 tw-p-4">
				{objectToArray( us_analysisErrorDataNames ).map(( errorCategory: TsInterface_UnspecifiedObject, errorCategoryIndex: TsType_Number ) => (
					<React.Fragment key={errorCategoryIndex}>
						{ returnJSX_ErrorSection( errorCategory ) }
					</React.Fragment>
				))}
			</Card>
			return resultsJSX
		}

		const returnJSX_AnaylysisResults_RouteRevenue = (): TsType_JSX => {
			let resultsJSX = <></>
			resultsJSX =
			<Box>
				<Typography variant="h6" className="tw-mb-2 tw-font-bold">{ s_STATION_TOTALS }</Typography>
				<Card className="tw-mb-2">
					<TableBasic
						tableAdditionalData={ { allData: us_filteredRouteRevenueBreakdown } }
						tableColumns={ tableColumns_StationRevenue }
						tableData={ objectToArray( us_filteredRouteStationBreakdown ) }
						tableSettings={ tableSettings_StationRevenue }
					/>
				</Card>
				<Typography variant="h6" className="tw-mb-2 tw-font-bold">{ s_ROUTE_TOTALS }</Typography>
				<Card className="tw-mb-2">
					<TableBasic
						tableAdditionalData={ { allData: us_filteredRouteRevenueBreakdown } }
						tableColumns={ tableColumns_RouteRevenue }
						tableData={ objectToArray( us_filteredRouteRevenueBreakdown ) }
						tableSettings={ tableSettings_RouteRevenue }
					/>
				</Card>
			</Box>
			return resultsJSX
		}

		const returnJSX_AnaylysisResults_PayrollExpenses = (): TsType_JSX => {
			let resultsJSX = <></>

			// TODO

			// resultsJSX =
			// <Card className="tw-mb-2">
			// 	<TableBasic
			// 		tableAdditionalData={ {
			// 			us_userHourColors: us_userHourColors
			// 		} }
			// 		tableColumns={ tableColumns_FullData }
			// 		tableData={ objectToArray( us_filteredTableData ) }
			// 		tableSettings={ tableSettings_AnalaysisDefault }
			// 	/>
			// </Card>

			return resultsJSX
		}

		// Page
		const returnJSX_ActiveSection = (): TsType_JSX => {
			let sectionJSX = <></>
			switch( us_selectedSection ){
				case "instructions":
					sectionJSX = returnJSX_InstructionsSection()
					break
				case "file_upload":
					sectionJSX = returnJSX_FileUploadSection()
					break
				case "data_mapping":
					sectionJSX = returnJSX_DataMappingSection()
					break
				case "results":
					sectionJSX = returnJSX_ResultsSection()
					break
			}
			return sectionJSX
		}

		const returnJSX_PageHeader = (): TsType_JSX => {
			let headerJSX = s_TIMECARD_AUDIT
			if(
				us_rootAnalysis != null &&
				us_rootAnalysis.name != null
			){
				headerJSX = <>{ headerJSX } - { us_rootAnalysis.name }</>
			}

			return headerJSX
		}

		const returnJSX_Page = (): TsType_JSX => {
			let pageContentJSX = <></>
			if( us_initialDataLoaded === false ){
				pageContentJSX =
				<Box className="tw-p-2 tw-text-center">
					<CircularProgress />
				</Box>
			} else {
				pageContentJSX =
				<Box>
					<Box>
						{ returnJSX_NavContainer() }
					</Box>
					<Box>
						{ returnJSX_ActiveSection() }
					</Box>
				</Box>
			}
			let pageJSX =
			<AuthenticatedContainer pageHeader={ returnJSX_PageHeader() } pageKey={pageKey} content={
				<Box>
					<Box>
						{ returnJSX_BackButton() }
					</Box>
					{ pageContentJSX }
				</Box>
			}/>
			return pageJSX
		}

		// Render
		return <>{returnJSX_Page()}</>

	}