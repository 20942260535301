///////////////////////////////
// Description
///////////////////////////////

	/*
		DESCRIPTION / USAGE:
			containers are pages / views used in the app and are made up of components and can interact with services and models

		TODO:

	*/


///////////////////////////////
// Imports
///////////////////////////////

import {
	Trans
} from 'react-i18next'
import {
	themeVariables
} from 'rfbp_aux/config/app_theme'
import {
	AuthenticatedContainer
} from 'rfbp_aux/containers/authenticated_container'
import {
	ApplicationPages
} from 'rfbp_aux/data/application_structure'
import {
	Icon
} from 'rfbp_core/components/icons'
import {
	TsType_JSX,
	TsType_String
} from 'rfbp_core/typescript/global_types'
import {
	Box,
	Typography
} from '@mui/material/'

///////////////////////////////
// Typescript
///////////////////////////////


///////////////////////////////
// Variables
///////////////////////////////

	// Authenticated Nav Data
	const pageKey: TsType_String = ApplicationPages["AuditFleetIndexPage"]["key"]

	// Displayed Translatable Strings
	// { sort-start } - displayed text - scoped sort plugin
	const s_FLEET_AUDITS: TsType_JSX = 				<Trans>Fleet Audits</Trans>
	const s_UNDER_CONSTRUCTION: TsType_JSX = 		<Trans>Under Construction</Trans>
	// { sort-end } - displayed text


///////////////////////////////
// Functions
///////////////////////////////


///////////////////////////////
// Container
///////////////////////////////

	export const Container: React.FC = (): TsType_JSX => {

		// Props
		// const params = useParams()
		// const itemKey: TsType_String = params.id as TsType_String

		// Hooks - useContext, useState, useReducer, other
		// { sort-start } - hooks
		// const [ items, setItems ] = 			useState<TsInterface_UnspecifiedObject>({})
		// const ur_forceRerender = 				useReducer(() => ({}), {})[1] as () => TsType_Void
		// { sort-end } - hooks

		// Hooks - useEffect
		// useEffect(() => { }, [])

		// Other Variables

		// Functions

		// Call Functions

		// JSX Generation
		const returnJSX_Page = (): TsType_JSX => {
			let pageJSX =
			<AuthenticatedContainer pageHeader={s_FLEET_AUDITS} pageKey={pageKey} content={
				<Box>
					<Box className="tw-text-center">
						<Icon icon="screwdriver-wrench" size="5x" type="solid" className="tw-mb-4" sx={ {color: themeVariables.primary_main} } />
						<Typography variant='h5'>{ s_UNDER_CONSTRUCTION }</Typography>
					</Box>
				</Box>
			}/>
			return pageJSX
		}

		// Render
		return <>{returnJSX_Page()}</>

	}