//======================================//
//		  ooOOOO BOILERPLATE TEMPLATE   //
//		 oo		 _____					//
//		_I__n_n__||_|| ________			//
//	  >(_________|_7_|-|______|			//
//	   /o ()() ()() o   oo  oo			//
//======================================//

///////////////////////////////
// Description
///////////////////////////////

	/*
		DESCRIPTION / USAGE:
			Generic unbranded login page

		TODO:
			[ ] Feature - Get Login email working

	*/


///////////////////////////////
// Imports
///////////////////////////////

import {
	LogoFull
} from 'app/images/logos/logo_full'
import {
	formInputs_UserNew,
	formOnChange_User,
	formSettings_User
} from 'app/models/users'
import axios from 'axios'
import {
	useContext,
	useEffect,
	useState
} from 'react'
import {
	Trans
} from 'react-i18next'
import {
	useNavigate
} from 'react-router-dom'
import {
	ApplicationPages
} from 'rfbp_aux/data/application_structure'
import {
	Form,
	TsInterface_FormInputs,
	TsInterface_FormSettings,
	TsType_FormOnChange,
	TsType_FormSubmission
} from 'rfbp_core/components/form'
import {
	Icon
} from 'rfbp_core/components/icons'
import {
	Context_UserInterface_AlertDialog,
	Context_UserInterface_ErrorDialog
} from 'rfbp_core/services/context'
import {
	authWithPassword,
	sendPasswordResetToEmail
} from 'rfbp_core/services/user_authentication'
import {
	TsType_JSX
} from 'rfbp_core/typescript/global_types'
import {
	Box,
	Button
} from '@mui/material/'

///////////////////////////////
// Typescript
///////////////////////////////


///////////////////////////////
// Variables
///////////////////////////////

	// Displayed Translatable Strings
	// { sort-start } - displayed text - scoped sort plugin
	const s_BACK_TO_LOGIN: TsType_JSX =											<Trans>Back to Log In</Trans>
	const s_CREATE_AN_ACCOUNT: TsType_JSX =										<Trans>Create an Account</Trans>
	const s_EMAIL: TsType_JSX =													<Trans>Email</Trans>
	const s_EMAIL_SENT_SUCCESSFULLY: TsType_JSX = 								<Trans>Email Sent Successfully</Trans>
	const s_FAILED_TO_CREATE_NEW_ACCOUNT: TsType_JSX =							<Trans>Failed to create new account</Trans>
	const s_FORGOT_PASSWORD: TsType_JSX =										<Trans>Forgot Password</Trans>
	const s_LOG_IN: TsType_JSX =												<Trans>Log In</Trans>
	const s_MISSING_REQUIRED_FIELDS: TsType_JSX =								<Trans>Missing Required Fields</Trans>
	const s_PASSWORD: TsType_JSX =												<Trans>Password</Trans>
	const s_SEND_PASSWORD_RESET_EMAIL: TsType_JSX = 							<Trans>Send Password Reset Email</Trans>
	const s_YOUR_PASSWORD_RESET_EMAIL_SHOULD_ARRIVE_MOMENTARILY: TsType_JSX = 	<Trans>Your password reset email should arrive momentarily</Trans>
	// { sort-end } - displayed text

	// Form Properties
	const formInputs_Login: TsInterface_FormInputs = {
		email: {
			data_type: "string",
			input_type: "text_basic",
			key: "email",
			label: s_EMAIL,
			required: true,
			startAdornment: <Icon icon="user" type="light"/>
		},
		password: {
			data_type: "string",
			input_type: "text_password",
			key: "password",
			label: s_PASSWORD,
			required: true,
			startAdornment: <Icon icon="lock-keyhole" type="light"/>
		},
	}

	const formSettings_Login: TsInterface_FormSettings = {
		submit_button_theme: "primary",
		submit_button_alignment: "center",
		submit_button_hide: false,
		submit_button_icon: <Icon icon="right-to-bracket"/>,
		submit_button_saving_icon: true,
		submit_button_text: <>{s_LOG_IN}</>,
	}

	const formInputs_ForgotEmail: TsInterface_FormInputs = {
		email: {
			data_type: "string",
			input_type: "text_basic",
			key: "email",
			label: s_EMAIL,
			required: true,
			startAdornment: <Icon icon="user" type="light"/>
		},
	}

	const FormSettings_ForgotEmail: TsInterface_FormSettings = {
		submit_button_theme: "primary",
		submit_button_alignment: "center",
		submit_button_hide: false,
		submit_button_icon: <Icon icon="paper-plane" />,
		submit_button_saving_icon: true,
		submit_button_text: <>{s_SEND_PASSWORD_RESET_EMAIL}</>,
	}


///////////////////////////////
// Functions
///////////////////////////////


///////////////////////////////
// Container
///////////////////////////////

	export const Container: React.FC = (): TsType_JSX => {

		// Props

		// Hooks - useContext, useState, useReducer, other
		// { sort-start } - hooks
		const [ us_formAdditionalData_ForgotEmail, us_setFormAdditionalData_ForgotEmail ] = 		useState({})
		const [ us_formAdditionalData_Login, us_setFormAdditionalData_Login ] = 					useState({})
		const [ us_formAdditionalData_NewAccount, us_setFormAdditionalData_NewAccount ] = 			useState({})
		const [ us_formData_ForgotEmail, us_setFormData_ForgotEmail ] = 							useState({})
		const [ us_formData_Login, us_setFormData_Login ] = 										useState({})
		const [ us_formData_NewAccount, us_setFormData_NewAccount ] = 								useState({})
		const [ us_pageType, us_setPageType ] = 													useState("log_in")
		const un_routerNaviation = 																	useNavigate()
		const { uc_setUserInterface_AlertDialogDisplay } = 											useContext(Context_UserInterface_AlertDialog)
		const { uc_setUserInterface_ErrorDialogDisplay } = 											useContext(Context_UserInterface_ErrorDialog)
		// { sort-end } - hooks

		// Hooks - useEffect
		useEffect(() => {
			us_setFormData_Login({})
			us_setFormAdditionalData_Login({})
			us_setFormData_ForgotEmail({})
			us_setFormData_NewAccount({})
			us_setFormAdditionalData_ForgotEmail({})
			us_setFormAdditionalData_NewAccount({})
			return () => {}
		}, [])

		// Other Variables

		// Functions
		const formOnChange_Login: TsType_FormOnChange = (formAdditionalData, formData, formInputs, formSettings) => {
			// Nothing
		}

		const formSubmission_Login: TsType_FormSubmission = (formSubmittedData, formAdditionalData) => {
			return new Promise((resolve, reject) => {
				authWithPassword(formSubmittedData.email, formSubmittedData.password).then((res_AWP) => {
					un_routerNaviation( ApplicationPages.HomePage.url() )
					resolve(res_AWP)
				}).catch((rej_AWP) => {
					reject(rej_AWP)
				})
			})
		}

		const formOnChange_ForgotEmail: TsType_FormOnChange = (formAdditionalData, formData, formInputs, formSettings) => {
			// Nothing
		}

		const formSubmission_ForgotEmail: TsType_FormSubmission = (formSubmittedData, formAdditionalData) => {
			return new Promise((resolve, reject) => {
				sendPasswordResetToEmail(formSubmittedData.email).then((res_SPRTE) => {
					uc_setUserInterface_AlertDialogDisplay({
						display: true,
						alert: {
							color: "primary",
							header: s_EMAIL_SENT_SUCCESSFULLY,
							icon: <Icon icon="paper-plane"/>,
							text: s_YOUR_PASSWORD_RESET_EMAIL_SHOULD_ARRIVE_MOMENTARILY,
						}
					})
					us_setPageType("log_in")
					resolve(res_SPRTE)
				}).catch((rej_SPRTE) => {
					reject(rej_SPRTE)
				})
			})
		}

		const formSubmission_NewAccount: TsType_FormSubmission = (formSubmittedData, formAdditionalData, formHooks) => {
			return new Promise((resolve, reject) => {
				if (
					formSubmittedData != null &&
					formSubmittedData["name"] != null &&
					formSubmittedData["email"] != null &&
					formSubmittedData["phone"] != null &&
					formSubmittedData["password"] != null
				){
					axios.post(
						'https://us-central1-loadcall-last-mile.cloudfunctions.net/userManagement/create_user_account/',
						{
							name: formSubmittedData["name"],
							email: formSubmittedData["email"],
							phone: formSubmittedData["phone"],
							password: formSubmittedData["password"],
						}
					).then(( res_A ) => {
						authWithPassword(formSubmittedData.email, formSubmittedData.password).then((res_AWP) => {
							un_routerNaviation( ApplicationPages.HomePage.url() )
							resolve(res_AWP)
						}).catch((rej_AWP) => {
							reject(rej_AWP)
						})
					}).catch((rej_A) => {
						reject(rej_A)
						uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_A.error })
					})
				} else {
					let error = {
						message: s_FAILED_TO_CREATE_NEW_ACCOUNT,
						details: s_MISSING_REQUIRED_FIELDS,
						code: "ER-D-L-CU-01"
					}
					reject({ success: false, error: error })
					uc_setUserInterface_ErrorDialogDisplay({ display: true, error: error })
				}
			})
		}

		// Call Functions

		// JSX Generation
		const returnJSX_Page = (): TsType_JSX => {
			let loginFormJSX: TsType_JSX = <></>
			if(us_pageType === "log_in"){
				loginFormJSX =
				<Box>
					<Box className="tw-px-4 tw-mx-auto tw-max-w-lg">
						<Form
							formAdditionalData={ us_formAdditionalData_Login }
							formData={ us_formData_Login }
							formInputs={ formInputs_Login }
							formOnChange={ formOnChange_Login }
							formSettings={ formSettings_Login }
							formSubmission={ formSubmission_Login }
						/>
					</Box>
					<Box className="tw-mb-16 tw-mt-4">
						<Button variant="outlined" className="tw-mr-2" onClick={() => { us_setPageType("forgot_email") }}>{s_FORGOT_PASSWORD}</Button>
						<Button variant="outlined" onClick={() => { us_setPageType("create_account") }}>{s_CREATE_AN_ACCOUNT}</Button>
					</Box>
				</Box>
			} else if(us_pageType === "forgot_email"){
				loginFormJSX =
				<Box>
					<Box className="tw-px-4 tw-mx-auto tw-max-w-lg">
						<Form
							formAdditionalData={ us_formAdditionalData_ForgotEmail }
							formData={ us_formData_ForgotEmail }
							formInputs={ formInputs_ForgotEmail }
							formOnChange={ formOnChange_ForgotEmail }
							formSettings={ FormSettings_ForgotEmail }
							formSubmission={ formSubmission_ForgotEmail }
						/>
					</Box>
					<Box className="tw-mb-16 tw-mt-4">
						<Button variant="outlined" onClick={() => { us_setPageType("log_in") }}>{s_BACK_TO_LOGIN}</Button>
					</Box>
				</Box>
			} else if(us_pageType === "create_account"){
				loginFormJSX =
				<Box>
					<Box className="tw-px-4 tw-mx-auto tw-max-w-lg">
						<Form
							formAdditionalData={ us_formAdditionalData_NewAccount }
							formData={ us_formData_NewAccount }
							formInputs={ formInputs_UserNew }
							formOnChange={ formOnChange_User }
							formSettings={ formSettings_User }
							formSubmission={ formSubmission_NewAccount }
						/>
					</Box>
					<Box className="tw-mb-16 tw-mt-4">
						<Button variant="outlined" onClick={() => { us_setPageType("log_in") }}>{s_BACK_TO_LOGIN}</Button>
					</Box>
				</Box>
			}
			let pageJSX =
			<Box className="tw-text-center tw-m-auto">
				<Box sx={{ marginTop: "30vh" }}>
					<LogoFull
						height="80px"
						mColor="#282B40"
						yColor="#FB9B39"
						otherColor="#50557e"
					/>
				</Box>
				{loginFormJSX}
			</Box>
			return pageJSX
		}

		// Render
		return <>{returnJSX_Page()}</>
	}