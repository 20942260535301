///////////////////////////////
// Imports
///////////////////////////////

import {
	collection,
	CollectionReference,
	doc,
	DocumentData,
	DocumentReference,
	getFirestore
} from 'firebase/firestore'
import {
	generateDatabaseQuery,
	TsInterface_QueryOperatorsArray
} from 'rfbp_core/services/database_management'
import {
	TsType_Date,
	TsType_String
} from 'rfbp_core/typescript/global_types'

///////////////////////////////
// Exports
///////////////////////////////

// Main
export const DatabaseRef_TimecardAnalysis_Collection =
	( clientKey: TsType_String ): CollectionReference< DocumentData > => {
	return collection( getFirestore(), "clients", clientKey, "analysis", "timecard", "main" ) }

export const DatabaseRef_TimecardAnalysis_Document =
	( clientKey: TsType_String, analysisKey: TsType_String ): DocumentReference< DocumentData > => {
	return doc( getFirestore(), "clients", clientKey, "analysis", "timecard", "main", analysisKey ) }

// Subcollections
export const DatabaseRef_TimecardAnalysis_DeliveryFile_Collection =
	( clientKey: TsType_String, analysisKey: TsType_String ): CollectionReference< DocumentData > => {
	return collection( getFirestore(), "clients", clientKey, "analysis", "timecard", "main", analysisKey, "delivery_upload" ) }

export const DatabaseRef_TimecardAnalysis_DeliveryFile_Document =
	( clientKey: TsType_String, analysisKey: TsType_String, itemKey: TsType_String ): DocumentReference< DocumentData > => {
	return doc( getFirestore(), "clients", clientKey, "analysis", "timecard", "main", analysisKey, "delivery_upload", itemKey ) }

export const DatabaseRef_TimecardAnalysis_TimecardFile_Collection =
	( clientKey: TsType_String, analysisKey: TsType_String ): CollectionReference< DocumentData > => {
	return collection( getFirestore(), "clients", clientKey, "analysis", "timecard", "main", analysisKey, "timecard_upload" ) }

export const DatabaseRef_TimecardAnalysis_TimecardFile_Document =
	( clientKey: TsType_String, analysisKey: TsType_String, itemKey: TsType_String ): DocumentReference< DocumentData > => {
	return doc( getFirestore(), "clients", clientKey, "analysis", "timecard", "main", analysisKey, "timecard_upload", itemKey ) }

export const DatabaseRef_TimecardAnalysis_DriverNotes_Collection =
	( clientKey: TsType_String, analysisKey: TsType_String ): CollectionReference< DocumentData > => {
	return collection( getFirestore(), "clients", clientKey, "analysis", "timecard", "main", analysisKey, "driver_notes" ) }

export const DatabaseRef_TimecardAnalysis_DriverNotes_Document =
	( clientKey: TsType_String, analysisKey: TsType_String, driverKey: TsType_String ): DocumentReference< DocumentData > => {
	return doc( getFirestore(), "clients", clientKey, "analysis", "timecard", "main", analysisKey, "driver_notes", driverKey ) }

// Mapping
export const DatabaseRef_TimecardAnalysis_UserMapping_Document =
	( clientKey: TsType_String ): DocumentReference< DocumentData > => {
	return doc( getFirestore(), "clients", clientKey, "analysis", "timecard", "mapping", "users" ) }

export const DatabaseRef_TimecardAnalysis_DeliveryReportColumnMapping_Document =
	( clientKey: TsType_String ): DocumentReference< DocumentData > => {
	return doc( getFirestore(), "clients", clientKey, "analysis", "timecard", "mapping", "delivery_report" ) }

export const DatabaseRef_TimecardAnalysis_TimecardReportColumnMapping_Document =
	( clientKey: TsType_String ): DocumentReference< DocumentData > => {
	return doc( getFirestore(), "clients", clientKey, "analysis", "timecard", "mapping", "timecard_report" ) }

export const DatabaseRef_TimecardAnalysis_TimecardReportPaycodeMultipliers_Document =
	( clientKey: TsType_String ): DocumentReference< DocumentData > => {
	return doc( getFirestore(), "clients", clientKey, "analysis", "timecard", "mapping", "paycode_multipliers" ) }

export const DatabaseRef_TimecardAnalysis_RoutePricing_Document =
	( clientKey: TsType_String ): DocumentReference< DocumentData > => {
	return doc( getFirestore(), "clients", clientKey, "analysis", "timecard", "mapping", "route_pricing" ) }


// Results
export const DatabaseRef_TimecardAnalysis_LockedResults_Collection =
	( clientKey: TsType_String, analysisKey: TsType_String ): CollectionReference< DocumentData > => {
	return collection( getFirestore(), "clients", clientKey, "analysis", "timecard", "main", analysisKey, "locked_results" ) }

export const DatabaseRef_TimecardAnalysis_LockedResults_Document =
	( clientKey: TsType_String, analysisKey: TsType_String, itemKey: TsType_String ): DocumentReference< DocumentData > => {
	return doc( getFirestore(), "clients", clientKey, "analysis", "timecard", "main", analysisKey, "locked_results", itemKey ) }

// Home Page Query
export const DatabaseRef_TimecardAnalysis_Query =
	( clientKey: TsType_String, startTimestamp: TsType_Date, endTimestamp: TsType_Date ) => {
	let queryOperatorsArray: TsInterface_QueryOperatorsArray = [
		{ prop: "locked_summary_dates.delivery_first_date", comparator: ">=", value: startTimestamp },
		{ prop: "locked_summary_dates.delivery_first_date", comparator: "<=", value: endTimestamp },
	]
	return generateDatabaseQuery( collection( getFirestore(), "clients", clientKey, "analysis", "timecard", "main" ), queryOperatorsArray, [], {}, null )}
