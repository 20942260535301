//======================================//
//		  ooOOOO USED BY BOILERPLATE    //
//		 oo		 _____					//
//		_I__n_n__||_|| ________			//
//	  >(_________|_7_|-|______|			//
//	   /o ()() ()() o   oo  oo			//
//======================================//

///////////////////////////////
// Imports
///////////////////////////////

import {
	getProp
} from 'rfbp_core/services/helper_functions'
import {
	TsType_JSX,
	TsType_String
} from 'rfbp_core/typescript/global_types'
import {
	Box
} from '@mui/material'

	///////////////////////////////
// Typescript
///////////////////////////////

	interface TsInterface_ComponentProps {
		height: TsType_String
		mColor: TsType_String
		yColor: TsType_String
		otherColor: TsType_String
	}


///////////////////////////////
// Variables
///////////////////////////////

	// Displayed Translatable Strings


///////////////////////////////
// Functions
///////////////////////////////


///////////////////////////////
// Component
///////////////////////////////

	export const LogoFull = ( props: TsInterface_ComponentProps ): TsType_JSX => {

		// Props
		let height: TsInterface_ComponentProps["height"] = 				getProp( props, "height", "30px" )
		let mColor: TsInterface_ComponentProps["mColor"] = 				getProp( props, "mColor", "#000000" )
		let yColor: TsInterface_ComponentProps["yColor"] = 				getProp( props, "yColor", "#ffffff" )
		let otherColor: TsInterface_ComponentProps["otherColor"] = 		getProp( props, "otherColor", "#000000" )

		// Hooks - useContext, useState, useReducer, other

		// Hooks - useEffect

		// Other Variables

		// Functions

		// Call Functions

		// JSX Generation
		const Logo =
		<Box>
			<svg version="1.1" x="0px" y="0px" height={ height } viewBox="0 0 2305 491.025">
				<path fill={ otherColor } d="M659.802,286.297c16.998,0,33.995-0.013,50.993,0.009c3.846,0.006,3.846,0.062,4.12,3.693
					c1.645,21.778,15.317,36.338,37.244,39.927c9.396,1.538,18.587,1.207,27.578-2.015c5.895-2.112,11.097-5.339,15.136-10.221
					c7.551-9.129,6.762-19.664-2.161-27.469c-6.734-5.893-14.955-8.611-23.538-10.195c-11.453-2.114-23.045-3.434-34.433-5.957
					c-18.742-4.154-37.021-9.609-53.938-18.948c-5.406-2.984-10.558-6.351-15.354-10.241c-20.747-16.829-29.435-38.697-27.771-65.181
					c2.025-32.235,17.255-56.747,43.043-75.302c6.094-4.384,12.557-8.161,19.294-11.468c19.612-9.627,40.377-15.241,61.986-18.086
					c17.93-2.36,35.882-2.743,53.844-0.833c27.759,2.952,53.842,10.871,76.443,27.847c30.162,22.655,44.092,53.342,43.821,90.78
					c-0.025,3.422-0.096,3.491-4.19,3.411c-29.155-0.571-58.31-1.175-87.464-1.762c-3.665-0.074-7.332-0.16-10.997-0.151
					c-1.635,0.004-2.277-0.55-2.426-2.287c-1.36-15.845-10.594-25.593-25.039-30.346c-13.936-4.586-28.158-4.785-42.107,0.536
					c-5.043,1.923-9.48,4.872-13.022,9.001c-6.903,8.051-5.793,16.683,2.987,22.599c6.493,4.375,13.918,6.314,21.532,7.416
					c16.149,2.334,32.247,4.91,48.041,9.085c15.967,4.221,31.486,9.63,45.912,17.835c8.884,5.053,17.029,11.06,24.021,18.567
					c12.451,13.362,18.904,29.278,19.586,47.418c1.386,36.868-14.135,65.689-42.8,87.933c-17.762,13.784-38.053,22.441-59.687,28.01
					c-29.016,7.466-58.496,9.509-88.232,6.09c-28.135-3.236-54.168-12.325-76.453-30.347c-23.434-18.948-37.506-43.31-41.2-73.412
					c-0.897-7.31-0.766-14.641-0.297-21.962c0.251-3.925,0.371-3.961,4.536-3.965C625.807,286.286,642.805,286.297,659.802,286.297z"/>
				<path fill={ otherColor } d="M274.821,388.918c5.289-13.952,10.34-27.312,15.416-40.661C324.4,258.396,358.57,168.535,392.738,78.674
					c0.413-1.088,0.959-2.145,1.221-3.267c0.463-1.983,1.613-2.743,3.594-2.534c0.988,0.104,1.997,0.016,2.997,0.016
					c59.318,0,118.636,0,177.954,0c0.667,0,1.333,0.009,2-0.001c4.113-0.064,3.719,0.053,3.809,3.84
					c0.677,28.815,1.375,57.631,2.05,86.447c0.935,39.809,1.825,79.619,2.791,119.428c0.814,33.646,1.723,67.288,2.558,100.934
					c0.155,6.259,0.927,5.552-5.477,5.555c-34.158,0.021-68.315,0.01-102.474,0.01c-0.5,0-1,0.002-1.5-0.001
					c-4.75-0.023-4.286,0.378-4.287-4.298c-0.004-16.829-0.007-33.658,0.001-50.486c0.004-4.588,0.266-4.27-4.377-4.27
					c-19.994,0.002-39.988,0-59.983,0.002c-5.004,0.001-4.993,0.012-6.559,4.621c-5.721,16.852-11.488,33.689-17.139,50.564
					c-0.87,2.6-1.833,3.973-5.059,3.959c-35.657-0.147-71.314-0.093-106.971-0.101C277.062,389.092,276.237,389.002,274.821,388.918z
					M433.928,255.981c9.163,0,17.799,0,26.437,0c4.998,0,9.998-0.055,14.994,0.025c1.886,0.031,2.822-0.576,2.622-2.559
					c-0.066-0.659-0.008-1.331-0.008-1.998c0.001-39.82,0.003-79.64-0.004-119.459c-0.001-1.164-0.097-2.328-0.147-3.492
					c-0.252,0.013-0.503,0.025-0.754,0.037C462.737,170.871,448.408,213.203,433.928,255.981z"/>
				<path fill={ otherColor } d="M2118.627,308.179c48.66,0,96.614,0,145.057,0c-1.048,6.118-2,11.83-3.008,17.532
					c-3.531,19.998-7.141,39.982-10.551,60.001c-0.469,2.749-1.537,3.49-4.174,3.44c-7.997-0.148-15.998-0.054-23.998-0.054
					c-74.83,0-149.66,0-224.489,0c-1.812,0-3.621,0-5.65,0c0.23-6.198,1.623-11.847,2.544-17.54c4.519-27.938,9.17-55.854,13.808-83.773
					c3.845-23.154,7.766-46.296,11.592-69.454c3.853-23.325,7.604-46.666,11.443-69.994c3.972-24.145,8.025-48.275,11.942-72.429
					c0.367-2.265,1.14-3.33,3.479-3.036c0.819,0.104,1.665,0.016,2.498,0.016c77.996,0,155.991,0,233.989,0c1.645,0,3.289,0,5.229,0
					c-0.248,5.505-1.581,10.468-2.469,15.476c-3.603,20.322-7.342,40.62-11.04,60.925c-0.742,4.071-0.763,4.1-5.11,4.101
					c-39.664,0.009-79.33,0.005-118.994,0.005c-1,0-2.017,0.117-2.997-0.022c-2.731-0.388-3.756,0.885-4.161,3.479
					c-1.694,10.854-3.55,21.683-5.321,32.524c-0.048,0.293,0.166,0.629,0.383,1.368c37.313,0,74.752,0,112.363,0
					c0.438,3.269-0.636,5.963-1.113,8.658c-4.052,22.781-8.254,45.535-12.43,68.294c-0.735,4.008-0.775,4.027-5.044,4.029
					c-33.665,0.008-67.33,0.004-100.995,0.004c-6.662,0-6.692-0.004-7.833,6.729C2121.938,288.138,2120.35,297.819,2118.627,308.179z"/>
				<path fill={ otherColor } d="M988.057,388.885c12.863-77.779,25.65-155.107,38.524-232.953c-30.323,0-60.252,0-90.521,0
					c2.028-11.977,3.98-23.442,5.91-34.911c2.431-14.443,4.754-28.906,7.304-43.328c1.013-5.73,0.157-4.784,5.394-4.786
					c93.811-0.025,187.62-0.019,281.43-0.019c1.314,0,2.629,0,4.279,0c-0.073,6.441-1.672,12.367-2.623,18.344
					c-2.722,17.093-5.745,34.137-8.653,51.2c-0.587,3.444-1.397,6.867-1.717,10.335c-0.245,2.657-1.473,3.184-3.86,3.168
					c-13.327-0.088-26.658-0.042-39.987-0.042c-12.997,0-25.994-0.009-38.991,0.005c-3.962,0.005-4.051,0.02-4.689,3.761
					c-2.938,17.227-5.829,34.462-8.724,51.696c-3.886,23.144-7.778,46.289-11.632,69.436c-3.854,23.149-7.651,46.311-11.497,69.462
					c-1.964,11.82-4.074,23.618-5.907,35.458c-0.424,2.739-1.485,3.475-4.146,3.469c-35.825-0.08-71.649-0.055-107.473-0.063
					C989.826,389.115,989.176,388.995,988.057,388.885z"/>
				<path fill={ otherColor } d="M178.66,73.31c-12.261,74.611-24.461,148.849-36.784,223.839c8.106,0.156,15.999,0.057,23.886,0.074
					c7.833,0.018,15.665,0.004,23.498,0.004c7.999,0,15.998,0,23.997,0s15.998,0,23.997,0c7.833,0,15.665,0,23.498,0
					c7.977,0,15.954,0,24.571,0c-4.802,30.799-9.535,61.149-14.294,91.669c-85.979,0-171.753,0-257.918,0
					c1.059-6.574,2.068-12.926,3.106-19.271c5.641-34.5,11.264-69.002,16.941-103.496c4.488-27.264,9.082-54.51,13.561-81.775
					c4.48-27.269,8.87-54.553,13.315-81.827c1.446-8.872,2.981-17.729,4.389-26.607c0.333-2.099,0.984-3.096,3.439-3.09
					c36.329,0.09,72.658,0.064,108.987,0.082C177.327,72.911,177.804,73.114,178.66,73.31z"/>
				<path fill="#020202" d="M1747.816,202.724c0.808-5.21,1.637-10.418,2.41-15.633c0.227-1.52,0.771-2.508,2.534-2.19
					c1.757,0.315,3.63-0.053,5.862,1.163c-3.353,5.625-6.674,11.195-9.993,16.764C1748.359,202.793,1748.088,202.759,1747.816,202.724z"/>
				<path fill={ otherColor } d="M1963.902,297.226c-7.832,0-15.664,0-23.496,0c-7.998,0-15.996,0-23.995,0c-7.998,0-15.996,0-23.995,0
					c-7.832,0-15.663,0.014-23.495-0.006c-7.897-0.019-15.801,0.086-23.925-0.076c12.334-75.058,24.553-149.41,36.765-223.729
					c-2.03-0.885-3.887-0.504-5.688-0.507c-17.997-0.032-35.993-0.02-53.989-0.02c-16.83,0-33.661,0.065-50.489-0.065
					c-2.727-0.021-3.948,0.866-4.112,3.479c-0.041,0.661-0.225,1.312-0.332,1.969c-2.976,18.066-5.959,36.131-8.916,54.201
					c-0.908,5.552-1.744,11.116-2.754,17.564c2.28-1.09,3.639-1.693,4.954-2.376c4.932-2.56,9.859-5.124,14.787-7.688l8.777,109.975
					c-18.897,31.451-37.795,62.902-56.793,94.294c-3.42,5.649-5.882,11.389-6.741,17.97c-1.154,8.851-2.768,17.638-4.224,26.694
					c86.141,0,171.907,0,257.904,0c4.757-30.507,9.49-60.858,14.296-91.681C1979.848,297.226,1971.875,297.226,1963.902,297.226z
					M1827.217,116.333h-4.93C1823.993,115.996,1825.654,116.017,1827.217,116.333z"/>
				<path fill={ mColor } d="M1546.126,2c0.597,1.844-0.652,3.207-1.32,4.659c-23.257,50.897-46.548,101.787-69.878,152.651
					c-0.668,1.463-1.004,3.19-2.471,4.253c-1.068-1.066-0.972-2.439-1.072-3.703c-1.146-14.444-2.208-28.899-3.396-43.339
					c-1.486-18.088-3.109-36.166-4.603-54.254c-1.164-14.106-2.247-28.224-3.31-42.337c-0.448-5.969-1.466-11.908-0.97-17.93
					c-66.679,0-133.357,0-200.036,0c-0.255,0.969-0.593,1.92-0.757,2.9c-4.051,24.298-8.084,48.602-12.127,72.9
					c-5.982,35.955-11.975,71.913-17.959,107.868c-5.329,32.016-10.663,64.031-15.979,96.049c-5.016,30.217-10.049,60.426-14.962,90.655
					c-0.879,5.385-2.255,10.719-2.314,16.185c1.874,1.052,3.744,0.615,5.537,0.615c40.833,0.027,81.665,0.022,122.499,0.022
					c1.001,0,2.002-0.068,2.997,0.008c2.106,0.167,2.812-0.826,3.143-2.861c2.898-17.911,5.937-35.797,8.885-53.698
					c3.813-23.16,7.562-46.325,11.387-69.483c3.227-19.543,6.511-39.071,9.779-58.606c1.368-8.18,2.767-16.357,4.146-24.531
					c1.999,15.092,3.396,30.153,4.694,45.218c1.488,17.253,2.843,34.521,4.345,51.778c1.473,16.92,3.088,33.826,4.581,50.741
					c1.691,19.075,3.368,38.151,4.931,57.238c0.365,4.459,0.531,4.199,4.787,4.199c29.335-0.003,58.667-0.059,87.999,0.072
					c3.212,0.015,4.664-1.168,5.917-3.955c4.7-10.485,9.656-20.857,14.52-31.27c27.2-58.25,54.402-116.504,81.604-174.75
					c0.531-1.141,0.824-2.459,2.413-3.362c-11.708,71.128-23.368,141.955-35.011,212.674c1.018,0.319,1.327,0.505,1.64,0.507
					c29.498,0.035,58.995,0.029,88.496,0.125c2.257,0.008,3.226-1.139,4.207-2.799c4.081-6.878,8.198-13.735,12.364-20.56
					c1.486-2.435,2.592-4.991,3.467-7.709c4.415-13.681,7.463-27.651,8.605-41.979c0.398-4.993,0.555-9.987,0.466-14.977h29.633
					c0.072-0.435,0.14-0.872,0.214-1.308c3.247-19.708,6.56-39.406,9.791-59.118c3.478-21.188,6.957-42.376,10.309-63.585
					c1.053-6.658,2.723-13.235,2.925-20.014c0.619-3.763,1.235-7.526,1.854-11.288c3.247-19.707,6.559-39.405,9.788-59.115
					c3.479-21.187,6.959-42.379,10.312-63.585c1.113-7.046,2.939-14.001,2.965-21.207C1676.148,2,1611.137,2,1546.126,2z
					M1602.49,130.364c0.766-5.406,4.195-8.62,9.181-10.084c3.636-1.067,7.186-0.339,10.617,1.438
					c9.903,5.141,19.88,10.139,29.844,15.17c9.974,5.036,19.955,10.055,29.931,15.088c-25.191-4.161-54.802-9.311-79.841-3.667
					c-0.003-4.156-0.009-8.312-0.011-12.467C1602.211,134.019,1602.232,132.17,1602.49,130.364z"/>
				{/* <path fill={ yColor } stroke="#FFFFFF" stroke-width="14" d="M1845.516,181.959c-0.191-18.57-0.35-37.139-0.555-55.71c-0.052-4.669,0.195-9.349-0.315-14.012 */}
				<path fill={ yColor } d="M1845.516,181.959c-0.191-18.57-0.35-37.139-0.555-55.71c-0.052-4.669,0.195-9.349-0.315-14.012
					c-0.722-6.604-5.359-11.905-11.806-13.491c-4.255-1.051-8.261-0.241-12.085,1.745c-29.105,15.096-58.21,30.183-87.31,45.287
					c-6.547,3.398-9.725,9.729-8.606,16.995c1.04,6.751,6.181,12.031,12.751,13.018c3.059,0.457,6.135,0.791,9.201,1.19
					c2.016,0.265,4.029,0.552,6.111,0.837c-2.651,5.362-42.01,70.408-43.153,71.335c-0.194-0.803-0.36-1.607-0.58-2.399
					c-1.464-5.271-2.678-10.623-4.713-15.727c-0.153-0.386-0.062-0.867-0.085-1.302c-0.357-0.23-0.383-0.63-0.5-0.984
					c-5.819-17.096-13.455-33.352-22.59-48.917c-0.274-0.471-0.708-0.887-0.672-1.641c0.616-0.034,1.225-0.075,1.828-0.099
					c1.959-0.072,3.909-0.126,5.866-0.285c6.122-0.495,13.757-5.677,14.059-15.342c0.199-6.347-2.623-11.244-8.265-14.264
					c-5.288-2.829-10.679-5.461-16.029-8.164c-23.777-12.017-47.556-24.029-71.332-36.044c-3.705-1.872-7.58-2.271-11.602-1.313
					c-3.573,0.848-6.427,2.943-8.823,5.597c-1.077,1.187-2.104,2.638-2.403,4.246c-0.426,2.282-0.847,4.579-0.834,6.94
					c0.105,27.667,0.168,55.334,0.248,83.003c0.011,4.485-0.041,8.972,0.068,13.455c0.167,6.828,4.743,12.787,11.391,14.71
					c6.535,1.896,13.15-0.129,17.334-5.802c0.971-1.324,1.807-2.747,2.676-4.136c1.335-2.129,2.647-4.277,3.957-6.395
					c0.511,0.211,0.666,0.578,0.879,0.882c7.523,10.737,14.082,22.014,19.127,34.144c6.659,16.014,10.346,32.691,10.975,50.021
					c0.211,5.803,0.156,11.615-0.268,17.421c-0.436,5.925-1.133,11.812-2.12,17.662c-1.854,10.97-4.708,21.694-8.188,32.256
					c-0.296,0.904-0.619,1.782-1.115,2.6c-4.014,6.635-8.017,13.278-12.019,19.919c-0.227,0.386-0.447,0.769-0.673,1.146
					c-10.499,17.475-20.988,34.955-31.51,52.417c-2.092,3.478-3.088,7.172-2.484,11.211c0.728,4.877,3.128,8.812,7.32,11.39
					c9.194,5.645,18.491,11.123,27.715,16.715c1.595,0.966,3.268,1.586,5.074,1.952c7.207,1.451,13.707-1.367,17.482-7.652
					c37.804-62.908,75.604-125.821,113.406-188.732c15.018-24.992,30.034-49.988,45.056-74.979c0.342-0.566,0.726-1.108,1.096-1.682
					c0.767,0.561,1.091,1.259,1.524,1.866c2.389,3.338,4.693,6.735,7.127,10.037c4.067,5.521,9.705,7.334,16.226,6.153
					c7.506-1.362,12.592-7.922,12.508-15.626C1845.77,202.926,1845.623,192.44,1845.516,181.959z"/>
			</svg>
		</Box>

		return Logo
	}
