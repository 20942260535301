///////////////////////////////
// Description
///////////////////////////////

	/*
		DESCRIPTION / USAGE:
			Model files contains data and business logic specific to an individual database collection type

		TODO:

	*/


///////////////////////////////
// Imports
///////////////////////////////

import {
	makeStripePostRequest
} from 'app/pages/home/user_settings'
import {
	DatabaseRef_RootClient_Document
} from 'rfbp_aux/services/database_endpoints/standard_database_endpoints'
import {
	DatabaseSetMergeDocument
} from 'rfbp_core/services/database_management'
import {
	getProp
} from 'rfbp_core/services/helper_functions'
import {
	TsInterface_UnspecifiedObject,
	TsType_Boolean,
	TsType_String,
	TsType_UnknownPromise
} from 'rfbp_core/typescript/global_types'

///////////////////////////////
// Typescript
///////////////////////////////


///////////////////////////////
// Variables
///////////////////////////////

	// Displayed Translatable Strings


///////////////////////////////
// Functions
///////////////////////////////

	// Staging
	export const StripeStaging_ListSubscriptionProducts = (
		pricingType: TsType_String,
	): TsType_UnknownPromise => {
		return new Promise(( resolve, reject ) => {
			makeStripePostRequest("staging-all-products", { pricingType: pricingType }).then(( res_MSGR ) => {
				let subscriptionOptions: TsInterface_UnspecifiedObject = {}
				if(
					res_MSGR != null &&
					// @ts-expect-error
					res_MSGR["data"] != null &&
					// @ts-expect-error
					res_MSGR["data"]["prices"] != null &&
					// @ts-expect-error
					res_MSGR["data"]["prices"]["data"] != null
				){
					// @ts-expect-error
					subscriptionOptions = res_MSGR["data"]["prices"]["data"]
					// us_setSubscriptionOptionsData( subscriptionOptions )
				}
				resolve( subscriptionOptions )
			}).catch(( rej_MSGR ) => {
				console.error( rej_MSGR )
				reject( rej_MSGR )
			})
		})
	}

	export const StripeStaging_ListUsersSubscriptions = (
		clientKey: TsType_String
	): TsType_UnknownPromise => {
		return new Promise(( resolve, reject ) => {
			makeStripePostRequest("staging-list-subscriptions", { clientKey: clientKey }).then(( res_MSGR ) => {
				let subscriptionData: TsInterface_UnspecifiedObject = {}
				if(
					res_MSGR != null &&
					// @ts-expect-error
					res_MSGR["data"] != null &&
					// @ts-expect-error
					res_MSGR["data"]["subscriptions"] != null &&
					// @ts-expect-error
					res_MSGR["data"]["subscriptions"]["data"] != null
				){
					// @ts-expect-error
					subscriptionData = res_MSGR["data"]["subscriptions"]["data"]
				}
				resolve( subscriptionData )
				// us_setStripeActiveSubscriptionData( subscriptionData )
				// us_setStripeSubscriptionViewMode( "customer_exists" )
			}).catch(( rej_MSGR ) => {
				reject( rej_MSGR )
			})
		})
	}

	export const StripeStaging_CreateCustomer = (
		clientKey: TsType_String,
		email: TsType_String,
	): TsType_UnknownPromise => {
		return new Promise(( resolve, reject ) => {
			makeStripePostRequest("staging-create-customer", { clientKey: clientKey, email: email }).then(( res_MSGR ) => {
				resolve( res_MSGR )
				// us_setStripeSubscriptionViewMode( "customer_exists_unknown_subscription" )
			}).catch(( rej_MSGR ) => {
				console.error( rej_MSGR )
			})
		})
	}

	export const StripeStaging_UpdateDatabaseSubscriptionRecords = (
		clientKey: TsType_String,
		userActiveStripeSubscriptionData: TsInterface_UnspecifiedObject,
		userDatabaseStripeData: TsInterface_UnspecifiedObject,
		alwaysSaveTimestamp: TsType_Boolean
	) => {
		return new Promise(( resolve, reject ) => {
			let updateObject: TsInterface_UnspecifiedObject = {
				timestamp_last_checked_staging_subscription_status: new Date(),
				stripe_data: {
					staging_subscriptions: {}
				}
			}
			let updateDatabase: TsType_Boolean = false
			let hasActiveSubscription: TsType_Boolean = false
			// Loop through staging subscriptions
			for( let loopIndex in userActiveStripeSubscriptionData ){
				let loopSubscription: TsInterface_UnspecifiedObject = userActiveStripeSubscriptionData[loopIndex]
				let loopSubscriptionID = getProp( loopSubscription, "id", null )
				let loopSubscriptionProductID: TsType_String = getProp( loopSubscription, "plan.id", null )
				if( loopSubscription["status"] === "active" ){
					hasActiveSubscription = true
				}
				if(
					userDatabaseStripeData != null &&
					(
						userDatabaseStripeData["stripe_data"] == null ||
						userDatabaseStripeData["stripe_data"]["staging_subscriptions"] == null ||
						userDatabaseStripeData["stripe_data"]["staging_subscriptions"][loopSubscriptionID] == null ||
						userDatabaseStripeData["stripe_data"]["staging_subscriptions"][loopSubscriptionID]["stripe_status"] == null ||
						userDatabaseStripeData["stripe_data"]["staging_subscriptions"][loopSubscriptionID]["stripe_status"] !== loopSubscription["status"]
					)
				){
					updateDatabase = true
					if( updateObject["stripe_data"]["staging_subscriptions"][loopSubscriptionID] == null ){
						updateObject["stripe_data"]["staging_subscriptions"][loopSubscriptionID] = {
							subscription_id: loopSubscriptionID,
							product_id: loopSubscriptionProductID
						}
					}
					updateObject["stripe_data"]["staging_subscriptions"][loopSubscriptionID]["stripe_status"] = loopSubscription["status"]
				}
				if(
					loopSubscription != null &&
					loopSubscription["current_period_start"] != null &&
					userDatabaseStripeData != null &&
					(
						userDatabaseStripeData["stripe_data"] == null ||
						userDatabaseStripeData["stripe_data"]["staging_subscriptions"] == null ||
						userDatabaseStripeData["stripe_data"]["staging_subscriptions"][loopSubscriptionID] == null ||
						userDatabaseStripeData["stripe_data"]["staging_subscriptions"][loopSubscriptionID]["current_period_start"] == null ||
						userDatabaseStripeData["stripe_data"]["staging_subscriptions"][loopSubscriptionID]["current_period_start"] !== ( parseInt( loopSubscription["current_period_start"] ) * 1000 )
					)
				){
					updateDatabase = true
					if( updateObject["stripe_data"]["staging_subscriptions"][loopSubscriptionID] == null ){
						updateObject["stripe_data"]["staging_subscriptions"][loopSubscriptionID] = {
							subscription_id: loopSubscriptionID,
							product_id: loopSubscriptionProductID
						}
					}
					updateObject["stripe_data"]["staging_subscriptions"][loopSubscriptionID]["current_period_start"] = parseInt( loopSubscription["current_period_start"] ) * 1000
				}
				if(
					loopSubscription != null &&
					loopSubscription["current_period_end"] != null &&
					userDatabaseStripeData != null &&
					(
						userDatabaseStripeData["stripe_data"] == null ||
						userDatabaseStripeData["stripe_data"]["staging_subscriptions"] == null ||
						userDatabaseStripeData["stripe_data"]["staging_subscriptions"][loopSubscriptionID] == null ||
						userDatabaseStripeData["stripe_data"]["staging_subscriptions"][loopSubscriptionID]["current_period_end"] == null ||
						userDatabaseStripeData["stripe_data"]["staging_subscriptions"][loopSubscriptionID]["current_period_end"] !== ( parseInt( loopSubscription["current_period_end"] ) * 1000 )
					)
				){
					updateDatabase = true
					if( updateObject["stripe_data"]["staging_subscriptions"][loopSubscriptionID] == null ){
						updateObject["stripe_data"]["staging_subscriptions"][loopSubscriptionID] = {
							subscription_id: loopSubscriptionID,
							product_id: loopSubscriptionProductID
						}
					}
					updateObject["stripe_data"]["staging_subscriptions"][loopSubscriptionID]["current_period_end"] = parseInt( loopSubscription["current_period_end"] ) * 1000
				}
			}
			// Save Control Access Data
			if( updateDatabase === true ){
				// // Save to Database
				updateObject["stripe_data"]["has_active_staging_subscription"] = hasActiveSubscription
				DatabaseSetMergeDocument( DatabaseRef_RootClient_Document( clientKey ), updateObject, {} ).then(( res_DSM ) => {
					resolve( res_DSM )
				}).catch(( rej_DSM ) => {
					reject( rej_DSM )
				})
			} else if(
				updateDatabase === false &&
				alwaysSaveTimestamp === true
			) {
				DatabaseSetMergeDocument( DatabaseRef_RootClient_Document( clientKey ), {
					timestamp_last_checked_staging_subscription_status: new Date(),
					stripe_data: {
						has_active_staging_subscription: hasActiveSubscription
					}
				}, {} ).then(( res_DSM ) => {
					resolve( res_DSM )
				}).catch(( rej_DSM ) => {
					reject( rej_DSM )
				})
			} else {
				resolve({success: true})
			}
		})
	}

	// Prod
	export const StripeProd_ListSubscriptionProducts = (
		pricingType: TsType_String
	): TsType_UnknownPromise => {
		return new Promise(( resolve, reject ) => {
			makeStripePostRequest("prod-all-products", { pricingType: pricingType }).then(( res_MSGR ) => {
				let subscriptionOptions: TsInterface_UnspecifiedObject = {}
				if(
					res_MSGR != null &&
					// @ts-expect-error
					res_MSGR["data"] != null &&
					// @ts-expect-error
					res_MSGR["data"]["prices"] != null &&
					// @ts-expect-error
					res_MSGR["data"]["prices"]["data"] != null
				){
					// @ts-expect-error
					subscriptionOptions = res_MSGR["data"]["prices"]["data"]
					// us_setSubscriptionOptionsData( subscriptionOptions )
				}
				resolve( subscriptionOptions )
			}).catch(( rej_MSGR ) => {
				console.error( rej_MSGR )
				reject( rej_MSGR )
			})
		})
	}

	export const StripeProd_ListUsersSubscriptions = (
		clientKey: TsType_String
	): TsType_UnknownPromise => {
		return new Promise(( resolve, reject ) => {
			makeStripePostRequest("prod-list-subscriptions", { clientKey: clientKey }).then(( res_MSGR ) => {
				let subscriptionData: TsInterface_UnspecifiedObject = {}
				if(
					res_MSGR != null &&
					// @ts-expect-error
					res_MSGR["data"] != null &&
					// @ts-expect-error
					res_MSGR["data"]["subscriptions"] != null &&
					// @ts-expect-error
					res_MSGR["data"]["subscriptions"]["data"] != null
				){
					// @ts-expect-error
					subscriptionData = res_MSGR["data"]["subscriptions"]["data"]
				}
				resolve( subscriptionData )
				// us_setStripeActiveSubscriptionData( subscriptionData )
				// us_setStripeSubscriptionViewMode( "customer_exists" )
			}).catch(( rej_MSGR ) => {
				reject( rej_MSGR )
			})
		})
	}

	export const StripeProd_CreateCustomer = (
		clientKey: TsType_String,
		email: TsType_String,
	): TsType_UnknownPromise => {
		return new Promise(( resolve, reject ) => {
			makeStripePostRequest("prod-create-customer", { clientKey: clientKey, email: email }).then(( res_MSGR ) => {
				resolve( res_MSGR )
				// us_setStripeSubscriptionViewMode( "customer_exists_unknown_subscription" )
			}).catch(( rej_MSGR ) => {
				console.error( rej_MSGR )
			})
		})
	}

	export const StripeProd_UpdateDatabaseSubscriptionRecords = (
		clientKey: TsType_String,
		userActiveStripeSubscriptionData: TsInterface_UnspecifiedObject,
		userDatabaseStripeData: TsInterface_UnspecifiedObject,
		alwaysSaveTimestamp: TsType_Boolean
	) => {
		return new Promise(( resolve, reject ) => {
			let updateObject: TsInterface_UnspecifiedObject = {
				timestamp_last_checked_prod_subscription_status: new Date(),
				stripe_data: {
					prod_subscriptions: {}
				}
			}
			let updateDatabase: TsType_Boolean = false
			let hasActiveSubscription: TsType_Boolean = false
			// Loop through prod subscriptions
			for( let loopIndex in userActiveStripeSubscriptionData ){
				let loopSubscription: TsInterface_UnspecifiedObject = userActiveStripeSubscriptionData[loopIndex]
				let loopSubscriptionID = getProp( loopSubscription, "id", null )
				let loopSubscriptionProductID: TsType_String = getProp( loopSubscription, "plan.id", null )
				if( loopSubscription["status"] === "active" ){
					hasActiveSubscription = true
				}
				if(
					userDatabaseStripeData != null &&
					(
						userDatabaseStripeData["stripe_data"] == null ||
						userDatabaseStripeData["stripe_data"]["prod_subscriptions"] == null ||
						userDatabaseStripeData["stripe_data"]["prod_subscriptions"][loopSubscriptionID] == null ||
						userDatabaseStripeData["stripe_data"]["prod_subscriptions"][loopSubscriptionID]["stripe_status"] == null ||
						userDatabaseStripeData["stripe_data"]["prod_subscriptions"][loopSubscriptionID]["stripe_status"] !== loopSubscription["status"]
					)
				){
					updateDatabase = true
					if( updateObject["stripe_data"]["prod_subscriptions"][loopSubscriptionID] == null ){
						updateObject["stripe_data"]["prod_subscriptions"][loopSubscriptionID] = {
							subscription_id: loopSubscriptionID,
							product_id: loopSubscriptionProductID
						}
					}
					updateObject["stripe_data"]["prod_subscriptions"][loopSubscriptionID]["stripe_status"] = loopSubscription["status"]
				}
				if(
					loopSubscription != null &&
					loopSubscription["current_period_start"] != null &&
					userDatabaseStripeData != null &&
					(
						userDatabaseStripeData["stripe_data"] == null ||
						userDatabaseStripeData["stripe_data"]["prod_subscriptions"] == null ||
						userDatabaseStripeData["stripe_data"]["prod_subscriptions"][loopSubscriptionID] == null ||
						userDatabaseStripeData["stripe_data"]["prod_subscriptions"][loopSubscriptionID]["current_period_start"] == null ||
						userDatabaseStripeData["stripe_data"]["prod_subscriptions"][loopSubscriptionID]["current_period_start"] !== ( parseInt( loopSubscription["current_period_start"] ) * 1000 )
					)
				){
					updateDatabase = true
					if( updateObject["stripe_data"]["prod_subscriptions"][loopSubscriptionID] == null ){
						updateObject["stripe_data"]["prod_subscriptions"][loopSubscriptionID] = {
							subscription_id: loopSubscriptionID,
							product_id: loopSubscriptionProductID
						}
					}
					updateObject["stripe_data"]["prod_subscriptions"][loopSubscriptionID]["current_period_start"] = parseInt( loopSubscription["current_period_start"] ) * 1000
				}
				if(
					loopSubscription != null &&
					loopSubscription["current_period_end"] != null &&
					userDatabaseStripeData != null &&
					(
						userDatabaseStripeData["stripe_data"] == null ||
						userDatabaseStripeData["stripe_data"]["prod_subscriptions"] == null ||
						userDatabaseStripeData["stripe_data"]["prod_subscriptions"][loopSubscriptionID] == null ||
						userDatabaseStripeData["stripe_data"]["prod_subscriptions"][loopSubscriptionID]["current_period_end"] == null ||
						userDatabaseStripeData["stripe_data"]["prod_subscriptions"][loopSubscriptionID]["current_period_end"] !== ( parseInt( loopSubscription["current_period_end"] ) * 1000 )
					)
				){
					updateDatabase = true
					if( updateObject["stripe_data"]["prod_subscriptions"][loopSubscriptionID] == null ){
						updateObject["stripe_data"]["prod_subscriptions"][loopSubscriptionID] = {
							subscription_id: loopSubscriptionID,
							product_id: loopSubscriptionProductID
						}
					}
					updateObject["stripe_data"]["prod_subscriptions"][loopSubscriptionID]["current_period_end"] = parseInt( loopSubscription["current_period_end"] ) * 1000
				}
			}
			// Save Control Access Data
			if( updateDatabase === true ){
				// // Save to Database
				updateObject["stripe_data"]["has_active_prod_subscription"] = hasActiveSubscription
				DatabaseSetMergeDocument( DatabaseRef_RootClient_Document( clientKey ), updateObject, {} ).then(( res_DSM ) => {
					resolve( res_DSM )
				}).catch(( rej_DSM ) => {
					reject( rej_DSM )
				})
			} else if(
				updateDatabase === false &&
				alwaysSaveTimestamp === true
			) {
				DatabaseSetMergeDocument( DatabaseRef_RootClient_Document( clientKey ), {
					timestamp_last_checked_prod_subscription_status: new Date(),
					stripe_data: {
						has_active_prod_subscription: hasActiveSubscription
					}
				}, {} ).then(( res_DSM ) => {
					resolve( res_DSM )
				}).catch(( rej_DSM ) => {
					reject( rej_DSM )
				})
			} else {
				resolve({success: true})
			}
		})
	}