//======================================//
//		  ooOOOO BOILERPLATE TEMPLATE   //
//		 oo		 _____					//
//		_I__n_n__||_|| ________			//
//	  >(_________|_7_|-|______|			//
//	   /o ()() ()() o   oo  oo			//
//======================================//

///////////////////////////////
// Description
///////////////////////////////

	/*
		DESCRIPTION / USAGE:
			Main home page for authenticated users

		TODO:
			[ ] Auto navigate to proper page based on user role

	*/


///////////////////////////////
// Imports
///////////////////////////////

import {
	CategoryScale,
	Chart as ChartJS,
	Legend,
	LinearScale,
	LineElement,
	PointElement,
	Title,
	Tooltip
} from 'chart.js'
import React, {
	useContext,
	useEffect,
	useReducer,
	useState
} from 'react'
import {
	Line
} from 'react-chartjs-2'
import {
	Trans
} from 'react-i18next'
import {
	useNavigate
} from 'react-router-dom'
import {
	themeVariables
} from 'rfbp_aux/config/app_theme'
import {
	AuthenticatedContainer
} from 'rfbp_aux/containers/authenticated_container'
import {
	ApplicationPages
} from 'rfbp_aux/data/application_structure'
import {
	DatabaseRef_TimecardAnalysis_Query
} from 'rfbp_aux/services/database_endpoints/analysis/timecard'
import {
	Icon
} from 'rfbp_core/components/icons'
import {
	Context_RootData_ClientKey
} from 'rfbp_core/services/context'
import {
	DatabaseGetLiveCollection
} from 'rfbp_core/services/database_management'
import {
	dynamicSort,
	getProp,
	objectToArray,
	returnDateFromUnknownDateFormat,
	returnFormattedDateKey
} from 'rfbp_core/services/helper_functions'
import {
	getClientKey
} from 'rfbp_core/services/user_authentication'
import {
	TsInterface_UnspecifiedObject,
	TsType_Boolean,
	TsType_JSX,
	TsType_Number,
	TsType_String,
	TsType_Void,
	TsType_VoidFunction
} from 'rfbp_core/typescript/global_types'
import {
	Box,
	Card,
	Stack,
	Tooltip as MuiTooltip,
	Typography
} from '@mui/material/'

///////////////////////////////
// Typescript
///////////////////////////////


///////////////////////////////
// Variables
///////////////////////////////

	// Authenticated Nav Data
	const pageKey: TsType_String = ApplicationPages["HomePage"]["key"]

	// Displayed Translatable Strings
	// { sort-start } - displayed text - scoped sort plugin
	const s_EMPLOYEES: TsType_JSX = 							<Trans>Employees</Trans>
	const s_HOME: TsType_JSX = 									<Trans>Home</Trans>
	const s_NO_RECENT_LOCKED_TIMECARD_AUDITS_YET: TsType_JSX = 	<Trans>No Recent Locked Timecard Audits Yet</Trans>
	const s_TIMECARD_AUDITS: TsType_JSX = 						<Trans>Timecard Audits</Trans>
	// { sort-end } - displayed text

	const darkRedColor = "#790f18"


///////////////////////////////
// Functions
///////////////////////////////


///////////////////////////////
// Container
///////////////////////////////

	export const Container: React.FC = (): TsType_JSX => {

		// Props
		// const params = useParams()
		ChartJS.register(
			CategoryScale,
			LinearScale,
			PointElement,
			LineElement,
			Title,
			Tooltip,
			Legend
		)

		// Hooks - useContext, useState, useReducer, other
		// { sort-start } - hooks
		const [ us_lockedTimecardAnalyses, us_setLockedTimecardAnalyses ] = 			useState< TsInterface_UnspecifiedObject >( {} )
		const [ us_lockedTimecardAnalysesLoaded, us_setLockedTimecardAnalysesLoaded ] = useState< TsType_Boolean >( false )
		const un_routerNaviation = 														useNavigate()
		const ur_forceRerender = 														useReducer(() => ({}), {})[1] as () => TsType_Void
		const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = 					useContext( Context_RootData_ClientKey )
		// { sort-end } - hooks

		// Hooks - useEffect
		useEffect(() => {
			let unsubscribeLiveData: TsType_VoidFunction
			const updateLiveData = ( newData: TsInterface_UnspecifiedObject ) => {
				us_setLockedTimecardAnalyses( newData )
				us_setLockedTimecardAnalysesLoaded( true )
				ur_forceRerender()
			}
			let currentDate = new Date()
			let startTimestamp = new Date( currentDate.getFullYear(), currentDate.getMonth() - 1, currentDate.getDate() - 14 )
			let endTimestamp = new Date()
			getClientKey( uc_RootData_ClientKey, uc_setRootData_ClientKey ).then(( res_GCK ) => {
				unsubscribeLiveData = DatabaseGetLiveCollection( DatabaseRef_TimecardAnalysis_Query( res_GCK.clientKey, startTimestamp, endTimestamp ), updateLiveData )
			}).catch(( rej_GCK ) => {
				console.error( rej_GCK )
			})
			return () => {
				if (typeof unsubscribeLiveData === 'function'){
					unsubscribeLiveData()
				}
			}
		}, [ uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender, us_setLockedTimecardAnalyses])

		// Other Variables

		// Functions
		// const potentiallyRedirect = (): TsType_Void => {
			// if(
			// 	RootData_ClientPermissions != null &&
			// 	RootData_ClientPermissions["client_type"] != null &&
			// 	RootData_ClientUser != null &&
			// 	RootData_ClientUser["user_role"] != null &&
			// 	ClientTypes != null &&
			// 	ClientTypes[ RootData_ClientPermissions["client_type"] ] != null &&
			// 	ClientTypes[ RootData_ClientPermissions["client_type"] ]["user_roles"] != null &&
			// 	ClientTypes[ RootData_ClientPermissions["client_type"] ]["user_roles"][ RootData_ClientUser["user_role"] ] != null &&
			// 	ClientTypes[ RootData_ClientPermissions["client_type"] ]["user_roles"][ RootData_ClientUser["user_role"] ]["home_redirect_page"] != null &&
			// 	ClientTypes[ RootData_ClientPermissions["client_type"] ]["user_roles"][ RootData_ClientUser["user_role"] ]["home_redirect_page"]["key"] != null &&
			// 	ClientTypes[ RootData_ClientPermissions["client_type"] ]["user_roles"][ RootData_ClientUser["user_role"] ]["home_redirect_page"]["url"] != null
			// ){
			// 	let redirectPageKey = ClientTypes[ RootData_ClientPermissions["client_type"] ]["user_roles"][ RootData_ClientUser["user_role"] ]["home_redirect_page"]["key"]
			// 	setTimeout( () => {
			// 		if ( RootData_UserPermissions != null && RootData_UserPermissions[ redirectPageKey ] === true ){
			// 			// @ts-expect-error
			// 			routerNaviation( ClientTypes[ RootData_ClientPermissions["client_type"] ]["user_roles"][ RootData_ClientUser["user_role"] ]["home_redirect_page"]["url"]() )
			// 		}
			// 	}, 1)
			// }
		// }

		// Call Functions
		// potentiallyRedirect()

		// JSX Generation
		const returnJSX_HourColorBarChart = (
			summaryData: TsInterface_UnspecifiedObject
		): TsType_JSX => {
			let barChartJSX = <></>
			if( summaryData != null ){
				let totalEmployees = summaryData.green_hour_employees + summaryData.yellow_hour_employees + summaryData.red_hour_employees + summaryData.dark_red_hour_employees
				barChartJSX =
				<Box className="tw-mb-2">
					<Box sx={{ width: "100%", height: "40px", minWidth: "300px"}} className="tw-rounded-lg tw-overflow-auto">
						<Stack direction="row">
							<MuiTooltip title={ <>{ getProp(summaryData, "green_hour_employees", 0) } { s_EMPLOYEES } </> } placement="top">
								<Box
									className="tw-text-center tw-py-2"
									sx={{
										color: themeVariables.white,
										background: themeVariables.success_main,
										width: (getProp(summaryData, "green_hour_employees", 0) / totalEmployees * 100) + "%",
										height: "40px"
								}}>
									{ (getProp(summaryData, "green_hour_employees", 0) / totalEmployees * 100).toFixed(1) + "%" }
								</Box>
							</MuiTooltip>
							<MuiTooltip title={ <>{ getProp(summaryData, "yellow_hour_employees", 0) } { s_EMPLOYEES } </> } placement="top">
								<Box
									className="tw-text-center tw-py-2"
									sx={{
										color: themeVariables.white,
										background: themeVariables.warning_main,
										width: (getProp(summaryData, "yellow_hour_employees", 0) / totalEmployees * 100) + "%",
										height: "40px"
								}}>
									{ (getProp(summaryData, "yellow_hour_employees", 0) / totalEmployees * 100).toFixed(1) + "%" }
								</Box>
							</MuiTooltip>
							<MuiTooltip title={ <>{ getProp(summaryData, "red_hour_employees", 0) } { s_EMPLOYEES } </> } placement="top">
								<Box
									className="tw-text-center tw-py-2"
									sx={{
										color: themeVariables.white,
										background: themeVariables.error_main,
										width: (getProp(summaryData, "red_hour_employees", 0) / totalEmployees * 100) + "%",
										height: "40px"
								}}>
									{ (getProp(summaryData, "red_hour_employees", 0) / totalEmployees * 100).toFixed(1) + "%" }
								</Box>
							</MuiTooltip>
							<MuiTooltip title={ <>{ getProp(summaryData, "dark_red_hour_employees", 0) } { s_EMPLOYEES } </> } placement="top">
								<Box
									className="tw-text-center tw-py-2"
									sx={{
										color: themeVariables.white,
										background: darkRedColor,
										width: (getProp(summaryData, "dark_red_hour_employees", 0) / totalEmployees * 100) + "%",
										height: "40px"
								}}>
									{ (getProp(summaryData, "dark_red_hour_employees", 0) / totalEmployees * 100).toFixed(1) + "%" }
								</Box>
							</MuiTooltip>
						</Stack>
					</Box>
				</Box>
			}
			return barChartJSX
		}

		const returnJSX_HoursLineChart = (
			sortedData: TsInterface_UnspecifiedObject
		): TsType_JSX => {
			// Generate Data
			let chartLabels: TsType_String[] = []
			let expectedDataset: TsType_Number[] = []
			let actualDataset: TsType_Number[] = []
			let totalPaidHoursDataset: TsType_Number[] = []
			for( let loopIndex in sortedData ){
				let loopData = sortedData[ loopIndex ]
				if( loopData != null && loopData["date"] != null ){
					chartLabels.push( loopData["date"] )
					expectedDataset.push( loopData["total_expected_route_hours"] )
					actualDataset.push( loopData["total_included_work_hours"] )
					totalPaidHoursDataset.push( loopData["total_all_hours"] )
				}
			}
			// Options
			let options = {
				responsive: true,
				plugins: {
					legend: {
						position: 'bottom' as const,
					},
					title: {
						display: false,
						text: '',
					},
				},
			}
			// Data
			let labels = chartLabels
			let data = {
				labels,
				datasets: [
					{
						label: 'Route Hours',
						data: expectedDataset,
						borderColor: themeVariables.success_main,
						backgroundColor: themeVariables.success_main,
					},
					{
						label: 'Work Hours',
						data: actualDataset,
						borderColor: themeVariables.error_main,
						backgroundColor: themeVariables.error_main
					},
					{
						label: 'Total Paid Hours',
						data: totalPaidHoursDataset,
						borderColor: themeVariables.warning_main,
						backgroundColor: themeVariables.warning_main,
					},
				],
			}
			// Chart JSX
			let scatterPlotJSX =
			<Card className="tw-mt-2">
				<Line options={options} data={data} />
			</Card>
			// Return
			return scatterPlotJSX
		}

		const returnJSX_TimecardAnalysisResults = (): TsType_JSX => {
			// Clean Data
			let cleanedData: TsInterface_UnspecifiedObject = {}
			for( let loopAuditKey in us_lockedTimecardAnalyses ){
				let loopAudit = us_lockedTimecardAnalyses[ loopAuditKey ]
				if(
					loopAudit != null &&
					loopAudit["status"] === "active" &&
					loopAudit["locked_summary_dates"] != null &&
					loopAudit["locked_summary_dates"]["delivery_first_date"] != null
				){
					let lockedSummaryData = getProp( loopAudit, "locked_summary_data", {} )
					cleanedData[ loopAuditKey ] = {
						date: returnFormattedDateKey(  returnDateFromUnknownDateFormat( loopAudit["locked_summary_dates"]["delivery_first_date"] ) ),
						total_all_hours: parseFloat( getProp( lockedSummaryData, "total_all_hours", 0 ).toFixed(1) ),
						total_expected_route_hours: parseFloat( getProp( lockedSummaryData, "total_expected_route_hours", 0 ).toFixed(1) ),
						total_included_work_hours: parseFloat( getProp( lockedSummaryData, "total_included_work_hours", 0 ).toFixed(1) ),
						total_work_minus_expected_route_hours: parseFloat( getProp( lockedSummaryData, "total_work_minus_expected_route_hours", 0 ).toFixed(1) ),
						dark_red_hour_employees: getProp( lockedSummaryData, "dark_red_hour_employees", 0 ),
						green_hour_employees: getProp( lockedSummaryData, "green_hour_employees", 0 ),
						red_hour_employees: getProp( lockedSummaryData, "red_hour_employees", 0 ),
						yellow_hour_employees: getProp( lockedSummaryData, "yellow_hour_employees", 0 ),
					}
				}
			}
			let sortedData = objectToArray( cleanedData ).sort( dynamicSort( "date", "asc" ) )
			// JSX
			let resultsJSX = <></>
			if( us_lockedTimecardAnalysesLoaded === true ){
				if( objectToArray( us_lockedTimecardAnalyses ).length > 0 ){
					resultsJSX =
					<Box>
						<Box className="tw-text-left">
							<Typography variant="h6" sx={{ fontWeight: "700" }}>
								{ s_TIMECARD_AUDITS }
								<Icon
									className='tw-ml-2 tw-opacity-30 hover:tw-opacity-100 tw-cursor-pointer'
									icon="magnifying-glass-chart"
									sx={{
										"&:hover": {
											color: themeVariables.info_main,
										}
									}}
									onClick={ () => {
										un_routerNaviation( ApplicationPages.AuditTimecardsIndexPage.url() )
									}}
								/>
							</Typography>
							{ returnJSX_HoursLineChart( sortedData ) }
							<Box className="tw-mt-2">
								{sortedData.map(( timecardAudit: TsInterface_UnspecifiedObject, timecardAuditIndex: TsType_Number ) => (
									<React.Fragment key={timecardAuditIndex}>
										<Typography variant="body1" sx={{ fontWeight: "700" }}>{ timecardAudit["date"] }</Typography>
										{ returnJSX_HourColorBarChart( timecardAudit ) }
									</React.Fragment>
								))}
							</Box>
						</Box>
					</Box>
				} else {
					resultsJSX =
					<Box>
						<Box className="tw-text-left">
							<Typography variant="h6" sx={{ fontWeight: "700" }}>
								{ s_TIMECARD_AUDITS }
								<Icon
									className='tw-ml-2 tw-opacity-30 hover:tw-opacity-100 tw-cursor-pointer'
									icon="magnifying-glass-chart"
									sx={{
										"&:hover": {
											color: themeVariables.info_main,
										}
									}}
									onClick={ () => {
										un_routerNaviation( ApplicationPages.AuditTimecardsIndexPage.url() )
									}}
								/>
							</Typography>
						</Box>
						<Box className="tw-text-center tw-opacity-30 tw-pt-2 tw-italic">
							<Typography variant="body1">{ s_NO_RECENT_LOCKED_TIMECARD_AUDITS_YET }</Typography>
						</Box>
					</Box>
				}
			}
			return resultsJSX
		}

		const returnJSX_Page = (): TsType_JSX => {
			let pageJSX: TsType_JSX =
			<AuthenticatedContainer pageHeader={s_HOME} pageKey={pageKey} content={
				<Box className="tw-text-center tw-m-auto" sx={{ maxWidth: "800px" }}>
					{ returnJSX_TimecardAnalysisResults() }
				</Box>
			}/>
			return pageJSX
		}

		// Render
		return <>{returnJSX_Page()}</>
	}