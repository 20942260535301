///////////////////////////////
// Description
///////////////////////////////

	/*
		DESCRIPTION / USAGE:
			Function to update user client keys

		TODO:

	*/


///////////////////////////////
// Imports
///////////////////////////////

import {
	Trans
} from 'react-i18next'
import {
	generateAvailableUserLevelPermissions,
	TsType_ClientTypes,
	TsType_UserRoles
} from 'rfbp_aux/data/application_structure'
import {
	cloudFunctionManageRequest
} from 'rfbp_aux/services/cloud_functions'
import {
	DatabaseRef_GlobalUser_Document
} from 'rfbp_aux/services/database_endpoints/database_endpoints'
import {
	CleanFormData,
	TsInterface_FormInputs
} from 'rfbp_core/components/form'
import {
	TsInterface_RootData_ClientPermissions
} from 'rfbp_core/services/context'
import {
	DatabaseSetMergeDocument
} from 'rfbp_core/services/database_management'
import {
	getProp
} from 'rfbp_core/services/helper_functions'
// import { getClientKey } from 'rfbp_core/services/user_authentication'
import {
	TsInterface_CatchError,
	TsInterface_GenericPromiseReject,
	TsInterface_GenericPromiseResolve,
	TsInterface_UnspecifiedObject,
	TsType_JSX,
	TsType_Null,
	TsType_String,
	TsType_Unknown,
	TsType_VoidFunctionSingleStringParam
} from 'rfbp_core/typescript/global_types'

///////////////////////////////
// Typescript
///////////////////////////////


///////////////////////////////
// Variables
///////////////////////////////

	// Displayed Translatable Strings
	const s_FAILED_TO_UPDATE_USER_CLIENT_KEY: TsType_JSX = 								<Trans>Failed to update user client key</Trans>
	const s_FAILED_TO_UPDATE_USER: TsType_JSX = 										<Trans>Failed to update user </Trans>
	const s_MISSING_REQUIRED_PARAMETERS: TsType_JSX = 									<Trans>Missing required parameters</Trans>
	const s_FAILED_TO_CREATE_USER: TsType_JSX = 										<Trans>Failed to create user</Trans>


///////////////////////////////
// Functions
///////////////////////////////

	const updateUserClientKeyProper = ( userKey: TsType_String, clientKey: TsType_String ): Promise< TsInterface_GenericPromiseReject | TsInterface_GenericPromiseResolve > => {

		// TODO - verify that the user has the rights to access the specified clientKey

		return new Promise( ( resolve, reject ) => {
			try {
				if ( userKey != null && clientKey != null ){
					let updateObject = {
						client_key: clientKey,
					}
					DatabaseSetMergeDocument( DatabaseRef_GlobalUser_Document( userKey ), updateObject, {}).then(( res_DSMD: TsInterface_GenericPromiseResolve ) => {
						resolve( res_DSMD )
					}).catch(( rej_DSMD: TsInterface_GenericPromiseReject ) => {
						reject( rej_DSMD )
					})
				} else {
					reject({
						success: false,
						error: {
							message: s_FAILED_TO_UPDATE_USER_CLIENT_KEY,
							details: s_MISSING_REQUIRED_PARAMETERS,
							code: "ER-S-S-UM-UUCK-01"
						}
					})
				}
			} catch( error: TsType_Unknown ){
				let errorMessage: TsType_String | TsType_Null = null
				let typeAssertedError = error as TsInterface_CatchError
				if ( typeAssertedError != null && typeAssertedError.message != null ){
					errorMessage = typeAssertedError.message
				}
				reject({
					success: false,
					error: {
						message: s_FAILED_TO_UPDATE_USER_CLIENT_KEY,
						details: errorMessage,
						code: "ER-S-S-UM-UUCK-02"
					}
				})
			}
		})
	}

	// const generateFullPermissionOverridesObject = ( userRole: TsType_UserRoles, clientType: TsType_ClientTypes, rootClientPermissions: TsInterface_RootData_ClientPermissions, effectiveUserPermissionObject: TsInterface_UnspecifiedObject ): TsInterface_UnspecifiedObject => {
		// let fullUserPermissionOverrides: TsInterface_UnspecifiedObject = {}
		// let userPermissionsList = generateAvailableUserLevelPermissions( userRole, clientType, rootClientPermissions )
		// // Loop through permissions and generate a full user permission object for editing
		// for ( let sectionKey in userPermissionsList ){
		// 	let section = userPermissionsList[ sectionKey ]
		// 	if ( section != null && section["permissions"] != null ){
		// 		for ( let permissionKey in section["permissions"] ){
		// 			let permission = section["permissions"][ permissionKey ]
		// 			// Default to any permissions specfied on a user
		// 			if ( effectiveUserPermissionObject[ permissionKey ] != null ){
		// 				fullUserPermissionOverrides[ permissionKey ] = effectiveUserPermissionObject[ permissionKey ]
		// 			} else {
		// 				// Otherwise use whatever is specified for the user
		// 				if ( permission.access === "always_yes" || permission.access === "default_yes" ){
		// 					fullUserPermissionOverrides[ permissionKey ] = true
		// 				} else {
		// 					fullUserPermissionOverrides[ permissionKey ] = false
		// 				}
		// 			}
		// 		}
		// 	}
		// }
		// return fullUserPermissionOverrides
	// }

	const generateEffectivePermissionOverridesObject = ( userRole: TsType_UserRoles, clientType: TsType_ClientTypes, rootClientPermissions: TsInterface_RootData_ClientPermissions, fullUserPermissionObject: TsInterface_UnspecifiedObject ): TsInterface_UnspecifiedObject => {
		let effectiveUserPermissionOverrides: TsInterface_UnspecifiedObject = {}
		let userPermissionsList = generateAvailableUserLevelPermissions( userRole, clientType, rootClientPermissions )
		// Loop through permissions and reduce a full permission object to just the permissions contrary to defaults for a specified user type
		for ( let sectionKey in userPermissionsList ){
			let section = userPermissionsList[ sectionKey ]
			if ( section != null && section["permissions"] != null ){
				for ( let permissionKey in section["permissions"] ){
					let permission = section["permissions"][ permissionKey ]
					// Default to any permissions specfied on a user
					if ( permission.access === "always_yes" ){
						// Not included in override
					} else if ( permission.access === "default_yes" ){
						if ( fullUserPermissionObject[ permissionKey ] === false ){
							effectiveUserPermissionOverrides[ permissionKey ] = false
						}
					} else if ( permission.access === "default_no" ){
						if ( fullUserPermissionObject[ permissionKey ] === true ){
							effectiveUserPermissionOverrides[ permissionKey ] = true
						}
					} else if ( permission.access === "always_no" ){
						// Not included in override
					}
				}
			}
		}
		return effectiveUserPermissionOverrides
	}

///////////////////////////////
// Exports
///////////////////////////////

	export const createUser = (
		formInputs: TsInterface_FormInputs,
		submittedFormData: TsInterface_UnspecifiedObject
	): Promise< TsInterface_GenericPromiseReject | TsInterface_GenericPromiseResolve > => {
		return new Promise( ( resolve, reject ) => {
			if (
				submittedFormData != null &&
				submittedFormData["name"] != null &&
				submittedFormData["email"] != null &&
				submittedFormData["phone"] != null &&
				submittedFormData["password"] != null
			){
				let userUpdateObject = CleanFormData( formInputs, submittedFormData )
				cloudFunctionManageRequest(
					"manageUser",
					{
						function: "createClientAndUserAccount",
						name: 			userUpdateObject["name"],
						email: 			userUpdateObject["email"],
						phone: 			userUpdateObject["phone"],
						password: 		userUpdateObject["password"],
					}
				).then(( res_CFMUR: TsType_Unknown ) => {
					resolve( { success: true, error: {} } )
				}).catch(( rej_CFMUR: TsInterface_GenericPromiseReject ) => {
					reject( { success: false, error: JSON.parse(getProp( rej_CFMUR, "message", "{}" ) ) } )
				})
			} else {
				reject({
					success: false,
					error: {
						message: s_FAILED_TO_CREATE_USER,
						details: s_MISSING_REQUIRED_PARAMETERS,
						code: "ER-D-MU-CU-01"
					}
				})
			}
		})
	}

	export const updateUser = ( userKey: TsType_String, formInputs: TsInterface_FormInputs, submittedFormData: TsInterface_UnspecifiedObject, rootClientPermissions: TsInterface_RootData_ClientPermissions, rootClientKey: TsType_String, setRootClientKey: TsType_VoidFunctionSingleStringParam, rootClientUser: TsInterface_UnspecifiedObject ): Promise< TsInterface_GenericPromiseReject | TsInterface_GenericPromiseResolve > => {
		return new Promise( ( resolve, reject ) => {
			if ( userKey != null ){
				let effectivePermissionOverrideOject = generateEffectivePermissionOverridesObject( submittedFormData.user_role, getProp( rootClientPermissions, "client_type", null ), rootClientPermissions, submittedFormData.permission_overrides )
				submittedFormData["permission_overrides"] = effectivePermissionOverrideOject
				let userUpdateObject = CleanFormData( formInputs, submittedFormData )
				// let editorUserName = getProp( rootClientUser, "name", null )
				// let editorUserKey = getProp( rootClientUser, "key", null )
				userUpdateObject["name_lowercase"] = getProp( userUpdateObject, "name", "" ).toLowerCase()
				DatabaseSetMergeDocument( DatabaseRef_GlobalUser_Document( userKey ), userUpdateObject, {} ).then(( res_DSMD ) =>{
					// const logCollectionRef = DatabaseRef_UserLogs_Collection( clientKey, userKey )
					// LogChange( logCollectionRef, "user_account_update", editorUserName, editorUserKey ).finally(() => {
						resolve( res_DSMD )
					// })
				}).catch((rej_DSMD: TsType_Unknown) => {
					reject( rej_DSMD )
				})
			} else {
				reject({
					success: false,
					error: {
						message: s_FAILED_TO_UPDATE_USER,
						details: s_MISSING_REQUIRED_PARAMETERS,
						code: "ER-D-MU-UU-01"
					}
				})
			}
		})
	}

	export const deactivateAccount = ( clientKey: TsType_String, userKey: TsType_String, userName: TsType_String, updaterUserKey: TsType_String ): Promise< TsInterface_GenericPromiseReject | TsInterface_GenericPromiseResolve > => {
		return new Promise( ( resolve, reject ) => {
			cloudFunctionManageRequest(
				"manageUser",
				{
					function: "disableUserAccount",
					client_key: clientKey,
					user_key: userKey,
				}
			).then(( res_CFMUR: TsType_Unknown ) => {
				let updateObject = {
					status: "deleted"
				}
				DatabaseSetMergeDocument( DatabaseRef_GlobalUser_Document( userKey ), updateObject, {} ).then(( res_DSMD ) => {
					// const logCollectionRef = DatabaseRef_UserLogs_Collection( clientKey, userKey )
					// LogChange( logCollectionRef, "user_account_disable", userName, userKey ).finally(() => {
						resolve( res_DSMD )
					// })
				}).catch(( rej_DSMD ) => {
					reject( rej_DSMD )
				})
				// const logCollectionRef = DatabaseRef_UserLogs_Collection( clientKey, userKey )
				// LogChange(logCollectionRef, "user_account_disable", userName, userKey).finally(() => {
				// 	resolve(disableResult as TsInterface_GenericPromiseResolve)
				// })
			}).catch(( rej_CFMUR: TsType_Unknown ) => {
				reject( rej_CFMUR )
			})
		})
	}

	export const activateAccount = ( clientKey: TsType_String, userKey: TsType_String, userName: TsType_String, updaterUserKey: TsType_String ): Promise< TsInterface_GenericPromiseReject | TsInterface_GenericPromiseResolve > => {
		return new Promise( ( resolve, reject ) => {
			cloudFunctionManageRequest(
				"manageUser",
				{
					function: "enableUserAccount",
					client_key: clientKey,
					user_key: userKey,
				}
			).then(( res_CFMUR: TsType_Unknown ) => {
				let updateObject = {
					status: "active"
				}
				DatabaseSetMergeDocument( DatabaseRef_GlobalUser_Document( userKey ), updateObject, {} ).then(( res_DSMD ) => {
					// const logCollectionRef = DatabaseRef_UserLogs_Collection( clientKey, userKey )
					// LogChange( logCollectionRef, "user_account_reenable", userName, userKey ).finally(() => {
						resolve( res_DSMD )
					// })
				}).catch(( rej_DSMD ) => {
					reject( rej_DSMD )
				})
				// const logCollectionRef = DatabaseRef_UserLogs_Collection( clientKey, userKey )
				// LogChange(logCollectionRef, "user_account_reenable", userName, userKey).finally(() => {
				// 	resolve(enableResult as TsInterface_GenericPromiseResolve)
				// })
			}).catch(( rej_CFMUR: TsType_Unknown ) => {
				reject( rej_CFMUR )
			})
		})
	}

	export const updateUserClientKey = ( clientKey: TsType_String, rootGlobalUser: TsInterface_UnspecifiedObject ): Promise< TsInterface_GenericPromiseReject | TsInterface_GenericPromiseResolve > => {
		return new Promise( ( resolve, reject ) => {
			if ( rootGlobalUser.super === true ){
				updateUserClientKeyProper( rootGlobalUser.key, clientKey ).then(( res_UUCKP: TsInterface_GenericPromiseResolve ) => {
					resolve( res_UUCKP )
				}).catch(( rej_UUCKP: TsInterface_GenericPromiseReject ) => {
					reject( rej_UUCKP )
				})
			} else {
				cloudFunctionManageRequest(
					"manageUser",
					{
						function: "updateUserClientKey",
						client_key: clientKey,
					}
				).then(( res_CFMUR: TsType_Unknown ) => {
					resolve( res_CFMUR as TsInterface_GenericPromiseResolve )
				}).catch(( rej_CFMUR: TsType_Unknown ) => {
					reject( rej_CFMUR )
				})
			}
		})
	}