//////////////////////////////////////////
//		  ooOOOO BOILERPLATE FILE		//
//		 oo		 _____					//
//		_I__n_n__||_|| ________			//
//	  >(_________|_7_|-|______|			//
//	   /o ()() ()() o   oo  oo			//
//////////////////////////////////////////

///////////////////////////////
// Description
///////////////////////////////

	/*
		DESCRIPTION / USAGE:
			Provides easy to use

		TODO:

	*/


///////////////////////////////
// Imports
///////////////////////////////

import {
	TsInterface_TableAdditionalData,
	TsInterface_TableColumn,
	TsInterface_TableDataRow,
	TsInterface_TableHooks,
	TsType_TableSortBy
} from 'rfbp_core/components/table'
import {
	returnFormattedDate,
	returnTimezoneAbbreviation
} from 'rfbp_core/services/helper_functions'
import {
	TsType_Boolean,
	TsType_JSX,
	TsType_String
} from 'rfbp_core/typescript/global_types'
import {
	Box
} from '@mui/material'

///////////////////////////////
// Typescript
///////////////////////////////


///////////////////////////////
// Variables
///////////////////////////////

	// Displayed Translatable Strings
	// { sort-start } - displayed text - scoped sort plugin

	// { sort-end } - displayed text


///////////////////////////////
// Functions
///////////////////////////////


///////////////////////////////
// Exports
///////////////////////////////

	export const TableCellTimestamp = (
		propKey: TsType_String,
		propName: TsType_String | TsType_JSX,
		sortBy: TsType_TableSortBy,
		dateFormat: TsType_String,
		includeTimezone: TsType_Boolean
	): TsInterface_TableColumn => {
		let basicCell = {
			header: {
				header_jsx: ( tableAdditionalData: TsInterface_TableAdditionalData ) => {
					return <>{ propName }</>
				},
				header_sort_by: sortBy
			},
			cell: {
				cell_jsx: ( rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks ) => {
					let cellJSX = <></>
					if( rowData[propKey] != null ){
						let timezoneJSX = <></>
						if( includeTimezone === true ){
							timezoneJSX = <Box component="span" className="tw-opacity-30 tw-italic"> { returnTimezoneAbbreviation( rowData[propKey] ) }</Box>
							cellJSX = <>{ returnFormattedDate( rowData[propKey], dateFormat ) } { timezoneJSX }</>
						} else {
							cellJSX = <>{ returnFormattedDate( rowData[propKey], dateFormat ) }</>
						}
					}
					return cellJSX
				},
			}
		}
		return basicCell
	}