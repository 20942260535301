//////////////////////////////////////////
//		  ooOOOO BOILERPLATE FILE		//
//		 oo		 _____					//
//		_I__n_n__||_|| ________			//
//	  >(_________|_7_|-|______|			//
//	   /o ()() ()() o   oo  oo			//
//////////////////////////////////////////

///////////////////////////////
// Description
///////////////////////////////

	/*
		DESCRIPTION / USAGE:
			Wrapper that includes navigation and page header for authenticated parts of the software

		TODO:
			[ ] Typescript - 5 instances of @ts-expect-error - on functions / components copied from mui examples

	*/


///////////////////////////////
// Imports
///////////////////////////////

// Components
import {
	LogoFull
} from 'app/images/logos/logo_full'
import {
	StripeProd_CreateCustomer,
	StripeProd_ListUsersSubscriptions,
	StripeProd_UpdateDatabaseSubscriptionRecords,
	StripeStaging_CreateCustomer,
	StripeStaging_ListUsersSubscriptions,
	StripeStaging_UpdateDatabaseSubscriptionRecords
} from 'app/models/stripe/stripe_functions'
// React
import {
	useContext,
	useEffect
} from 'react'
import {
	Trans
} from 'react-i18next'
import {
	Link,
	Navigate,
	useNavigate
} from 'react-router-dom'
// Config
import {
	ApplicationMajorPages,
	ApplicationNavPages,
	ApplicationPages,
	EmptyApplicationNavigationObject,
	generateActiveUserApplicationPermissions,
	generateApplicationNavigationObject,
	TsInterface_NavigationObject,
	TsInterface_NavPage
} from 'rfbp_aux/data/application_structure' // OUTSIDE BOILERPLATE
import {
	DatabaseRef_RootClient_Document
} from 'rfbp_aux/services/database_endpoints/standard_database_endpoints'
import {
	Icon
} from 'rfbp_core/components/icons'
// Context
import {
	Context_RootData_AuthenticatedUser,
	Context_RootData_ClientKey,
	Context_RootData_ClientPermissions,
	Context_RootData_ClientUser,
	Context_RootData_GlobalUser,
	Context_RootData_UserPermissions,
	Context_UserInterface_AlertDialog,
	Context_UserInterface_ErrorDialog,
	Context_UserInterface_LoadingBar,
	Context_UserInterface_NavBar,
	Context_UserInterface_PromptDialog
} from 'rfbp_core/services/context'
import {
	DatabaseSetMergeDocument
} from 'rfbp_core/services/database_management'
import {
	cloneObjectWithoutReference,
	getProp,
	objectToArray,
	returnDateFromUnknownDateFormat,
	returnFormattedDate
} from 'rfbp_core/services/helper_functions'
import {
	getClientKey
} from 'rfbp_core/services/user_authentication'
// Typescript
import {
	TsInterface_UnspecifiedObject,
	TsType_Any,
	TsType_Boolean,
	TsType_Date,
	TsType_JSX,
	TsType_Null,
	TsType_Number,
	TsType_String,
	TsType_Void
} from 'rfbp_core/typescript/global_types'
// MUI Components
import {
	Box,
	Button,
	CircularProgress,
	Divider,
	Drawer,
	IconButton,
	LinearProgress,
	List,
	ListItem,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	styled,
	Typography
} from '@mui/material/'
import MuiAppBar from '@mui/material/AppBar'
import {
	themeVariables
} from '../config/app_theme'

		// Third Party


///////////////////////////////
// Typescript
///////////////////////////////

	interface TsInterface_ComponentProps {
		content: TsType_JSX
		pageHeader: TsType_JSX
		pageKey: TsType_String
		showRegardlessOfSubscriptionStatus?: TsType_Boolean
	}

	interface TsInterface_NavLinkStyle {
		py: TsType_String
		px: TsType_Number
		color?: TsType_String
		fontWeight?: TsType_Number
		'&:hover, &:focus': {
			bgcolor: TsType_String
		}
		borderLeft: TsType_String
	}

	interface TsInterface_ItemCategoryStyle {
		boxShadow: TsType_String
		py: TsType_Number
		px: TsType_Number
	}


///////////////////////////////
// Variables
///////////////////////////////

	// Displayed Translatable Strings
	const s_FREE_TRIAL_ACTIVE_UNTIL: TsType_JSX = 										<Trans>Free Trial Active Until</Trans>
	const s_NO_ACTIVE_SUBSCRIPTION: TsType_JSX = 										<Trans>No Active Subscription</Trans>
	const s_MANAGE_SUBSCRIPTION: TsType_JSX = 											<Trans>Manage Subscription</Trans>
	const s_ENTER_ACCESS_CODE: TsType_JSX = 											<Trans>Enter Access Code</Trans>
	const s_ACCESS_ENDS_ON: TsType_JSX = 												<Trans>Access ends on</Trans>
	const s_SUBSCRIPTION_HAS_BEEN_CANCELLED: TsType_JSX = 								<Trans>Subscription has been cancelled</Trans>
	const s_ACCESS_WILL_END_AT_NEXT_BILLING_CYCLE: TsType_JSX = 						<Trans>Access will end at next billing cycle</Trans>
	const s_SUBMIT: TsType_JSX = 														<Trans>Submit</Trans>
	const s_ACCESS_CODE: TsType_JSX = 													<Trans>Access Code</Trans>
	const s_ENTER_AN_ACCESS_CODE_TO_START_FREE_TRIAL: TsType_JSX = 						<Trans>Enter an access code to start free trial</Trans>
	const s_INVALID_ACCESS_CODE: TsType_JSX = 											<Trans>Invalid Access Code</Trans>
	const s_THE_ACCESS_CODE_YOU_ENTERED_IS_INVALID_OR_NO_LONGER_ACTIVE: TsType_JSX = 	<Trans>The access code you entered is invalid or no longer active</Trans>
	const s_VALID_ACCESS_CODE: TsType_JSX = 											<Trans>Valid Access Code</Trans>
	const s_FREE_TRIAL_ACTIVATED: TsType_JSX = 											<Trans>Free Trial Activated</Trans>

	// Other
	const drawerWidth: TsType_Number = 250
	const itemCategory: TsInterface_ItemCategoryStyle = {
		boxShadow: '0 -1px 0 rgb(255,255,255,0.1) inset',
		py: 1.5,
		px: 3,
	}

	// Colors
	const unselectedNavColor = themeVariables.white
	const selectedNavColor = themeVariables.warning_main
	const appBarBackgroundColor = themeVariables.background_default
	const logoPrimaryDarkColor = themeVariables.primary_dark
	// const navGradientTopColor = themeVariables.info_main
	// const navGradientBottomColor = themeVariables.info_dark
	const navGradientTopColor = themeVariables.logo_primary_light
	const navGradientBottomColor = themeVariables.logo_primary_dark

	// const unselectedNavColor: TsType_String = themeVariables.white
	// const selectedNavColor: TsType_String = themeVariables.white
	// const appBarBackgroundColor: TsType_String = themeVariables.background_default
	// const logoPrimaryDarkColor: TsType_String = "#3c82c4"
	// const logoSecondaryColor: TsType_String = "#3c82c4"
	// const logoAdditionalColor: TsType_String = "#3c82c4"
	// const navGradientTopColor: TsType_String = "#77a8d6"
	// const navGradientBottomColor: TsType_String = "#2a5b8a"


///////////////////////////////
// Functions
///////////////////////////////

	const DrawerHeader = styled( 'div' )(({ theme }) => ({
		display: 'flex',
		alignItems: 'center',
		padding: theme.spacing( 0, 1 ),
		// necessary for content to be below app bar
		...theme.mixins.toolbar,
		justifyContent: 'flex-end',
	}))

	const getMainWidth = ( open: TsType_Boolean ): TsType_String => {
		let width = "100%"
		if ( open ){
			width = `calc(100% - ${drawerWidth + 16}px)`
		}
		return width
	}

	const getMainLeftMargin = ( open: TsType_Boolean ): TsType_String => {
		let width = `-${drawerWidth}px`
		if ( open ){
			width = `-${drawerWidth + 16}px`
		}
		return width
	}

	// @ts-expect-error
	const Main = styled('main', { shouldForwardProp: ( prop: TsType_String ) => prop !== 'open' })(({ theme, open }) => ({
		flexGrow: 1,
		width: getMainWidth( open ),
		// padding: theme.spacing( 3 ),
		transition: theme.transitions.create( 'margin', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		marginLeft: getMainLeftMargin( open ),
		...( open && {
			transition: theme.transitions.create('margin', {
				easing: theme.transitions.easing.easeOut,
				duration: theme.transitions.duration.enteringScreen,
			}),
			marginLeft: 0,
		}),
	}))

	const AppBar = styled( MuiAppBar, {
		shouldForwardProp: ( prop: TsType_String) => prop !== 'open',
	// @ts-expect-error
	})(({ theme, open }) => ({
		transition: theme.transitions.create( ['margin', 'width'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		...( open && {
			width: `calc(100% - ${drawerWidth + 0}px)`,
			marginLeft: `${drawerWidth + 0}px`,
			transition: theme.transitions.create( ['margin', 'width'], {
				easing: theme.transitions.easing.easeOut,
				duration: theme.transitions.duration.enteringScreen,
			}),
		}),
	}))

	const  NavOpenKeyListener = ( props: TsType_Any ) => {

		// Props
		const openNav: TsType_Any = 			getProp( props, "openNav", () => {} )
		const closeNav: TsType_Any = 			getProp( props, "closeNav", () => {} )
		const navOpen: TsType_Any = 			getProp( props, "navOpen", null )

		// Hooks - useEffect
		useEffect(() => {
			const handleKeyDown = (event: TsType_Any ) => {
				if (event.metaKey && event.keyCode === 66) {
					setTimeout( () => {
						if( navOpen === true ){
							closeNav()
						}
						if( navOpen === false ){
							openNav( true )
						}
					}, 1)
				}
			}
			document.addEventListener("keydown", handleKeyDown)
			return () => {
				document.removeEventListener("keydown", handleKeyDown)
			}
		}, [ openNav, closeNav, navOpen]);

		return null;
	}

	export const returnApplicationSubscriptionStatus = (
		rootClientData: TsInterface_UnspecifiedObject
	): TsType_String => {
		let status = "unknown_subscription_status"
		let currentTimestamp = new Date().getTime()
		if(
			rootClientData != null &&
			rootClientData.stripe_data != null &&
			rootClientData.stripe_data.has_active_prod_subscription === true
		) {
			status = "active_prod_subscription"
			if(
				rootClientData.stripe_data.pending_prod_cancellation === true
			) {
				status = "active_prod_subscription_pending_cancellation"
			}
		} else if(
			rootClientData != null &&
			rootClientData.stripe_data != null &&
			rootClientData.stripe_data.has_active_staging_subscription === true
		) {
			status = "active_staging_subscription"
			if(
				rootClientData.stripe_data.pending_staging_cancellation === true
			) {
				status = "active_staging_subscription_pending_cancellation"
			}
		} else if(
			rootClientData != null &&
			getProp( rootClientData, "timestamp_free_trial_end", null ) != null &&
			returnDateFromUnknownDateFormat( getProp( rootClientData, "timestamp_free_trial_end", null ) ).getTime() > currentTimestamp
		) {
			status = "active_free_trial"
		} else if(
			rootClientData != null &&
			getProp( rootClientData, "timestamp_free_trial_end", null ) != null &&
			returnDateFromUnknownDateFormat( getProp( rootClientData, "timestamp_free_trial_end", null ) ).getTime() < currentTimestamp
		) {
			status = "expired_free_trial"
		} else if(
			rootClientData != null &&
			rootClientData.email != null &&
			rootClientData.email !== ""
		){
			status = "no_subscription"
		}
		return status
	}

///////////////////////////////
// Component
///////////////////////////////

	export const AuthenticatedContainer = ( props: TsInterface_ComponentProps ): TsType_JSX => {

		// Props
		const pr_showRegardlessOfSubscriptionStatus: TsInterface_ComponentProps["showRegardlessOfSubscriptionStatus"] = 		getProp( props, "showRegardlessOfSubscriptionStatus", false )
		const pr_pageContent: TsInterface_ComponentProps["content"] = 															getProp( props, "content", <></> )
		const pr_pageHeader: TsInterface_ComponentProps["pageHeader"] = 														getProp( props, "pageHeader", <></> )
		const pr_pageKey: TsInterface_ComponentProps["pageKey"] = 																getProp( props, "pageKey", "" )

		// Hooks - useContext, useState, useReducer, other
		// { sort-start } - hooks
		const un_routerNaviation = 																useNavigate()
		const { uc_RootData_AuthenticatedUser } = 												useContext( Context_RootData_AuthenticatedUser )
		const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = 							useContext( Context_RootData_ClientKey )
		const { uc_RootData_ClientPermissions } = 												useContext( Context_RootData_ClientPermissions )
		const { uc_RootData_ClientUser } = 														useContext( Context_RootData_ClientUser )
		const { uc_RootData_GlobalUser } = 														useContext( Context_RootData_GlobalUser )
		const { uc_RootData_UserPermissions, uc_setRootData_UserPermissions } = 				useContext( Context_RootData_UserPermissions )
		const { uc_UserInterface_LoadingBarDisplay } = 											useContext( Context_UserInterface_LoadingBar )
		const { uc_UserInterface_NavBarDisplay, uc_setUserInterface_NavBarDisplay } = 			useContext( Context_UserInterface_NavBar )
		const { uc_setUserInterface_AlertDialogDisplay } = 										useContext( Context_UserInterface_AlertDialog )
		const { uc_setUserInterface_ErrorDialogDisplay } = 										useContext( Context_UserInterface_ErrorDialog )
		const { uc_setUserInterface_PromptDialogDisplay } = 									useContext( Context_UserInterface_PromptDialog )
		// { sort-end } - hooks

		// Hooks - useEffect
		useEffect(() => {
			getClientKey( uc_RootData_ClientKey, uc_setRootData_ClientKey ).then(( getResult ) => {
				// Nothing
			}).catch(( getReject) => {
				un_routerNaviation( ApplicationPages.UnauthenticatedLoginPage.url() )
			})
		}, [ un_routerNaviation, uc_RootData_ClientKey, uc_setRootData_ClientKey ])

		useEffect(() => {
			generateActiveUserApplicationPermissions( uc_RootData_ClientUser, uc_RootData_GlobalUser, uc_RootData_ClientPermissions ).then(( permissionResult ) => {
				if ( permissionResult.success ){
					uc_setRootData_UserPermissions( permissionResult.permissions )
				}
			})
			return () => { }
		}, [ uc_RootData_ClientUser, uc_RootData_GlobalUser, uc_RootData_ClientPermissions, uc_setRootData_UserPermissions ])

		useEffect(() => {
			let cutoffDate = new Date().getTime() - 1000 * 60 * 60 * 8
			if(
				uc_RootData_ClientUser != null &&
				(
					// @ts-expect-error
					uc_RootData_ClientUser.timestamp_last_checked_staging_subscription_status == null ||
					// @ts-expect-error
					returnDateFromUnknownDateFormat( uc_RootData_ClientUser.timestamp_last_checked_staging_subscription_status ).getTime() < cutoffDate
				)
			){
				let clientStripeData = getProp( uc_RootData_ClientUser, "stripe_data", {} )
				if(
					uc_RootData_ClientUser.key != null &&
					uc_RootData_ClientUser.key !== ""
				){
					if(
						clientStripeData != null &&
						clientStripeData["stripe_staging_customer_id"] != null
					){
						// Create Stripe Customer Since One Does Not Exist
						getClientKey( uc_RootData_ClientKey, uc_setRootData_ClientKey ).then(( res_GCK ) => {
							StripeStaging_ListUsersSubscriptions( res_GCK.clientKey ).then(( res_SSLUS ) => {
								StripeStaging_UpdateDatabaseSubscriptionRecords(
									res_GCK.clientKey,
									res_SSLUS as TsInterface_UnspecifiedObject, // us_stripeActiveSubscriptionData,
									uc_RootData_ClientUser,
									true
								)
							}).catch(( rej_SSLUS ) => {
								console.error( rej_SSLUS )
							})
						}).catch(( rej_GCK ) => {
							console.error( rej_GCK )
						})
					} else if(
						(
							clientStripeData == null ||
							clientStripeData["stripe_staging_customer_id"] == null
						) &&
						uc_RootData_ClientUser != null &&
						uc_RootData_ClientUser["email"] != null
					){
						// Create Stripe Customer Since One Does Not Exist
						getClientKey( uc_RootData_ClientKey, uc_setRootData_ClientKey ).then(( res_GCK ) => {
							StripeStaging_CreateCustomer( res_GCK.clientKey, uc_RootData_ClientUser["email"] ).then(( res_SSCC ) => {
								// Should rerun this function to list subscriptions and save timestamp
							}).catch(( rej_SSCC ) => {
								console.error( rej_SSCC )
							})
						}).catch(( rej_GCK ) => {
							console.error( rej_GCK )
						})
					} else {
						// Nothing?
					}
				}
			}
		}, [uc_RootData_ClientKey, uc_RootData_ClientUser, uc_setRootData_ClientKey])

		useEffect(() => {
			let cutoffDate = new Date().getTime() - 1000 * 60 * 60 * 8
			if(
				uc_RootData_ClientUser != null &&
				(
					// @ts-expect-error
					uc_RootData_ClientUser.timestamp_last_checked_prod_subscription_status == null ||
					// @ts-expect-error
					returnDateFromUnknownDateFormat( uc_RootData_ClientUser.timestamp_last_checked_prod_subscription_status ).getTime() < cutoffDate
				)
			){
				let clientStripeData = getProp( uc_RootData_ClientUser, "stripe_data", {} )
				if(
					uc_RootData_ClientUser.key != null &&
					uc_RootData_ClientUser.key !== ""
				){
					if(
						clientStripeData != null &&
						clientStripeData["stripe_prod_customer_id"] != null
					){
						// Create Stripe Customer Since One Does Not Exist
						getClientKey( uc_RootData_ClientKey, uc_setRootData_ClientKey ).then(( res_GCK ) => {
							StripeProd_ListUsersSubscriptions( res_GCK.clientKey ).then(( res_SSLUS ) => {
								StripeProd_UpdateDatabaseSubscriptionRecords(
									res_GCK.clientKey,
									res_SSLUS as TsInterface_UnspecifiedObject, // us_stripeActiveSubscriptionData,
									uc_RootData_ClientUser,
									true
								)
							}).catch(( rej_SSLUS ) => {
								console.error( rej_SSLUS )
							})
						}).catch(( rej_GCK ) => {
							console.error( rej_GCK )
						})
					} else if(
						(
							clientStripeData == null ||
							clientStripeData["stripe_prod_customer_id"] == null
						) &&
						uc_RootData_ClientUser != null &&
						uc_RootData_ClientUser["email"] != null
					){
						// Create Stripe Customer Since One Does Not Exist
						getClientKey( uc_RootData_ClientKey, uc_setRootData_ClientKey ).then(( res_GCK ) => {
							StripeProd_CreateCustomer( res_GCK.clientKey, uc_RootData_ClientUser["email"] ).then(( res_SSCC ) => {
								// Should rerun this function to list subscriptions and save timestamp
							}).catch(( rej_SSCC ) => {
								console.error( rej_SSCC )
							})
						}).catch(( rej_GCK ) => {
							console.error( rej_GCK )
						})
					} else {
						// Nothing?
					}
				}
			}
		}, [uc_RootData_ClientKey, uc_RootData_ClientUser, uc_setRootData_ClientKey])

		// Other Variables
		const pageRootNavPermission: TsType_String = getProp( ApplicationPages[ pr_pageKey ], "root_nav_page_permission", "" )
		const pageRoleAccessPermissions: TsInterface_NavPage["page_role_access_permissions"] = getProp( ApplicationNavPages[ pageRootNavPermission ], "page_role_access_permissions", {} )
		let topLoadingBar: TsType_JSX
		let sideBarNavObject: TsInterface_NavigationObject
		let authorizedToViewPage: TsType_Boolean = false
		let clientType: TsType_String
		let userRole: TsType_String

		// Functions
		const determineClientTypeAndUserRole = (): TsType_Void => {
			if ( uc_RootData_ClientPermissions != null && uc_RootData_ClientPermissions.client_type != null ){
				clientType = uc_RootData_ClientPermissions.client_type
			}
			if ( uc_RootData_ClientUser != null && uc_RootData_ClientUser.user_role != null ){
				userRole = uc_RootData_ClientUser.user_role
			} else if ( uc_RootData_GlobalUser != null && uc_RootData_GlobalUser.user_role != null ){
				userRole = uc_RootData_GlobalUser.user_role
			}
		}

		const applyPermissionsToSideNavBarObject = (): TsType_Void => {
			let initialApplicationNavigationObject = generateApplicationNavigationObject( clientType, userRole )
			let permittedApplicationNavigationObject: TsInterface_NavigationObject = {}
			for ( let loopSectionKey in initialApplicationNavigationObject ){
				let loopSection = initialApplicationNavigationObject[ loopSectionKey ]
				for ( let loopLinkKey in loopSection["links"] ){
					let loopLink = loopSection["links"][ loopLinkKey ]
					if ( uc_RootData_UserPermissions != null && uc_RootData_UserPermissions[ loopLinkKey ] === true && loopLink != null ){
						if ( permittedApplicationNavigationObject[ loopSectionKey ] == null ){
							permittedApplicationNavigationObject[ loopSectionKey ] = cloneObjectWithoutReference( loopSection )
							permittedApplicationNavigationObject[ loopSectionKey ]["links"] = {}
						}
						permittedApplicationNavigationObject[ loopSectionKey ]["links"][ loopLinkKey ] = loopLink
					}
				}
			}
			if ( permittedApplicationNavigationObject == null || objectToArray( permittedApplicationNavigationObject ).length === 0 ){
				permittedApplicationNavigationObject = EmptyApplicationNavigationObject
			}
			sideBarNavObject = permittedApplicationNavigationObject
		}

		const openNav = (): TsType_Void => {
			uc_setUserInterface_NavBarDisplay( true )
		}

		const closeNav = (): TsType_Void => {
			uc_setUserInterface_NavBarDisplay( false )
		}

		const checkIfNavSectionIsActive = ( sectionKey: TsType_String, linkKey: TsType_String ): TsType_Boolean => {
			let active = false
			if (
				sectionKey === "home" &&
				linkKey === "home" &&
				pr_pageKey === "HomePage"
			){
				active = true
			} else if (
				clientType != null &&
				userRole != null &&
				pageRoleAccessPermissions != null &&
				pageRoleAccessPermissions[ clientType + "_" + userRole ] != null
			){
				let compositeKey: TsType_String = clientType + "_" + userRole
				if (
					pageRoleAccessPermissions[ compositeKey ]["highlighted_nav_section"] === sectionKey &&
					pr_pageKey === linkKey
				){
					active = true
				}
			}
			return active
		}

		const returnNavLinkStyle = ( selected: TsType_Boolean ): TsInterface_NavLinkStyle => {
			let style
			if ( selected === true ){
				style = {
					py: '2px',
					px: 3,
					color: selectedNavColor,
					fontWeight: 700,
					// color: 'rgba(255, 255, 255, 0.7)',
					'&:hover, &:focus': {
						bgcolor: 'rgba(255, 255, 255, 0.08)',
					},
					"borderLeft": "6px solid " + selectedNavColor
				}
			} else {
				style = {
					py: '2px',
					px: 3,
					color: 'rgba(255, 255, 255, 0.7)',
					fontWeight: 700,
					'&:hover, &:focus': {
						bgcolor: 'rgba(255, 255, 255, 0.08)',
					},
					"borderLeft": "6px solid rgba(0,0,0,0)"
				}
			}
			return style
		}

		const returnNavIconStyle = ( selected: TsType_Boolean ): TsInterface_UnspecifiedObject => {
			let navIconSX = {
				color: unselectedNavColor
			}
			if( selected === true ){
				navIconSX["color"] = selectedNavColor
			}
			return navIconSX
		}

		const determineLoadingBarVisibility = (): TsType_Void => {
			if ( uc_UserInterface_LoadingBarDisplay === true ){
				topLoadingBar = <LinearProgress color="secondary" />
			} else {
				topLoadingBar = <Box className="top_loading_bar_placeholder"></Box>
			}
		}

		const determinePageAuthorization = (): TsType_Void => {
			if ( pr_pageKey === "HomePage" ){
				authorizedToViewPage = true
			} else if ( clientType == null || userRole == null ) {
				authorizedToViewPage = true
			} else {
				if ( uc_RootData_UserPermissions == null || objectToArray( uc_RootData_UserPermissions ).length === 0 || uc_RootData_UserPermissions[pageRootNavPermission] === true ){
					authorizedToViewPage = true
				}
			}
		}

		const getMainSectionPadding = ( open: TsType_Boolean ): TsType_String => {
			let cssClassName = ""
			if ( open === true ){
				// cssClassName = "tw-pl-2"
			}
			return cssClassName
		}

		const getHeaderTextMaxWidth = ( open: TsType_Boolean ): TsType_String => {
			// TODO - add 40 for each nav icon visible - i.e. notifications
			let otherHeaderContentWidth = 130
			let maxWidth = `calc(100% - ${otherHeaderContentWidth}px)`
			return maxWidth
		}

		const returnJSX_NavButton = ( open: TsType_Boolean ): TsType_JSX => {
			let navButtonJSX = <></>
			if ( open === true ){
				navButtonJSX =
				<IconButton sx={{ height: "40px", width: "40px", marginTop: "2px", ...( uc_UserInterface_NavBarDisplay && { display: 'none' } ) }} className="tw-inline-block" onClick={ () => { closeNav() } }>
					<Icon icon="chevron-left" />
				</IconButton>
			} else {
				navButtonJSX =
				<IconButton sx={{ height: "40px", width: "40px", marginTop: "2px", ...( !uc_UserInterface_NavBarDisplay && { display: 'none' } ) }} className="tw-inline-block" onClick={ () => { openNav() } }>
					<Icon icon="bars" />
				</IconButton>
			}
			return navButtonJSX
		}

		const returnStagingSubscriptionEndDate = (): TsType_Date | TsType_Null => {
			let expirationCutoffTimestamp = null
			let expirationCutoffDate = null
			if(
				uc_RootData_ClientUser != null &&
				// @ts-expect-error
				uc_RootData_ClientUser.stripe_data != null &&
				// @ts-expect-error
				uc_RootData_ClientUser.stripe_data.staging_subscriptions != null
			){
				// @ts-expect-error
				for( let loopSubscriptionKey in uc_RootData_ClientUser.stripe_data.staging_subscriptions ){
					// @ts-expect-error
					let loopSubscription = uc_RootData_ClientUser.stripe_data.staging_subscriptions[loopSubscriptionKey]
					if(
						loopSubscription != null &&
						loopSubscription.subscription_id != null &&
						loopSubscription.database_status === "to_be_cancelled" &&
						loopSubscription.current_period_end != null
					){
						if(
							expirationCutoffTimestamp == null ||
							loopSubscription.current_period_end - ( 1000 * 60 * 60 * 24 ) > expirationCutoffTimestamp
						){
							expirationCutoffTimestamp = loopSubscription.current_period_end - ( 1000 * 60 * 60 * 24 )
							expirationCutoffDate = new Date( expirationCutoffTimestamp )
						}
					}
				}
			}
			return expirationCutoffDate
		}

		const returnProdSubscriptionEndDate = (): TsType_Date | TsType_Null => {
			let expirationCutoffTimestamp = null
			let expirationCutoffDate = null
			if(
				uc_RootData_ClientUser != null &&
				// @ts-expect-error
				uc_RootData_ClientUser.stripe_data != null &&
				// @ts-expect-error
				uc_RootData_ClientUser.stripe_data.prod_subscriptions != null
			){
				// @ts-expect-error
				for( let loopSubscriptionKey in uc_RootData_ClientUser.stripe_data.prod_subscriptions ){
					// @ts-expect-error
					let loopSubscription = uc_RootData_ClientUser.stripe_data.prod_subscriptions[loopSubscriptionKey]
					if(
						loopSubscription != null &&
						loopSubscription.subscription_id != null &&
						loopSubscription.database_status === "to_be_cancelled" &&
						loopSubscription.current_period_end != null
					){
						if(
							expirationCutoffTimestamp == null ||
							loopSubscription.current_period_end - ( 1000 * 60 * 60 * 24 ) > expirationCutoffTimestamp
						){
							expirationCutoffTimestamp = loopSubscription.current_period_end - ( 1000 * 60 * 60 * 24 )
							expirationCutoffDate = new Date( expirationCutoffTimestamp )
						}
					}
				}
			}
			return expirationCutoffDate
		}

		// const hasAccessRoAuth

		// Call Functions
		determineClientTypeAndUserRole()
		applyPermissionsToSideNavBarObject()
		determineLoadingBarVisibility()
		determinePageAuthorization()

		// JSX Generation
		const returnJSX_SubscriptionStatusBanner = (): TsType_JSX => {
			let bannerJSX = <></>
			let subscriptionStatus = returnApplicationSubscriptionStatus( uc_RootData_ClientUser )
			let disabledButton = false
			if( pr_showRegardlessOfSubscriptionStatus === true ){
				disabledButton = true
			}
			let reactivateSubscriptionButtonJSX =
			<Button
				variant="contained"
				color="info"
				size='small'
				className="tw-ml-2"
				disabled={ disabledButton }
				onClick={ () => { un_routerNaviation( ApplicationPages.UserSettingsPage.url() ) } }
			>
				{ s_MANAGE_SUBSCRIPTION }
			</Button>
			if( subscriptionStatus === "active_prod_subscription_pending_cancellation" ){
				if( returnProdSubscriptionEndDate() != null ){
					bannerJSX =
					<Box className="tw-w-full tw-rounded-lg tw-p-2 tw-mb-2" sx={{ background: themeVariables.warning_main, color: themeVariables.white }}>
						<Icon icon="triangle-exclamation" className='tw-mr-2' />
						{ s_SUBSCRIPTION_HAS_BEEN_CANCELLED }. { s_ACCESS_ENDS_ON } { returnFormattedDate( returnProdSubscriptionEndDate(), "D MMM YYYY" ) }
						{ reactivateSubscriptionButtonJSX }
					</Box>
				} else {
					bannerJSX =
					<Box className="tw-w-full tw-rounded-lg tw-p-2 tw-mb-2" sx={{ background: themeVariables.warning_main, color: themeVariables.white }}>
						<Icon icon="triangle-exclamation" className='tw-mr-2' />
						{ s_SUBSCRIPTION_HAS_BEEN_CANCELLED }. { s_ACCESS_WILL_END_AT_NEXT_BILLING_CYCLE }
						{ reactivateSubscriptionButtonJSX }
					</Box>
				}
			} else if( subscriptionStatus === "active_staging_subscription_pending_cancellation" ){
				if( returnStagingSubscriptionEndDate() != null ){
					bannerJSX =
					<Box className="tw-w-full tw-rounded-lg tw-p-2 tw-mb-2" sx={{ background: themeVariables.warning_main, color: themeVariables.white }}>
						<Icon icon="triangle-exclamation" className='tw-mr-2' />
						{ s_SUBSCRIPTION_HAS_BEEN_CANCELLED }. { s_ACCESS_ENDS_ON } { returnFormattedDate( returnStagingSubscriptionEndDate(), "D MMM YYYY" ) }
						{ reactivateSubscriptionButtonJSX }
					</Box>
				} else {
					bannerJSX =
					<Box className="tw-w-full tw-rounded-lg tw-p-2 tw-mb-2" sx={{ background: themeVariables.warning_main, color: themeVariables.white }}>
						<Icon icon="triangle-exclamation" className='tw-mr-2' />
						{ s_SUBSCRIPTION_HAS_BEEN_CANCELLED }. { s_ACCESS_WILL_END_AT_NEXT_BILLING_CYCLE }
						{ reactivateSubscriptionButtonJSX }
					</Box>
				}
			} else if( subscriptionStatus === "active_free_trial" ){
				bannerJSX =
				<Box className="tw-w-full tw-rounded-lg tw-p-2 tw-mb-2" sx={{ background: themeVariables.warning_main, color: themeVariables.white }}>
					<Icon icon="triangle-exclamation" className='tw-mr-2' />
					{ s_FREE_TRIAL_ACTIVE_UNTIL } { returnFormattedDate( getProp( uc_RootData_ClientUser, "timestamp_free_trial_end", null ), "D MMM YYYY" ) }
					{ reactivateSubscriptionButtonJSX }
				</Box>
			}
			return bannerJSX
		}

		const returnJSX_FreeTrialInputButton = (): TsType_JSX => {
			let buttonJSX =
			<Button
				variant="contained"
				color="success"
				className="tw-mt-4"
				onClick={ () => {

					uc_setUserInterface_PromptDialogDisplay({
						display: true,
						prompt: {
							color: "success",
							confirm_text: s_SUBMIT,
							default_value: "",
							header: s_ENTER_ACCESS_CODE,
							icon: <Icon icon="circle-plus" type="solid" />,
							input_label: s_ACCESS_CODE,
							input_type: "text",
							text: <>{ s_ENTER_AN_ACCESS_CODE_TO_START_FREE_TRIAL }</>,
							submit_callback: ( promptValue: TsType_String ) => {
								return new Promise( ( resolve, reject ) => {
									if( promptValue !== "" && promptValue != null ){
										switch( promptValue ){
											case "alpine":
												getClientKey( uc_RootData_ClientKey, uc_setRootData_ClientKey ).then(( res_GCK ) => {
													let updateObject = {
														timestamp_free_trial_end: new Date(2024, 3, 1),
													}
													DatabaseSetMergeDocument( DatabaseRef_RootClient_Document( res_GCK.clientKey ), updateObject, {} ).then(( res_DSM ) => {
														resolve( res_DSM )
													}).catch(( rej_DSM ) => {
														uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSM.error })
														reject( rej_DSM )
													})
												}).catch(( rej_GCK ) => {
													reject(rej_GCK)
												})
												resolve({})
												uc_setUserInterface_AlertDialogDisplay({
													display: true,
													alert: {
														color: "success",
														header: s_VALID_ACCESS_CODE,
														icon: <Icon icon="circle-check"/>,
														text: s_FREE_TRIAL_ACTIVATED,
													}
												})
												break
											default:
												resolve({})
												uc_setUserInterface_AlertDialogDisplay({
													display: true,
													alert: {
														color: "error",
														header: s_INVALID_ACCESS_CODE,
														icon: <Icon icon="circle-exclamation"/>,
														text: s_THE_ACCESS_CODE_YOU_ENTERED_IS_INVALID_OR_NO_LONGER_ACTIVE,
													}
												})
										}
									} else {
										resolve({ close_dialog: false })
									}
								})
							}
						}
					})

				} }
			>
				<Icon icon="key" className='tw-mr-2' />
				{ s_ENTER_ACCESS_CODE }
			</Button>

			return buttonJSX
		}

		const returnJSX_ComponentInnerContent = (): TsType_JSX => {
			let innerContentJSX = <></>
			let subscriptionStatus = returnApplicationSubscriptionStatus( uc_RootData_ClientUser )
			if( pr_showRegardlessOfSubscriptionStatus === true ){
				innerContentJSX = pr_pageContent
			} else if(
				subscriptionStatus === "active_prod_subscription" ||
				subscriptionStatus === "active_staging_subscription" ||
				subscriptionStatus === "active_prod_subscription_pending_cancellation" ||
				subscriptionStatus === "active_staging_subscription_pending_cancellation" ||
				subscriptionStatus === "active_free_trial"
			) {
				innerContentJSX = pr_pageContent
			} else if(
				subscriptionStatus === "expired_free_trial" ||
				subscriptionStatus === "no_subscription"
			){
				innerContentJSX =
				<Box className="tw-text-center tw-pt-8">
					<Icon
						icon="lock-keyhole"
						type="solid"
						className="tw-mb-8"
						sx={{ fontSize: "160px", color: themeVariables.warning_main }}
					/>
					<Typography variant='h4' className="tw-font-bold">{ s_NO_ACTIVE_SUBSCRIPTION }</Typography>
					<Button
						variant="contained"
						color="info"
						className="tw-mt-4 tw-mr-2"
						onClick={ () => { un_routerNaviation( ApplicationPages.UserSettingsPage.url() ) } }
					>
						<Icon icon="credit-card" className='tw-mr-2' />
						{ s_MANAGE_SUBSCRIPTION }
					</Button>
					{ returnJSX_FreeTrialInputButton() }
				</Box>
			} else if( subscriptionStatus === "unknown_subscription_status" ){
				innerContentJSX =
				<Box className="tw-text-center">
					<CircularProgress />
				</Box>
			}
			return innerContentJSX
		}

		const returnJSX_Component = (): TsType_JSX => {
			let authContentJSX = <Box></Box>
			if ( uc_RootData_AuthenticatedUser == null || uc_RootData_AuthenticatedUser.loggedIn == null ){
				authContentJSX =
				<Box className="tw-text-center tw-p-4">
					<CircularProgress />
				</Box>
			} else if ( uc_RootData_AuthenticatedUser != null && uc_RootData_AuthenticatedUser.loggedIn === false ){
				authContentJSX = <Navigate to={ApplicationPages.UnauthenticatedLoginPage.url()} replace />
			} else if ( !authorizedToViewPage ){
				authContentJSX = <Navigate to={ApplicationPages.HomePage.url()} replace />
			} else {
				authContentJSX =
				<Box component='div' className="tw-flex">
					{/* @ts-expect-error */}
					<AppBar position="fixed" open={ uc_UserInterface_NavBarDisplay } sx={ { backgroundColor: appBarBackgroundColor, boxShadow: "none" } } className="tw-pl-0" >
						{ topLoadingBar }
						<Box>
							<Box className="tw-inline-block tw-float-left">
								{ returnJSX_NavButton( uc_UserInterface_NavBarDisplay ) }
							</Box>
							<Typography
								className="tw-inline-block tw-ml-1 tw-mt-2"
								variant="h5"
								noWrap
								component="span"
								sx={{
									"color": logoPrimaryDarkColor,
									"fontWeight": 700,
									"marginTop": "0px",
									"width": "100%",
									"maxWidth": getHeaderTextMaxWidth( uc_UserInterface_NavBarDisplay )
								}}
							>
								{ pr_pageHeader }
							</Typography>


							{/* TODO - implement top right icons */}

							<Box className="tw-inline-block tw-float-right">
								{/* <IconButton sx={{ height: "40px", width: "40px", marginTop: "4px" }} className="tw-inline-block" onClick={ () => { console.log("TEST") } }>
									<NotificationsActiveIcon/>
								</IconButton> */}
								<Link to={ ApplicationMajorPages.UserSettingsPage.url() } >
									<IconButton sx={{ height: "40px", width: "40px", marginTop: "4px" }} className="tw-inline-block" onClick={ () => {  } }>
										<Icon icon="circle-user" />
									</IconButton>
								</Link>
							</Box>
						</Box>
						<Divider />
					</AppBar>
					<Box className="TEMP_nav">
						<Drawer
							sx={{
								width: drawerWidth,
								flexShrink: 0,
								'& .MuiDrawer-paper': {
									width: drawerWidth,
									boxSizing: 'border-box',
								},
							}}
							variant="persistent"
							anchor="left"
							open={ uc_UserInterface_NavBarDisplay }
						>
							<Box sx={{ background: 'linear-gradient(to bottom, ' + navGradientTopColor + ', ' + navGradientBottomColor + ')', height: "calc(100vh - 0px)", overflow: "scroll" }}>
								<DrawerHeader sx={ itemCategory }>
									<Box style={{width:'100%', textAlign: "left", height: "35px"}}>
										<LogoFull
											height="35px"
											mColor="#FFFFFF"
											yColor="#FB9B39"
											otherColor="#FFFFFF"
										/>
									</Box>
								</DrawerHeader>
								<List disablePadding>
									{Object.keys( sideBarNavObject ).map(( sectionKey, sectionIndex ) => (
										<Box component='div' key={ sectionKey }>
											{Object.keys( sideBarNavObject[ sectionKey ]["links"] ).map(( navLinkKey, navLinkName ) => (
												<Link to={ sideBarNavObject[ sectionKey ]["links"][ navLinkKey ].url } key={ sideBarNavObject[ sectionKey ]["links"][ navLinkKey ].key }>
													<ListItem disablePadding >
														<ListItemButton sx={ returnNavLinkStyle( checkIfNavSectionIsActive( sectionKey, navLinkKey ) ) }>
															<ListItemIcon sx={ returnNavIconStyle( checkIfNavSectionIsActive( sectionKey, navLinkKey ) ) }>
																<Icon size="lg" icon={ sideBarNavObject[ sectionKey ]["links"][ navLinkKey ].icon  } />
															</ListItemIcon>
															<ListItemText>{ sideBarNavObject[ sectionKey ]["links"][ navLinkKey ].name }</ListItemText>
														</ListItemButton>
													</ListItem>
												</Link>
											))}
											<Divider className="tw-my-2" sx={{ borderColor: "rgba(255, 255, 255, 0.12)" }} />
										</Box>
									))}
								</List>
							</Box>
						</Drawer>
					</Box>
					{/* @ts-expect-error */}
					<Main className={ getMainSectionPadding(uc_UserInterface_NavBarDisplay) } open={ uc_UserInterface_NavBarDisplay }>
						<Box sx={{ height: "50px" }}/>
						<Box className="tw-px-2 tw-py-2">
							{ returnJSX_SubscriptionStatusBanner()}
							{ returnJSX_ComponentInnerContent() }
						</Box>
						<NavOpenKeyListener openNav={ openNav } closeNav={ closeNav } navOpen={ uc_UserInterface_NavBarDisplay } />
					</Main>
				</Box>
			}
			return authContentJSX
		}

		// Render
		return <>{ returnJSX_Component() }</>
	}