//////////////////////////////////////////
//		  ooOOOO BOILERPLATE FILE		//
//		 oo		 _____					//
//		_I__n_n__||_|| ________			//
//	  >(_________|_7_|-|______|			//
//	   /o ()() ()() o   oo  oo			//
//////////////////////////////////////////

///////////////////////////////
// Description
///////////////////////////////

	/*
		DESCRIPTION / USAGE:
			External Requests contain all functions that interface with cloud function endpoints or external APIs

		TODO:
			[ ] Typescript - write better interfaces for cloud function errors and request params
			[ ] Typescript - 2 instances of TsType_Any
	*/


///////////////////////////////
// Imports
///////////////////////////////

import axios from 'axios'
import {
	getFunctions,
	httpsCallable
} from 'firebase/functions'
import {
	waitForAuthenticationVerification
} from 'rfbp_core/services/user_authentication'
import {
	TsInterface_UnspecifiedObject,
	TsType_Any,
	TsType_String
} from 'rfbp_core/typescript/global_types'

///////////////////////////////
// Typescript
///////////////////////////////


///////////////////////////////
// Variables
///////////////////////////////

	// Displayed Translatable Strings
	// { sort-start } - displayed text - scoped sort plugin

	// { sort-end } - displayed text

	// Cloud Functions
	const functions = getFunctions()


///////////////////////////////
// Functions
///////////////////////////////

	const parseCloudFunctionError = ( cloudFunctionError: TsType_Any ) => {
		if( typeof cloudFunctionError === "string" ){
			return { message: cloudFunctionError }
		} else if( typeof cloudFunctionError === "object" ){
			return cloudFunctionError
		} else if( cloudFunctionError != null && cloudFunctionError.message != null ) {
			return JSON.parse( cloudFunctionError.message )
		} else {
			return { message: "parseCloudFunctionError" }
		}
	}


///////////////////////////////
// Exports
///////////////////////////////

	export const cloudFunctionManageRequest = (functionName: TsType_String, requestParams: TsInterface_UnspecifiedObject) => {
		return new Promise(( resolve, reject ) => {
			// waitForAuthenticationVerification().finally(() => {
				const cloudFunction = httpsCallable( functions, functionName )
				cloudFunction( requestParams ).then(( res_CF ) => {
					resolve( res_CF.data )
				}).catch(( rej_CF ) => {
					reject( parseCloudFunctionError( rej_CF ) )
				})
			// })
		})
	}

	// export const cloudFunctionManageUserRequests = (requestParams: TsInterface_UnspecifiedObject) => {
		// return new Promise(( resolve, reject ) => {
		// 	let config = {
		// 		method: 'GET',
		// 		url: 'https://us-central1-loadcall-last-mile.cloudfunctions.net/manageUser',
		// 		params: requestParams
		// 	}
		// 	axios.request(config).then(( res_A ) => {
		// 		resolve(res_A)
		// 	}).catch((rej_A) => {
		// 		reject(rej_A)
		// 	})
		// })
	// }
